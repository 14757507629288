import { useMediaQuery, useTheme } from '@mui/material'
import { Dispatch, useEffect, useState, JSX, SetStateAction } from 'react'

import FormInputText from '../../../../../components/FormInputText'
import SubmitButton from '../../../../../components/SubmitButton'
import { GridWrapper } from '../../../../../components/wrapper'
import { validateInput } from '../../../../../utils/validateInput'
import { InputsGrid } from '../../../../Register/PatientForm/PatientFormStyle'

interface IBookigForm {
  disabledButton: boolean
  comments: string
  reference: string
  disabledForm: boolean
  onSubmit: (e: { preventDefault(): void }) => Promise<void>
  setComments: Dispatch<SetStateAction<string>>
  setReference: Dispatch<SetStateAction<string>>
}

function BookigForm({
  disabledButton,
  comments,
  reference,
  disabledForm,
  onSubmit,
  setComments,
  setReference,
}: IBookigForm): JSX.Element {
  const [validLength, setValidLength] = useState<boolean>(false)
  const [blur, setBlur] = useState<boolean[]>([false, false])
  const [requiredLength] = useState<number>(40)
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  useEffect(() => {
    setValidLength(comments.length >= requiredLength ? true : false)
  }, [comments, requiredLength])

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...blur]
    newValidationFocus[index] = true
    setBlur(newValidationFocus)
  }

  const validatorTextArea = (): string => {
    let textError = ''
    if (validLength) {
      textError = 'Máximo número de caracteres 40.'
    }
    if (!validateInput(comments) && comments.length === 0 && blur[0]) {
      textError = 'Campo requerido.'
    }
    return textError
  }
  const validatorErrorTextArea: string = validatorTextArea()

  return (
    <>
      <form
        data-testid="patient-form"
        onSubmit={onSubmit}
        style={{
          width: !isExtraSmallSize ? '60%' : '100%',
          marginRight: !isExtraSmallSize ? '3.125rem' : 0,
        }}
      >
        <InputsGrid item xs={12}>
          <GridWrapper item xs={12} md={4}>
            <FormInputText
              text="Motivo de la consulta"
              type="text"
              id="booking-comments"
              placeholder="Tengo dolor de cabeza"
              fullWidth
              name="comments"
              value={comments}
              onChange={(event) => setComments(event.target.value)}
              error={(comments.length === 0 && blur[0]) || validLength}
              onBlur={() => onValidationFocus(0)}
              texterror={validatorErrorTextArea}
              disabled={disabledForm}
              autoFocus
            />
          </GridWrapper>
          <GridWrapper item xs={12} md={4} mt={isExtraSmallSize ? 3 : 0}>
            <FormInputText
              text="¿Alguien te refirió a el Dr./a.?"
              type="text"
              id="booking-ref"
              placeholder="Juan Forero"
              fullWidth
              name="reference"
              value={reference}
              onChange={(event) => setReference(event.target.value)}
              disabled={disabledForm}
            />
          </GridWrapper>
          <GridWrapper item xs={12} md={4}>
            <SubmitButton
              id="button"
              type="submit"
              variant="contained"
              fullWidth
              text="Confirmar Cita"
              data-testid="ok-button"
              sx={{ margin: !isExtraSmallSize ? '29px' : '29px 0 0 0' }}
              disabled={comments.length < 5 || disabledButton}
            />
          </GridWrapper>
        </InputsGrid>
      </form>
    </>
  )
}

export default BookigForm
