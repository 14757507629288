import { AxiosError } from 'axios'

import { IResponseApi } from '../../dtos'
import { IResponseDoctorFeedback } from '../../dtos/DoctorFeedback'
import DoctorFeedbackDataSource from '../dataSource/DoctorFeedbackDataSource'
import { http } from '../Http'

export default class DoctorFeedbackMapper implements DoctorFeedbackDataSource {
  async getDoctorFeedback(doctorId: string): Promise<IResponseApi<IResponseDoctorFeedback>> {
    try {
      const { data } = await http.get<IResponseApi<IResponseDoctorFeedback>>(`/send_data/doctor_feedback/${doctorId}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: { message: (error as AxiosError).response?.statusText as string },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
