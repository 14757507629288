import { QualificationQuestionsResponse } from '../../infrastructure/dtos/QualificationQuestions'
import { QualificationQuestionsRepository } from '../repository/QualificationQuestionsRepository'

export interface QualificationQuestionsUseCase {
  invoke: () => Promise<QualificationQuestionsResponse>
}

export default class QualificationQuestions
  implements QualificationQuestionsUseCase
{
  private objectUseCase: QualificationQuestionsRepository

  constructor(_objectUseCase: QualificationQuestionsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(): Promise<QualificationQuestionsResponse> {
    return this.objectUseCase.getQualificationQuestions()
  }
}
