import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { JSX } from 'react'

import GoogleMap from './Components/GoogleMap'
import { Severity } from '../../../contexts/AuthReducer'
import { ISearchDoctorMap } from '../../../infrastructure/dtos/DoctorInfo'
import { IAddressPatient } from '../../Register/types'

interface DoctorResultsGoogleMapProps {
  results: ISearchDoctorMap[]
  query: string
  selectedAddress?: IAddressPatient
  userId?: string
  handleAlert?: (open: boolean, message: string, severity?: Severity) => void
  handleOpenDetails: (result: ISearchDoctorMap) => void
}

export default function DoctorResultsGoogleMap({
  results,
  query,
  selectedAddress,
  userId,
  handleAlert,
  handleOpenDetails,
}: DoctorResultsGoogleMapProps): JSX.Element {
  const render = (status: Status): JSX.Element => {
    switch (status) {
      case Status.LOADING:
        return <h2>Cargando Mapa...</h2>
      case Status.FAILURE:
        return <h2>Error</h2>
      case Status.SUCCESS:
        return <GoogleMap
          results={results}
          selectedAddress={selectedAddress}
          handleAlert={handleAlert}
          userId={userId}
          query={query}
          handleOpenDetails={handleOpenDetails}
        />
    }
  }

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
      libraries={['marker', 'places']}
      id="container_maps_wrapper"
      render={render}
    />
  )
}
