import { IApiDaysAvailability } from '../../infrastructure/dtos/OfficeDaysAvailability'
import { IApiMonthAvailability } from '../../infrastructure/dtos/OfficeMonthAvailability'
import { IApiResponseOffices, IOfficesProps } from '../../infrastructure/dtos/Offices'
import { OfficesRepository } from '../repository/OfficesRepository'

export interface GetOfficesUseCase {
  invoke: (params: IOfficesProps) => Promise<IApiResponseOffices>
  getMonthAvailability: (idOffice: string, idDoctor: string, officeType: string) => Promise<IApiMonthAvailability>
  getDaysAvailability(
    idOffice: string,
    idDoctor: string,
    officeType: string,
    date: string,
  ): Promise<IApiDaysAvailability>
}

export class GetOffices implements GetOfficesUseCase {
  private objectUseCase: OfficesRepository

  constructor(_objectUseCase: OfficesRepository) {
    this.objectUseCase = _objectUseCase
  }

  async getDaysAvailability(
    idOffice: string,
    idDoctor: string,
    officeType: string,
    date: string,
  ): Promise<IApiDaysAvailability> {
    return this.objectUseCase.getDaysAvailability(idOffice, idDoctor, officeType, date)
  }

  async getMonthAvailability(idOffice: string, idDoctor: string, officeType: string): Promise<IApiMonthAvailability> {
    return this.objectUseCase.getMonthAvailability(idOffice, idDoctor, officeType)
  }

  async invoke(params: IOfficesProps): Promise<IApiResponseOffices> {
    return this.objectUseCase.getOffices(params)
  }
}
