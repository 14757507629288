import { DoctorCM } from '../../../domain/useCase/DoctorCM'
import { IApiDoctorCM } from '../../../infrastructure/dtos/DoctorCM'
import DoctorCMMapper from '../../../infrastructure/http/mappers/DoctorCMMapper'
import { DoctorCMRepositoryImpl } from '../../../infrastructure/RepositoryImpl/DoctorCMRepositoryImpl'

export interface IDoctorCMService {
  status: number
  data: IApiDoctorCM | string
}

export async function getDoctorCMService(idBranchCM: string, query: string): Promise<IDoctorCMService> {
  try {
    const UseCaseDoctorCM = new DoctorCM(new DoctorCMRepositoryImpl(new DoctorCMMapper()))
    return {
      status: 1,
      data: await UseCaseDoctorCM.getDoctorCM(idBranchCM, query),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
