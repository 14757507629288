import { styled } from '@mui/material'

import { IconButtonWrapper } from '../wrapper'

export const CircularOutLineDarkButton = styled(IconButtonWrapper)(
  ({ theme }) => ({
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    '&:hover': { color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark },
    height: '43px',
    width: '43px',
    lineHeight: '13.5px',
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0
    }
  })
)
