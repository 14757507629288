import { useAuth0 } from '@auth0/auth0-react'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AccountContext, AuthContextType } from '../../contexts/AccountContext'
import { BodyPatientInfo, IUtilityPatientInfo } from '../../infrastructure/dtos/Patient'
import { IAddressPatientAux } from '../../views/Register/types'
import { GetPatientRegisterUtility } from '../Contracts/Utility/PatientUtility'

interface IUseSessionHook {
  fetchDoctorInfo: () => Promise<void>
  isAuthenticated: boolean
}

export function useSession(): IUseSessionHook {
  const { isAuthenticated, user, isLoading } = useAuth0()
  const { savePatientData } = useContext(AccountContext) as AuthContextType
  const navigate = useNavigate()
  const fetchDoctorInfo = async (): Promise<void> => {
    const userAuth0 = user?.sub as string
    const userId = userAuth0.split('|')
    const userBody: IUtilityPatientInfo = await GetPatientRegisterUtility(userId[1])

    if (userBody.status === 0) {
      navigate('/register')
    } else {
      const prevAddresses: IAddressPatientAux[] =
        (userBody?.data as BodyPatientInfo)?.locations_patient &&
        (userBody?.data as BodyPatientInfo)?.locations_patient
          ?.map((address) => ({
            id_location: address.id_location,
            address: address.address_locations_patient,
            name: address.name_locations_patient,
            coordinates: address.coordinates_medical_office,
          }))
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      const userIdObjectJSON = JSON.stringify({
        userId: (userBody?.data as BodyPatientInfo).user_id,
      })
      localStorage.setItem('userData', userIdObjectJSON)
      savePatientData({
        userId: (userBody?.data as BodyPatientInfo).user_id,
        name: (userBody?.data as BodyPatientInfo).name_patient,
        lastName: (userBody?.data as BodyPatientInfo).last_name_patient,
        document_type: (userBody?.data as BodyPatientInfo).document_type,
        idNumber: (userBody?.data as BodyPatientInfo).identification_patient,
        mobile: (userBody?.data as BodyPatientInfo).number_phone_patient,
        email: (userBody?.data as BodyPatientInfo).email_patient,
        birthdate: (userBody?.data as BodyPatientInfo).date_birth,
        gender: (userBody?.data as BodyPatientInfo).gender,
        addresses: prevAddresses,
        photo_patient: (userBody?.data as BodyPatientInfo)?.photo_patient,
        age: (userBody?.data as BodyPatientInfo)?.age,
      })
    }
  }

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        fetchDoctorInfo()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated])
  return {
    fetchDoctorInfo,
    isAuthenticated,
  }
}
