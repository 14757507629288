import { styled } from '@mui/material'

import TextFieldWrapper from '../wrapper/TextFieldWrapper'

export const InputField = styled(TextFieldWrapper)(({ theme }) => ({
  '& .MuiInputLabel-root': {},
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: theme.palette.common.white,
    fontSize: '1.125rem',
    color: theme.palette.text.secondary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.4375rem',
    boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.02)',
    '& .MuiInputBase-input': {
      paddingLeft: '30px' // Add your desired marginLeft (as paddingLeft) here
    },
    '&::placeholder': {
      marginLeft: '10px' // Add your desired marginLeft here
    }
  },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: theme?.palette?.primary?.main
    }
  },

  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& > fieldset': {
      border: 'none'
    }
  },

  '& fieldset': {
    borderColor: theme.palette.common.white
  }
}))
