import { ISaveRegisterPatient, IUtilityRegisterMedical } from '../../../infrastructure/dtos/PatientRegister'
import { PostRegisterPatientService } from '../Persistencia/RegisterPatientService'

export async function PostPatientRegisterUtility(params: ISaveRegisterPatient): Promise<IUtilityRegisterMedical> {
  try {
    const { data, status } = await PostRegisterPatientService(params)
    return {
      data: data.body,
      status: status !== 1 || data.statusCode !== 200 ? 0 : status,
    }
  } catch (error: unknown) {
    return {
      data: 'Ups! Tengo inconvenientes en este momento, por favor intenta más tarde.',
      status: 0,
    }
  }
}
