import { styled } from '@mui/material'

import SelectWrapper from '../wrapper/SelectWrapper'

export const SelectInput = styled(SelectWrapper)(({theme, open}) => ({
  borderRadius: open ? '42px 42px 0px 0px' : '42px',
  backgroundColor: theme.palette.common.white,
  width: '100%',
  boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.03)',
  '& fieldset': { border: 'none' },
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '5px'
    },
    '& img': {
      marginRight: '7px',
      width: '1.4rem',
      height: '1.4rem',
    }
  },
  '& .MuiSelect-iconOutlined': {
    borderRadius: '50%',
    width: '30px',
    backgroundColor: theme.palette.common.white,
  }
}))
