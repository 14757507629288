import { TextFieldProps } from '@mui/material'
import { JSX } from 'react'

import { InputField } from '../StyledComponents/InputField'
import { LabelInput } from '../StyledComponents/LabelInput'
import { LabelInputError } from '../StyledComponents/LabelInputError'

interface InputText {
  text?: string
  texterror?: string
  error?: boolean
}

export type InputCustomProps = TextFieldProps & InputText

const FormTextInput = (props: InputCustomProps): JSX.Element => {
  const { text, texterror, error, id } = props
  return (
    <>
      {text && <LabelInput>{text}</LabelInput>}

      <InputField {...props} />

      {error && (
        <LabelInputError data-testid={`${id}-text-error`} id={`label-${id}`}>
          {texterror}
        </LabelInputError>
      )}
    </>
  )
}

export default FormTextInput
