import { IResponseGetCalendarEvent } from '../../dtos/CalendarInfo'
import CalendarInfoDataSource from '../dataSource/CalendarInfoDataSource'
import { http } from '../Http'

export default class CalendarInfoMapper implements CalendarInfoDataSource {
  async getCalendarInfo(idDoctor: string, idOffice: string, duration: number): Promise<IResponseGetCalendarEvent> {
    const data = await http
      .get(`/send_data/schedule_availability_month/${idOffice}/${idDoctor}/1/${duration}`)
      .then((response) => {
        const { data } = response
        return data
      })
      .catch((error) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
            'Access-Control-Allow-Credentials': '',
          },
        }
        return data
      })
    return data
  }
}
