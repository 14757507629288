import { CardActions } from '@mui/material'
import { JSX } from 'react'
import { useNavigate } from 'react-router-dom'

import { OutLineButton } from '../../../../../../components/StyledComponents/OutLineButton'
import { BoxWrapper, CardContentWrapper, GridWrapper, TypographyWrapper } from '../../../../../../components/wrapper'
import { IPastAppointment } from '../../../../../../infrastructure/dtos/Appointments'
import { NewDateCard } from '../../NextDatesList/NextDateCard/NextDateCardStyle'
import { AvatarNextDate } from '../../NextDatesList/styles'
import { PrimaryDarkButton } from '../../PastMedicalAppointmentsList/PastAppointmentsListStyle'

interface IPastAppointmentsCard {
  data: IPastAppointment
  chooseRecipeModal: (item: IPastAppointment) => Promise<void>
  handleOpenModal: (item: IPastAppointment) => void
}

function PastAppointmentsCard({ data, chooseRecipeModal, handleOpenModal }: IPastAppointmentsCard): JSX.Element {
  const navigate = useNavigate()

  return (
    <NewDateCard sx={{ maxWidth: 390, marginBottom: '20px' }}>
      <GridWrapper container>
        <GridWrapper item xs={4} sx={{ paddingTop: '16px' }}>
          <BoxWrapper
            sx={{
              width: 70,
              height: 70,
              borderRadius: '50%',
              overflow: 'hidden',
              margin: 'auto',
            }}
          >
            <AvatarNextDate src={data.photo_doctor} sx={{ width: '100%', height: '100%' }} />
          </BoxWrapper>
        </GridWrapper>
        <GridWrapper item xs={7}>
          <CardContentWrapper sx={{ textAlign: 'left' }}>
            <TypographyWrapper variant="body2" color={data.assistance === 'NOT_ASSISTED' ? 'lightgray' : 'inherit'}>
              {`${data.name_doctor} ${data.last_name_doctor}`}
            </TypographyWrapper>
            <TypographyWrapper variant="body2" color={data.assistance === 'NOT_ASSISTED' ? 'lightgray' : 'inherit'}>
              {`${data.speciality_doctor}`}
            </TypographyWrapper>
            <TypographyWrapper variant="body2" color={data.assistance === 'NOT_ASSISTED' ? 'lightgray' : 'inherit'}>
              {`${data.appointment_date.split('-').reverse().join('/')} ${data.hour_from.split(':').join('h')}`}
            </TypographyWrapper>
            <TypographyWrapper variant="body2" color={data.assistance === 'NOT_ASSISTED' ? 'lightgray' : 'inherit'}>
              {`${data.reference_medical_office}`}
            </TypographyWrapper>
          </CardContentWrapper>
        </GridWrapper>
        <GridWrapper item xs={12}>
          <CardActions
            sx={{
              '& :not(:first-of-type)': {
                marginLeft: 'inherit',
              },
            }}
          >
            <PrimaryDarkButton
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                marginRight: '5px',
                fontSize: '12px',
                width: '362px',
                minWidth: 0,
              }}
              onClick={() => {
                navigate(`/appointment/booking/${data.user_id}`)
              }}
            >
              Agendar
            </PrimaryDarkButton>
            <OutLineButton
              variant="outlined"
              disabled={data.assistance === 'NOT_ASSISTED' || data.prescription === false}
              onClick={() => chooseRecipeModal(data)}
              sx={{ lineHeight: '13.5px', width: '362px' }}
            >
              Ver receta
            </OutLineButton>
            <OutLineButton
              variant="outlined"
              disabled={
                data.assistance === 'NOT_ASSISTED' ||
                data.qualification_status === true ||
                data.assistance === 'PENDING'
              }
              sx={{
                margin: 0,
              }}
              onClick={() => handleOpenModal(data)}
            >
              Calificar
            </OutLineButton>
          </CardActions>
        </GridWrapper>
      </GridWrapper>
    </NewDateCard>
  )
}

export default PastAppointmentsCard
