import { useAuth0 } from '@auth0/auth0-react'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AccountContext, AuthContextType } from '../../../contexts/AccountContext'
import { ISaveRegisterPatient, IUtilityRegisterMedical, LocationsPatient } from '../../../infrastructure/dtos/PatientRegister'
import { PostPatientRegisterUtility } from '../../../services/Contracts/Utility/RegisterPatientUtility'
import { IPatientInfo } from '../types'

interface ISaveProfileMedicalHook {
  SaveData: () => Promise<void>
  message: IMessage
  openAlert: boolean
  loading: boolean
  onOption: () => void
}
interface IMessage {
  title: string
  message: string
  navigate: boolean
}

interface IUserData {
  userId: string
  email: string
}

export function useRegisterPatient(patient: IPatientInfo): ISaveProfileMedicalHook {
  const { savePatientData } = useContext(AccountContext) as AuthContextType
  const { user } = useAuth0()
  const [openAlert, setOpen] = useState<boolean>(false)
  const [loading, setloading] = useState<boolean>(false)
  const [message, setMessage] = useState<IMessage>({
    message: '',
    title: 'Aviso',
    navigate: false,
  })
  const userAuth0 = user?.sub as string
  const userId = userAuth0.split('|')
  const userData: IUserData = {
    userId: userId[1],
    email: user?.email as string,
  }
  const navigate = useNavigate()

  const onOption = (): void => {
    if (message.navigate) {
      navigate('/Dashboard/inicio')
      setloading(false)
      window.location.reload()
    } else {
      setloading(false)
      setOpen(false)
    }
  }

  /**
   * Structuring Data medical
   * @returns dataSaveProfile: ISaveRegisterMedical
   */
  const StructuringData = (): ISaveRegisterPatient => {
    const profileDataToSend: LocationsPatient[] = []
    for (let i = 0; i < patient.addresses.length; i++) {
      profileDataToSend.push({
        address_locations_patient: patient.addresses[i].address,
        coordinates_medical_office: patient.addresses[i].coordinates,
        name_locations_patient: patient.addresses[i].name,
        id_location: '',
      })
    }

    const dataSaveProfile: ISaveRegisterPatient = {
      user_id: userData.userId,
      email_patient: userData.email,
      name_patient: patient.name,
      last_name_patient: patient.lastName,
      number_phone_patient: patient.mobile,
      identification_patient: patient.idNumber,
      locations_patient: profileDataToSend,
      date_birth: patient.birthdate,
      gender: patient.gender,
      document_type: patient.document_type,
    }
    return dataSaveProfile
  }
  /**
   *
   * @returns Result Data
   */
  const SaveData = async (): Promise<void> => {
    setloading(true)
    const dataSavePatient: ISaveRegisterPatient = StructuringData()

    const ResultAPISavePatient: IUtilityRegisterMedical = await PostPatientRegisterUtility(dataSavePatient)

    if (ResultAPISavePatient.status === 1) {
      setOpen(true)
      savePatientData({
        userId: dataSavePatient.user_id,
        name: dataSavePatient.name_patient,
        lastName: dataSavePatient.last_name_patient,
        document_type: dataSavePatient.document_type,
        idNumber: dataSavePatient.identification_patient,
        mobile: dataSavePatient.number_phone_patient,
        email: dataSavePatient.email_patient,
        birthdate: dataSavePatient.date_birth,
        gender: dataSavePatient.gender,
        addresses:
          dataSavePatient.locations_patient &&
          dataSavePatient.locations_patient.map((address) => ({
            id_location: address.id_location,
            address: address.address_locations_patient,
            name: address.name_locations_patient,
            coordinates: address.coordinates_medical_office,
          })),
      })
      setMessage({
        title: 'AVISO',
        message: ResultAPISavePatient.data,
        navigate: true,
      })
    } else {
      setOpen(true)
      setMessage({
        title: 'AVISO',
        message: ResultAPISavePatient.data,
        navigate: false,
      })
    }
  }

  return {
    SaveData,
    message,
    onOption,
    openAlert,
    loading,
  }
}
