import { GetAutocompleteSuggestions } from '../../../domain/useCase/SearchUseCase/SearchUseCase'
import { IResponseService } from '../../../infrastructure/dtos'
import { IAPIResponseAutocompleteSuggestions } from '../../../infrastructure/dtos/Search'
import { SearchMapper } from '../../../infrastructure/http/mappers/SearchMapper'
import { SearchRepositoryImpl } from '../../../infrastructure/RepositoryImpl/SearchRepositoryImpl/SearchRepositoryImpl'

export async function getAutocompleteSuggestionsService(
  query: string,
): Promise<IResponseService<IAPIResponseAutocompleteSuggestions>> {
  try {
    const useCase = new GetAutocompleteSuggestions(new SearchRepositoryImpl(new SearchMapper()))

    return {
      status: 1,
      data: await useCase.invoke(query),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: 500,
        body: {
          message: 'Internal Server Error',
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      },
    }
  }
}
