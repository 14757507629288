import { styled } from '@mui/material/styles'

import { AvatarWrapper, BadgeWrapper } from '../../../../components'

export const CustomAvatar = styled(AvatarWrapper)(() => ({
  border: '1px solid white',
  width: 120,
  height: 120,
}))

export const BadgeHeader = styled(BadgeWrapper)(({ theme }) => ({
  '& .MuiBadge-badge': {
    borderRadius: '50%',
    width: 30,
    height: 30,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    cursor: 'pointer',
  },
}))
