import { useContext, useEffect, useState } from 'react'

import { NavigateFunction, useNavigate } from 'react-router-dom'

import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'
import { IRecommendedDoctors } from '../../../../infrastructure/dtos/DoctorInfo'
import { RecommendedDoctorsUtility } from '../../../../services/Contracts/Utility/DoctorInfoUtility'

interface IUseSuggestedDoctors {
  doctors: IRecommendedDoctors[]
  loading: boolean
  navigate: NavigateFunction
}

export const useSuggestedDoctors = (idPatient: string): IUseSuggestedDoctors => {
  const navigate = useNavigate()
  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const [doctors, setDoctors] = useState<IRecommendedDoctors[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const fetchRecommendedDoctorsUtility = async (): Promise<void> => {
    try {
      setLoading(true)
      const { data, status } = await RecommendedDoctorsUtility(idPatient)
      if (status) {
        setDoctors(data as IRecommendedDoctors[])
      }
      setLoading(false)
    } catch (error: unknown) {
      setLoading(false)
      handleAlert(true, 'Error al obtener los doctores recomendados', 'error')
    }
  }

  useEffect(() => {
    if (doctors.length === 0) {
      fetchRecommendedDoctorsUtility()
    }
  }, [])
  return {
    doctors,
    loading,
    navigate,
  }
}
