import { useEffect, useRef, useState, JSX } from 'react'

import { MedicalOffice } from '../../../../../../../infrastructure/dtos/Offices'
import LocationPanelMap from '../LocationPanelMap'

interface MapConfig {
  center: google.maps.LatLngLiteral
  zoom: number
}

interface IMaps {
  offices: MedicalOffice[]
  selectedMarker: (officeId: string) => void
}

export default function Maps({ offices, selectedMarker }: IMaps): JSX.Element {
  const [map, setMap] = useState<google.maps.Map | undefined>()

  type latlng = {
    lat_min: number
    lat_max: number
    lng_min: number
    lng_max: number
  }

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const coordinates: latlng = offices?.reduce(
      (acc: latlng, cur) => {
        if (Number(cur.coordinates_medical_office.latitude) < acc.lat_min) {
          acc.lat_min = Number(cur.coordinates_medical_office.latitude)
        }
        if (Number(cur.coordinates_medical_office.latitude) > acc.lat_max) {
          acc.lat_max = Number(cur.coordinates_medical_office.latitude)
        }
        if (Number(cur.coordinates_medical_office.longitude) < acc.lng_min) {
          acc.lng_min = Number(cur.coordinates_medical_office.longitude)
        }
        if (Number(cur.coordinates_medical_office.longitude) > acc.lng_max) {
          acc.lng_max = Number(cur.coordinates_medical_office.longitude)
        }
        return acc
      },
      {
        lat_min: Infinity,
        lat_max: -Infinity,
        lng_min: Infinity,
        lng_max: -Infinity,
      },
    )

    if (ref.current) {
      const mapConfig: MapConfig = {
        center: {
          lat: (coordinates?.lat_max + coordinates?.lat_min || -1.831239) / 2.0,
          lng: (coordinates?.lng_max + coordinates?.lng_min || -78.183406) / 2.0,
        },
        zoom: 12,
      }

      const mapOptions: google.maps.MapOptions = {
        ...mapConfig,
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        streetViewControl: false,
        fullscreenControl: false,
        mapId: 'hjerwhjkhlk',
      }

      const googleMap = new google.maps.Map(ref.current, mapOptions)

      setMap(googleMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div ref={ref} id="map" style={{ width: '100%', height: '250px' }} data-testid="container-maps-div" />
      {map && (
        <LocationPanelMap map={map} offices={offices} selectedMarker={(officeId: string) => selectedMarker(officeId)} />
      )}
    </>
  )
}
