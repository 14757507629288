import { TemporaryPhoneCodeResponse } from '../../infrastructure/dtos/TemporaryPhoneVerification'
import { TemporaryPhoneVerificationRepository } from '../repository/TemporaryPhoneVerificationRepository'

export interface TemporaryPhoneVerificationUseCase {
  invoke: (
    user_id: string,
    number_phone: string
  ) => Promise<TemporaryPhoneCodeResponse>
}

export class PostCode implements TemporaryPhoneVerificationUseCase {
  private objectUseCase: TemporaryPhoneVerificationRepository

  constructor(_objectUseCase: TemporaryPhoneVerificationRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(user_id: string, number_phone: string): Promise<TemporaryPhoneCodeResponse> {
    return this.objectUseCase.postCode(user_id, number_phone)
  }
}
