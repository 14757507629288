import { styled } from '@mui/material'

import { BoxWrapper, FadeWrapper, GridWrapper, IconButtonWrapper, ModalWrapper, TypographyWrapper } from '../../../../../../../components'
import { LabelInput } from '../../../../../../../components/StyledComponents/LabelInput'

export const ModalOrder = styled(ModalWrapper)(() => ({
  height: '100%',
  overflowY: 'scroll',
}))

export const FadePatientOrderModal = styled(FadeWrapper)(({ theme }) => ({
  height: 'auto',
  [theme.breakpoints.only('xs')]: {
    width: '90%',
  },
}))

export const BoxModalOrder = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '140px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '850px',
  minHeight: '650px',
  bgcolor: theme.palette.background.paper,
  background: theme.palette.common.white,
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
  padding: '40px 30px',
}))

export const CloseButton = styled(IconButtonWrapper)((props) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px',
}))

export const OrderDisableTextInput = styled(GridWrapper)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1fr)',
  gridGap: '10px',
  backgroundColor: '#FAF9FD',
  borderRadius: '32px',
  padding: '20px',
  width: '100%',
  [theme.breakpoints.only('xs')]: {
    fontSize: '13px',
  },
}))

export const TypographyOrderNumber = styled(TypographyWrapper)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
  [theme.breakpoints.only('xs')]: {
    fontSize: '10px',
  },
}))

export const TypographyOrderDoctorName = styled(TypographyWrapper)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '35px',
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
  lineHeight: '1',
  [theme.breakpoints.only('xs')]: {
    fontSize: '17px',
  },
}))

export const TypographyOrderDoctorInfo = styled(TypographyWrapper)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 400,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
  lineHeight: '1.2',
  [theme.breakpoints.only('xs')]: {
    fontSize: '13px',
  },
}))

export const TypographyOrderLabel = styled(TypographyWrapper)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 400,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
  [theme.breakpoints.only('xs')]: {
    fontSize: '13px',
  },
}))

export const OrderDisabledInput = styled(GridWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '32px',
  [theme.breakpoints.only('xs')]: {
    fontSize: '13px',
  },
}))

export const LabelOrderInputTitle = styled(LabelInput)(() => ({
  fontWeight: 'bold',
}))

export const SeparatorOrder = styled(GridWrapper)(() => ({
  backgroundColor: '#D6D6D6',
}))
