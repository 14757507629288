import { Avatar, AvatarProps } from '@mui/material';
import React from 'react';



const AvatarWrapper: React.FC<AvatarProps> = ({ ...props }) => (
  <Avatar {...props} />
);

export default AvatarWrapper;
