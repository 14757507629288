import { ReschedulingAppointmentRepository } from '../../domain/repository/ReschedulingAppointmentRepository'
import {
  ReschedulingAppointment,
  ResponseReschedulingAppointment
} from '../dtos/ReschedulingAppointment'
import ReschedulingAppointmentDataSource from '../http/dataSource/ReschedulingAppointmentDataSource'

export class ReschedulingAppointmentRepositoryImpl
  implements ReschedulingAppointmentRepository
{
  dataSource: ReschedulingAppointmentDataSource

  constructor(_datasource: ReschedulingAppointmentDataSource) {
    this.dataSource = _datasource
  }

  saveReschedulingAppointment(
    dataReschedulingAppointment: ReschedulingAppointment
  ): Promise<ResponseReschedulingAppointment> {
    return this.dataSource.saveReschedulingAppointment(
      dataReschedulingAppointment
    )
  }
}
