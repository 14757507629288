import { Paper, PaperProps } from '@mui/material'
import React from 'react'

const PaperWrapper: React.ForwardRefExoticComponent<
  Omit<PaperProps, 'ref'> & React.RefAttributes<HTMLDivElement>
> = React.forwardRef<HTMLDivElement, PaperProps>((props, ref) => (
  <Paper {...props} ref={ref} />
))

export default PaperWrapper
