import React, { useEffect, useRef, useState, JSX } from 'react'

import { Severity } from '../../../../../contexts/AuthReducer'
import { ISearchDoctorMap } from '../../../../../infrastructure/dtos/DoctorInfo'
import { IAddressPatient } from '../../../../Register/types'
import LocationDoctor from '../LocationDoctor'

interface MapConfig {
  center: google.maps.LatLngLiteral
  zoom: number
}

interface GoogleMapProps {
  results: ISearchDoctorMap[]
  query: string
  selectedAddress: IAddressPatient | undefined
  userId?: string
  handleOpenDetails: (result: ISearchDoctorMap) => void
  handleAlert?: (open: boolean, message: string, severity?: Severity | undefined) => void
}

export default function GoogleMap({
  results,
  selectedAddress,
  query,
  userId,
  handleOpenDetails,
  handleAlert,
}: GoogleMapProps): JSX.Element {
  const [map, setMap] = useState<google.maps.Map | undefined>()
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      const mapConfig: MapConfig = {
        center: {
          lat: Number(selectedAddress?.coordinates.latitude) || -2.8949329,
          lng: Number(selectedAddress?.coordinates.longitude) || -79.0041135,
        },
        zoom: 13,
      }

      const mapOptions: google.maps.MapOptions = {
        ...mapConfig,
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        streetViewControl: false,
        fullscreenControl: false,
        mapId: 'hjerwhjkhlk', // TODO, set correct mapId
      }

      const googleMap = new google.maps.Map(ref.current, mapOptions)

      setMap(googleMap)
    }
  }, [])
  return (
    <>
      <div ref={ref} id="map" style={{ width: '100%', height: 'calc(100vh - 110px)' }} data-testid="container-maps-div" />
      {map
        && <LocationDoctor
          map={map}
          results={results}
          selectedPatientAddress={selectedAddress}
          handleAlert={handleAlert}
          userId={userId}
          query={query}
          handleOpenDetails={handleOpenDetails}
        />
      }
    </>
  )
}
