import { useEffect, useState, JSX, useContext } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { GridWrapper } from '../../components'
import CircularProgressWrapper from '../../components/wrapper/LoaderWrapper'
import { AccountContext, AuthContextType } from '../../contexts/AccountContext'
import { IAppointmentStatus, IConfirmAppointmentProps } from '../../infrastructure/dtos/Appointments'
import { confirmAppointmentUtility } from '../../services/Contracts/Utility/AppointmentsUtility'

export function ConfirmAppointment(): JSX.Element {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { appointmentId, rescheduleId } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [params] = useState<IConfirmAppointmentProps>({
    action: searchParams.get('action') as string,
    appointment_id: appointmentId as string,
    reschedule_id: rescheduleId as string,
  })

  const fetchConfirmAppointment = async (): Promise<void> => {
    if (params.action === 'reschedule') {
      navigate('/')
      return
    }
    try {
      const { data } = await confirmAppointmentUtility(params)
      navigate('/', { state: data as IAppointmentStatus })
    } catch (error) {
      handleAlert(true, 'Error al confirmar cita', 'error')
    }
  }

  useEffect(() => {
    if (params.action && params.appointment_id) {
      fetchConfirmAppointment()
    }
  }, [])
  return (
    <GridWrapper textAlign={'center'} marginTop={4}>
      <CircularProgressWrapper data-testid="loading-spinner" />
    </GridWrapper>
  )
}
