import { Close as CloseIcon } from '@mui/icons-material'
import { JSX } from 'react'

import { useModalConfirmPhoneNumber } from './hook'
import {
  BoxModal,
  CloseButton,
  ModalConfirm,
  ResendCodeButton,
  SubtitleTypographyModal,
  TitleTypographyModal,
} from './styles'
import { GridWrapper } from '../../../../components'
import FormTextInput from '../../../../components/FormInputText'
import { LabelInputError } from '../../../../components/StyledComponents/LabelInputError'
import SubmitButton from '../../../../components/SubmitButton'

interface IModalConfirmPhoneNumberProps {
  handleClose: () => void
  open: boolean
  newPhone?: string
}
export function ModalConfirmPhoneNumber({ handleClose, open, newPhone }: IModalConfirmPhoneNumberProps): JSX.Element {
  const {
    codeValue,
    countdownTimer,
    enabledResendCodeButton,
    message,
    phoneVerification,
    onChangeCodeValue,
    fetchResendCode,
    fetchVerifyCode,
  } = useModalConfirmPhoneNumber(newPhone, handleClose)

  return (
    <>
      <ModalConfirm onClose={handleClose} open={open}>
        <BoxModal>
          <GridWrapper container display={'flex'} justifyContent={'center'}>
            <TitleTypographyModal id="transition-modal-title" variant="h6">
              Confirma tu número de celular
            </TitleTypographyModal>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
          </GridWrapper>

          <GridWrapper
            container
            data-testid="code-section"
            marginTop={5}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <GridWrapper item>
              <SubtitleTypographyModal>
                Ingresa código de verificación enviado a tu número de teléfono
              </SubtitleTypographyModal>
            </GridWrapper>
            <GridWrapper item xs={6} marginTop={2}>
              <FormTextInput
                placeholder="Ingresa tu código aquí"
                id="code-sended"
                type="text"
                fullWidth
                value={codeValue}
                name="code-sended"
                onChange={(event) => onChangeCodeValue(event.target.value)}
                sx={{
                  '& .MuiInputBase-input': {
                    textAlign: 'center',
                    paddingLeft: 0,
                  },
                }}
              />
            </GridWrapper>
            <GridWrapper item marginTop={3}>
              <ResendCodeButton variant="text" onClick={fetchResendCode} disabled={!enabledResendCodeButton}>
                ¿No recibiste el código? Reenvíalo
              </ResendCodeButton>
            </GridWrapper>
            <GridWrapper item marginTop={0.5}>
              <LabelInputError sx={{ color: 'GrayText' }}>
                El código de verificación expira en: <b>{countdownTimer}</b>
              </LabelInputError>
            </GridWrapper>
          </GridWrapper>
          <GridWrapper container marginTop={5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {message && (
              <GridWrapper item marginBottom={0.5}>
                <LabelInputError sx={{ color: 'primary.dark' }} textAlign={'center'}>
                  {message}
                </LabelInputError>
              </GridWrapper>
            )}
            <SubmitButton
              id={'confirm-phone-number'}
              data-testid={'confirm-phone-number'}
              variant={'contained'}
              fullWidth
              type={'submit'}
              text={'Confirmar'}
              disabled={!codeValue || phoneVerification?.remaining_intents_validation === 0}
              onClick={fetchVerifyCode}
            />
          </GridWrapper>
        </BoxModal>
      </ModalConfirm>
    </>
  )
}
