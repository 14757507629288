import { styled } from '@mui/material/styles'

import { FormControlWrapper } from '../wrapper'

export const FormControlSelect = styled(FormControlWrapper)(() => ({
  minWidth: '70%',
  borderRadius: '2rem',
  height: '3rem',
  backgroundColor: 'white',
  '& fieldset': { border: 'none' },
}))
