import { IReminderResponse } from '../../infrastructure/dtos/Reminders';
import ReminderRepository from '../repository/ReminderRepository';

export interface ReminderUseCase {
  invoke: (patient_id: string) => Promise<IReminderResponse>;
}

export class Reminders implements ReminderUseCase {
  private objectUseCase: ReminderRepository;

  constructor(_objectUseCase: ReminderRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(patient_id: string): Promise<IReminderResponse> {
    return this.objectUseCase.getReminders(patient_id);
  }
}
