import { NewDateRepository } from '../../../domain/repository/NewDateRepository'
import { IApiResponseSaveNewDate, SaveNewDate } from '../../dtos/NewDate'
import NewDateDataSource from '../../http/dataSource/NewDateDataSource'

export class NewDateRepositoryImpl implements NewDateRepository {
  dataSource: NewDateDataSource

  constructor(_datasource: NewDateDataSource) {
    this.dataSource = _datasource
  }

  async postNewDate(
    dataNewDate: SaveNewDate
  ): Promise<IApiResponseSaveNewDate> {
    return this.dataSource.postNewDate(dataNewDate)
  }
}
