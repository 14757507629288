import { styled } from '@mui/material'

import { CardHeaderWrapper } from '../../../../../components'

export const StyledCardHeader = styled(CardHeaderWrapper)(() => ({
  paddingBlock: 8,
  '& .MuiTypography-h5': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  '& .MuiTypography-body1': {
    fontSize: '12px'
  }
}))

export const SubHeaderP = styled('p')(() => ({
  marginBlock: 0,
  lineHeight: 1.2,
}))
