import { LocationOnOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear'
import { ClickAwayListener } from '@mui/material'

import DoctorCM from './components/DoctorCM/DoctorCM'
import { LabelCMTextName, SubTitle, SubTitleCM } from './styles'
import { useCMResultsDetails } from './useCMResultsDetails';
import hospitalLogo from '../../../../../assets/img/hospital_logo.jpg'
import { AvatarWrapper, GridWrapper, IconButtonWrapper } from '../../../../../components/wrapper'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'
import { ISearchDoctorMap } from '../../../../../infrastructure/dtos/DoctorInfo';
import { DetailsSTyled } from '../../../SearchDoctor/styles';

export interface DoctorDetailsRightPanelProps {
  onClose(path?: string): void
  cmLocation: ISearchDoctorMap
  query: string
}

export default function CMResultsDetails({
  onClose,
  cmLocation,
  query,
}: DoctorDetailsRightPanelProps): JSX.Element {
  const { loading, doctors } = useCMResultsDetails(cmLocation.branch_id_cm as string, query)
  return (
    <DetailsSTyled data-testid="cm-results-details">
      <ClickAwayListener onClickAway={() => onClose()}>
        {!loading ? (
          <GridWrapper container>
            <GridWrapper item xs={12}>
              <IconButtonWrapper onClick={() => onClose()}>
                <ClearIcon sx={{ color: 'primary.main' }} />
              </IconButtonWrapper>
            </GridWrapper>
            <GridWrapper item xs={12}>
              <GridWrapper
                gap={2}
                display={'flex'}
                justifyContent={'center'}
                height={'auto'}
                padding={'1rem'}
              >
                <GridWrapper
                  item
                  xs={4}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginBottom={1}
                >
                  <AvatarWrapper 
                    src={cmLocation.photo || hospitalLogo}
                    sx={{
                      border: '1px solid white',
                      width: 120,
                      height: 120,
                    }}
                  />
                </GridWrapper>

                <GridWrapper item xs={7}>
                  <GridWrapper container direction={'column'}>
                    <LabelCMTextName data-testid="doctor-name">
                      <b>{cmLocation.name}</b>
                    </LabelCMTextName>
                    <SubTitleCM>{cmLocation.phone || '-'}</SubTitleCM>
                    <SubTitleCM>{cmLocation.email || '-'}</SubTitleCM>
                    <GridWrapper display={'flex'} alignItems={'center'} mt={1}>
                      <LocationOnOutlined sx={{ marginRight: '5px' }} />
                      <SubTitle><b>Dirección:</b> {cmLocation.direction_office}</SubTitle>
                    </GridWrapper>
                  </GridWrapper>
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>  

            <GridWrapper item xs={12} paddingX={2}>
              {doctors.map((doctor, index) => (
                <GridWrapper key={`doctor-cm-${index}`}>
                  <DoctorCM {...doctor} onClose={(path) => onClose(path)}/>
                </GridWrapper>
              ))}
            </GridWrapper>
          </GridWrapper>
        ) : (
          <GridWrapper item display={'Flex'} justifyContent={'center'} paddingTop={5}>
            <CircularProgressWrapper sx={{ color: 'primary.dark' }}/>
          </GridWrapper>
        )}
      </ClickAwayListener>
    </DetailsSTyled>
  )
}
