import { styled } from '@mui/material'

import { BadgeWrapper } from '../../../../../../../../../components'

export const BadgeRatedDoctorStyled = styled(BadgeWrapper)((props) => ({
  position: 'relative',
  '& .MuiBadge-badge': {
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    top: 16,
    borderRadius: '50%',
    width: 30,
    height: 30,
    backgroundColor: props.theme.palette.secondary.main,
    color: props.theme.palette.background.paper,
  },
}))

export const BadgeDoctorFriendsStyled = styled(BadgeWrapper)(({ theme }) => ({
  zIndex: -1,
  position: 'relative',
  '& .MuiBadge-badge': {
    position: 'absolute',
    bottom: 55,
    right: 3,
    borderRadius: '50%',
    width: 55,
    height: 30,
    fontWeight: '400',
    letterSpacing: -1,
    backgroundColor: 'rgba(0,0,0,0)',
    color: theme.palette.text.primary,
  },
}))
