import { GoogleMap, OverlayView } from '@react-google-maps/api'
import React, { useCallback, useContext } from 'react'

import { StyledRoomOutlinedIcon } from './styles'
import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'

const containerStyle = {
  width: '100%',
  height: '45vh',
}
interface ICoords {
  lat: number
  lng: number
}

interface IPropsMap {
  map: google.maps.Map | null
  markerPosition: google.maps.LatLng | null
  zoom: number
  address: string
  mapCenter: ICoords
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | null>>
  setMarkerPosition: React.Dispatch<React.SetStateAction<google.maps.LatLng | null>>
  setZoom: React.Dispatch<React.SetStateAction<number>>
  setAddress: React.Dispatch<React.SetStateAction<string>>
  setMapCenter: React.Dispatch<React.SetStateAction<ICoords>>
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  isLoaded: boolean
}
export const MapsGoogle = (props: IPropsMap): JSX.Element => {
  const {
    address,
    isLoaded,
    map,
    mapCenter,
    setSelected,
    markerPosition,
    setAddress,
    setMap,
    setMapCenter,
    setMarkerPosition,
    setZoom,
    zoom,
  } = props

  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const handleMapDrag = (): void => {
    if (map) {
      const newCenter: ICoords = {
        lat: map.getCenter()?.lat() as number,
        lng: map.getCenter()?.lng() as number,
      }
      setMapCenter(newCenter)
      setZoom(map.getZoom() || 0)
    }
  }

  const onLoad = (map: google.maps.Map): void => {
    setMap(map)
  }

  const onClick = (e: google.maps.MapMouseEvent): void => {
    // avoid directly mutating state
    setMarkerPosition(e.latLng)
    getAddressFromLatLng(e.latLng!)
    setSelected(true)
  }

  const getAddressFromLatLng = useCallback(
    async (latLng: google.maps.LatLng): Promise<void> => {
      try {
        const geocoder = new google.maps.Geocoder()
        await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
          geocoder.geocode({ location: latLng }, function (results, status) {
            if (status === 'OK') {
              setAddress(results === null ? '' : results[0].formatted_address)
            } else {
              reject(new Error(`Couldnt't find the location ${address}`))
            }
          })
        })
      } catch (error) {
        handleAlert(true, 'Error al obtener la dirección', 'error')
      }
    },
    [setAddress],
  )

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={zoom}
        onClick={onClick}
        onLoad={onLoad}
        onDragEnd={handleMapDrag}
        onZoomChanged={handleMapDrag}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
        }}
      >
        {markerPosition && (
          <OverlayView position={markerPosition} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <StyledRoomOutlinedIcon />
          </OverlayView>
        )}
      </GoogleMap>
    </>
  ) : (
    <div>Loading Map...</div>
  )
}

export default Map
