import { IReminderResponse, IReminderService, IReminderUtility } from '../../../infrastructure/dtos/Reminders'
import { getReminderService } from '../Persistencia/ReminderService'

export async function getReminders(patient_id: string): Promise<IReminderUtility> {
  try {
    const response: IReminderService = await getReminderService(patient_id)
    return {
      status: (response.data as IReminderResponse).statusCode === 200 ? 1 : 0,
      data: (response.data as IReminderResponse).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
