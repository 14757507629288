import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import React, { useContext, JSX } from 'react'
import { useNavigate } from 'react-router-dom'

import { LinkWrapper } from '../../../../../components'
import BreadcrumbsWrapper from '../../../../../components/wrapper/BreadcrumbsWrapper'
import {
  PatientAppointmentContext,
  PatientAppointmentInitState,
} from '../../../../../contexts/PatientAppointmentContext'

interface IBreadcrumbsBookingProps {
  isReschedule: boolean
}

function BreadcrumbsBooking({ isReschedule }: IBreadcrumbsBookingProps): JSX.Element {
  const { saveScheduleSelected } = useContext(PatientAppointmentContext)

  const navigate = useNavigate()

  const handleGoBack = (): void => {
    navigate(isReschedule ? '/dates' : '/appointment')
    saveScheduleSelected(PatientAppointmentInitState)
  }

  const titleLink: string[] = [
    isReschedule ? 'Citas' : 'Resultados de búsqueda',
    isReschedule ? 'Reagendar' : 'Nueva cita',
  ]

  return (
    <BreadcrumbsWrapper
      separator={<ArrowBackIosIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ margin: '10px 0 30px 0', color: 'primary.dark' }}
    >
      <LinkWrapper underline="hover" color="inherit" onClick={handleGoBack} role="link" aria-current="page">
        {titleLink[0]}
      </LinkWrapper>
      <LinkWrapper underline="hover" color="inherit" aria-current="page" role="link">
        {titleLink[1]}
      </LinkWrapper>
    </BreadcrumbsWrapper>
  )
}

export default BreadcrumbsBooking
