import { styled } from '@mui/material'

import { GridItem } from '../../../../components/GridItem/GridItem'

export const GridMap = styled(GridItem)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    '&.MuiGrid-item': {
      paddingTop: 16,
    },
  },
}))
