import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IVerifyTemporaryPhoneParams,
} from '../../infrastructure/dtos/PhoneVerification';
import VerifyTemporaryPhoneRepository from '../repository/VerifiyTemporaryPhoneRepository';

export interface VerifyTemporaryPhoneUseCase {
  invoke: (
    params: IVerifyTemporaryPhoneParams,
  ) => Promise<IApiResponsePhoneVerification<IPhoneVerification>>;
}

export class VerifyTemporaryCode implements VerifyTemporaryPhoneUseCase {
  private objectUseCase: VerifyTemporaryPhoneRepository;

  constructor(_objectUseCase: VerifyTemporaryPhoneRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(
    params: IVerifyTemporaryPhoneParams,
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.objectUseCase.verifyTemporaryPhone(params);
  }
}
