import { styled } from '@mui/material/styles'

import { ButtonWrapper } from '../wrapper'

export const ButtonBack = styled(ButtonWrapper)((props) => ({
  borderRadius: '100%',
  backgroundColor: props.theme.palette?.common?.white,
  color: props.theme.palette?.primary?.dark,
  textTransform: 'none',
  height: '60px',
  boxShadow: '2px 2px 8px 4px rgba(0, 0, 0, 0.05)',
}))
