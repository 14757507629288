import { IResponseApi } from '../../infrastructure/dtos';
import {
  IApiResponseDoctorInfo,
  IRecommendedDoctors,
  ISearchDoctorParams,
  ISuggestedDoctorsParams,
} from '../../infrastructure/dtos/DoctorInfo';
import { DoctorInfoRepository } from '../repository/DoctorInfoRepository';

export interface GetDoctorInfoUseCase {
  invoke: (idDoctor: string) => Promise<IApiResponseDoctorInfo>;
}

export interface SearchDoctorUseCase {
  invoke: (params: ISearchDoctorParams) => Promise<IApiResponseDoctorInfo>;
}

export interface GetSuggestedDoctorsUseCase {
  invoke: (params: ISuggestedDoctorsParams) => Promise<IApiResponseDoctorInfo>;
}

export interface GetRecommendedDoctorsUseCase {
  invoke: (
    patient_id: string
  ) => Promise<IResponseApi<IRecommendedDoctors[] | string>>;
}
export class GetDoctoInfo implements GetDoctorInfoUseCase {
  private objectUseCase: DoctorInfoRepository;

  constructor(_objectUseCase: DoctorInfoRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(idDoctor: string): Promise<IApiResponseDoctorInfo> {
    return this.objectUseCase.getDoctorInfo(idDoctor);
  }
}

export interface GetDoctorInfoUseCase {
  invoke: (idDoctor: string) => Promise<IApiResponseDoctorInfo>;
}

export class SearchDoctor implements SearchDoctorUseCase {
  private objectUseCase: DoctorInfoRepository;

  constructor(_objectUseCase: DoctorInfoRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(params: ISearchDoctorParams): Promise<IApiResponseDoctorInfo> {
    return this.objectUseCase.searchDoctor(params);
  }
}

export class GetSuggestedDoctor implements GetSuggestedDoctorsUseCase {
  private objectUseCase: DoctorInfoRepository;

  constructor(_objectUseCase: DoctorInfoRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(params: ISuggestedDoctorsParams): Promise<IApiResponseDoctorInfo> {
    return this.objectUseCase.getSuggestedDoctors(params);
  }
}

export class GetRecommendedDoctors implements GetRecommendedDoctorsUseCase {
  private objectUseCase: DoctorInfoRepository;

  constructor(_objectUseCase: DoctorInfoRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(patient_id: string): Promise<IResponseApi<string | IRecommendedDoctors[]>> {
    return this.objectUseCase.getRecommendedDoctors(patient_id);
  }
}
