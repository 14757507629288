import { JSX } from 'react'

import { TypographyWrapperValidationPhoneFalse } from '../views/Register/PatientForm/PatientFormStyle'

export function validatePhoneNumber(number: string): boolean {
  const regex = /^09\d{8}$/
  return regex.test(number)
}

export function validateAndDisplayPhone(mobile: string, message: string): JSX.Element | null {
  // Assuming the phone number should have 10 digits and start with 0
  const regex = /^09\d{8}$/
  /* If the phone number is invalid and not empty,
   return the typography component */
  if (!regex.test(mobile) && mobile?.length > 0) {
    return <TypographyWrapperValidationPhoneFalse>{message}</TypographyWrapperValidationPhoneFalse>
  }
  // Otherwise, return null or an empty component
  return null
}
