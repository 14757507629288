import { styled } from '@mui/material'

import { GridWrapper } from '../../../../components/wrapper'

export const GridResponsiveDoctor = styled(GridWrapper)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '10px 20px',
  margin: '20px 0 40px 0',
  backgroundColor: theme.palette.tertiary?.light,
  borderRadius: '3rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center'
}))
