import { IApiDoctorCM } from '../../infrastructure/dtos/DoctorCM'
import DoctorCMRepository from '../repository/DoctorCMRepository'

export interface DoctorCMUseCase {
  getDoctorCM: (idBranchCM: string, query: string) => Promise<IApiDoctorCM>
}

export class DoctorCM implements DoctorCMUseCase {
  private objectUseCase: DoctorCMRepository

  constructor(_objectUseCase: DoctorCMRepository) {
    this.objectUseCase = _objectUseCase
  }

  async getDoctorCM(idBranchCM: string, query: string): Promise<IApiDoctorCM> {
    return this.objectUseCase.getDoctorCM(idBranchCM, query)
  }
}
