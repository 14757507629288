import React, { useEffect, JSX } from 'react'
import { Carousel } from 'react-responsive-carousel'

import PastAppointmentsCard from './components/PastAppointmentsCard'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'
import { IPastAppointment } from '../../../../../infrastructure/dtos/Appointments'
import { IRecipePrescription } from '../../../../../infrastructure/dtos/Prescription'
import { BoxLabel } from '../../../../Home/News/NewsStyles'
import QualifyDoctorModal from '../../../../Home/Pendings/components/QualifyDoctorModal'
import { LoadingRecipesListModal } from '../PastMedicalAppointmentsList/components/LoadingRecipes/LoadingRecipe'
import Recipe from '../PastMedicalAppointmentsList/components/Recipe/Recipe'
import { ShowRecipesListModal } from '../PastMedicalAppointmentsList/components/ShowRecipeList/ShowRecipesList'
import { useShowRecipesListModal } from '../PastMedicalAppointmentsList/components/ShowRecipeList/useShowRecipesListModal'
import { usePastAppointmentsList } from '../PastMedicalAppointmentsList/hooks/PastAppointmentsListHook'

function PastAppointmentsCarousel(): JSX.Element {
  const {
    message,
    pastAppointments,
    loading,
    openModal,
    handleCloseModal,
    pastId,
    fetchPastAppointments,
    handleOpenModal,
  } = usePastAppointmentsList()

  const {
    handleOpenRecipesListModal,
    openRecipesListModal,
    openLoadingRecipesModal,
    handleOpenRecipe,
    openRecipe,
    appointmentDetails,
    prescriptionIndex,
    fetchAppointmentRecipes,
    handleOpenLoadingRecipesModal,
    handleSetPrescriptionIndex,
  } = useShowRecipesListModal()

  const chooseRecipeModal = async (item: IPastAppointment): Promise<void> => {
    handleOpenLoadingRecipesModal(true)
    await fetchAppointmentRecipes(item.appointment_id)
  }

  const onSetPrescriptionIndex = (index: number): void => {
    handleSetPrescriptionIndex(index)
    handleOpenRecipesListModal(false)
    handleOpenRecipe(true)
  }

  useEffect(() => {
    handleOpenLoadingRecipesModal(false)
    if (appointmentDetails) {
      if (appointmentDetails?.prescription.length === 1) {
        handleSetPrescriptionIndex(0)
        handleOpenRecipe(true)
      } else {
        handleOpenRecipesListModal(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetails])

  return (
    <>
      {Boolean(message) ? (
        ''
      ) : (
        <>
          {!loading ? (
            pastAppointments.length > 0 && (
              <>
                {Boolean(message) ? '' : <BoxLabel>Citas pasadas</BoxLabel>}
                <Carousel
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  renderItem={(item: React.ReactNode) => <div style={{ margin: '0 3px 0 3px' }}>{item}</div>}
                  renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const indicatorStyle = {
                      display: 'inline-block',
                      margin: '0 4px',
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      backgroundColor: isSelected ? '#007aff' : '#c7c7cc',
                    }
                    return (
                      <li
                        style={indicatorStyle}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        aria-label={`${label} ${index + 1}`}
                      />
                    )
                  }}
                >
                  {pastAppointments.map((item: IPastAppointment, index: number) => (
                    <PastAppointmentsCard
                      data={item}
                      chooseRecipeModal={chooseRecipeModal}
                      key={index}
                      handleOpenModal={() => handleOpenModal(item)}
                    />
                  ))}
                </Carousel>
                {openLoadingRecipesModal && (
                  <LoadingRecipesListModal
                    handleOpenLoadingRecipesModal={handleOpenLoadingRecipesModal}
                    open={openLoadingRecipesModal}
                  />
                )}

                {openRecipesListModal && (
                  <ShowRecipesListModal
                    appointmentDetails={appointmentDetails}
                    handleOpenRecipesListModal={handleOpenRecipesListModal}
                    open={openRecipesListModal}
                    handleSetPrescriptionIndex={onSetPrescriptionIndex}
                  />
                )}
                {openRecipe && (
                  <Recipe
                    appointmentDetails={appointmentDetails as IRecipePrescription}
                    handleOpenRecipe={handleOpenRecipe}
                    open={openRecipe}
                    prescriptionIndex={prescriptionIndex as number}
                  />
                )}
                {openModal && (
                  <QualifyDoctorModal
                    handleClose={handleCloseModal}
                    open={openModal}
                    pending={pastId}
                    fetchPendingsTasks={fetchPastAppointments}
                  />
                )}
              </>
            )
          ) : (
            <CircularProgressWrapper data-testid="loading-spinner" />
          )}
        </>
      )}
    </>
  )
}

export default PastAppointmentsCarousel
