import { AxiosError } from 'axios'

import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IVerifyTemporaryPhoneParams,
} from '../../dtos/PhoneVerification'
import VerifyTemporaryPhoneDataSource from '../dataSource/VerifyTemporaryPhoneDataSource'
import { http } from '../Http'

export default class VerifyTemporaryPhoneMapper implements VerifyTemporaryPhoneDataSource {
  async verifyTemporaryPhone(
    params: IVerifyTemporaryPhoneParams,
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    try {
      const data = await http.post<IApiResponsePhoneVerification<IPhoneVerification>>(
        'save_data/verify_temporary_phone',
        { ...params },
      )
      return data.data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
