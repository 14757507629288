import { debounce } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'

import { getAutocompleteSuggestionsUtility } from '../../services/Contracts/Utility/SearchUtility'

interface IUseSearchAutocomplete {
  options: string[]
}

interface IUseSearchAutocompleteProps {
  query: string
}

export const useSearchAutocomplete = ({ query }: IUseSearchAutocompleteProps): IUseSearchAutocomplete => {
  const [options, setOptions] = useState<string[]>([])

  const fetchAutocompleteSuggestionsUtility = async (value: string): Promise<void> => {
    const { data } = await getAutocompleteSuggestionsUtility(value)
    setOptions(data.data)
  }

  const searchDelayed = useMemo(() => debounce(fetchAutocompleteSuggestionsUtility, 600), [])

  useEffect(() => {
    if (query.length > 0) {
      searchDelayed(query)
    } else {
      setOptions([])
    }
  }, [query])

  return { options }
}
