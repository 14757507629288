import { Breadcrumbs, BreadcrumbsProps } from '@mui/material'
import React from 'react'

const BreadcrumbsWrapper: React.ForwardRefExoticComponent<
  Omit<BreadcrumbsProps, 'ref'> & React.RefAttributes<HTMLElement>
> = React.forwardRef<HTMLElement, BreadcrumbsProps>((props, ref) => (
  <Breadcrumbs {...props} ref={ref} />
))

export default BreadcrumbsWrapper
