import { styled } from '@mui/material/styles'

import {
  BoxWrapper,
  ButtonWrapper,
  GridWrapper,
  IconButtonWrapper,
  ModalWrapper,
  TypographyWrapper,
} from '../../../../../components'

export const ModalConfirmPhone = styled(ModalWrapper)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  overflowY: 'auto',
  height: 'auto',
  [theme.breakpoints.only('xs')]: {
    marginBottom: '56px',
  },
}))

export const BoxModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '120px',
  width: '600px',
  minHeight: '500px',
  bgcolor: theme.palette.background.paper,
  background: theme.palette.common.white,
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
  padding: '40px 60px',
  [theme.breakpoints.only('sm')]: {
    width: '80%',
    padding: '40px',
  },
  [theme.breakpoints.only('xs')]: {
    width: '95%',
    padding: '30px',
  },
}))

export const TitleTypographyModal = styled(TypographyWrapper)((props) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '17px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: props.theme.palette.primary.dark,
}))

export const EditPhoneButton = styled(ButtonWrapper)(() => ({
  border: 0,
  flexDirection: 'column',
  lineHeight: '2',
  textTransform: 'capitalize',
  borderRadius: '2.625rem',
  paddingBottom: 0,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
}))

export const SubtitleTypographyModal = styled(TypographyWrapper)(() => ({
  opacity: 1,
  color: 'rgba(0,0,0,1)',
  fontFamily: 'Helvetica',
  fontSize: '14px',
  fontWeight: 600,
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
}))

export const ResendCodeButton = styled(ButtonWrapper)(() => ({
  textTransform: 'initial',
}))

export const CloseButton = styled(IconButtonWrapper)(({ theme }) => ({
  position: 'absolute',
  right: 50,
  top: 30,
  color: theme.palette.primary.dark,
  [theme.breakpoints.only('xs')]: {
    right: 20,
  },
}))

export const GridInputCode = styled(GridWrapper)(({ theme }) => ({
  marginTop: 16,
  [theme.breakpoints.only('xs')]: {
    width: '100%',
  },
}))
