import {
  IApiResponsePatientInfo,
  PatientBody,
  PatientUpdateResponseBody,
  DataPatient
} from '../../../infrastructure/dtos/Patient'
import { PatientInfoRepository } from '../../repository/PatientRepository'

export interface GetPatientInfoUseCase {
  invoke: (idPatient: string) => Promise<IApiResponsePatientInfo>
}

export interface UpdatePatientInfoUseCase {
  invoke: (
    params: PatientBody,
    idPatient: string
  ) => Promise<PatientUpdateResponseBody>
}

export interface GetPatientbyCiUseCase {
  invoke: (ciPatient: string) => Promise<DataPatient>
}

export class GetPatientInfo implements GetPatientInfoUseCase {
  private objectUseCase: PatientInfoRepository

  constructor(_objectUseCase: PatientInfoRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(idPatient: string): Promise<IApiResponsePatientInfo> {
    return this.objectUseCase.getPatientInfo(idPatient)
  }
}

export class UpdatePatientInfo implements UpdatePatientInfoUseCase {
  private objectUseCase: PatientInfoRepository

  constructor(_objectUseCase: PatientInfoRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: PatientBody, idPatient: string): Promise<PatientUpdateResponseBody> {
    return this.objectUseCase.updatePatientInfo(params, idPatient)
  }
}

export class GetPatientInfoByCi implements GetPatientbyCiUseCase {
  private objectUseCase: PatientInfoRepository

  constructor(_objectUseCase: PatientInfoRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(ciPatient: string): Promise<DataPatient> {
    return this.objectUseCase.getPatientInfoByCi(ciPatient)
  }
}
