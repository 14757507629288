import { IApiDoctorCM, IDoctorCMResponse } from '../../../infrastructure/dtos/DoctorCM'
import { getDoctorCMService } from '../Persistencia/DoctorCMService'

export interface IDoctorCMUtility {
  status: number
  data: IDoctorCMResponse | string
}

export async function DoctorCMUtility(idBranchCM: string, query: string): Promise<IDoctorCMUtility> {
  try {
    const { data, status } = await getDoctorCMService(idBranchCM, query)
    if (status !== 1 || (data as IApiDoctorCM).statusCode !== 200) {
      return {
        data: ((data as IApiDoctorCM).body as IDoctorCMResponse).message as string,
        status: 0,
      }
    }
    return {
      status: 1,
      data: (data as IApiDoctorCM).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: 'Ups! Tengo inconvenientes en este momento, por favor intenta más tarde.',
    }
  }
}
