import { FollowRepository } from '../../domain/repository/FollowRepository'
import { SaveDataFollow, IApiResponseSaveDataFollow } from '../dtos/Follow'
import FollowDataSource from '../http/dataSource/FollowDataSource'

export class FollowRepositoryImpl implements FollowRepository {
  dataSource: FollowDataSource

  constructor(_datasource: FollowDataSource) {
    this.dataSource = _datasource
  }

  saveFollow(params: SaveDataFollow): Promise<IApiResponseSaveDataFollow> {
    return this.dataSource.saveFollow(params)
  }
}
