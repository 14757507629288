import { DoctorFeedbackRepository } from '../../domain/repository/DoctorFeedbackRepository';
import { IResponseApi } from '../dtos';
import { IResponseDoctorFeedback } from '../dtos/DoctorFeedback';
import DoctorFeedbackDataSource from '../http/dataSource/DoctorFeedbackDataSource';

export class DoctorFeedbackRepositoryImpl implements DoctorFeedbackRepository {
  dataSource: DoctorFeedbackDataSource;

  constructor(_dataSource: DoctorFeedbackDataSource) {
    this.dataSource = _dataSource;
  }

  async getDoctorFeedback(
    doctorId: string
  ): Promise<IResponseApi<IResponseDoctorFeedback>> {
    return this.dataSource.getDoctorFeedback(doctorId);
  }
}
