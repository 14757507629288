import React, { useContext, useState, JSX } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useNavigate } from 'react-router-dom'

import NextDatesCarouselCard from './components/NextDatesCarouselCard'
import { GridWrapper } from '../../../../../components'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'
import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import { INextAppointment } from '../../../../../infrastructure/dtos/Appointments'
import { BoxLabel } from '../../../../Home/News/NewsStyles'
import { CancelAppointment } from '../NextDatesList/CancelAppointment/CancelAppointment'
import { CancelReasons } from '../NextDatesList/CancelAppointment/modals/CancelReasons/CancelReasons'
import { useCancelReasonsHook } from '../NextDatesList/CancelAppointment/modals/CancelReasons/useCancelReasons'
import { useCancelAppointment } from '../NextDatesList/CancelAppointment/useCancelAppointment'
import ConfirmAppointments from '../NextDatesList/ConfirmAppointments/ConfirmAppointments'
import { useConfirm } from '../NextDatesList/ConfirmAppointments/hooks/useConfirmModal'
import { useNewDatesList } from '../NextDatesList/NewDatesLitsHook'

function NextDatesCarousel(): JSX.Element {
  const [appointmentToModal, setAppointmentToModal] = useState<INextAppointment>({} as INextAppointment)
  const { openCancelModal, handleOpenCancelAppointment } = useCancelAppointment()
  const navigate = useNavigate()
  const { anchorEl, nextAppointments, message, loading, handleClick, handleClose, fetchNextAppointments } =
    useNewDatesList()

  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const { handleCloseConfirm, handleOpenConfirm, openConfirm } = useConfirm()

  const { openCancelReasons, handleOpenCancelReasons, handleCloseCancelReasons } = useCancelReasonsHook()

  const handleShowCancelReasons = (): void => {
    handleOpenCancelAppointment(false)
    handleOpenCancelReasons()
  }

  const handleClickOption = (option: string, appointment: INextAppointment, index: number): void => {
    if (appointment.assistance === 'PENDING') {
      switch (option) {
        case 'Reagendar':
          navigate(`/appointment/booking/${appointment.user_id}/${appointment.appointment_id}`)
          break
        case 'Cancelar':
          handleOpenCancelAppointment(true)
          setAppointmentToModal(appointment)
          break
        default:
          handleClose(index)
      }
    } else {
      const message = appointment.assistance === 'ATTENDED' ? 'asistida' : 'no asistida'
      handleAlert(true, `¡Lo siento! La cita ya fue marcada como ${message}.`, 'error')
    }
  }

  return (
    <>
      {Boolean(message) ? (
        ''
      ) : (
        <>
          {!loading ? (
            nextAppointments.length > 0 && (
              <>
                <BoxLabel data-testid="next-dates-title">Tus próximas citas</BoxLabel>
                <GridWrapper data-testid="next-dates-carousel">
                  <Carousel
                    showThumbs={false}
                    showArrows={false}
                    showStatus={false}
                    renderItem={(item: React.ReactNode) => <div style={{ margin: '0 3px 0 3px' }}>{item}</div>}
                    renderIndicator={(onClickHandler, isSelected, index, label) => {
                      const indicatorStyle = {
                        display: 'inline-block',
                        margin: '0 8px',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        backgroundColor: isSelected ? '#007aff' : '#c7c7cc',
                      }
                      return (
                        <li
                          style={indicatorStyle}
                          onClick={onClickHandler}
                          onKeyDown={onClickHandler}
                          value={index}
                          key={index}
                          role="button"
                          tabIndex={0}
                          aria-label={`${label} ${index + 1}`}
                        />
                      )
                    }}
                  >
                    {nextAppointments.map((item: INextAppointment, index: number) => (
                      <NextDatesCarouselCard
                        data={item}
                        handleClickOption={handleClickOption}
                        handleClick={handleClick}
                        key={index}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        fetchNextAppointments={fetchNextAppointments}
                        index={index}
                        handleOpenConfirm={handleOpenConfirm}
                        setAppointmentToModal={setAppointmentToModal}
                        data-testid={`carousel-card-${index}`}
                      />
                    ))}
                  </Carousel>
                  {openConfirm && (
                    <ConfirmAppointments
                      handleClose={handleCloseConfirm}
                      open={openConfirm}
                      appointment={appointmentToModal}
                      fetchAppointments={fetchNextAppointments}
                    />
                  )}
                  {openCancelModal && (
                    <CancelAppointment
                      handleClose={() => handleOpenCancelAppointment(false)}
                      open={openCancelModal}
                      handleShowCancelReasons={handleShowCancelReasons}
                    />
                  )}
                  {openCancelReasons && (
                    <CancelReasons
                      handleClose={() => handleCloseCancelReasons()}
                      open={openCancelReasons}
                      fetchAppointments={fetchNextAppointments}
                      appointment={appointmentToModal}
                    />
                  )}
                </GridWrapper>
              </>
            )
          ) : (
            <CircularProgressWrapper data-testid="loading-spinner" />
          )}
        </>
      )}
    </>
  )
}

export default NextDatesCarousel
