import ClearIcon from '@mui/icons-material/Clear'
import { ClickAwayListener } from '@mui/material'

import PanelMap from './components/PanelMap'
import { RecommendedDoctors } from './components/RecommendedDoctors'
import { Skills } from './components/Skills/Skills'
import { useDoctorDetailsRightPanel } from './Hook'
import {
  GridList,
  LabelTextName,
  ListContent,
  ListTitle,
  SubTitle,
  SubTitleSpecialty,
  TypoDetailsInfo,
  TypoDetailsTitle,
} from './styles'
import { FollowButton } from '../../../../../components/FollowButton/FollowButton'
import { LabelInputError } from '../../../../../components/StyledComponents/LabelInputError'
import { Title } from '../../../../../components/StyledComponents/Title'
import SubmitButton from '../../../../../components/SubmitButton'
import { DividerWrapper, GridWrapper, IconButtonWrapper } from '../../../../../components/wrapper'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'
import { Severity } from '../../../../../contexts/AuthReducer'
import { IParamsGetNextAppointmentHour } from '../../../../../infrastructure/dtos/Calendar'
import { MedicalOffice } from '../../../../../infrastructure/dtos/Offices'
import { USDollar } from '../../../../../utils/currency'
import { IAddressPatient } from '../../../../Register/types'
import PhotoDoctor from '../../../../ScheduleAppointment/components/PhotoDoctor'
import { DetailsSTyled } from '../../../SearchDoctor/styles'

export interface DoctorDetailsRightPanelProps {
  onClose(path?: string): void
  rol?: string
  userId?: string
  handleAlert?: (open: boolean, message: string, severity?: Severity) => void
  doctor?: IParamsGetNextAppointmentHour
  selectedPatientAddress?: IAddressPatient
}

export default function DoctorDetailsRightPanel({
  onClose,
  doctor,
  selectedPatientAddress,
  userId,
  rol,
  handleAlert,
}: DoctorDetailsRightPanelProps): JSX.Element {
  const {
    doctorInfo,
    loading,
    suggestedDoctors,
    selectedMarker,
    updateFollowButton,
    handleQualifications
  } = useDoctorDetailsRightPanel({ doctor, selectedPatientAddress, userId })

  return (
    <DetailsSTyled data-testid="doctor-right-panel">
      <ClickAwayListener onClickAway={() => onClose()}>
        {!loading && doctorInfo ? (
          <GridWrapper container>
            <GridWrapper item xs={12}>
              <IconButtonWrapper onClick={() => onClose()}>
                <ClearIcon sx={{ color: 'primary.main' }} />
              </IconButtonWrapper>
            </GridWrapper>
            <GridWrapper item xs={12}>
              <GridWrapper
                container
                spacing={2}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                height={'auto'}
                padding={'1rem'}
              >
                <GridWrapper
                  item
                  xs={12}
                  sm={4}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <GridWrapper marginBottom={1}>
                    <PhotoDoctor
                      photo={doctorInfo.medical.photo_doctor as string}
                      rating={doctorInfo.medical.rating?.toFixed(1) as string}
                      doctorId={doctorInfo.medical.user_id}
                      handleAlert={handleAlert}
                    />
                  </GridWrapper>
                  <FollowButton
                    callbackAfterFollow={() => Promise.resolve(updateFollowButton(!doctorInfo.follow as boolean))}
                    doctorId={doctorInfo.medical.user_id as string}
                    fromHome={false}
                    idPatient={userId as string}
                    isFollow={doctorInfo.follow as boolean}
                    status={''}
                    size="small"
                  />
                </GridWrapper>

                <GridWrapper item xs={12} sm={7}>
                  <GridWrapper container direction={'column'}>
                    <LabelTextName data-testid="doctor-name">
                      <b>{`Dr. ${doctorInfo.medical.name_doctor} ${doctorInfo.medical.last_name_doctor}`}</b>
                    </LabelTextName>
                    <SubTitleSpecialty>{doctorInfo.medical.speciality_doctor}</SubTitleSpecialty>
                    <Skills skills={doctorInfo.medical.medical_skills as string[]} />
                    <SubTitle marginTop={'15px'}>
                      <b>Años de experiencia:</b> {doctorInfo.medical.years_experience_doctor}
                    </SubTitle>
                    <SubTitle>
                      <b>Estudios:</b> {doctorInfo.medical.university_doctor}
                    </SubTitle>
                    <SubTitle>
                      <b>Dirección seleccionada:</b> {doctorInfo.office_selected.reference_medical_office}
                    </SubTitle>
                    <SubTitle>
                      <b>Atenciones:</b> {Number(doctorInfo.medical.people_attended).toFixed()}
                    </SubTitle>
                  </GridWrapper>
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>

            <GridWrapper item xs={12} sx={{ height: '100%', padding: '0.4rem 1.5rem 1rem 1.5rem' }}>
              <PanelMap
                offices={doctorInfo.data_office as MedicalOffice[]}
                selectedMarker={selectedMarker}
              />
            </GridWrapper>
            <GridWrapper item xs={12} sx={{ height: '100%', padding: '0.2rem 1.5rem' }}>
              <GridWrapper
                container
                sx={{ backgroundColor: 'tertiary.light', padding: 1, borderRadius: 3 }}
                width={'100%'}
                justifyContent={'center'}
              >
                <GridWrapper item padding={1} width={'22%'} display={'flex'} flexDirection={'column'}>
                  <TypoDetailsTitle>Distancia</TypoDetailsTitle>
                  <GridWrapper display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                    <TypoDetailsInfo data-testid="test-distance">{doctorInfo.distance || '-'}</TypoDetailsInfo>
                  </GridWrapper>
                </GridWrapper>
                <DividerWrapper orientation="vertical" variant="middle" flexItem />
                <GridWrapper item padding={1} width={'35%'} display={'flex'} flexDirection={'column'}>
                  <TypoDetailsTitle>Precio de la consulta</TypoDetailsTitle>
                  <GridWrapper display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                    <TypoDetailsInfo>
                      {USDollar.format(Number(doctorInfo.office_selected.consultation_value) || 0)}
                    </TypoDetailsInfo>
                  </GridWrapper>
                </GridWrapper>
                <DividerWrapper orientation="vertical" variant="middle" flexItem />
                <GridWrapper
                  item
                  padding={1}
                  width={'40%'}
                  display={'flex'}
                  flexDirection={'column'}
                  data-testid="next-date-text"
                >
                  <TypoDetailsTitle>Próxima cita disponible</TypoDetailsTitle>
                  <GridWrapper display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
                    <TypoDetailsInfo>{doctorInfo.result}</TypoDetailsInfo>
                  </GridWrapper>
                </GridWrapper>
              </GridWrapper>
              {!selectedPatientAddress && (
                <LabelInputError
                  sx={{ color: 'primary.dark', fontWeight: 'bold' }}
                  textAlign={'center'}
                  data-testid="no-address-selected-message"
                >
                  Ingresa una dirección en la búsqueda para conocer la distancia del consultorio
                </LabelInputError>
              )}
            </GridWrapper>
            <GridWrapper
              item
              xs={12}
              sx={{
                height: '100%',
                padding: '0.2rem 1.5rem',
                paddingBottom: '1.5rem',
              }}
            >
              {doctorInfo.nearby_office && (
                <GridList item xs={12}>
                  <GridWrapper container>
                    <GridWrapper item xs={6}>
                      <ListTitle noWrap variant="subtitle2">
                        Ubicación más cercana
                      </ListTitle>
                    </GridWrapper>
                    <GridWrapper item xs={6}>
                      <ListContent variant="subtitle2">{doctorInfo.nearby_office}</ListContent>
                    </GridWrapper>
                  </GridWrapper>

                  <DividerWrapper />
                </GridList>
              )}
              <GridList item xs={12}>
                <GridWrapper container>
                  <GridWrapper item xs={6}>
                    <ListTitle noWrap variant="subtitle2">
                      {selectedPatientAddress ? 'Otras ubicaciones' : 'Ubicación de consultorios'}
                    </ListTitle>
                  </GridWrapper>
                  <GridWrapper item xs={6}>
                    {doctorInfo.others_office?.length ? (
                      doctorInfo.others_office.map((item: string, index: number) => (
                        <ListContent key={index} variant="subtitle2">
                          {item}
                        </ListContent>
                      ))
                    ) : (
                      <ListContent variant="subtitle2">Sin Consultorios</ListContent>
                    )}
                  </GridWrapper>
                </GridWrapper>
              </GridList>
              <DividerWrapper />
            </GridWrapper>
            <GridWrapper container width="100%" display={'grid'}>
              <GridWrapper item width="80%" display={'flex'} justifySelf={'center'}>
                <SubmitButton
                  id={'avoid-date'}
                  variant={'contained'}
                  type={'button'}
                  text={'Agendar cita'}
                  fullWidth
                  sx={{
                    fontSize: '13px',
                    backgroundColor: 'primary.dark',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    }
                  }}
                  onClick={() => {
                    onClose(`/appointment/booking/${doctorInfo.medical.user_id}?officeId=${doctorInfo.office_selected.office_id}&rol=${rol}`)
                  }}
                />
              </GridWrapper>
              {typeof suggestedDoctors !== 'string' && suggestedDoctors.length > 0 && (
                <GridWrapper item marginBottom={1} data-testid="suggested-doctors-title">
                  <Title sx={{ fontSize: '16px' }} paddingX={'1.5rem'}>
                    Médicos similares
                  </Title>
                  <RecommendedDoctors
                    doctors={suggestedDoctors}
                    getDoctorInfo={selectedMarker}
                    handleQualifications={handleQualifications}
                  />
                </GridWrapper>
              )}
            </GridWrapper>
          </GridWrapper>
        ) : (
          <GridWrapper item display={'Flex'} justifyContent={'center'} paddingTop={5}>
            <CircularProgressWrapper sx={{ color: 'primary.dark' }} />
          </GridWrapper>
        )}
      </ClickAwayListener>
    </DetailsSTyled>
  )
}
