import { useAuth0 } from '@auth0/auth0-react'
import { ExpandMore } from '@mui/icons-material'
import { useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useState, JSX } from 'react'

import { useNavigate } from 'react-router-dom'

import { AvatarWrapper, IconButtonWrapper, MenuItemWrapper, MenuWrapper } from '../../../../components'
import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'

function SessionOptionsButton(): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { isAuthenticated, isLoading, logout } = useAuth0()
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const { patient } = useContext(AccountContext) as AuthContextType

  const theme = useTheme()

  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleSignout = (): void => {
    if (!isLoading) {
      if (isAuthenticated) {
        logout()
      }
    }
  }

  const handleEditProfile = (): void => {
    navigate('/profile')
    handleClose()
  }

  return (
    <div>
      <IconButtonWrapper
        onClick={handleClick}
        size="large"
        edge="end"
        color="inherit"
        aria-label="menu"
        data-testid="options-button"
      >
        {!isExtraSmallSize ? <ExpandMore /> : <AvatarWrapper src={patient?.photo_patient} alt={patient.name} />}
      </IconButtonWrapper>
      <MenuWrapper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          root: {
            style: {
              zIndex: 3000,
            },
          },
        }}
        data-testid="menu"
      >
        <MenuItemWrapper data-testid="edit-profile" onClick={handleEditProfile}>
          Editar perfil
        </MenuItemWrapper>
        <MenuItemWrapper data-testid="sign-out" onClick={handleSignout}>
          Cerrar sesión
        </MenuItemWrapper>
      </MenuWrapper>
    </div>
  )
}

export default SessionOptionsButton
