import { ReactNode, createContext, useState } from 'react'

import { IAddressPatient } from '../views/Register/types'

interface Props {
  children?: ReactNode
}

export interface ISearchContextProps {
  doctor_name: string
  address: string
  addressData: IAddressPatient | undefined
  fromHome?: boolean
}

export const SearchInitState: ISearchContextProps = {
  doctor_name: '',
  address: '',
  addressData: undefined,
  fromHome: false,
}

type SearchContextProps = {
  searchOffices: ISearchContextProps
  savelastSearch: (lastSearch: ISearchContextProps) => void
}

export const SearchOfficesContext = createContext({} as SearchContextProps)

export const SearchOfficesProvider = ({ children }: Props): JSX.Element => {
  const [searchOffices, setSearchOffices] = useState(SearchInitState)

  const savelastSearch = (lastSearch: ISearchContextProps): void => {
    setSearchOffices(lastSearch)
  }

  return (
    <SearchOfficesContext.Provider
      value={{
        searchOffices,
        savelastSearch,
      }}
    >
      {children}
    </SearchOfficesContext.Provider>
  )
}
