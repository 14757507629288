import SaveQualification from '../../../domain/useCase/SaveQualificationUseCase'
import { QualificationDoctor, QualificationDoctorResponse } from '../../../infrastructure/dtos/SaveQualifications'
import SaveQualifiactionMapper from '../../../infrastructure/http/mappers/SaveQualificationMapper'
import SaveQualificationRepositoryImpl from '../../../infrastructure/RepositoryImpl/SaveQualificationRepositoryImpl'

interface ISaveQualificationService {
  status: number
  data: QualificationDoctorResponse | string
}

export async function SaveQualificationService(dataQuestions: QualificationDoctor): Promise<ISaveQualificationService> {
  try {
    const UseCaseApi = new SaveQualification(new SaveQualificationRepositoryImpl(new SaveQualifiactionMapper()))
    return {
      status: 1,
      data: await UseCaseApi.invoke(dataQuestions),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
