import { styled } from '@mui/material/styles'

import { BoxWrapper } from '../../../components'

export const BoxReminderStyled = styled(BoxWrapper)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'white',
  borderRadius: '21px',
  padding: '0px 20px',
  gridColumn: 'span 12',
  minHeight: '50px'
}))
