import { styled } from '@mui/material/styles'

import { GridWrapper } from '../../../../components'

export const GridContent = styled(GridWrapper)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#F7F8FD',
  display: 'flex',
  justifyContent: 'center',
}))

export const GridLayoutLeft = styled(GridWrapper)(() => ({
  height: '100%',
  display: 'grid',
  alignContent: 'flex-start',
  paddingTop: '10px',
  justifyContent: 'flex-start',
  gridTemplateColumns: '1fr',
}))

export const GridLayoutRight = styled(GridWrapper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.only('xs')]: {
    display: 'block',
  },
}))

export const GridLayoutBottom = styled(GridWrapper)(() => ({
  width: '100%',
  height: '111px',
  backgroundColor: '#F7F8FD',
  display: 'flex',
  justifyContent: 'center',
}))
