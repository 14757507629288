import { Autocomplete, styled } from '@mui/material'

import { FormControlWrapper } from '../wrapper'

export const FormControlStyled = styled(FormControlWrapper)(() => ({
  width: '100%',
}))

export const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  border: 'none',
  width: '100%',
  borderRadius: '55px',
  backgroundColor: theme.palette.common.white,
  fontSize: '20px',
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))
