import { JSX } from 'react'

import { useReminderComponent } from './ReminderComponentHook'
import { BoxReminderStyled } from './ReminderComponentStyles'
import { BoxWrapper } from '../../../components'
import { ReminderLogo } from '../../../components/icons/ReminderLogo'
import { LabelInputReminder } from '../../../components/StyledComponents/LabelInputReminder'
import CircularProgressWrapper from '../../../components/wrapper/LoaderWrapper'
import { ErrorBody } from '../../../infrastructure/dtos/Reminders'

export const Reminder = (): JSX.Element => {
  const { reminders, loadingReminders, haveReminders } = useReminderComponent()
  return (
    <BoxWrapper sx={{ width: 1 }} data-testid="reminders">
      {!loadingReminders ? (
        <BoxWrapper display="grid" gridTemplateColumns="repeat(12, 1fr)">
          {haveReminders ? (
            reminders?.map((reminder) => (
              <BoxReminderStyled key={reminder.reminder_id}>
                <ReminderLogo />
                <LabelInputReminder>{reminder.reminder_content}</LabelInputReminder>
              </BoxReminderStyled>
            ))
          ) : (
            <BoxReminderStyled>
              <LabelInputReminder>{(reminders as unknown as ErrorBody).message}</LabelInputReminder>
            </BoxReminderStyled>
          )}
        </BoxWrapper>
      ) : (
        <CircularProgressWrapper data-testid="loading-spinner-reminder" />
      )}
    </BoxWrapper>
  )
}
