import { styled } from '@mui/material'

import { ToolbarWrapper } from '../../components/wrapper'

export const AppToolbar = styled(ToolbarWrapper)(({ theme }) => ({
  [theme.breakpoints.only('xs')]: {
    display: 'flex',
    justifyContent: 'right',
    padding: '10px 0',
    marginRight: '30px'
  }
}))
