
import {
  IApiAppointment,
  IAppointmentInfoResponse,
  IAppointmentReasonCancellation,
  IAppointmentStatus,
  IConfirmAppointmentProps,
  INextAppointmentResponse,
  IPastAppointmentResponse
} from '../../infrastructure/dtos/Appointments';

import AppointmentsRepository from '../repository/AppointmentsRepository';

export interface ConfirmAppointmentUseCase {
  invoke: (params: IConfirmAppointmentProps) => Promise<IApiAppointment<IAppointmentStatus | string>>
}

export interface NextAppointmentsUseCase {
  invoke: (userId: string) => Promise<IApiAppointment<INextAppointmentResponse | string>>
}


export interface GetAppointmentInfoUseCase {
  invoke: (appointmentId: string) => Promise<IApiAppointment<IAppointmentInfoResponse | string>>
}

export interface PastAppointmentsUseCase {
  invoke: (userId: string) => Promise<IApiAppointment<IPastAppointmentResponse | string>>
}
export interface ReasonCancellationAppointmentUseCase {
  invoke: () => Promise<IApiAppointment<IAppointmentReasonCancellation | string>>
}

export class ConfirmAppointment implements ConfirmAppointmentUseCase {
  private objectUseCase: AppointmentsRepository

  constructor(_objectUseCase: AppointmentsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: IConfirmAppointmentProps): Promise<IApiAppointment<IAppointmentStatus | string>> {
    return this.objectUseCase.confirmAppointment(params)
  }
}

export class GetAppointmentInfo implements GetAppointmentInfoUseCase {
  private objectUseCase: AppointmentsRepository

  constructor(_objectUseCase: AppointmentsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(appointmentId: string): Promise<IApiAppointment<IAppointmentInfoResponse | string>> {
    return this.objectUseCase.getAppointmentInfo(appointmentId)
  }
}

export class NextAppointments implements NextAppointmentsUseCase {
  private objectUseCase: AppointmentsRepository

  constructor(_objectUseCase: AppointmentsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(userId: string): Promise<IApiAppointment<INextAppointmentResponse | string>> {
    return this.objectUseCase.nextAppointments(userId)
  }
}

export class PastAppointments implements PastAppointmentsUseCase {
  private objectUseCase: AppointmentsRepository

  constructor(_objectUseCase: AppointmentsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(userId: string): Promise<IApiAppointment<IPastAppointmentResponse | string>> {
    return this.objectUseCase.pastAppointments(userId)
  }
}

export class GetAppointmentReasonCancellation implements ReasonCancellationAppointmentUseCase {
  private objectUseCase: AppointmentsRepository

  constructor(_objectUseCase: AppointmentsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(): Promise<IApiAppointment<IAppointmentReasonCancellation | string>> {
    return this.objectUseCase.reasonCancellationAppointment()
  }
}