import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { JSX } from 'react'

import { TypographyWrapperLabel } from '../../../../ScheduleAppointment/components/OfficesList/OfficeListStyle'
import { DateBox } from '../../PatientFormStyle'
import 'dayjs/locale/es'

interface IBirthDate {
  text: string
  selectedDate: dayjs.Dayjs | null
  handleChangeDate: (value: dayjs.Dayjs | null) => void
}

function BirthDate({ handleChangeDate, selectedDate, text }: IBirthDate): JSX.Element {
  return (
    <>
      <TypographyWrapperLabel className="label">{text}</TypographyWrapperLabel>
      <DateBox data-testid="birthday-picker">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            disableFuture
            onChange={handleChangeDate}
            value={selectedDate}
            format="DD/MM/YYYY"
            slotProps={{
              toolbar: { hidden: true },
            }}
          />
        </LocalizationProvider>
      </DateBox>
    </>
  )
}

export default BirthDate
