import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import { Theme, useMediaQuery, useTheme } from '@mui/material'
import { useJsApiLoader } from '@react-google-maps/api'
import { useState, JSX } from 'react'

import { useMaps } from './Hooks/useMaps'
import { AutocompleteGoogleMaps } from './MapComponents/AutocompleteGoogle'
import { MapsGoogle } from './MapComponents/MapsGoogle'
import { MobileBackButton, SideGrid } from './styles/RegisterAddressFormStyles'
import { BackButton } from '../../../components/BackButton/BackButton'
import { GridFullScreen } from '../../../components/GridFullScreen/GridFullScreen'
import { List } from '../../../components/List/List'
import { Loading } from '../../../components/Loading/Loading'
import { GridHalfScreen } from '../../../components/StyledComponents/GridHalfScreen'
import { LabelInput } from '../../../components/StyledComponents/LabelInput'
import { Title } from '../../../components/StyledComponents/Title'
import SubmitButton from '../../../components/SubmitButton'
import { StackWrapper, GridWrapper } from '../../../components/wrapper'
import { ButtonCorreo, ButtonSelect, TextFielRegister } from '../../../utils/RegisterMedicalFormStyles'
import containerText from '../PatientForm/PatientForm.json'
import { IAddressPatientAux, IPatientInfo } from '../types'

export interface IMapPageFormProps {
  onBack: () => void
  handlePatientAddresses: (addresses: IAddressPatientAux[]) => void
  patientInfo: IPatientInfo
  onChangeSubmit: () => void
}

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}

interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}

interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
}

type Libraries = (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
  | 'marker'
  )[]

const libraries = ['marker', 'places'] satisfies Libraries

export const RegisterAddressPatient = ({
  onBack,
  handlePatientAddresses,
  patientInfo,
  onChangeSubmit,
}: IMapPageFormProps): JSX.Element => {
  const theme: Theme = useTheme()
  const isExtraSmallSize: boolean = useMediaQuery(theme.breakpoints.only('xs'))

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
  })
  const [addressPatient, setAddressPatient] = useState<IAddressPatientAux[]>(patientInfo.addresses)
  const {
    zoom,
    markerPosition,
    address,
    selected,
    setAddress,
    setMapCenter,
    setMarkerPosition,
    setSelected,
    setZoom,
    mapCenter,
    nameLocation,
    setNameLocation,
    // editar,
    eliminar,
    modificar,
    newAddressPatient,
    optionsList,
    addressEdition,
    value,
    setValue,
    disable,
    guardar,
  } = useMaps({ addressPatient, setAddressPatient })

  const [, setValueEdition] = useState<PlaceType | null>(null)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const onChangeNameAddress = (value: string): void => {
    setNameLocation(value)
  }

  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    addressPatient.map((value: IAddressPatientAux) => {
      const aux = value
      delete aux.id
      delete aux.state

      return aux
    })

    handlePatientAddresses(addressPatient)
    onChangeSubmit()
  }

  const handleGoBack = (): void => {
    handlePatientAddresses(addressPatient)
    onBack()
  }

  return (
    <GridFullScreen id="RegisterConsultoryForm">
      {!isExtraSmallSize ? (
        <GridHalfScreen
          style={{
            flexBasis: '10%',
          }}
        >
          <BackButton id="backButton" text="Regresar" onClick={handleGoBack} />
        </GridHalfScreen>
      ) : (
        <MobileBackButton onClick={handleGoBack}>
          <ChevronLeftIcon />
        </MobileBackButton>
      )}
      <SideGrid data-testid="address-form">
        <Title>{containerText.address_title}</Title>
        {isExtraSmallSize && (
          <LabelInput sx={{ textAlign: 'center', marginLeft: 0 }}>{containerText.address_subtitle}</LabelInput>
        )}
        {isLoaded ? (
          <form onSubmit={handleSubmit}>
            <GridWrapper item xs={12} sx={{ mt: 2 }}>
              <StackWrapper
                spacing={{ xs: 0, md: 2 }}
                direction={{ xs: 'column-reverse', md: 'row' }}
                sx={{ marginBottom: 4, marginLeft: '0px' }}
              >
                <GridWrapper item xs={12} md={5}>
                  <GridWrapper sx={{ width: '100%' }}>
                    {!isExtraSmallSize && (
                      <LabelInput sx={{ marginRight: '15px' }}>{containerText.address_subtitle}</LabelInput>
                    )}

                    <LabelInput sx={{ marginTop: 4 }}>{containerText.name}</LabelInput>
                    <List
                      id="nameLocation"
                      lists={optionsList}
                      minWidth="100%"
                      onChange={onChangeNameAddress}
                      value={nameLocation}
                      disabled={disable}
                    />
                    <LabelInput sx={{ marginTop: 2 }}>{containerText.address}</LabelInput>
                    <AutocompleteGoogleMaps
                      address={address}
                      setMarkerPosition={setMarkerPosition}
                      setZoom={setZoom}
                      setAddress={setAddress}
                      setMapCenter={setMapCenter}
                      isLoaded={isLoaded}
                      setSelected={setSelected}
                      selected={selected}
                      value={value}
                      setValue={setValue}
                      isDisabled={disable}
                    />
                  </GridWrapper>

                  <StackWrapper
                    spacing={isExtraSmallSize ? 0 : 2}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mb: 4, mt: 2 }}
                  >
                    <GridWrapper sx={{ width: '300px' }} />
                    <ButtonCorreo
                      id="add"
                      variant="contained"
                      role="button"
                      sx={{ width: '45%' }}
                      onClick={newAddressPatient}
                      disabled={!nameLocation || !address || addressPatient.length >= 5}
                    >
                      {containerText.add}
                    </ButtonCorreo>
                  </StackWrapper>
                  {addressPatient.length > 0 && (
                    <LabelInput sx={{ marginTop: 2 }}>{containerText.saved_addresses}</LabelInput>
                  )}
                  {addressPatient?.map((item: IAddressPatientAux, index: number) => {
                    return (
                      <GridWrapper
                        key={index}
                        item
                        sx={{
                          mb: 4,
                          mt: 2,
                          background: 'white',
                          borderRadius: '40px',
                        }}
                      >
                        <AutocompleteGoogleMaps
                          address={address}
                          setMarkerPosition={setMarkerPosition}
                          setZoom={setZoom}
                          setAddress={setAddress}
                          setMapCenter={setMapCenter}
                          isLoaded={isLoaded}
                          setSelected={setSelected}
                          selected={selected}
                          isDisabled={!item.state}
                          value={item.value as PlaceType | null}
                          setValue={setValueEdition}
                          editingValueEdition={addressEdition}
                          id={item.id}
                        />

                        <TextFielRegister
                          sx={{
                            height: !item.state ? '35px' : 'auto',
                            '& .MuiInputBase-root': {
                              backgroundColor: !item.state ? 'white' : '#fafafa',
                            },
                          }}
                          placeholder="Edificio, Piso, Consultorio"
                          disabled
                          type="text"
                          value={item.name}
                          // onChange={(event) => editar(item, event.target.value)}
                          fullWidth
                          multiline
                          maxRows={4}
                        />

                        <StackWrapper direction="row" alignItems="center" justifyContent="center">
                          {item.state ? (
                            <ButtonSelect
                              sx={{ mb: 2, mt: 2, height: '30px' }}
                              variant="outlined"
                              role="button"
                              onClick={() => guardar(item)}
                            >
                              <SaveIcon /> &nbsp; Guardar
                            </ButtonSelect>
                          ) : (
                            <ButtonSelect
                              sx={{ mb: 2, mt: 2, height: '30px' }}
                              variant="outlined"
                              role="button"
                              onClick={() => modificar(item)}
                            >
                              <EditIcon /> &nbsp; Modificar
                            </ButtonSelect>
                          )}
                          {!item.state && (
                            <ButtonSelect
                              sx={{ mb: 2, mt: 2, height: '30px' }}
                              variant="outlined"
                              role="button"
                              onClick={() => eliminar(item)}
                            >
                              <DeleteOutlineIcon />
                              &nbsp; Eliminar
                            </ButtonSelect>
                          )}
                        </StackWrapper>
                      </GridWrapper>
                    )
                  })}
                </GridWrapper>
                <GridWrapper item xs={12} md={7} sx={{ marginLeft: '100px' }}>
                  <MapsGoogle
                    map={map}
                    markerPosition={markerPosition}
                    zoom={zoom}
                    address={address}
                    mapCenter={mapCenter}
                    setMap={setMap}
                    setMarkerPosition={setMarkerPosition}
                    setZoom={setZoom}
                    setAddress={setAddress}
                    setMapCenter={setMapCenter}
                    isLoaded={isLoaded}
                    setSelected={setSelected}
                  />
                </GridWrapper>
              </StackWrapper>
            </GridWrapper>
            <GridWrapper item xs={12} sx={{ my: 2 }}>
              <SubmitButton
                id="button"
                type="submit"
                variant="contained"
                fullWidth
                text="Empezar"
                data-testid="ok-button"
                disabled={addressPatient.length === 0 || disable}
              />
            </GridWrapper>
          </form>
        ) : (
          <Loading />
        )}
      </SideGrid>
    </GridFullScreen>
  )
}

export default RegisterAddressPatient
