import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IResendCodeParams,
  IVerifyCodeParams,
  IUpdatePatientPhoneParams
} from '../../infrastructure/dtos/PhoneVerification'
import PhoneVerificationRepository from '../repository/PhoneVerificationRepository'

export interface ResendCodeUseCase {
  invoke: (
    params: IResendCodeParams
  ) => Promise<IApiResponsePhoneVerification<IPhoneVerification>>
}

export interface VerifyPhoneValidationUseCase {
  invoke: (
    userId: string
  ) => Promise<IApiResponsePhoneVerification<IPhoneVerification>>
}

export interface VerifyCodeUseCase {
  invoke: (
    params: IVerifyCodeParams
  ) => Promise<IApiResponsePhoneVerification<IPhoneVerification>>
}

export interface UpdatePatientPhoneCase {
  invoke: (
    params: IUpdatePatientPhoneParams
  ) => Promise<IApiResponsePhoneVerification<string>>
}

export class ResendCode implements ResendCodeUseCase {
  private objectUseCase: PhoneVerificationRepository

  constructor(_objectUseCase: PhoneVerificationRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: IResendCodeParams
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.objectUseCase.resendCode(params)
  }
}

export class SendCode implements VerifyPhoneValidationUseCase {
  private objectUseCase: PhoneVerificationRepository

  constructor(_objectUseCase: PhoneVerificationRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    userId: string
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.objectUseCase.sendCode(userId)
  }
}

export class VerifyPhoneValidation implements VerifyPhoneValidationUseCase {
  private objectUseCase: PhoneVerificationRepository

  constructor(_objectUseCase: PhoneVerificationRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    userId: string
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.objectUseCase.verifyPhoneValidation(userId)
  }
}

export class VerifyCode implements VerifyCodeUseCase {
  private objectUseCase: PhoneVerificationRepository

  constructor(_objectUseCase: PhoneVerificationRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: IVerifyCodeParams
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return this.objectUseCase.verifyCode(params)
  }
}

export class UpdatePatientPhone implements UpdatePatientPhoneCase {
  private objectUseCase: PhoneVerificationRepository

  constructor(_objectUseCase: PhoneVerificationRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(
    params: IUpdatePatientPhoneParams
  ): Promise<IApiResponsePhoneVerification<string>> {
    return this.objectUseCase.updatePatientPhone(params)
  }
}
