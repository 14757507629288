import { useMediaQuery, useTheme } from '@mui/material'
import { useContext, useEffect } from 'react'

import Address from './components/Address'
import CardSearchResult from './components/CardSearchResult'
import { useSearchDoctor } from './Hooks/useSearchDoctor'
import { StyledForm } from './styles'
import SearchAutocomplete from '../../../components/SearchAutocomplete'
import { useSearchAutocomplete } from '../../../components/SearchAutocomplete/useSearchAutocomplete'
import { DrawerSidePanel } from '../../../components/StyledComponents/DrawerSidePanel'
import { GridPartialScreen } from '../../../components/StyledComponents/GridPartialScreen'
import { LabelInput } from '../../../components/StyledComponents/LabelInput'
import { Title } from '../../../components/StyledComponents/Title'
import SubmitButton from '../../../components/SubmitButton'
import { GridWrapper, StackWrapper } from '../../../components/wrapper'
import CircularProgressWrapper from '../../../components/wrapper/LoaderWrapper'
import { AccountContext, AuthContextType } from '../../../contexts/AccountContext'
import { SearchOfficesContext } from '../../../contexts/SeachOfficesContext'
import DoctorResultsGoogleMap from '../../pages/DoctorResultsGoogleMap'
import { AddressPanel } from '../../Profile/components/ManageAddress/AddressPanel'
import { IAddressPatientAux } from '../../Register/types'
import CMResultsDetails from '../DoctorResultsGoogleMap/Components/CMResultsDetails'
import DoctorDetailsRightPanel from '../DoctorResultsGoogleMap/Components/DoctorDetailsRightPanel'

function SearchDoctor(): JSX.Element {
  const { patient, handleAlert, savePatientData } = useContext(AccountContext) as AuthContextType
  const { searchOffices } = useContext(SearchOfficesContext)
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const {
    showResults,
    searching,
    results,
    resultsInMap,
    address,
    addressData,
    query,
    addNewAddress,
    showMap,
    selectedOffice,
    selectedResult,
    handleSubmit,
    handleSubmitted,
    onChangeQuery,
    onChangeAddress,
    onChangeAddressData,
    handleToggleDrawer,
    handleOpenDetails,
    handleCloseDetails,
  } = useSearchDoctor()
  const { options } = useSearchAutocomplete({ query })

  const handleUpdateAddress = (addresses: IAddressPatientAux[]): void => {
    savePatientData({
      ...patient,
      addresses,
    })
  }

  useEffect(() => {
    if (searchOffices.fromHome) {
      handleSubmitted(true)
    }
  }, [])

  return (
    <GridWrapper container spacing={isExtraSmallSize ? 0 : 4} id="appointment" direction="row" justifyContent={'cemter'}>
      <GridPartialScreen item xs={12} sm={6} md={8} lg={8.5} position={'relative'}>
        {!!selectedResult && selectedResult.rol === 'DOCTOR' && (
          <DoctorDetailsRightPanel
            onClose={handleCloseDetails}
            doctor={selectedOffice}
            selectedPatientAddress={addressData}
            userId={patient.userId}
            handleAlert={handleAlert}
            rol={selectedResult.rol}
          />
        )}

        {!!selectedResult && selectedResult.rol === 'CM' && (
          <CMResultsDetails
            onClose={handleCloseDetails}
            cmLocation={selectedResult}
            query={query as string}
          />
        )}
        {addressData && showMap
          && <DoctorResultsGoogleMap
            results={resultsInMap}
            selectedAddress={addressData}
            handleAlert={handleAlert}
            userId={patient.userId}
            query={query}
            handleOpenDetails={handleOpenDetails}
          />
        }
      </GridPartialScreen>
      <GridPartialScreen
        item
        xs={12}
        sm={6}
        md={4}
        lg={3.5}
        data-testid="profile-form"
        sx={{
          height: 'calc(100vh - 80px)',
          overflowY: 'scroll',
          '&.MuiGrid-item': {
            paddingInline: isExtraSmallSize ? '10px' : '32px',
          }
        }}
      >
        <Title textAlign={'center'} variant="h5" sx={{ fontSize: '20px' }}>{`Hola ${patient.name}, empecemos a agendar tu cita`}</Title>
        <StyledForm onSubmit={handleSubmit}>
          <GridWrapper item xs={12} mt={1}>
            <StackWrapper spacing={{ xs: 4, sm: 2 }} direction={'column'} sx={{ marginBottom: 1 }}>
              <GridWrapper item xs={12}>
                <LabelInput>¿Qué estás buscando?</LabelInput>
                <SearchAutocomplete
                  id="doctor-name"
                  options={options}
                  setValue={onChangeQuery}
                  value={query}
                  placeholder={'Dr. Jon Doe'}
                />
              </GridWrapper>
            </StackWrapper>
            <StackWrapper spacing={{ xs: 4, sm: 2 }} direction={'column'} sx={{ marginBottom: 4 }}>
              <GridWrapper item xs={12}>
                <LabelInput>Tu dirección</LabelInput>
                <Address address={address} onChange={onChangeAddress} setAddressData={onChangeAddressData} />
              </GridWrapper>
              <GridWrapper item xs={12} display={'flex'} alignItems={'flex-end'}>
                <SubmitButton
                  data-testid="search-button"
                  id="button"
                  type="submit"
                  variant="contained"
                  fullWidth
                  text="Buscar"
                  disabled={!Boolean(query) || searching}
                />
              </GridWrapper>
            </StackWrapper>
          </GridWrapper>
        </StyledForm>
        {searching && (
          <GridPartialScreen display={'flex'} justifyContent={'center'}>
            <CircularProgressWrapper />
          </GridPartialScreen>
        )}
        {results.length === 0 && showResults && (
          <LabelInput sx={{ marginLeft: 0, textAlign: 'center' }}>Lo siento, no encontramos médicos con los datos ingresados.</LabelInput>
        )}
        {results.map((item, index) => (
          <GridWrapper key={`list-${index}`} my={2}>
            <CardSearchResult result={item} onClick={handleOpenDetails} selectedResult={selectedResult} />
          </GridWrapper>
        ))}
      </GridPartialScreen>

      <DrawerSidePanel
        anchor={isExtraSmallSize ? 'bottom' : 'right'}
        open={addNewAddress}
        onClose={() => handleToggleDrawer(false)}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0)',
            },
          },
        }}
      >
        <AddressPanel
          toggleDrawer={handleToggleDrawer}
          patient={patient}
          handleAlert={handleAlert}
          handleUpdateAddress={handleUpdateAddress}
          itemIndex={-1}
        />
      </DrawerSidePanel>
    </GridWrapper>
  )
}

export default SearchDoctor
