import { AxiosError } from 'axios'

import { GendersResponse } from '../../dtos/Genders'
import GendersDataSource from '../dataSource/GendersDataSource'
import { http } from '../Http'

export default class GendersMapper implements GendersDataSource {
  async getGenders(): Promise<GendersResponse> {
    try {
      const { data } = await http.get<GendersResponse>(`send_data/genders`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false
        }
      }
    }
  }
}
