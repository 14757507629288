import { JSX } from 'react'

import { useSuggestedDoctors } from './hooks/useSuggestedDoctors'
import { AvatarDoctor, GridSuggestedDoctor, SuggestedDoctorsHomeBox } from './SuggestedDoctorsStyle'
import { GridWrapper, TypographyWrapper } from '../../../components'
import SubmitButton from '../../../components/SubmitButton'
import CircularProgressWrapper from '../../../components/wrapper/LoaderWrapper'
import { IRecommendedDoctors } from '../../../infrastructure/dtos/DoctorInfo'
import { BoxLabel } from '../News/NewsStyles'

interface ISuggestedDoctorsProps {
  idPatient: string
  isExtraSmallSize: boolean
}

export const SuggestedDoctors = ({ idPatient, isExtraSmallSize }: ISuggestedDoctorsProps): JSX.Element => {
  const { doctors, loading, navigate } = useSuggestedDoctors(idPatient)
  return (
    <SuggestedDoctorsHomeBox data-testid="suggested-doctors-home-box">
      <BoxLabel>Médicos sugeridos para ti</BoxLabel>
      {!loading &&
        doctors?.length > 0 &&
        doctors?.map((doctor: IRecommendedDoctors, index: number) => (
          <GridSuggestedDoctor
            container
            direction={'row'}
            key={`suggested-${index}`}
            data-testid="grid-suggested-doctor"
            textAlign={'center'}
          >
            <GridWrapper item xs={isExtraSmallSize ? 3 : 2}>
              <AvatarDoctor src={doctor.photo_doctor} />
            </GridWrapper>

            {!isExtraSmallSize ? (
              <>
                <GridWrapper item xs={3}>
                  {doctor.name_doctor} {doctor.last_name_doctor}
                </GridWrapper>
                <GridWrapper item xs={4}>
                  {doctor.specialty_doctor}
                </GridWrapper>
              </>
            ) : (
              <GridWrapper item xs={5} textAlign={'left'}>
                <TypographyWrapper>
                  {doctor.name_doctor} {doctor.last_name_doctor}
                </TypographyWrapper>
                <TypographyWrapper>{doctor.specialty_doctor}</TypographyWrapper>
              </GridWrapper>
            )}
            <GridWrapper item xs={isExtraSmallSize ? 4 : 3}>
              <SubmitButton
                fullWidth
                id="suggested-doctor-button"
                text="Cita"
                type="button"
                variant="contained"
                onClick={() => {
                  navigate(`/appointment/booking/${doctor.user_id}`)
                }}
              />
            </GridWrapper>
          </GridSuggestedDoctor>
        ))}
      {!loading && doctors.length === 0 && (
        <GridWrapper>
          <TypographyWrapper>No se encontraron resultados</TypographyWrapper>
        </GridWrapper>
      )}
      {loading && (
        <GridWrapper>
          <CircularProgressWrapper size={50} data-testid="circular-progress-wrapper" />
        </GridWrapper>
      )}
    </SuggestedDoctorsHomeBox>
  )
}
