import { IResponseApi, IResponseService } from '../../../infrastructure/dtos'
import { IPatientActivitiesInfo } from '../../../infrastructure/dtos/PatientActivitiesInfo'
import { PatientActivitiesInfoService } from '../Persistencia/PatientActivitiesInfoService'

export async function PatientActivitiesInfoUtility(user_id: string): Promise<IResponseService<IPatientActivitiesInfo>> {
  try {
    const { data, status } = await PatientActivitiesInfoService(user_id)
    if (status !== 0 && typeof data !== 'string') {
      return {
        status: data?.statusCode === 200 ? 1 : 0,
        data: data?.statusCode === 200 ? data?.body : data?.body?.message,
      }
    }

    return {
      status: 0,
      data: (data as IResponseApi<IPatientActivitiesInfo>).body.message,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
