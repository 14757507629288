
import { IApiPrescription, IRecipePrescription } from '../../infrastructure/dtos/Prescription'
import { PrescriptionsRepository } from '../repository/PrescriptionsRepository'

export interface GetAppointmentRecipesUseCase {
  invoke: (appointmentId: string) => Promise<IApiPrescription<IRecipePrescription | string>>
}

export class GetAppointmentRecipes implements GetAppointmentRecipesUseCase {
  private objectUseCase: PrescriptionsRepository

  constructor(_objectUseCase: PrescriptionsRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(appointmentId: string): Promise<IApiPrescription<IRecipePrescription | string>> {
    return this.objectUseCase.getAppointmentRecipes(appointmentId)
  }
}
