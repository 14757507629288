import { IApiResponseSaveNewDate, SaveNewDate } from '../../../infrastructure/dtos/NewDate'
import { NewDateService } from '../Persistencia/NewDateService'

export interface NewDateData {
  status: number
  data: string
}
export async function PostNewDateUtility(dataNewDate: SaveNewDate): Promise<NewDateData> {
  try {
    const { data, status } = await NewDateService(dataNewDate)
    return {
      data: (data as IApiResponseSaveNewDate).body.message as string,
      status: status !== 1 || (data as IApiResponseSaveNewDate)?.statusCode !== 200 ? 0 : 1,
    }
  } catch (error: unknown) {
    return {
      data: 'Ups! Tengo inconvenientes en este momento, por favor intenta más tarde.',
      status: 0,
    }
  }
}
