import { Alert, AlertProps } from '@mui/material'
import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AlertWrapper = React.forwardRef<any, AlertProps>(
  ({ children, ...props }, ref) => (
    <Alert {...props} ref={ref}>
      {children}
    </Alert>
  ),
)

export default AlertWrapper
