import { styled } from '@mui/material'

import { GridWrapper } from '../../../components'

export const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px'
  }
}))

export const DetailsSTyled = styled(GridWrapper)(({ theme }) => ({
  zIndex: 1000,
  right: 30,
  top: 60,
  height: '90%',
  overflowY: 'scroll',
  width: 500,
  backgroundColor: 'white',
  borderRadius: '30px',
  padding: '20px',
  position: 'absolute',
  [theme.breakpoints.down('sm')]: {
    width: 350,
    top: 40,
  }
}))
