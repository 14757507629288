import { SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'
import { PatientAppointmentContext, PatientAppointmentInitState } from '../../../../contexts/PatientAppointmentContext'
import { SearchInitState, SearchOfficesContext } from '../../../../contexts/SeachOfficesContext'
import { IAppointmentInfo, IAppointmentInfoResponse } from '../../../../infrastructure/dtos/Appointments'
import { IAvailableHours } from '../../../../infrastructure/dtos/Calendar'
import { ICalendarData, IResponseGetCalendarEvent } from '../../../../infrastructure/dtos/CalendarInfo'
import { IDoctorInfo } from '../../../../infrastructure/dtos/DoctorInfo'
import { IDaysAvailability } from '../../../../infrastructure/dtos/OfficeDaysAvailability'
import { IPhoneVerification } from '../../../../infrastructure/dtos/PhoneVerification'
import { getAvailableHoursInADay } from '../../../../services/Contracts/Persistencia/Calendar'
import { GetDaysAvailabilityInAMonth } from '../../../../services/Contracts/Persistencia/CalendarInfoService'
import { getAppointmentInfoUtility } from '../../../../services/Contracts/Utility/AppointmentsUtility'
import { PostNewDateUtility } from '../../../../services/Contracts/Utility/NewDateUtility'
import { getDaysAvailabilityUtility, getMonthAvailabilityUtility } from '../../../../services/Contracts/Utility/OfficesUtility'
import {
  ICodeUtility,
  verifyPhoneValidationUtility,
} from '../../../../services/Contracts/Utility/PhoneVerificationUtility'
import { saveReschedulingAppointmentUtility } from '../../../../services/Contracts/Utility/ReschedulingAppointmentUtility'
import { IPatientInfo } from '../../../Register/types'
import { useDataDoctor } from '../../../ScheduleAppointment/Hooks/useDataDoctor'
import { useModalConfirmPhoneNumber } from '../components/ModalConfirmPhoneNumber/hook'

interface IUseBookingDate {
  openModal: boolean
  dataDoctor: IDoctorInfo
  isExtraSmallSize: boolean
  loadCalendar: boolean
  showCalendar: boolean
  showAvailableHours: boolean
  loadAvailableHours: boolean
  disabledButton: boolean
  showForm: boolean
  setReference: Dispatch<SetStateAction<string>>
  handleChangeOffice: (event: SelectChangeEvent<unknown>) => void
  handleChangeDate: (value: dayjs.Dayjs | null) => void
  handleSelectedHour: (value: string) => void
  onSubmit: (e: { preventDefault(): void }) => Promise<void>
  isOptionDisabled: (hour: string) => boolean
  handleGoBack: () => void
  patient: IPatientInfo
  appointmentId: string | undefined
  selectedOffice: string
  doctorId: string | undefined
  selectedDate: dayjs.Dayjs | null
  daysAvailability: ICalendarData | undefined
  availableHours: string[]
  selectedHour: string
  comments: string
  reference: string
  setComments: Dispatch<SetStateAction<string>>
  handleCloseModal: () => void
  phoneVerification: IPhoneVerification | undefined
  setPhoneVerification: Dispatch<SetStateAction<IPhoneVerification | undefined>>
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
  globalError: string
  idSpecialty: string
  rol: string
  branchId: string
  doctorCM: string
}

export function useBookingDate(): IUseBookingDate {
  const { PatientAppointment, saveScheduleSelected } = useContext(PatientAppointmentContext)
  const { patient, handleAlert } = useContext(AccountContext) as AuthContextType
  const { savelastSearch } = useContext(SearchOfficesContext)

  const [phoneVerification, setPhoneVerification] = useState<IPhoneVerification | undefined>(undefined)

  const handleCloseAlert = (): void => {
    navigate('/')
    saveScheduleSelected(PatientAppointmentInitState)
    savelastSearch(SearchInitState)
    handleCloseModal()
  }

  const { openModal, handleCloseModal, handleOpenModal } = useModalConfirmPhoneNumber(
    phoneVerification as IPhoneVerification,
  )

  const { doctorId, appointmentId } = useParams<string>()
  const [searchParams] = useSearchParams()
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))
  const navigate = useNavigate()
  const { dataDoctor } = useDataDoctor(doctorId as string)

  const [officeId] = useState(searchParams.get('officeId') as string)
  const [idSpecialty] = useState(searchParams.get('idSpecialty') as string)
  const [rol] = useState(searchParams.get('rol') as string)
  const [branchId] = useState(searchParams.get('branchId') as string)
  const [doctorCM] = useState(searchParams.get('doctorCM') as string)

  const [selectedOffice, setSelectedOffice] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
    PatientAppointment?.date_appointment ? dayjs(PatientAppointment?.date_appointment) : null,
  )

  const [selectedHour, setSelectedHour] = useState<string>(PatientAppointment?.hour_appointment)
  const [consultationTimeMinutes, setConsultationTimeMinutes] = useState<string>('')

  const [loadCalendar, setLoadCalendar] = useState<boolean>(false)
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [daysAvailability, setDaysAvailability] = useState<ICalendarData>()
  const [showAvailableHours, setShowAvailableHours] = useState<boolean>(false)
  const [loadAvailableHours, setLoadAvailableHours] = useState<boolean>(false)
  const [availableHours, setAvailableHours] = useState<string[]>([])
  const [disabledButton, setDisabledButton] = useState<boolean>(true)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>(0)

  const [appointmentToReschedule, setAppointmentToReschedule] = useState<IAppointmentInfo>()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const [comments, setComments] = useState<string>('')
  const [reference, setReference] = useState<string>('')
  const [globalError, setGlobalError] = useState<string>('')

  const setSaveScheduleSelected = (): void => {
    saveScheduleSelected({
      ...PatientAppointment,
      office_id: selectedOffice,
      date_appointment: selectedDate?.format('YYYY-MM-DD') as string,
      hour_appointment: selectedHour,
      consultation_time_minutes: parseInt(consultationTimeMinutes.split(' ')[0]),
      comment: comments,
      reference: reference,
    })
  }

  const fetchDaysAvailabilityInAMonth = async (): Promise<ICalendarData> => {
    const getDuration = PatientAppointment.office?.appointment_types?.filter(
      (type) => type.name === appointmentToReschedule?.appointment_reason,
    )

    const filterType = PatientAppointment.office?.appointment_types?.some(
      (type) => type.name === appointmentToReschedule?.appointment_reason,
    )

    let appointmentDuration;
    if (!filterType) {
      appointmentDuration = appointmentToReschedule?.consultation_time_minutes?.split(' ')[0] as unknown as number
    } else {
      appointmentDuration= Number(getDuration?.[0].duration)
    }

    setDuration(appointmentDuration)

    if (appointmentId) {
      const { data } = await GetDaysAvailabilityInAMonth(
        doctorId as string,
        selectedOffice,
        appointmentDuration,
      )
      return (data as IResponseGetCalendarEvent).body
    }

    const { data } = await getMonthAvailabilityUtility(
      selectedOffice,
      rol === 'DOCTOR' ? doctorId as string : doctorCM as string,
      rol === 'DOCTOR' ? 'OFFICE' : 'OFFICE_CM'
    )
    return data.data
  }

  const fetchAppointmentInfo = async (appointmentId: string): Promise<void> => {
    const { data, status } = await getAppointmentInfoUtility(appointmentId)
    if (status) {
      const appointmentData = (data as IAppointmentInfoResponse).appointment
      setAppointmentToReschedule(appointmentData)
    }
  }

  const isOptionDisabled = (hour: string): boolean =>
    Boolean(appointmentId) &&
    selectedOffice === appointmentToReschedule?.office_id &&
    selectedDate?.format('YYYY-MM-DD') === appointmentToReschedule?.appointment_date &&
    hour === appointmentToReschedule?.hour_from

  const handleGoBack = (): void => {
    navigate('/')
  }

  const handleChangeOffice = (event: SelectChangeEvent<unknown>): void => {
    setSelectedOffice((event.target as HTMLSelectElement)?.value)
    setSelectedDate(null)
    setSelectedHour('')
    setDisabledButton(true)
  }

  const handleChangeDate = (value: dayjs.Dayjs | null): void => {
    setSelectedDate(value)
    setSelectedHour('')
    setDisabledButton(true)
  }

  const handleSelectedHour = (value: string): void => setSelectedHour(value)

  const fetchAvailableHoursInADay = async (): Promise<void> => {
    try {
      setLoadAvailableHours(true)
      const getDuration = PatientAppointment.office?.appointment_types?.filter(
        (type) => type.name === appointmentToReschedule?.appointment_reason,
      )

      const filterType = PatientAppointment.office?.appointment_types?.some(
        (type) => type.name === appointmentToReschedule?.appointment_reason,
      )

      let appointmentDuration;
      if (!filterType) {
        appointmentDuration = appointmentToReschedule?.consultation_time_minutes?.split(' ')[0] as unknown as number
      } else {
        appointmentDuration = Number(getDuration?.[0].duration)
      }

      let hours: string[] = []
      let consultationTimeMinutes = ''

      if (appointmentId) {
        const { body } = await getAvailableHoursInADay({
          user_id: doctorId as string,
          date_search: selectedDate?.format('YYYY-MM-DD') as string,
          office_id: selectedOffice,
          duration: !appointmentId
            ? Number(PatientAppointment.office?.appointment_types[0].duration)
            : appointmentDuration,
        })
        hours = (body as IAvailableHours).available_hours
        consultationTimeMinutes = (body as IAvailableHours).consultation_time_minutes
      } else {
        const { data: responseData } = await getDaysAvailabilityUtility(
          selectedOffice,
          rol === 'DOCTOR' ? doctorId as string : doctorCM as string,
          rol === 'DOCTOR' ? 'OFFICE' : 'OFFICE_CM',
          selectedDate?.format('YYYY-MM-DD') as string,
        )
        hours = (responseData.data as IDaysAvailability).intervals_available_hours
        consultationTimeMinutes = (responseData.data as IDaysAvailability).consultation_time_minutes.toString()
      }


      // const hours: string[] = (data).available_hours
      if (
        appointmentId &&
        selectedOffice === appointmentToReschedule?.office_id &&
        selectedDate?.format('YYYY-MM-DD') === appointmentToReschedule?.appointment_date
      ) {
        const itemIndex = hours.findIndex((h) =>
          dayjs(`${selectedDate?.format('YYYY-MM-DD')}T${h}`).isAfter(
            dayjs(`${selectedDate?.format('YYYY-MM-DD')}T${appointmentToReschedule?.hour_from}`),
          ),
        )
        if (itemIndex >= 0) {
          hours.splice(itemIndex, 0, appointmentToReschedule?.hour_from)
        } else {
          hours.push(appointmentToReschedule?.hour_from)
        }
      }

      setAvailableHours(hours)
      setConsultationTimeMinutes(consultationTimeMinutes)
      setShowAvailableHours(true)
      setLoadAvailableHours(false)
    } catch (error) {
      handleAlert(true, 'Error al obtener las horas disponibles del día seleccionado', 'error')
      setShowAvailableHours(true)
      setLoadAvailableHours(false)
    }
  }

  const onSubmit = async (e: { preventDefault(): void }): Promise<void> => {
    try {
      e.preventDefault()
      setSaveScheduleSelected()
      setDisabledButton(true)
      const validatePhone: ICodeUtility<IPhoneVerification> = await verifyPhoneValidationUtility(
        patient?.userId as string,
      )
      if (Number((validatePhone.data as IPhoneVerification).verification_status) === 0) {
        setPhoneVerification(validatePhone.data as IPhoneVerification)
      } else {
        setIsSubmitted(true)
      }
      setDisabledButton(false)
    } catch (error) {
      setDisabledButton(false)
      handleAlert(true, 'Ha ocurrido un error al agendar su cita', 'error')
    }
  }

  useEffect(() => {
    if (selectedOffice) {
      if (appointmentId) {
        const filterType = PatientAppointment.office?.appointment_types?.some(
          (type) => type.name === appointmentToReschedule?.appointment_reason,
        )
        if (
          appointmentToReschedule?.appointment_type === 'OVERBOOKING' && appointmentId) {
          setGlobalError('No se puede re agendar esta cita, por favor toma contacto con el médico.')
          return
        }
        if(
          (appointmentToReschedule?.reason_type === 'DEFAULT' && appointmentId && !filterType) ||
          (appointmentToReschedule?.reason_type === 'CUSTOM' && appointmentToReschedule.office_id !== selectedOffice && appointmentId)) {
          setGlobalError('No se puede re agendar este tipo de cita en este consultorio.')
          return
        }
      }

      setGlobalError('')
      setShowAvailableHours(false)
      setShowForm(false)
      setLoadCalendar(true)
      fetchDaysAvailabilityInAMonth().then((result) => {
        setDaysAvailability(result)
        setLoadCalendar(false)
        setShowCalendar(true)
      })
    }
  }, [selectedOffice])

  useEffect(() => {
    if (selectedHour) {
      setShowForm(true)
      if (!appointmentId) {
        window.scrollTo({
          top: 500,
          behavior: 'smooth',
        })
        setDisabledButton(false)
      } else if (
        (appointmentId &&
          selectedOffice === appointmentToReschedule?.office_id &&
          selectedDate?.format('YYYY-MM-DD') === appointmentToReschedule?.appointment_date &&
          selectedHour === appointmentToReschedule?.hour_from) ||
        !selectedHour
      ) {
        setDisabledButton(true)
      } else {
        window.scrollTo({
          top: 500,
          behavior: 'smooth',
        })
        setDisabledButton(false)
      }
    }
  }, [selectedHour])

  useEffect(() => {
    if (selectedDate) {
      setShowAvailableHours(true)
      setShowForm(false)
      fetchAvailableHoursInADay()
    }
  }, [selectedDate])

  useEffect(() => {
    if (phoneVerification) {
      handleOpenModal()
    }
    if (isSubmitted) {
      // setDisabledButton(true)
      if (appointmentId) {
        saveReschedulingAppointmentUtility({
          appointment_id: appointmentToReschedule?.appointment_id as string,
          appointment_date: PatientAppointment.date_appointment,
          hour_from: PatientAppointment.hour_appointment,
          office_id: PatientAppointment.office_id,
          modified_by: 'patient',
          duration: duration,
        })
          .then((result) => {
            if (result.status === 1) {
              handleAlert(true, result.data, 'success')
              navigate('/dates')
            } else {
              handleAlert(true, result.data, 'error')
            }
          })
          .catch((error) => {
            handleAlert(true, error, 'error')
            setDisabledButton(false)
            setIsSubmitted(false)
          })
      } else {
        const data = {
          appointment_date: PatientAppointment.date_appointment,
          comment: PatientAppointment.comment,
          email: patient.email as string,
          hour_from: PatientAppointment.hour_appointment,
          office_id: PatientAppointment?.office_id,
          phone: patient.mobile,
          user_id: rol === 'DOCTOR' ? doctorId as string : doctorCM as string,
          reference: PatientAppointment.reference,
          patient_id: patient.userId,
          patient_id_number: patient.idNumber,
          office_type: rol === 'DOCTOR' ? 'OFFICE' : 'OFFICE_CM',
        }
        PostNewDateUtility(data)
          .then((result) => {
            if (result.status === 1) {
              handleAlert(true, result.data, 'success')
              handleCloseAlert()
            }
            if (result.status === 0) {
              handleAlert(true, result.data, 'error')
              setIsSubmitted(false)
            }
            setIsSubmitted(false)
          })
          .catch(() => {
            handleAlert(true, 'Error al guardar eñ nuevo evento', 'error')
            setIsSubmitted(false)
            setIsSubmitted(false)
          })
      }
    }
  }, [phoneVerification, isSubmitted])

  useEffect(() => {
    if (appointmentId) {
      fetchAppointmentInfo(appointmentId)
    }
  }, [appointmentId])

  useEffect(() => {
    if (appointmentToReschedule) {
      setSelectedOffice(appointmentToReschedule?.office_id)
      setComments(appointmentToReschedule?.comment)
    }
  }, [appointmentToReschedule])

  useEffect(() => {
    if (officeId) {
      setSelectedOffice(officeId)
    }
  }, [officeId])

  useEffect(() => {
    if (appointmentId && selectedOffice === appointmentToReschedule?.office_id) {
      setSelectedDate(dayjs(appointmentToReschedule?.appointment_date))
    }
  }, [daysAvailability])

  useEffect(() => {
    if (
      appointmentId &&
      selectedOffice === appointmentToReschedule?.office_id &&
      selectedDate?.format('YYYY-MM-DD') === appointmentToReschedule?.appointment_date
    ) {
      setSelectedHour(appointmentToReschedule?.hour_from)
    }
  }, [availableHours])

  return {
    dataDoctor,
    openModal,
    isExtraSmallSize,
    disabledButton,
    loadAvailableHours,
    loadCalendar,
    showAvailableHours,
    showCalendar,
    showForm,
    setReference,
    handleChangeDate,
    handleChangeOffice,
    handleSelectedHour,
    onSubmit,
    handleGoBack,
    isOptionDisabled,
    patient,
    appointmentId,
    selectedOffice,
    doctorId,
    selectedDate,
    daysAvailability,
    availableHours,
    selectedHour,
    comments,
    reference,
    setComments,
    handleCloseModal,
    phoneVerification,
    setPhoneVerification,
    setIsSubmitted,
    globalError,
    idSpecialty,
    rol,
    branchId,
    doctorCM,
  }
}
