import { GetPatientActivities } from '../../../domain/useCase/PatientActivitiesInfoUseCase'
import { IResponseApi, IResponseService } from '../../../infrastructure/dtos'
import { IPatientActivitiesInfo } from '../../../infrastructure/dtos/PatientActivitiesInfo'
import PatientActivitiesInfoMapper from '../../../infrastructure/http/mappers/PatientActivitiesInfoMapper'
import { PatientActivitiesRepositoryImpl } from '../../../infrastructure/RepositoryImpl/PatientActivitiesInfoRepositoryImpl'

export async function PatientActivitiesInfoService(
  user_id: string,
): Promise<IResponseService<IResponseApi<IPatientActivitiesInfo>>> {
  try {
    const useCaseData: GetPatientActivities = new GetPatientActivities(
      new PatientActivitiesRepositoryImpl(new PatientActivitiesInfoMapper()),
    )
    return {
      status: 1,
      data: await useCaseData.invoke(user_id),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
