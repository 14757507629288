import { Star as StarIcon, Close as CloseIcon, ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material'
import { PopoverProps } from '@mui/material'

import {
  GridComment,
  ProgressRate,
  TypographyComment,
  TypographyCommentName,
  ButtonLink,
  ThpographyRate,
  GridContent,
  PopoverQualification,
} from './BoxQualificationsStyles'
import { useBoxQualifications } from './useBoxQualifications'
import { Severity } from '../../contexts/AuthReducer'
import {
  IQualificationList,
  IQualificationParameter,
  IQualificationParameters,
} from '../../infrastructure/dtos/DoctorFeedback'
import { GridItem } from '../GridItem/GridItem'
import { AvatarWrapper, GridWrapper, IconButtonWrapper, TypographyWrapper, LoaderWrapper } from '../wrapper'

const progressMaxValue = 5

interface IBoxQualificationsProps {
  closeQualification: () => void
  handleAlert?: (open: boolean, message: string, severity?: Severity | undefined) => void,
  popoverProps: PopoverProps
  doctorId?: string
}

const QualificationParametersLabels: { [key in IQualificationParameter]: string } = {
  attention: 'Atención',
  punctuality: 'Puntualidad',
}

export const BoxQualifications = ({
  closeQualification,
  handleAlert,
  popoverProps,
  doctorId,
}: IBoxQualificationsProps): JSX.Element => {
  const {
    showComments,
    loadingFeedback,
    feedback,
    setShowComments
  } = useBoxQualifications(
    doctorId,
    handleAlert
  )

  return (
    <PopoverQualification onClose={() => closeQualification()} {...popoverProps}>
      {!loadingFeedback && (
        <>
          {showComments && (
            <IconButtonWrapper
              size="small"
              onClick={() => setShowComments(false)}
              data-testid="hide-qualifications"
              sx={{
                position: 'absolute',
                left: 15,
                top: 15,
              }}
            >
              <ArrowBackIosIcon />
            </IconButtonWrapper>
          )}
          <GridWrapper container display={'flex'} justifyContent={'left'} fontWeight={'bold'} paddingX={2}>
            <GridItem xs={2} color={'#3850B2'}>
              <StarIcon />
            </GridItem>
            <GridItem xs={8}>
              <ThpographyRate>{feedback?.rating_doctor?.rating} - </ThpographyRate>
              <ButtonLink
                variant="text"
                onClick={() => setShowComments(true)}
                disabled={feedback?.qualification_doctor?.number_qualification === 0}
                data-testid="reseñas-button"
              >
                {` ${feedback?.qualification_doctor?.number_qualification || 0} reseñas`}
              </ButtonLink>
            </GridItem>
          </GridWrapper>

          <IconButtonWrapper
            onClick={closeQualification}
            size="small"
            id="close-quelifications"
            data-testid="close-quelifications"
            sx={{
              position: 'absolute',
              top: 15,
              right: 15,
            }}
          >
            <CloseIcon id="close-quelifications-svg" />
          </IconButtonWrapper>

          <GridContent>
            {!showComments && feedback?.rating_doctor?.qualification_parameters ? (
              Object.keys(feedback.rating_doctor.qualification_parameters as IQualificationParameters).map(
                (parameter: string, index: number) => (
                  <GridWrapper container spacing={1} mt={1} display={'flex'} key={`parameter-${index}`}>
                    <GridItem xs={5} justifyContent={'flex-start'}>
                      <TypographyWrapper noWrap>
                        {QualificationParametersLabels[parameter as IQualificationParameter]}
                      </TypographyWrapper>
                    </GridItem>
                    <GridItem xs={5}>
                      <ProgressRate
                        value={
                          ((feedback.rating_doctor?.qualification_parameters as IQualificationParameters)[
                            parameter as IQualificationParameter
                          ] *
                            100) /
                          progressMaxValue
                        }
                        variant="determinate"
                      />
                    </GridItem>
                    <GridItem>
                      <TypographyWrapper noWrap>
                        {' '}
                        {
                          (feedback.rating_doctor?.qualification_parameters as IQualificationParameters)[
                          parameter as keyof IQualificationParameters
                          ]
                        }{' '}
                      </TypographyWrapper>
                    </GridItem>
                  </GridWrapper>
                ),
              )
            ) : (
              <>
                {feedback && feedback.qualification_doctor?.qualification_list &&
                  feedback.qualification_doctor?.qualification_list?.length > 0 ? (
                  feedback.qualification_doctor?.qualification_list.map(
                    (qualification: IQualificationList, index: number) => (
                      <GridWrapper
                        container
                        mt={index === 0 ? 0 : 2}
                        display={'flex'}
                        justifyContent={'center'}
                        key={`qualification-${index}`}
                      >
                        <GridComment item xs={12} display={'flex'}>
                          <GridItem item xs={3} alignItems={'flex-start'}>
                            <AvatarWrapper src={qualification.photo_patient} />
                          </GridItem>
                          <GridWrapper item xs={9}>
                            <TypographyCommentName textAlign={'left'}>
                              {qualification.name_patient}
                            </TypographyCommentName>
                            <TypographyComment textAlign={'start'}>{qualification.comment_patient}</TypographyComment>
                          </GridWrapper>
                        </GridComment>
                      </GridWrapper>
                    ),
                  )
                ) : (
                  <GridWrapper display="flex" justifyContent={'center'} mt={1}>
                    <TypographyCommentName>Sin comentarios para mostrar</TypographyCommentName>
                  </GridWrapper>
                )}
              </>
            )}
          </GridContent>
        </>
      )}
      {loadingFeedback && (
        <GridWrapper display={'flex'} justifyContent={'center'}>
          <LoaderWrapper sx={{ color: '#3850B2' }} />
        </GridWrapper>
      )}
    </PopoverQualification>
  )
}
