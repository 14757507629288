import { OfficesRepository } from '../../domain'
import { IApiDaysAvailability } from '../dtos/OfficeDaysAvailability'
import { IApiMonthAvailability } from '../dtos/OfficeMonthAvailability'
import { IApiResponseOffices, IOfficesProps } from '../dtos/Offices'
import OfficesDataSource from '../http/dataSource/OfficesDataSource'

export class OfficesRepositoryImpl implements OfficesRepository {
  dataSource: OfficesDataSource

  constructor(_datasource: OfficesDataSource) {
    this.dataSource = _datasource
  }

  async getDaysAvailability(
    idOffice: string,
    idDoctor: string,
    officeType: string,
    date: string,
  ): Promise<IApiDaysAvailability> {
    return this.dataSource.getDaysAvailability(idOffice, idDoctor, officeType, date)
  }

  async getMonthAvailability(idOffice: string, idDoctor: string, officeType: string): Promise<IApiMonthAvailability> {
    return this.dataSource.getMonthAvailability(idOffice, idDoctor, officeType)
  }

  async getOffices(params: IOfficesProps): Promise<IApiResponseOffices> {
    return this.dataSource.getOffices(params)
  }
}
