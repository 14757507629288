import { SelectChangeEvent } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SearchOfficesContext } from '../../../../contexts/SeachOfficesContext'
import { IParamsGetNextAppointmentHour } from '../../../../infrastructure/dtos/Calendar'
import { ISearchDoctorMap, ISearchDoctorResponse } from '../../../../infrastructure/dtos/DoctorInfo'
import { SearchDoctorUtility } from '../../../../services/Contracts/Utility/DoctorInfoUtility'
import { IAddressPatient } from '../../../Register/types'

export interface IPagination {
  page: number
  limit: number
  totalPages: number
}

interface IUseSearchDoctorHook<T> {
  showResults: boolean
  searching: boolean
  results: ISearchDoctorMap[]
  query: string
  address: T | null
  addressData?: IAddressPatient
  addNewAddress: boolean
  showMap: boolean
  resultsInMap: ISearchDoctorMap[]
  selectedOffice?: IParamsGetNextAppointmentHour
  selectedResult?: ISearchDoctorMap
  handleSubmit(event: { preventDefault: () => void }): void
  handleSubmitted(value: boolean): void
  onChangeQuery(value: string): void
  onChangeAddress(event: SelectChangeEvent<unknown>): void
  onChangeAddressData(value: IAddressPatient | undefined): void
  handleToggleDrawer: (open: boolean) => void
  handleOpenDetails: (result: ISearchDoctorMap) => void
  handleCloseDetails: (path?: string) => void
}

export function useSearchDoctor<T>(): IUseSearchDoctorHook<T> {
  const { savelastSearch, searchOffices } = useContext(SearchOfficesContext)
  const navigate = useNavigate()

  const [showResults, setShowResults] = useState<boolean>(false)
  const [addNewAddress, setAddNewAddress] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [showMap, setShowMap] = useState<boolean>(true)
  const [submitted, setSubmitted] = useState<boolean | undefined>(undefined)
  const [results, setResults] = useState<ISearchDoctorMap[]>([])
  const [resultsInMap, setResultsInMap] = useState<ISearchDoctorMap[]>([])
  const [pagination, setPagination] = useState<IPagination>({
    page: 0,
    limit: 10,
    totalPages: 10,
  })
  const [address, setAddress] = useState<T | null>(searchOffices?.address as unknown as null)
  const [addressData, setAddressData] = useState<IAddressPatient | undefined>(searchOffices?.addressData)
  const [query, setQuery] = useState<string>(searchOffices?.doctor_name)
  const [selectedOffice, setSelectedOffice] = useState<IParamsGetNextAppointmentHour>()
  const [selectedResult, setSelectedResult] = useState<ISearchDoctorMap>()

  const onChangeQuery = (value: string): void => setQuery(value)
  const onChangeAddress = (event: SelectChangeEvent<unknown>): void => {
    if (event.target.value !== 4) {
      setAddress(event.target.value as T)
    } else {
      setAddNewAddress(true)
    }
  }
  const onChangeAddressData = (value?: IAddressPatient): void => {
    if (value) {
      setAddressData(value)
    } else {
      setAddNewAddress(true)
    }
  }

  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    setShowResults(false)
    setPagination({
      ...pagination,
      page: 1,
    })
    savelastSearch({
      doctor_name: query,
      address: address as string,
      addressData: addressData,
      fromHome: false,
    })
    handleSubmitted(true)
  }

  const handleSearchDoctor = async (): Promise<void> => {
    setSearching(true)
    const { data } = await SearchDoctorUtility({
      page: pagination.page,
      limit: pagination.limit,
      query,
    })
    setResults((data as ISearchDoctorResponse).data.data_list)
    setResultsInMap((data as ISearchDoctorResponse).data.data_map)
    setSearching(false)
    setShowResults(true)
    handleSubmitted(false)
  }

  const handleSubmitted = (value: boolean): void => setSubmitted(value)

  const handleToggleDrawer = (open: boolean): void => {
    setAddNewAddress(open)
  }

  const handleOpenDetails = (result: ISearchDoctorMap): void => {
    if (result.rol === 'DOCTOR_CM') {
      navigate(`/appointment/booking/${result.user_id}?officeId=${result.office_id_cm}&idSpecialty=${result.id_speciality}&rol=${result.rol}&branchId=${result.branch_id_cm}&doctorCM=${result.doctor_id_cm}`)
      return
    }
    setSelectedResult(result)
    setSelectedOffice({
      office_id: result.office_id,
      user_id: result.user_id,
      coordinates: {
        lat: result.coordinates.lat.toString(),
        log: result.coordinates.lng.toString(),
      },
      specialty: result.speciality,
    })
  }

  const handleCloseDetails = (path?: string): void => {
    setSelectedResult(undefined)
    if (path) {
      navigate(path)
    }
  }

  useEffect(() => {
    if (pagination.page > 0) {
      handleSubmitted(true)
    }
  }, [pagination.page])

  useEffect(() => {
    if (submitted) {
      handleSearchDoctor()
    }
  }, [submitted])

  useEffect(() => {
    setShowMap(false)
  }, [results, resultsInMap])

  useEffect(() => {
    if (!showMap) {
      setShowMap(true)
    }
  }, [showMap])

  return {
    showResults,
    searching,
    results,
    query,
    address,
    addressData,
    addNewAddress,
    showMap,
    selectedOffice,
    selectedResult,
    resultsInMap,
    handleSubmit,
    handleSubmitted,
    onChangeQuery,
    onChangeAddress,
    onChangeAddressData,
    handleToggleDrawer,
    handleOpenDetails,
    handleCloseDetails,
  }
}
