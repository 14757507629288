import React, { JSX } from 'react'

import { Carousel } from 'react-responsive-carousel'

import { GridResponsiveDoctor } from './DoctorCarouselStyle'
import SubmitButton from '../../../../components/SubmitButton'
import { GridWrapper, TypographyWrapper } from '../../../../components/wrapper'
import CircularProgressWrapper from '../../../../components/wrapper/LoaderWrapper'
import { IRecommendedDoctors } from '../../../../infrastructure/dtos/DoctorInfo'
import { BoxLabel } from '../../News/NewsStyles'
import { useSuggestedDoctors } from '../hooks/useSuggestedDoctors'
import { AvatarDoctor, SuggestedDoctorsHomeBox } from '../SuggestedDoctorsStyle'

interface ISuggestedDoctorsProps {
  idPatient: string
}

function DoctorCarousel({ idPatient }: ISuggestedDoctorsProps): JSX.Element {
  const { doctors, loading, navigate } = useSuggestedDoctors(idPatient)

  return (
    <SuggestedDoctorsHomeBox data-testid="suggested-doctors-home-box">
      <BoxLabel data-testid="suggested-doctor-title">Médicos sugeridos para ti</BoxLabel>
      {!loading ? (
        doctors.length > 0 && (
          <Carousel
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            renderItem={(item: React.ReactNode) => <div style={{ margin: 0 }}>{item}</div>}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const indicatorStyle = {
                display: 'inline-block',
                margin: '0 8px',
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: isSelected ? '#007aff' : '#c7c7cc',
              }
              return (
                <li
                  style={indicatorStyle}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  aria-label={`${label} ${index + 1}`}
                />
              )
            }}
          >
            {doctors.map((item: IRecommendedDoctors, index: number) => (
              <GridResponsiveDoctor
                container
                direction={'row'}
                key={`suggested-${index}`}
                data-testid="grid-suggested-doctor"
                textAlign={'center'}
              >
                <GridWrapper item xs={3}>
                  <AvatarDoctor src={item.photo_doctor} />
                </GridWrapper>
                <GridWrapper item xs={5} textAlign={'left'}>
                  <TypographyWrapper>
                    {item.name_doctor} {item.last_name_doctor}
                  </TypographyWrapper>
                  <TypographyWrapper>{item.specialty_doctor}</TypographyWrapper>
                </GridWrapper>
                <GridWrapper item xs={4}>
                  <SubmitButton
                    fullWidth
                    id="suggested-doctor-button"
                    data-testid="suggested-doctor-button"
                    text="Cita"
                    type="button"
                    variant="contained"
                    onClick={() => {
                      navigate(`/appointment/booking/${item.user_id}`)
                    }}
                  />
                </GridWrapper>
              </GridResponsiveDoctor>
            ))}
          </Carousel>
        )
      ) : (
        <CircularProgressWrapper data-testid="loading-spinner" />
      )}
    </SuggestedDoctorsHomeBox>
  )
}

export default DoctorCarousel
