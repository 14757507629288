import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import { IPhoneVerification } from '../../../../../infrastructure/dtos/PhoneVerification'
import {
  VerifyCodeUtility,
  resendCodeUtility,
  sendCodeUtility,
  updatePatientPhoneUtility,
} from '../../../../../services/Contracts/Utility/PhoneVerificationUtility'

interface IUseModalConfirmPhoneNumberHook {
  isEditingPhoneNumber: boolean
  enableSendCodeButton: boolean
  enabledResendCodeButton: boolean
  isSavingPhone: boolean
  openModal: boolean
  phoneNumber: string
  codeValue: string
  countdownTimer: string
  message: string
  loadCreatingNewAppointment: boolean
  loadValidatePhone: boolean
  handleIsEditingPhoneNumber(editing: boolean): void
  onChangePhoneNumber(value: string): void
  onChangeCodeValue(value: string): void
  handleCloseModal(): void
  handleOpenModal(): void
  fetchResendCode(): Promise<void>
  fetchSendCode(): Promise<void>
  fetchVerifyCode(): Promise<void>
}

export interface IConfirmPhoneNumber {
  phoneNumber: string
  codeValue: string
}

export function useModalConfirmPhoneNumber(
  phoneVerification?: IPhoneVerification,
  setPhoneVerification?: Dispatch<SetStateAction<IPhoneVerification | undefined>>,
  setIsSubmitted?: Dispatch<SetStateAction<boolean>>,
): IUseModalConfirmPhoneNumberHook {
  const { patient, savePatientData, handleAlert } = useContext(AccountContext) as AuthContextType

  const [initCountdown, setInitCountdown] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [oldPhoneNumber, setOldPhoneNumber] = useState<string>('')
  const [codeValue, setCodeValue] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState<boolean>(false)
  const [enableSendCodeButton, setEnableSendCodeButton] = useState<boolean>(true)
  const [enabledResendCodeButton, setEnabledResendCodeButton] = useState<boolean>(false)
  const [isSavingPhone, setIsSavingPhone] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [loadCreatingNewAppointment, setLoadCreatingNewAppointment] = useState<boolean>(false)
  const [loadValidatePhone, setLoadValidatePhone] = useState<boolean>(false)

  const [countdownTimer, setCountdownTimer] = useState<string>('')

  const handleIsEditingPhoneNumber = (editing: boolean): void => {
    setIsEditingPhoneNumber(editing)
    if (!editing) {
      fetchUpdatePhoneNumber()
    } else {
      setOldPhoneNumber(phoneNumber)
    }
  }
  const onChangePhoneNumber = (value: string): void => setPhoneNumber(value)
  const onChangeCodeValue = (value: string): void => setCodeValue(value)
  const handleCloseModal = (): void => setOpenModal(false)
  const handleOpenModal = (): void => setOpenModal(true)

  // Cambiar el telefono
  const fetchUpdatePhoneNumber = async (): Promise<void> => {
    setIsSavingPhone(true)
    try {
      const { data, status } = await updatePatientPhoneUtility({
        phoneNumber,
        userId: patient?.userId as string,
      })
      if (status) {
        savePatientData({ ...patient, mobile: phoneNumber })
      } else {
        setPhoneNumber(oldPhoneNumber)
      }
      setMessage(data)
      setOldPhoneNumber('')
    } catch {
      setPhoneNumber(oldPhoneNumber)
      setOldPhoneNumber('')
    }
    setIsSavingPhone(false)
  }

  // Reenviar codigo
  const fetchResendCode = async (): Promise<void> => {
    try {
      setMessage('')
      const { data, status } = await resendCodeUtility({
        type: 'patient',
        userId: patient?.userId as string,
      })
      if (status) {
        setPhoneVerification?.(data as IPhoneVerification)
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al reenviar el código', 'error')
    }
  }

  // Verificar el codigo
  const fetchVerifyCode = async (): Promise<void> => {
    try {
      setMessage('')
      setLoadValidatePhone(true)
      const { data, status } = await VerifyCodeUtility({
        userId: patient?.userId as string,
        code: codeValue,
      })
      if (status) {
        setPhoneVerification?.({
          ...(data as IPhoneVerification),
          message:
            (data as IPhoneVerification).remaining_intents_validation === 0
              ? `${
                  (data as IPhoneVerification).message
                } Número máximo de intentos excedido, espere unos minutos para volver a intentar.`
              : (data as IPhoneVerification).message,
        })
      }
      setLoadValidatePhone(false)
    } catch (error: unknown) {
      handleAlert(true, 'Error al verificar el código', 'error')
      setLoadValidatePhone(false)
    }
  }

  // Enviar el codigo
  const fetchSendCode = async (): Promise<void> => {
    try {
      setMessage('')
      setEnableSendCodeButton(false)
      const { data, status } = await sendCodeUtility(patient?.userId as string)
      if (status) {
        setPhoneVerification?.(data as IPhoneVerification)
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al enviar el código', 'error')
    }
  }

  useEffect(() => {
    if (patient?.mobile) {
      onChangePhoneNumber(patient?.mobile)
    }
    if (phoneVerification) {
      setPhoneVerification?.(phoneVerification)
    }
  }, [patient?.mobile, phoneVerification, setPhoneVerification])

  useEffect(() => {
    if (initCountdown && phoneVerification?.created_up) {
      const date1: dayjs.Dayjs = dayjs(new Date())
      const date2: dayjs.Dayjs = dayjs(phoneVerification?.created_up)
      let min: number = date2.diff(date1, 'minutes')
      let sec: number = date2.diff(date1, 'seconds') - min * 60
      if (
        (min <= 0 && sec <= 0) ||
        phoneVerification?.send_status === false ||
        phoneVerification?.resend_status === false
      ) {
        // set message
        return () => null
      }
      // eslint-disable-next-line no-undef
      const interval: NodeJS.Timer = setInterval(() => {
        setEnableSendCodeButton(false)
        sec = -sec
        if (sec === -1) {
          sec = 59
          min = -min
        }
        setEnabledResendCodeButton(min < 4)
        setCountdownTimer(
          `${min}:${sec.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`,
        )
        if (min === 0 && sec === 0) {
          setEnabledResendCodeButton(false)
          setEnableSendCodeButton(true)
          clearInterval(interval)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCountdown, phoneVerification?.created_up])

  useEffect(() => {
    if (phoneVerification) {
      if (phoneVerification?.verify_status === true) {
        setLoadCreatingNewAppointment(true)
        setIsSubmitted?.(true)
      }
      if (phoneVerification?.created_at && phoneVerification?.created_up) {
        setInitCountdown(true)
      }
      setMessage(phoneVerification?.message || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneVerification])

  return {
    isEditingPhoneNumber,
    enableSendCodeButton,
    enabledResendCodeButton,
    isSavingPhone,
    openModal,
    phoneNumber,
    codeValue,
    countdownTimer,
    message,
    loadCreatingNewAppointment,
    loadValidatePhone,
    handleIsEditingPhoneNumber,
    onChangePhoneNumber,
    onChangeCodeValue,
    handleCloseModal,
    handleOpenModal,
    fetchResendCode,
    fetchSendCode,
    fetchVerifyCode,
  }
}
