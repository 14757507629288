import { MouseEvent, ReactElement, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'

import { GridWrapper } from '../../../../../../../../../components'
import { BoxQualifications } from '../../../../../../../../../components/BoxQualifications/BoxQualifications'
import { Severity } from '../../../../../../../../../contexts/AuthReducer'
import { IParamsGetNextAppointmentHour } from '../../../../../../../../../infrastructure/dtos/Calendar'
import { USDollar } from '../../../../../../../../../utils/currency'
import {
  AvatarStyled,
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  GridArrowLeftIcon,
  GridArrowRightIcon,
  ShowDoctorButtomnStyled,
  TypographyStyled,
  SmallAvatar,
} from '../../styled'
import { BadgeDoctorFriends, BadgeRatedDoctor } from '../Badges'
import { PromotionChip } from '../PromotionChip'

export interface IRecommendedDoctorsProps {
  doctors: IDoctorProps[]
  getDoctorInfo: (officeId: string, doctor: IParamsGetNextAppointmentHour) => void
  handleQualifications: (open: boolean, index: number) => void
  handleAlert?: (open: boolean, message: string, severity?: Severity) => void
}

export interface IDoctorProps {
  id: string
  name_doctor: string
  photo_doctor: string
  advertised: boolean
  suggested: boolean
  rating: number
  price: number
  next_appointment_hour: string
  officeId: string
  specialty: string
  related_friends?: {
    name_friend?: string
    photo_friend?: string
    another_friends?: number
  }
  showQualification?: boolean
}

export function CarouselFunction({
  doctors,
  getDoctorInfo,
  handleQualifications,
  handleAlert,
}: IRecommendedDoctorsProps): ReactElement<Carousel> {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const onclickHandler = (clickHandler: () => void): void => {
    if (doctors.length > 3) {
      clickHandler()
    }
  }

  const showQualification = (open: boolean, index: number, event?: MouseEvent<HTMLSpanElement>): void => {
    handleQualifications(open, index)
    setAnchorEl(open && event ? event?.currentTarget : null)
  }

  const round = (num: number): number => {
    return Number(num.toFixed(1));
  }

  return (
    <Carousel
      showIndicators={false}
      showThumbs={false}
      centerMode
      showStatus={false}
      centerSlidePercentage={33}
      width={'100%'}
      renderArrowPrev={(clickHandler) => (
        <GridArrowLeftIcon onClick={() => onclickHandler(clickHandler)}>
          <ChevronLeftIconStyled />
        </GridArrowLeftIcon>
      )}
      renderArrowNext={(clickHandler) => (
        <GridArrowRightIcon onClick={() => onclickHandler(clickHandler)}>
          <ChevronRightIconStyled />
        </GridArrowRightIcon>
      )}
    >
      {doctors.length === 0
        ? []
        : doctors.map((doctor: IDoctorProps, index: number) => (
          <GridWrapper key={doctor?.id}>
            <BadgeRatedDoctor
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              rating={round(doctor?.rating)}
              onClick={(event: MouseEvent<HTMLSpanElement>) => showQualification(true, index, event)}
            >
              <BadgeDoctorFriends
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <GridWrapper display="flex" color="#878787" alignItems="center">
                    <SmallAvatar
                      alt={doctor?.related_friends?.name_friend}
                      src={doctor?.related_friends?.photo_friend}
                    />{' '}
                    + 5
                  </GridWrapper>
                }
              >
                <AvatarStyled src={doctor?.photo_doctor} />
              </BadgeDoctorFriends>
            </BadgeRatedDoctor>
            {doctor.showQualification && (
              <BoxQualifications
                doctorId={doctor.id}
                closeQualification={() => showQualification(false, index)}
                handleAlert={handleAlert}
                popoverProps={{
                  open: doctor.showQualification,
                  anchorEl,
                }}
              />
            )}
            {(doctor?.suggested || doctor?.advertised) && (
              <GridWrapper item marginTop={'-25px'}>
                <PromotionChip doctor={doctor} />
              </GridWrapper>
            )}
            <GridWrapper display={'flex'} justifyContent={'center'} justifyItems={'center'} flexDirection={'column'}>
              <TypographyStyled data-testid="suggested-doctor-name">Dr. {doctor?.name_doctor}</TypographyStyled>
              {doctor?.next_appointment_hour && (
                <TypographyStyled color={'primary'}>{doctor?.next_appointment_hour + 'h'}</TypographyStyled>
              )}
              <TypographyStyled>{USDollar.format(doctor?.price)}</TypographyStyled>
              {!doctor?.next_appointment_hour && (
                <TypographyStyled>
                  <br />
                </TypographyStyled>
              )}
              <GridWrapper display={'grid'} justifyContent={'center'} justifyItems={'center'}>
                <ShowDoctorButtomnStyled
                  id={doctor?.id.toString()}
                  role="button"
                  variant="outlined"
                  size="medium"
                  data-testid="see-button"
                  onClick={() =>
                    getDoctorInfo(doctor?.officeId, {
                      user_id: doctor?.id,
                      office_id: doctor?.officeId,
                      specialty: doctor?.specialty,
                    })
                  }
                >
                  Ver
                </ShowDoctorButtomnStyled>
              </GridWrapper>
            </GridWrapper>
          </GridWrapper>
        ))}
    </Carousel>
  )
}
