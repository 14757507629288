import { useMediaQuery, useTheme } from '@mui/material'
import { useContext, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../../../../contexts/AccountContext'

interface IDocumentStatus {
  name: string
  isLoaded: boolean,
}

interface IUseOrder {
  openDocumentsModal: boolean
  documents: IDocumentStatus[]
  isExtraSmallSize: boolean
  handleOpenDocumentsModal: (value: boolean) => void
  handleChangeDocuments: (file: File | null, index: number) => void
  handleSendDocuments: () => void
}

export const useDocuments = (): IUseOrder => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))
  const [openDocumentsModal, setOpenDocumentsModal] = useState<boolean>(false)
  const [documents, setDocuments] = useState<IDocumentStatus[]>([{
    name: 'Receta',
    isLoaded: false
  }, {
    name: 'Pedido',
    isLoaded: false,
  }, {
    name: 'Factura',
    isLoaded: false,
  }])

  const handleOpenDocumentsModal = (value: boolean): void => setOpenDocumentsModal(value)

  const handleChangeDocuments = (file: File | null, index: number): void => {
    const temp = structuredClone(documents)
    temp[index].isLoaded = file !== null
    setDocuments([...temp])
  }

  const handleSendDocuments = (): void => {
    handleAlert(true, 'Documentos enviados con éxito', 'success')
    handleOpenDocumentsModal(false)
  }

  return {
    openDocumentsModal,
    documents,
    isExtraSmallSize,
    handleOpenDocumentsModal,
    handleChangeDocuments,
    handleSendDocuments,
  }
}
