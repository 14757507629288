import {
  BottomNavigationAction,
  BottomNavigationActionProps
} from '@mui/material'
import React from 'react'

const BottomNavigationActionWrapper = React.forwardRef<HTMLButtonElement, BottomNavigationActionProps>((props, ref) => (
  <BottomNavigationAction {...props} ref={ref} />
))

export default BottomNavigationActionWrapper
