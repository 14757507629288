import { Snackbar, SnackbarProps } from '@mui/material'
import React from 'react'

type SnackbarWrapperProps = SnackbarProps & React.RefAttributes<HTMLDivElement>

const SnackbarWrapper = React.forwardRef<HTMLDivElement, SnackbarWrapperProps>(
  ({ children, ...props }, ref) => (
    <Snackbar {...props} ref={ref}>
      {children}
    </Snackbar>
  ),
)

export default SnackbarWrapper
