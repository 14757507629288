import { styled } from '@mui/material'

import { BoxWrapper } from '../wrapper'

export const BoxSidePanel = styled(BoxWrapper)(({ theme }) => ({
  width: 500,
  height: 'auto',
  padding: '0 20px',
  [theme.breakpoints.only('xs')]: {
    width: 'auto',
    height: 500,
  },
}))
