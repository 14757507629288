import { styled } from '@mui/material/styles'

import { AvatarWrapper, ButtonWrapper, TypographyWrapper } from '../../../../components'
import CardWrapper from '../../../../components/wrapper/CardWrapper'

export const CustomAvatar = styled(AvatarWrapper)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.white}`,
  width: 100,
  height: 100,
}))

export const CardInfo = styled(CardWrapper)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 600,
  border: 'none',
  boxShadow: 'none',
  backgroundColor: '#F7F8FD',
}))

export const TypographyInfo = styled(TypographyWrapper)(() => ({
  fontSize: 20,
  fontWeight: 'bold',
}))
export const LabelText = styled(TypographyWrapper)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.common.black,
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.3rem',
}))
export const ButtonR = styled(ButtonWrapper)(({ theme }) => ({
  margin: '10px 5px',
  textTransform: 'capitalize',
  borderRadius: '50px',
  backgroundColor: theme.palette.common.white,
  height: 50,
  color: theme.palette.primary.main,
  padding: '7px 16px',
  border: '1px solid ' + theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))
