import { linearProgressClasses, styled } from '@mui/material'

import { ButtonWrapper, GridWrapper, LinearProgressWrapper, PopoverWrapper, TypographyWrapper } from '../wrapper'

export const ProgressRate = styled(LinearProgressWrapper)(({ theme }) => ({
  width: '100%',
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[300],
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#3850B2',
  },
}))

export const PopoverQualification = styled(PopoverWrapper)(({ theme }) => ({
  zIndex: 3000,
  '& .MuiPaper-root': {
    height: 300,
    width: 300,
    overflowY: 'clip',
    backgroundColor: theme.palette.common.white,
    borderRadius: '30px',
    padding: '20px',
    [theme.breakpoints.only('xs')]: {
      width: 280,
    },
  },
}))

export const GridContent = styled(GridWrapper)(() => ({
  height: '88%',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: '0.4em',
  },
  '::-webkit-scrollbar-track': {},
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'lightgray',
  },
}))

export const GridComment = styled(GridWrapper)(() => ({
  backgroundColor: '#F7F8FD', // TODO research how to get mui material custom palette in google maps
  borderRadius: '9px',
  '&.MuiGrid-item': {
    padding: 4,
  },
}))

export const TypographyCommentName = styled(TypographyWrapper)(() => ({
  fontWeight: 'bold',
  fontSize: '11px',
}))

export const TypographyComment = styled(TypographyWrapper)(() => ({
  fontSize: '10px',
}))

export const ButtonLink = styled(ButtonWrapper)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.common.black,
}))

export const ThpographyRate = styled(TypographyWrapper)(() => ({
  fontSize: '0.875rem',
  fontWeight: 500,
}))
