import { styled } from '@mui/material'

import { GridWrapper, TypographyWrapper } from '../../../../../components/wrapper'

export const LabelTextName = styled(TypographyWrapper)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.common.black,
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.3rem',
  [theme.breakpoints.only('xs')]: {
    textAlign: 'center',
  },
}))

export const SubTitle = styled(TypographyWrapper)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.common.black,
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.3rem',
}))

export const SubTitleSpecialty = styled(SubTitle)(({ theme }) => ({
  textAlign: 'left',
  [theme.breakpoints.only('xs')]: {
    textAlign: 'center',
  },
}))

export const ListTitle = styled(TypographyWrapper)(() => ({
  color: 'grey',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  margin: '15px 0',
}))

export const ListContent = styled(TypographyWrapper)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 'bold',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  margin: '15px 0',
  textAlign: 'right',
}))

export const GridList = styled(GridWrapper)(() => ({
  display: 'block',
}))

export const TypoDetailsTitle = styled(TypographyWrapper)(() => ({
  fontSize: 10,
  textAlign: 'center',
}))

export const TypoDetailsInfo = styled(TypographyWrapper)(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  textAlign: 'center',
}))
