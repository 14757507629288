import { GendersRepository } from '../../domain/repository/GendersRepository'
import { GendersResponse } from '../dtos/Genders'
import GendersDataSource from '../http/dataSource/GendersDataSource'

export class GendersRepositoryImpl implements GendersRepository {
  dataSource: GendersDataSource

  constructor(_dataSource: GendersDataSource) {
    this.dataSource = _dataSource
  }

  async getGenders(): Promise<GendersResponse> {
    return this.dataSource.getGenders()
  }
}
