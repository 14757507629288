import {
  IApiResponseSaveDataFollow,
  IResponseSaveDataFollow,
  SaveDataFollow,
} from '../../../infrastructure/dtos/Follow'
import { FollowService } from '../Persistencia/FollowService'

export interface IFollowUtility {
  status: number
  data: IResponseSaveDataFollow | string
}

export async function FollowUtility(params: SaveDataFollow): Promise<IFollowUtility> {
  try {
    const { data, status } = await FollowService(params)
    return {
      status,
      data: (data as IApiResponseSaveDataFollow).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
