import { GetAppointmentRecipes } from '../../../domain/useCase/PrescriptionsUseCase'
import { IApiPrescription, IRecipePrescription } from '../../../infrastructure/dtos/Prescription'
import PrescriptionsMapper from '../../../infrastructure/http/mappers/PrescriptionMapper'
import { PrescriptionsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/PrescriptionsRepositoryImpl'

export interface IGetAppointmentRecipesService {
  status: number
  data: IApiPrescription<IRecipePrescription | string> | string
}

export async function getAppointmentRecipesService(appointmentId: string): Promise<IGetAppointmentRecipesService> {
  try {
    const UseCasePrescription = new GetAppointmentRecipes(new PrescriptionsRepositoryImpl(new PrescriptionsMapper()))
    return {
      status: 1,
      data: await UseCasePrescription.invoke(appointmentId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
