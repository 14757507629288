import { styled } from '@mui/material'

import { BoxWrapper } from '../../../components'
import GridWrapper from '../../../components/wrapper/GridWrapper'

export const GridBookingForm = styled(GridWrapper)(({ theme }) => ({
  width: '100%',
  height: '111px',
  backgroundColor: '#F7F8FD',
  display: 'flex',
  justifyContent: 'right',
  margin: '40px 50px 0 0',
  [theme.breakpoints.only('xs')]: {
    display: 'block',
    margin: '10px 0 200px 0',
  },
}))

export const ProfileBox = styled(BoxWrapper)(() => ({
  backgroundColor: 'white',
  marginRight: '30%',
  borderRadius: '30px',
  display: 'flex',
  justifyContent: 'center',
  padding: '20px',
}))
