import { AxiosError, AxiosResponse } from 'axios'

import { QualificationQuestionsResponse } from '../../dtos/QualificationQuestions'
import QualificationQuestionsDataSource from '../dataSource/QualificationQuestionsDataSource'
import { http } from '../Http'

export default class QualificationQuestionsMapper
  implements QualificationQuestionsDataSource
{
  async getQualificationQuestions(): Promise<QualificationQuestionsResponse> {
    const data = await http
      .get<QualificationQuestionsResponse>(`send_data/qualification_questions`)
      .then((response: AxiosResponse) => {
        const { data } = response
        return data
      })
      .catch((error: AxiosError) => {
        const data: QualificationQuestionsResponse = {
          statusCode: error.response?.status as number,
          body: error.response?.statusText as string,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }
        return data
      })
    return data
  }
}
