import { JSX } from 'react'

import { StyledCardHeader, SubHeaderP } from './styles'
import hospitalLogo from '../../../../../assets/img/hospital_logo.jpg'
import { ListItemWrapper, ListWrapper } from '../../../../../components'
import { CardInfo } from '../../../../../components/StyledComponents/CardInfo'
import { CustomAvatar } from '../../../../../components/StyledComponents/CustomAvatar'
import CardContentWrapper from '../../../../../components/wrapper/CardContentWrapper'
import GridWrapper from '../../../../../components/wrapper/GridWrapper'
import { ISearchDoctorMap } from '../../../../../infrastructure/dtos/DoctorInfo'
import { BadgeHeader } from '../../../../ScheduleAppointment/components/PhotoDoctor/styles'
import { SubTitleCM } from '../../../DoctorResultsGoogleMap/Components/CMResultsDetails/styles'

interface ICardDoctorProps {
  result: ISearchDoctorMap
  selectedResult?: ISearchDoctorMap
  onClick: (result: ISearchDoctorMap) => void
}

function CardSearchResult({ result, selectedResult, onClick }: ICardDoctorProps): JSX.Element {
  const isDoctor: boolean = ['DOCTOR', 'DOCTOR_CM'].includes(result.rol)

  const getPhoto = (photo: string, rol: string): string => {
    if (['DOCTOR', 'DOCTOR_CM'].includes(rol)) { return photo }
    if (photo) { return photo }
    return hospitalLogo
  }

  const isSelected: boolean = result.id === selectedResult?.id

  return (
    <CardInfo
      id="card-info"
      onClick={() => onClick(result)}
      sx={{
        backgroundColor: isSelected ? '#DCE7FF' : 'common.white'
      }}
    >
      <GridWrapper container justifyContent={'space-around'}>
        <GridWrapper item>
          <BadgeHeader
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={result.rating.toFixed(1)}
            color="secondary"
          >
            <CustomAvatar sx={{ margin: '10% 0' }} src={getPhoto(result.photo, result.rol)} alt={result.name} />
          </BadgeHeader>
        </GridWrapper>
        <GridWrapper item xs={8} sx={{
          '&.MuiGrid-item': {
            paddingLeft: 0
          }
        }}>
          <GridWrapper item display={'flex'} justifyContent={'space-between'} paddingRight={'10px'}>
            <GridWrapper item>
              <StyledCardHeader title={result.name} subheader={
                isDoctor
                  ? <SubHeaderP>{result.speciality}</SubHeaderP>
                  : <>
                    <SubHeaderP>{result.phone}</SubHeaderP>
                    <SubHeaderP>{result.email}</SubHeaderP>
                  </>
              } />
            </GridWrapper>
            <GridWrapper item sx={{ paddingBlock: '8px' }}>
              {result.rol === 'DOCTOR_CM' && <SubTitleCM
                sx={{
                  fontSize: '10px',
                  color: 'primary.main',
                  fontWeight: 'bold',
                  textAlign: 'right',
                  letterSpacing: .1,
                }}
              >
                precio normal: <span style={{ textDecoration: 'line-through' }}>{result.full_price}</span>
              </SubTitleCM>}
              {isDoctor && <SubTitleCM sx={{ color: 'primary.dark', textAlign: 'right' }} fontWeight={'bold'}>
                {result.consultation_value}
              </SubTitleCM>}
            </GridWrapper>
          </GridWrapper>

          <CardContentWrapper sx={{ paddingTop: 1 }}>
            <ListWrapper sx={{ fontSize: '12px', padding: '0px' }}>
              <ListItemWrapper disablePadding sx={{ alignItems: 'flex-start' }}>
                <SubHeaderP><strong>Direccion:</strong>{` ${result.direction_office}`}</SubHeaderP>
              </ListItemWrapper>
              {isDoctor && <ListItemWrapper disablePadding>
                <SubHeaderP><strong>Atenciones:</strong> {` ${result.people_attended}`}</SubHeaderP>
              </ListItemWrapper>}
            </ListWrapper>
          </CardContentWrapper>
        </GridWrapper>
      </GridWrapper>
    </CardInfo>
  )
}

export default CardSearchResult
