import { PostCreateLocation, PostDeletedLocation, PostModifiedLocation } from '../../../domain/useCase/LocationUseCase'
import {
  IApiLocation,
  ICreateLocation,
  IDeletedLocation,
  ILocationService,
  IModifiedLocation,
} from '../../../infrastructure/dtos/location'
import LocationMapper from '../../../infrastructure/http/mappers/LocationMapper'
import { LocationRepositoryImpl } from '../../../infrastructure/RepositoryImpl/LocationRepositoryImpl'

export async function CreateLocationService(params: ICreateLocation): Promise<ILocationService<IApiLocation>> {
  try {
    const UseCaseAppointment: PostCreateLocation = new PostCreateLocation(
      new LocationRepositoryImpl(new LocationMapper()),
    )
    return {
      status: 1,
      data: await UseCaseAppointment.invoke(params),
    }
  } catch (error) {
    return {
      status: 0,
      data: 'Error Service.',
    }
  }
}

export async function ModifiedLocationService(params: IModifiedLocation): Promise<ILocationService<IApiLocation>> {
  try {
    const UseCaseAppointment: PostModifiedLocation = new PostModifiedLocation(
      new LocationRepositoryImpl(new LocationMapper()),
    )
    return {
      status: 1,
      data: await UseCaseAppointment.invoke(params),
    }
  } catch (error) {
    return {
      status: 0,
      data: 'Error Service.',
    }
  }
}

export async function DeletedLocationService(params: IDeletedLocation): Promise<ILocationService<IApiLocation>> {
  try {
    const UseCaseAppointment: PostDeletedLocation = new PostDeletedLocation(
      new LocationRepositoryImpl(new LocationMapper()),
    )
    return {
      status: 1,
      data: await UseCaseAppointment.invoke(params),
    }
  } catch (error) {
    return {
      status: 0,
      data: 'Error Service.',
    }
  }
}
