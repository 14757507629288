
import { Popover, PopoverProps } from '@mui/material';
import React from 'react';

// const PopoverWrapper: React.FC<PopoverProps> = ({ ...props }) => <Popover {...props} />
const PopoverWrapper = React.forwardRef<HTMLDivElement, PopoverProps>((props, ref) => (
  <Popover {...props} ref={ref} />
))

export default PopoverWrapper;
