import { EventOutlined, HomeOutlined } from '@mui/icons-material'
import { useState, JSX } from 'react'

import { useNavigate } from 'react-router-dom'

import { CalendarDate } from '../../../../components/StyledComponents/CalendarDate'
import { BottomNavigationActionWrapper, BottomNavigationWrapper, PaperWrapper } from '../../../../components/wrapper'

function NavBarButton(): JSX.Element {
  const [value, setValue] = useState<number>(0)
  const navigate = useNavigate()
  return (
    <PaperWrapper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        zIndex: 2000,
      }}
      elevation={3}
      data-testid="paper-wrapper"
    >
      <BottomNavigationWrapper
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue)
        }}
        data-testid="bottom-navigation-wrapper"
      >
        <BottomNavigationActionWrapper
          label="Inicio"
          icon={<HomeOutlined fontSize="medium" data-testid="home-icon" />}
          onClick={() => {
            navigate('/')
          }}
          data-testid="bottom-navigation-action-home"
        />
        <BottomNavigationActionWrapper
          label="Agendar"
          icon={<EventOutlined fontSize="medium" />}
          onClick={() => {
            navigate('/appointment')
          }}
          data-testid="bottom-navigation-action-event"
        />
        <BottomNavigationActionWrapper
          label="Citas"
          icon={<CalendarDate fontSize="medium" style={{ marginRight: 0 }} />}
          onClick={() => {
            navigate('/dates')
          }}
          data-testid="bottom-navigation-action-calendar"
        />
      </BottomNavigationWrapper>
    </PaperWrapper>
  )
}

export default NavBarButton
