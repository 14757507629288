import { ValidatorsRepository } from '../../domain/repository/ValidatorsRepository';
import { IResponseApi } from '../dtos';
import { ISendValidationIdDocument } from '../dtos/Validation';
import ValidatorsDataSource from '../http/dataSource/ValidatorsDataSource';

export class ValidatorsRepositoryImpl implements ValidatorsRepository {
  dataSource: ValidatorsDataSource;

  constructor(_dataSource: ValidatorsDataSource) {
    this.dataSource = _dataSource;
  }

  async IdDocument(
    params: ISendValidationIdDocument
  ): Promise<IResponseApi<string>> {
    return this.dataSource.IdDocument(params);
  }
}
