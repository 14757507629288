import { DialogTitle, DialogTitleProps } from '@mui/material';
import React from 'react';



const DialogTitleWrapper: React.FC<DialogTitleProps> = ({ ...props }) => (
  <DialogTitle {...props} />
);

export default DialogTitleWrapper;
