import { useState, JSX } from 'react'

import { SubTitleSpecialty } from '../../styles'

interface ISkillsProps {
  skills: string[]
}

export const Skills = ({ skills = [] }: ISkillsProps): JSX.Element => {
  const [showAll, setShowAll] = useState(false)

  const displayedSkills = showAll ? skills : skills.slice(0, 4)

  return (
    <>
      {skills.length > 0 && (
        <>
          <SubTitleSpecialty sx={{ color: 'tertiary.dark' }}>
            Habilidades: {displayedSkills.join(', ')}
          </SubTitleSpecialty>
          {skills.length > 4 && (
            <SubTitleSpecialty
              sx={{
                color: 'primary.main',
                cursor: 'pointer',
                //   add estilo de subrayado
                textDecoration: 'underline',
              }}
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? 'Ver menos' : 'Ver más'}
            </SubTitleSpecialty>
          )}
        </>
      )}
    </>
  )
}
