import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { debounce, useTheme, useMediaQuery } from '@mui/material'
import React, { FormEvent, useContext, useEffect, useMemo, useState, JSX } from 'react'
import { useParams } from 'react-router-dom'

import { ButtonR, GridRow } from './FillDataStyles'
import { GridWrapper, StackWrapper } from '../../../../components'
import { BackButton } from '../../../../components/BackButton/BackButton'
import FormTextInput from '../../../../components/FormInputText'
import { List } from '../../../../components/List/List'
import { Advert } from '../../../../components/Notification/Advert/Advert'
import { GridHalfScreen } from '../../../../components/StyledComponents/GridHalfScreen'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import SubmitButton from '../../../../components/SubmitButton'
import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'
import { IAppointmentInfo, PatientAppointmentContext } from '../../../../contexts/PatientAppointmentContext'
import { PatientInfo } from '../../../../infrastructure/dtos/Patient'
import { PostNewDateUtility } from '../../../../services/Contracts/Utility/NewDateUtility'
import { GetPatientRegisterByCiUtility } from '../../../../services/Contracts/Utility/PatientUtility'
import { getValidationMessage, validateField, validateId } from '../../../../utils/requiredField'
import { validateEmail } from '../../../../utils/validateEmail'
import { validateAndDisplayPhone, validatePhoneNumber } from '../../../../utils/validatePhoneNumber'
import { useValidationComponent } from '../../../Register/PatientForm/PatientFormHook'
import { InputsGrid } from '../../../Register/PatientForm/PatientFormStyle'
import { MobileBackButton } from '../../../Register/PatientMap/styles/RegisterAddressFormStyles'
import DataDoctor from '../../components/DataDoctor'
import DoctorInfo from '../../components/DoctorInfo'
import { useDataDoctor } from '../../Hooks/useDataDoctor'
import { GridContent, GridLayoutLeft, GridLayoutRight } from '../SelectSchedule/SelectScheduleStyle'

interface FillDataProps {
  onStepper: () => void
}

const idTypes: string[] = ['Cédula', 'Pasaporte']

export const FillData = ({ onStepper }: FillDataProps): JSX.Element => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { idDoctor } = useParams<string>()
  const { dataDoctor } = useDataDoctor(idDoctor as string)
  const { PatientAppointment, saveAppointmentFormInfo } = useContext(PatientAppointmentContext)
  const [idType, setIdType] = useState<string>('Cédula')

  const DocumentType: { [key: string]: string } = {
    Pasaporte: 'PASSPORT',
    Cédula: 'CI',
  }

  const [appointmentInfo, setAppointmentInfo] = useState<IAppointmentInfo>({
    patient_name: PatientAppointment?.patient_name,
    patient_last_name: PatientAppointment?.patient_last_name,
    patient_email: PatientAppointment?.patient_email,
    patient_phone_number: PatientAppointment?.patient_phone_number,
    appointment_reason: PatientAppointment?.appointment_reason,
    add_to_calendar: PatientAppointment?.add_to_calendar,
    patient_id_number: PatientAppointment?.patient_id_number,
    document_type: DocumentType[idType],
  })
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isSubmittedOk, setIsSubmittedOk] = useState<boolean>(false)
  const [isInputsDisabled, setIsInputsDisabled] = useState<boolean>(false)
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([false, false, false, false, false])

  const [dataFound, setDataFound] = useState<boolean>(true)

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [messageModal, setMessageModal] = useState<string>('')

  const { fetchValidationIdDocument, validatorIdDocument } = useValidationComponent()

  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const textErrorIdNumber = (): string => {
    if (!validateId(idType, validateIdNumberPassport, validateIdNumberCI)) {
      return validatorIdDocument.message
    }
    return validateId(idType, validateIdNumberPassport, validateIdNumberCI)
  }

  const setFormData = (): void => {
    saveAppointmentFormInfo({
      ...PatientAppointment,
      patient_name: appointmentInfo.patient_name,
      patient_last_name: appointmentInfo.patient_last_name,
      patient_email: appointmentInfo.patient_email,
      patient_phone_number: appointmentInfo.patient_phone_number,
      appointment_reason: appointmentInfo.appointment_reason,
      add_to_calendar: appointmentInfo.add_to_calendar,
      patient_id_number: appointmentInfo.patient_id_number,
      document_type: DocumentType[idType],
    })
  }

  const handleGoBack = (): void => {
    setFormData()
    onStepper()
  }

  useEffect(() => {
    if (isSubmitted) {
      PostNewDateUtility({
        appointment_date: PatientAppointment.date_appointment,
        comment: PatientAppointment.appointment_reason,
        email: PatientAppointment.patient_email,
        hour_from: PatientAppointment.hour_appointment,
        // hour_to: dayjs(`${PatientAppointment.date_appointment}T${PatientAppointment.hour_appointment}`)
        //   .add(PatientAppointment.consultation_time_minutes, 'minutes')
        //   .format('HH:mm'),
        patient_id_number: PatientAppointment.patient_id_number,
        office_id: PatientAppointment?.office_id,
        patient_last_name: PatientAppointment.patient_last_name,
        patient_name: PatientAppointment.patient_name,
        phone: PatientAppointment.patient_phone_number,
        user_id: idDoctor as string,
        document_type: DocumentType[idType],
        office_type: 'OFFICE',
        // duration: Number(PatientAppointment?.office?.appointment_types[0].duration),
      })
        .then((result) => {
          if (result.status === 1) {
            setIsInputsDisabled(true)
            setIsSubmittedOk(true)
          } else {
            setIsSubmitted(false)
          }
          setMessageModal(result.data)
          window.scrollTo(0, 0)
          setOpenModal(true)
        })
        .catch(() => {
          handleAlert(true, 'Error guardando nuevo evento:', 'error')
        })
    }
  }, [PatientAppointment, isSubmitted])

  const onSubmit = (event: FormEvent): void => {
    event.preventDefault()
    setFormData()
    setIsSubmitted(true)
  }
  const openLoginHelthy = (): void => {
    const url = process.env.REACT_APP_URL
    window.open(url, '_blank')
  }

  const validateIdNumberPassport: boolean = !(appointmentInfo?.patient_id_number?.length >= 3) && ValidationFocus[0]

  const validateIdNumberCI: boolean =
    !(appointmentInfo?.patient_id_number?.length === 10 && /^\d*$/.test(appointmentInfo?.patient_id_number)) &&
    ValidationFocus[0]

  const validateIdNumber: boolean = idType === 'Pasaporte' ? validateIdNumberPassport : validateIdNumberCI

  const validateName: boolean = validateField(appointmentInfo?.patient_name, ValidationFocus[1])
  const validateLastName: boolean = validateField(appointmentInfo?.patient_last_name, ValidationFocus[2])

  const validateEmailField = (): boolean => {
    const isValid = validateField(appointmentInfo?.patient_email, ValidationFocus[3])
    return isValid || (!validateEmail(appointmentInfo?.patient_email) && ValidationFocus[3])
  }

  const validateFieldArrowFunction = (email: string, validationFocus: boolean): string => {
    return validateField(email, validationFocus)
      ? 'Campo requerido.'
      : !validateEmail(email) && validationFocus
        ? 'Por favor, ingresa una dirección de correo válida'
        : ''
  }

  const validateMobile: boolean = validateField(appointmentInfo?.patient_phone_number, ValidationFocus[4])

  const validateReason: boolean = validateField(appointmentInfo?.appointment_reason, ValidationFocus[5])

  const submitValidation = (): boolean => {
    return (
      appointmentInfo.patient_name?.length === 0 ||
      appointmentInfo.patient_last_name?.length === 0 ||
      appointmentInfo.patient_email?.length === 0 ||
      !validateEmail(appointmentInfo.patient_email) ||
      appointmentInfo.patient_phone_number?.length === 0 ||
      !validatePhoneNumber(appointmentInfo.patient_phone_number) ||
      appointmentInfo.appointment_reason?.length === 0 ||
      appointmentInfo.patient_id_number?.length === 0 ||
      isSubmitted ||
      validateIdNumber ||
      validatorIdDocument.status
    )
  }

  const isDisabled: boolean = submitValidation()

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputName: string,
  ): void => {
    setAppointmentInfo({
      ...appointmentInfo,
      [inputName]: event.target.value,
    })
  }

  const fetchPatient = async (value: string): Promise<void> => {
    const { data, status } = await GetPatientRegisterByCiUtility(value)

    const xdata: PatientInfo = data as PatientInfo
    if (status === 0) {
      setDataFound(false)
    }
    if (status === 1) {
      setDataFound(true)
    }
    setAppointmentInfo({
      ...appointmentInfo,
      patient_name: xdata.name_patient,
      patient_last_name: xdata.last_name_patient,
      patient_email: xdata.email_patient,
      patient_phone_number: xdata.number_phone_patient,
      patient_id_number: value,
    })
  }

  const searchDelayed = useMemo(() => debounce(fetchPatient, 1000), [])

  const handleDocumentNumberChange = (event: React.ChangeEvent<{ value: string }>): void => {
    const { value } = event.target
    setAppointmentInfo({
      ...appointmentInfo,
      patient_id_number: value,
    })
  }

  useEffect(() => {
    if (
      (idType === 'Pasaporte' && appointmentInfo.patient_id_number?.length >= 3) ||
      (idType === 'Cédula' && appointmentInfo.patient_id_number?.length === 10)
    ) {
      const document_type: 'CI' | 'PASSPORT' = idType === 'Cédula' ? 'CI' : 'PASSPORT'
      fetchValidationIdDocument({
        document: appointmentInfo.patient_id_number,
        document_type: document_type,
        type: 'APPOINTMENT',
      })
      searchDelayed(appointmentInfo.patient_id_number)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentInfo.patient_id_number])

  return (
    <>
      <GridContent sx={{ height: '80vw' }}>
        <GridLayoutLeft
          sx={{
            width: isSubmittedOk ? (isExtraSmallSize ? '100' : '65%') : '100%',
          }}
        >
          <GridHalfScreen item sm={2}>
            {!isInputsDisabled && (
              <>
                {!isExtraSmallSize ? (
                  <BackButton id="backButton" text="Regresar" onClick={handleGoBack} />
                ) : (
                  <MobileBackButton onClick={handleGoBack} sx={{ zIndex: 99999, top: '30px', position: 'absolute' }}>
                    <ChevronLeftIcon />
                  </MobileBackButton>
                )}
              </>
            )}
          </GridHalfScreen>
          <GridWrapper
            item
            xs={12}
            marginBottom={2}
            width={!isSubmittedOk ? (isExtraSmallSize ? '100%' : '55%') : '100%'}
            justifySelf="center"
          >
            {isSubmittedOk && isExtraSmallSize ? (
              <></>
            ) : (
              <GridWrapper>
                <GridRow
                  item
                  marginBottom={5}
                  marginTop={2}
                  marginLeft={isSubmittedOk ? (isExtraSmallSize ? 0 : 5) : 0}
                >
                  <DoctorInfo
                    message="Confirma tu cita con el Dr. "
                    info={dataDoctor}
                    patientAppointment={PatientAppointment}
                    imageSize={
                      isSubmittedOk
                        ? {
                            width: '140px',
                            height: '140px',
                          }
                        : {
                            width: '160px',
                            height: '160px',
                          }
                    }
                    sx={{
                      fontSize: isExtraSmallSize ? '18px' : '22px',
                    }}
                    align={isSubmittedOk}
                  />
                </GridRow>
              </GridWrapper>
            )}

            {isSubmittedOk && !isExtraSmallSize && (
              <LabelInput
                textAlign="left"
                sx={{ fontSize: '20px', fontWeight: 'bold' }}
                marginTop={isExtraSmallSize ? 15 : 5}
              >
                Ingresa tus datos
              </LabelInput>
            )}
          </GridWrapper>

          {isSubmittedOk && isExtraSmallSize ? (
            <GridWrapper
              item
              sm={12}
              justifyContent="center"
              alignItems="center"
              margin={'auto'}
              display={'flex'}
              flexDirection={'column'}
              sx={{
                width: '100%',
              }}
            >
              <DataDoctor info={dataDoctor} />
              <GridWrapper
                container
                item
                sm={12}
                justifyContent="center"
                alignItems="center"
                margin={'auto'}
                display={'flex'}
                flexDirection={'column'}
                sx={{
                  marginTop: '30px',
                  width: '100%',
                }}
              >
                <GridWrapper item>
                  <LabelInput
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '16px',
                      width: '80%',
                      textAlign: 'center',
                    }}
                  >
                    Ingresa a Helthy y realiza el seguimiento de tu cita
                  </LabelInput>
                </GridWrapper>
                <GridWrapper item>
                  <ButtonR
                    type="button"
                    onClick={() => {
                      openLoginHelthy()
                    }}
                    data-testid="modal-button"
                  >
                    Ingresar
                  </ButtonR>
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>
          ) : (
            <GridWrapper
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              flexDirection="column"
              marginTop={0}
            >
              <form
                onSubmit={onSubmit}
                data-testid="patient-appointment-form"
                style={{
                  width: isSubmittedOk ? '90%' : isExtraSmallSize ? '90%' : '55%',
                }}
              >
                <InputsGrid item xs={12}>
                  <GridWrapper
                    item
                    xs={12}
                    md={6}
                    sx={
                      isExtraSmallSize
                        ? {
                            marginBottom: '22px',
                          }
                        : {}
                    }
                  >
                    <List
                      id="selectIdType"
                      lists={idTypes}
                      onChange={(event: string) => setIdType(event)}
                      value={idType}
                      minWidth="100%"
                      text={'Tipo de documento'}
                    />
                  </GridWrapper>
                  <GridWrapper item xs={12} md={6}>
                    <FormTextInput
                      text="¿Cuál es tu número de documento?"
                      placeholder=""
                      id="patient_id_number"
                      type="text"
                      fullWidth
                      value={appointmentInfo.patient_id_number}
                      name="reason"
                      disabled={isInputsDisabled}
                      onBlur={() => onValidationFocus(0)}
                      onChange={handleDocumentNumberChange}
                      error={validateIdNumber || validatorIdDocument.status}
                      texterror={textErrorIdNumber()}
                      sx={{
                        '& .MuiInputBase-root': {
                          color: 'tertiary.dark',
                        },
                      }}
                    />
                  </GridWrapper>
                </InputsGrid>

                <InputsGrid item xs={12}>
                  <GridWrapper
                    item
                    xs={12}
                    md={6}
                    sx={
                      isExtraSmallSize
                        ? {
                            marginBottom: '22px',
                          }
                        : {}
                    }
                  >
                    <FormTextInput
                      text="¿Cuál es tu nombre?"
                      placeholder="Joe"
                      id="name"
                      type="text"
                      fullWidth
                      value={appointmentInfo.patient_name || ''}
                      name="name"
                      disabled={isInputsDisabled || dataFound || validateIdNumber}
                      onBlur={() => onValidationFocus(1)}
                      onChange={(event) => handleInputChange(event, 'patient_name')}
                      error={validateName}
                      texterror={getValidationMessage(validateName)}
                      sx={{
                        '& .MuiInputBase-root': {
                          color: 'tertiary.dark',
                        },
                      }}
                    />
                  </GridWrapper>
                  <GridWrapper item xs={12} md={6}>
                    <FormTextInput
                      text="¿Cuál es tu apellido?"
                      placeholder="Doe"
                      id="lastName"
                      name="lastName"
                      type="text"
                      fullWidth
                      value={appointmentInfo.patient_last_name || ''}
                      disabled={isInputsDisabled || dataFound || validateIdNumber}
                      onBlur={() => onValidationFocus(2)}
                      onChange={(event) => handleInputChange(event, 'patient_last_name')}
                      error={validateLastName}
                      texterror={getValidationMessage(validateLastName)}
                    />
                  </GridWrapper>
                </InputsGrid>

                <InputsGrid item xs={12}>
                  <GridWrapper
                    item
                    xs={12}
                    md={6}
                    sx={
                      isExtraSmallSize
                        ? {
                            marginBottom: '22px',
                          }
                        : {}
                    }
                  >
                    <FormTextInput
                      text="¿Cuál es tu correo electrónico?"
                      placeholder="dr@email.com"
                      id="email"
                      type="email"
                      fullWidth
                      value={appointmentInfo.patient_email || ''}
                      name="email"
                      disabled={isInputsDisabled || dataFound || validateIdNumber}
                      onBlur={() => onValidationFocus(3)}
                      onChange={(event) => handleInputChange(event, 'patient_email')}
                      error={validateEmailField()}
                      texterror={validateFieldArrowFunction(appointmentInfo?.patient_email, ValidationFocus[3])}
                    />
                  </GridWrapper>
                  <GridWrapper item xs={12} md={6}>
                    <FormTextInput
                      text="¿Cuál es tu número de celular?"
                      placeholder="0999911111"
                      id="phone_number"
                      name="phone_number"
                      type="text"
                      fullWidth
                      value={appointmentInfo.patient_phone_number || ''}
                      disabled={isInputsDisabled || dataFound || validateIdNumber}
                      onBlur={() => onValidationFocus(4)}
                      onChange={(event) => handleInputChange(event, 'patient_phone_number')}
                      error={validateMobile}
                      texterror={getValidationMessage(validateMobile)}
                    />
                    {validateAndDisplayPhone(
                      appointmentInfo.patient_phone_number,
                      'Por favor, ingresa un teléfono celular válido',
                    )}
                  </GridWrapper>
                </InputsGrid>

                <InputsGrid item xs={12}>
                  <GridWrapper item xs={12} md={6}>
                    <FormTextInput
                      text="Coméntale a tu doctor el motivo de la consulta"
                      placeholder=""
                      id="reason"
                      type="text"
                      fullWidth
                      value={appointmentInfo.appointment_reason}
                      name="reason"
                      disabled={isInputsDisabled}
                      onBlur={() => onValidationFocus(5)}
                      onChange={(event) => handleInputChange(event, 'appointment_reason')}
                      error={validateReason}
                      texterror={getValidationMessage(validateReason)}
                      sx={{
                        '& .MuiInputBase-root': {
                          color: 'tertiary.dark',
                        },
                      }}
                    />
                  </GridWrapper>
                  <GridWrapper item xs={12} md={6} display="flex" alignItems="center" />
                </InputsGrid>

                <GridRow item xs={12} width="90%" marginTop={10}>
                  <StackWrapper spacing={2} direction="row" sx={{ marginBottom: 4, width: '100%' }}>
                    <SubmitButton
                      id="confirm-appointment-button"
                      data-testid="confirm-appointment-button"
                      variant="contained"
                      fullWidth
                      type="submit"
                      text="Confirmar cita"
                      disabled={isDisabled}
                    />
                  </StackWrapper>
                </GridRow>
              </form>
            </GridWrapper>
          )}
        </GridLayoutLeft>
        {isSubmittedOk && !isExtraSmallSize && (
          <GridLayoutRight
            sx={{
              width: isSubmittedOk && !isExtraSmallSize ? '35%' : '0%',
              backgroundColor: '#FFF',
              display: 'flex',
              alignItems: 'start',
            }}
            container
          >
            <>
              <GridWrapper item sm={12}>
                <DataDoctor info={dataDoctor} />
                <GridWrapper
                  container
                  justifyContent="center"
                  alignItems="center"
                  margin={'auto'}
                  display={'flex'}
                  sx={{
                    marginTop: '30px',
                    width: '80%',
                  }}
                >
                  <GridWrapper item>
                    <LabelInput
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '16px',
                        width: '80%',
                        textAlign: 'center',
                      }}
                    >
                      Ingresa a Helthy y realiza el seguimiento de tu cita
                    </LabelInput>
                  </GridWrapper>
                  <GridWrapper item>
                    <ButtonR
                      type="button"
                      onClick={() => {
                        openLoginHelthy()
                      }}
                      data-testid="modal-button"
                    >
                      Ingresar
                    </ButtonR>
                  </GridWrapper>
                </GridWrapper>
              </GridWrapper>
              <GridWrapper sx={{ height: '20%' }} item sm={12} />
            </>
          </GridLayoutRight>
        )}
      </GridContent>
      <Advert message={messageModal} onClose={() => setOpenModal(false)} open={openModal} title="Aviso" />
    </>
  )
}
