import { useContext, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../../../../contexts/AccountContext'
import { IRecipePrescription } from '../../../../../../../infrastructure/dtos/Prescription'
import { getAppointmentRecipesUtility } from '../../../../../../../services/Contracts/Utility/PrescriptionsUtility'

interface IUseShowRecipesListModal {
  handleOpenRecipesListModal(value: boolean): void
  openRecipesListModal: boolean
  loadingRecipesList: boolean
  appointmentDetails?: IRecipePrescription
  openRecipe: boolean
  openLoadingRecipesModal: boolean
  prescriptionIndex?: number
  handleOpenRecipe(value: boolean): void
  handleOpenLoadingRecipesModal: (value: boolean) => void
  fetchAppointmentRecipes: (appointmentId: string) => Promise<void>
  handleSetPrescriptionIndex: (index: number) => void
}

export function useShowRecipesListModal(): IUseShowRecipesListModal {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [openRecipesListModal, setOpenRecipesListModal] = useState<boolean>(false)
  const [openLoadingRecipesModal, setOpenLoadingRecipesModal] = useState<boolean>(false)
  const [openRecipe, setOpenRecipe] = useState<boolean>(false)
  const [prescriptionIndex, setPrescriptionIndex] = useState<number>()
  const [loadingRecipesList, setLoadingRecipesList] = useState<boolean>(false)
  const [appointmentDetails, setAppointmentDetails] = useState<IRecipePrescription>()

  const fetchAppointmentRecipes = async (appointmentId: string): Promise<void> => {
    try {
      setLoadingRecipesList(true)
      const { data, status } = await getAppointmentRecipesUtility(appointmentId)
      if (status) {
        setAppointmentDetails(data as IRecipePrescription)
      }
      setLoadingRecipesList(false)
    } catch (error: unknown) {
      setLoadingRecipesList(false)
      handleAlert(true, 'Error al obtener los récipes', 'error')
    }
  }

  const handleOpenRecipe = (value: boolean): void => setOpenRecipe(value)
  const handleOpenLoadingRecipesModal = (value: boolean): void => setOpenLoadingRecipesModal(value)
  const handleOpenRecipesListModal = (value: boolean): void => setOpenRecipesListModal(value)

  const handleSetPrescriptionIndex = (index: number): void => setPrescriptionIndex(index)

  return {
    handleOpenRecipesListModal,
    handleOpenRecipe,
    handleOpenLoadingRecipesModal,
    fetchAppointmentRecipes,
    handleSetPrescriptionIndex,
    prescriptionIndex,
    openRecipesListModal,
    loadingRecipesList,
    appointmentDetails,
    openRecipe,
    openLoadingRecipesModal,
  }
}
