import { useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useState, JSX } from 'react'
import { useNavigate } from 'react-router-dom'

import News from './News/News'
import NewsCorousel from './News/NewsCorousel/NewsCorousel'
import { Pendings } from './Pendings/PendingsComponent'
import { Reminder } from './Reminder/ReminderComponent'
import { Resume } from './Resume/ResumeComponent'
import { HomeBox, SmallHomeBox } from './styles'
import DoctorCarousel from './SuggestedDoctors/DoctorCarousel/DoctorCarousel'
import { SuggestedDoctors } from './SuggestedDoctors/SuggestedDoctors'
import { SuggestedDoctorsHomeBox } from './SuggestedDoctors/SuggestedDoctorsStyle'
import { BoxWrapper, GridWrapper } from '../../components'
import SearchAutocomplete from '../../components/SearchAutocomplete'
import { useSearchAutocomplete } from '../../components/SearchAutocomplete/useSearchAutocomplete'
import { AvatarBox } from '../../components/StyledComponents/AvatarBox'
import { LabelInput } from '../../components/StyledComponents/LabelInput'
import { AccountContext, AuthContextType } from '../../contexts/AccountContext'
import { SearchOfficesContext } from '../../contexts/SeachOfficesContext'
import NextDateCard from '../pages/Dates/components/NextDatesList/NextDateCard/NextDateCard'
import NextDatesList from '../pages/Dates/components/NextDatesList/NextDatesList'

const Home = (): JSX.Element => {
  const [search, setSearch] = useState<string>('')
  const { patient } = useContext(AccountContext) as AuthContextType
  const { savelastSearch } = useContext(SearchOfficesContext)
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))
  const navigate = useNavigate()
  const { options } = useSearchAutocomplete({ query: search })

  const saveQuery = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      savelastSearch({
        doctor_name: search,
        address: '',
        addressData: undefined,
        fromHome: true,
      })
      window.scrollTo({
        top: 300,
        behavior: 'smooth',
      })
      navigate('/appointment')
    }
  }

  return (
    <BoxWrapper flexGrow={1} paddingY={'5px'} paddingX={!isExtraSmallSize ? '32px' : '15px'}>
      <GridWrapper container spacing={isExtraSmallSize ? 0 : 2} columns={16}>
        <GridWrapper item sm={16} md={4} mb={2}>
          {!isExtraSmallSize ? (
            <HomeBox>
              <AvatarBox data-testid="avatar-profile" src={patient?.photo_patient} />
              <Resume />
            </HomeBox>
          ) : (
            <SuggestedDoctorsHomeBox sx={{ padding: '20px 20px' }}>
              <AvatarBox data-testid="avatar-profile" src={patient?.photo_patient} />
              <Resume />
            </SuggestedDoctorsHomeBox>
          )}
          {!isExtraSmallSize && (
            <SmallHomeBox data-testid="small-home-box">
              <LabelInput>Recordatorio</LabelInput>
              <Reminder />
            </SmallHomeBox>
          )}
        </GridWrapper>
        <GridWrapper item sm={16} md={12} marginTop={!isExtraSmallSize ? '27px' : '1px'}>
          <BoxWrapper sx={{ flexGrow: 1 }}>
            <GridWrapper container spacing={2}>
              <GridWrapper item xs={12}>
                <SearchAutocomplete
                  id="home-search"
                  options={options}
                  setValue={setSearch}
                  value={search}
                  placeholder={`Hola ${patient?.name}, busca por médico o especialidad y agenda tu cita`}
                  onKeyDown={saveQuery}
                />
              </GridWrapper>
              <GridWrapper item xs={12}>
                {!isExtraSmallSize ? <NextDatesList fromHome /> : <NextDateCard />}
              </GridWrapper>
              <GridWrapper item xs={12} sm={5} sx={{ paddingTop: '0px' }}>
                {!isExtraSmallSize ? <News /> : <DoctorCarousel idPatient={patient?.userId as string} />}
              </GridWrapper>
              <GridWrapper item xs={12} sm={7} flexDirection={'column'}>
                {!isExtraSmallSize ? (
                  <SuggestedDoctors idPatient={patient?.userId as string} isExtraSmallSize={isExtraSmallSize} />
                ) : (
                  <NewsCorousel />
                )}
                {isExtraSmallSize && (
                  <SmallHomeBox data-testid="small-home-box">
                    <LabelInput>Recordatorio</LabelInput>
                    <Reminder />
                  </SmallHomeBox>
                )}
                <LabelInput mt={4}>Tus pendientes</LabelInput>
                <Pendings />
              </GridWrapper>
            </GridWrapper>
          </BoxWrapper>
        </GridWrapper>
      </GridWrapper>
    </BoxWrapper>
  )
}

export default Home
