import { useEffect, useState } from 'react'

import { AvatarMap, BadgeMap, GridDoctorInfo, PatientIconMap } from './styles'
import hospitalLogo from '../../../../../assets/img/hospital_logo.jpg'
import { TypographyWrapper } from '../../../../../components'
import { BoxQualifications } from '../../../../../components/BoxQualifications/BoxQualifications'
import Marker from '../../../../../components/Marker'
import { ISearchDoctorMap } from '../../../../../infrastructure/dtos/DoctorInfo'
import { IClickEvent } from '../../../SearchDoctor/types/indes'
import { ILocationDoctorProps, ILocationExtraProps } from '../../types'
// import CMResultsDetails from '../CMResultsDetails'
// import DoctorDetailsRightPanel from '../DoctorDetailsRightPanel'

export default function LocationDoctor({
  map,
  results,
  selectedPatientAddress,
  handleAlert,
  // userId,
  // query,
  handleOpenDetails,
}: ILocationDoctorProps & ILocationExtraProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const [data, setData] = useState<(ISearchDoctorMap & { showQualification: boolean })[]>([])

  useEffect(() => {
    const xLocation = results?.reduce((
      acc: (ISearchDoctorMap & { showQualification: boolean })[],
      result: ISearchDoctorMap
    ) => {
      acc.push({ ...result, showQualification: false })
      return acc
    }, [])

    setData(xLocation as (ISearchDoctorMap & { showQualification: boolean })[])
  }, [])

  const handleClick = (event: IClickEvent, location: ISearchDoctorMap, key: number): void => {
    setAnchorEl(event.domEvent.target)
    if (
      event.domEvent.target?.id === 'image-clickable' ||
      event.domEvent.target?.parentElement?.id === 'image-clickable' ||
      (
        event.domEvent.target?.parentElement?.dataset?.testid === 'PersonIcon' &&
        event.domEvent.target?.parentElement?.parentElement?.id === 'image-clickable'
      ) ||
      ['grid-info', 'typo-info-name', 'typo-info-specialty', 'typo-info-price'].includes(event.domEvent.target?.dataset.testid as string)
    ) {
      // toggleDrawer(true, location)
      handleOpenDetails(location)
    } else if (event.domEvent.target?.id === 'badge-clickable') {
      handleQualifications(key, true)
    }
  }

  // const handleDetails = (open: boolean, item?: ISearchDoctorMap, redirect?: boolean, path?: string): void => {
  //   const tempLocation: ISearchDoctorMap[] = structuredClone(results)
  //   const index = tempLocation?.findIndex((i) => i.office_id === item?.office_id)
  //   if (index >= 0) {
  //     tempLocation[index].showDetails = open
  //     // setData(tempLocation)
  //     if (!open && redirect && path) {
  //       // setPathToredirect(path)
  //     }
  //   }
  // }

  // const toggleDrawer = (open: boolean, location?: ISearchDoctorMap): void => {
  //   if (location) {
  //     setSelectedOffice({
  //       office_id: location?.office_id as string,
  //       user_id: location?.user_id as string,
  //       specialty: location?.speciality,
  //     })
  //   }
  // }

  const handleQualifications = (key: number, open: boolean): void => {
    const tempLocation: (ISearchDoctorMap & { showQualification: boolean })[] = structuredClone(data)
    tempLocation[key].showQualification = open
    setData(tempLocation)
  }

  const getPhoto = (location: ISearchDoctorMap): string => {
    if (['DOCTOR', 'DOCTOR_CM'].includes(location.rol)) {
      return location.photo as string
    }
    if (location.photo) {
      return location.photo
    }
    return hospitalLogo
  }

  return (
    <>
      {Boolean(selectedPatientAddress) && (
        <Marker
          key={'patientPosition'}
          map={map}
          position={{
            lat: Number(selectedPatientAddress?.coordinates.latitude),
            lng: Number(selectedPatientAddress?.coordinates.longitude),
          }}
          testId="patient-locate"
          children={<PatientIconMap />}
        />
      )}
      {data?.map((location, key) => {
        return (
          <Marker
            key={`marker-${key}`}
            map={map}
            position={{
              lat: Number(location.coordinates.lat),
              lng: Number(location.coordinates.lng),
            }}
            onClick={(event: IClickEvent) => handleClick?.(event, location, key)}
            testId="doctor-locate"
          >
            <AvatarMap
              src={getPhoto(location)}
              data-testid="doctor-img-clickable"
              imgProps={{
                id: 'image-clickable',
              }}
              property="click"
              id="image-clickable"
            />
            <BadgeMap
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              badgeContent={location.rating.toFixed(1)}
              slotProps={{
                badge: {
                  id: 'badge-clickable',
                },
              }}
            >
              {location.showQualification && (
                <BoxQualifications
                  doctorId={location.user_id}
                  closeQualification={() => handleQualifications(key, false)}
                  handleAlert={handleAlert}
                  popoverProps={{
                    open: location.showQualification,
                    anchorEl,
                  }}
                />
              )}
            </BadgeMap>
            <GridDoctorInfo data-testid="grid-info">
              <TypographyWrapper data-testid="typo-info-name" fontSize={'13px'} lineHeight={1.1} position={'relative'} fontWeight={'bold'} textAlign={'center'}>{location.name}</TypographyWrapper>
              <TypographyWrapper data-testid="typo-info-specialty" fontSize={'12px'} lineHeight={1.1}>{location.speciality}</TypographyWrapper>
              {['DOCTOR', 'DOCTOR_CM'].includes(location.rol)
                ? <TypographyWrapper data-testid="typo-info-price" fontSize={'12px'} lineHeight={1.1}>
                  {location.consultation_value}
                </TypographyWrapper>
                : <TypographyWrapper data-testid="typo-info-price" fontSize={'12px'} lineHeight={1.1}>
                  {location.no_doctor + ' médico' + (location.no_doctor > 1 ? 's' : '')}
                </TypographyWrapper>
              }
            </GridDoctorInfo>
            {/* {location.showDetails && location.rol === 'DOCTOR' && (
              <DoctorDetailsRightPanel
                onClose={(redirect, path) => toggleDrawer(false, location, redirect, path)}
                doctor={selectedOffice as IParamsGetNextAppointmentHour}
                selectedPatientAddress={selectedPatientAddress as IAddressPatient}
                userId={userId}
                handleAlert={handleAlert}
                popoverProps={{
                  open: location.showDetails,
                  // anchorEl,
                }}
              />
            )}

            {location.showDetails && location.rol === 'CM' && (
              <CMResultsDetails
                onClose={(redirect, path) => toggleDrawer(false, location, redirect, path)}
                cmLocation={location}
                query={query as string}
                popoverProps={{
                  open: location.showDetails,
                  // anchorEl,
                }}
              />
            )} */}
          </Marker>
        )
      })}
    </>
  )
}
