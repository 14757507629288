import { styled } from '@mui/material/styles'

import { TextFieldWrapper, TypographyWrapper } from '../../../../components'
import BoxWrapper from '../../../../components/wrapper/BoxWrapper'
import GridWrapper from '../../../../components/wrapper/GridWrapper'

export const TextFielRegister = styled(TextFieldWrapper)(() => ({
  '& .MuiInputLabel-root': {
    marginBotton: '20px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '55px',
    backgroundColor: '#fff',
    fontSize: '20px',
    color: '#7f7f7f',
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '100%',
  },
  '& fieldset': { border: 'none' },
  '&::placeholder': {
    marginLeft: '30px',
  },
}))
export const GridGeneral = styled(GridWrapper)(() => ({
  alignItems: 'center',
}))

export const GridItem = styled(GridWrapper)(() => ({
  display: 'flex',
  width: 44,
}))

export const GridrespuestaItem = styled(GridWrapper)(() => ({
  width: 'calc(100% - 44px)',
  wordWrap: 'break-word',
}))

interface IParts {
  text: string
  highlight: boolean
}
export const BoxResults = styled(BoxWrapper)((part: IParts) => ({
  fontWeight: part.highlight ? 'bold' : 'regular',
}))

export const TypographyName = styled(TypographyWrapper)(() => ({
  fontFamily: 'Helvetica',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
}))
