import { Reminders } from '../../../domain/useCase/ReminderUseCase'
import { IReminderService } from '../../../infrastructure/dtos/Reminders'
import ReminderMapper from '../../../infrastructure/http/mappers/ReminderMapper'
import { ReminderRepositoryImpl } from '../../../infrastructure/RepositoryImpl/ReminderRepositoryImpl'

export async function getReminderService(patient_id: string): Promise<IReminderService> {
  try {
    const useReminder = new Reminders(new ReminderRepositoryImpl(new ReminderMapper()))
    return {
      status: 1,
      data: await useReminder.invoke(patient_id),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
