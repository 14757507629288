import React, { useEffect, useRef, JSX } from 'react'
import { createRoot, Root } from 'react-dom/client'

import { GridWrapper } from '..'
import { ILocationDoctorProps } from '../../views/pages/DoctorResultsGoogleMap/types'

export default function Marker({ map, children, position, onClick, testId = '' }: ILocationDoctorProps): JSX.Element {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | undefined>()
  const rootRef = useRef<Root | null>(null)

  useEffect(() => {
    if (!rootRef.current) {
      const container: HTMLDivElement = document.createElement('div')
      container.style.transform = 'translateZ(0) translate(0%, 50%)'
      container.style.width = '130px'
      container.style.display = 'flex'
      container.style.flexDirection = 'column'
      container.style.alignItems = 'center'
      container.style.width = '20px'
      container.style.height = '20px'
      rootRef.current = createRoot(container)

      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        position,
        content: container,
      })
    }
  }, [])

  useEffect(() => {
    rootRef.current?.render(children)
    ;(markerRef.current as google.maps.marker.AdvancedMarkerElement).position = position
    ;(markerRef.current as google.maps.marker.AdvancedMarkerElement).map = map
    ;(markerRef.current as google.maps.marker.AdvancedMarkerElement).title = testId
    if (onClick && !google.maps.event.hasListeners(markerRef.current as google.maps.marker.AdvancedMarkerElement, 'click')) {
      // eslint-disable-next-line @typescript-eslint/ban-types
      ;(markerRef.current as google.maps.marker.AdvancedMarkerElement)?.addListener('click', onClick as Function)
    }
  }, [map, position, children])

  return <GridWrapper />
}
