import { useState } from 'react'

interface IUseCancelAppointmentHook {
  openCancelModal: boolean
  handleOpenCancelAppointment: (value: boolean) => void
}

export function useCancelAppointment(): IUseCancelAppointmentHook {
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)

  const handleOpenCancelAppointment = (value: boolean): void => setOpenCancelModal(value)

  return {
    handleOpenCancelAppointment,
    openCancelModal,
  }
}
