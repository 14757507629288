import { useEffect, useState, JSX } from 'react'

import { usePatient } from './Hooks/usePatient'
import { useRegisterPatient } from './Hooks/useRegisterPatient'
import PatientForm from './PatientForm/PatientForm'
import RegisterAddressPatient from './PatientMap/RegisterAddressForm'
import { Loading } from '../../components/Loading/Loading'
import { Advert } from '../../components/Notification/Advert/Advert'
import { GridLayout } from '../ScheduleAppointment/ScheduleAppointmentStyle'

enum STEPS {
  PATIENT_FORM,
  ADDRESS_FORM,
}

export const PatientRegister = (): JSX.Element => {
  const { patient, setPatientInfo, setPatientAddresses } = usePatient()
  const { SaveData, loading, message, onOption, openAlert } = useRegisterPatient(patient)

  const [stepper, setStepper] = useState<number>(0)
  const [inSubmit, setInSubmit] = useState<boolean>(false)

  const onNext = (): void => setStepper(STEPS.ADDRESS_FORM)
  const onBack = (): void => setStepper(STEPS.PATIENT_FORM)
  const onChangeSubmit = (): void => setInSubmit(true)

  useEffect(() => {
    if (inSubmit) {
      SaveData()
      setInSubmit(false)
    }
  }, [inSubmit])

  return (
    <>
      {loading ? (
        <>
          <Loading />
          <Advert
            message={message.message}
            onClose={() => {
              onOption()
            }}
            open={openAlert}
            title={message.title}
          />
        </>
      ) : (
        <GridLayout>
          {stepper === STEPS.PATIENT_FORM && (
            <PatientForm onNext={onNext} handlePatientInfo={setPatientInfo} patientInfo={patient} />
          )}
          {stepper === STEPS.ADDRESS_FORM && (
            <RegisterAddressPatient
              onBack={onBack}
              handlePatientAddresses={setPatientAddresses}
              patientInfo={patient}
              onChangeSubmit={onChangeSubmit}
            />
          )}
        </GridLayout>
      )}
    </>
  )
}
