import { AxiosError } from 'axios'

import { INewsResponse } from '../../dtos/News'
import NewsDataSource from '../dataSource/NewsDataSource'
import { http } from '../Http'

export default class NewsMapper implements NewsDataSource {
  async getNews(): Promise<INewsResponse> {
    try {
      const { data } = await http.get<INewsResponse>('/send_data/news')
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: { message: (error as AxiosError).response?.statusText as string },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
