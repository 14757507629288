import { styled } from '@mui/material'

import { AvatarWrapper } from '../wrapper'

export const AvatarBox = styled(AvatarWrapper)(({ theme }) => ({
  width: '120px',
  height: '120px',
  margin: '0 auto',
  [theme.breakpoints.only('xs')]: {
    margin: '20px 100px 0 100px'
  }
}))
