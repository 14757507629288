import { BadgeProps } from '@mui/material'
import { JSX } from 'react'

import { BadgeRatedDoctorStyled, BadgeDoctorFriendsStyled } from './styles'

interface ICustomProps {
  rating?: number
}

type IBadgeDoctorProps = ICustomProps & BadgeProps

export function BadgeRatedDoctor(props: IBadgeDoctorProps): JSX.Element {
  const { rating } = props
  return <BadgeRatedDoctorStyled overlap="circular" badgeContent={rating} {...props} />
}

export function BadgeDoctorFriends(props: IBadgeDoctorProps): JSX.Element {
  return <BadgeDoctorFriendsStyled overlap="circular" color="default" {...props} />
}
