import { AxiosError, AxiosResponse } from 'axios'

import { IApiResponseSaveNewDate, SaveNewDate } from '../../dtos/NewDate'
import NewDateDataSource from '../dataSource/NewDateDataSource'
import { http } from '../Http'

export default class NewDateMapper implements NewDateDataSource {
  async postNewDate(
    dataNewDate: SaveNewDate
  ): Promise<IApiResponseSaveNewDate> {
    const data = await http
      .post<IApiResponseSaveNewDate>(`save_data/appointment_by_patient`, {
        ...dataNewDate
      })
      .then((response: AxiosResponse) => {
        const { data } = response
        return data
      })
      .catch((error: AxiosError) => {
        const data: IApiResponseSaveNewDate = {
          statusCode: error.response?.status as number,
          body: { message: error.response?.statusText as string },
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }
        return data
      })
    return data
  }
}
