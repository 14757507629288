import {
  IApiLocation,
  ICreateLocation,
  IDeletedLocation,
  ILocationService,
  IModifiedLocation,
} from '../../../infrastructure/dtos/location'
import { CreateLocationService, DeletedLocationService, ModifiedLocationService } from '../Persistencia/LocationService'

export async function CreateLocationUtility(params: ICreateLocation): Promise<ILocationService<string>> {
  try {
    const { data, status }: ILocationService<IApiLocation> = await CreateLocationService(params)
    if (typeof data != 'string') {
      return {
        status: data.statusCode === 200 && status ? status : 0,
        data: data.body,
      }
    }
    return {
      status: status,
      data: data,
    }
  } catch (error) {
    return {
      status: 0,
      data: 'Error Utility',
    }
  }
}

export async function ModifiedLocationUtility(params: IModifiedLocation): Promise<ILocationService<string>> {
  try {
    const { data, status }: ILocationService<IApiLocation> = await ModifiedLocationService(params)
    if (typeof data != 'string') {
      return {
        status: data.statusCode === 200 && status ? status : 0,
        data: data.body,
      }
    }
    return {
      status: status,
      data: data,
    }
  } catch (error) {
    return {
      status: 0,
      data: 'Error Utility',
    }
  }
}

export async function DeletedLocationUtility(params: IDeletedLocation): Promise<ILocationService<string>> {
  try {
    const { data, status }: ILocationService<IApiLocation> = await DeletedLocationService(params)
    if (typeof data != 'string') {
      return {
        status: data.statusCode === 200 && status ? status : 0,
        data: data.body,
      }
    }
    return {
      status: status,
      data: data,
    }
  } catch (error) {
    return {
      status: 0,
      data: 'Error Utility',
    }
  }
}
