import { styled } from '@mui/material/styles'

import { ButtonWrapper } from '../wrapper'

export const ButtonGeneric = styled(ButtonWrapper)((props) => ({
  width: '100%',
  borderRadius: '2.625rem',
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.common.white,
  fontSize: '14px',
  height: '3.4375rem',
  textTransform: 'none',
  ':hover': {
    color: props.theme.palette.primary.main,
  },
}))
