import React, { JSX } from 'react'

import { Carousel } from 'react-responsive-carousel'

import CarouselCard from './components/CarouselCard'
import { GridWrapper } from '../../../../components'
import CircularProgressWrapper from '../../../../components/wrapper/LoaderWrapper'
import { News } from '../../../../infrastructure/dtos/News'
import { useNews } from '../hook/useNews'

import { BoxLabel } from '../NewsStyles'

function NewsCorousel(): JSX.Element {
  const { loadingNews, news } = useNews()

  return (
    <>
      {!loadingNews ? (
        news?.length > 0 && (
          <>
            <BoxLabel>Novedades</BoxLabel>
            <Carousel
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              renderItem={(item: React.ReactNode) => <div style={{ margin: '0 3px 0 3px' }}>{item}</div>}
              renderIndicator={(onClickHandler, isSelected, index, label) => {
                const indicatorStyle = {
                  display: 'inline-block',
                  margin: '0 8px',
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: isSelected ? '#007aff' : '#c7c7cc',
                }
                return (
                  <li
                    style={indicatorStyle}
                    onClick={onClickHandler}
                    onKeyDown={onClickHandler}
                    value={index}
                    key={index}
                    role="button"
                    tabIndex={0}
                    aria-label={`${label} ${index + 1}`}
                  />
                )
              }}
            >
              {news?.map((item: News, index: number) => (
                <GridWrapper data-testid="carousel-card" key={`carousel-card-${index}`}>
                  <CarouselCard imageUrl={item.logo_url} title={item.title} description={item.summary} key={index} />
                </GridWrapper>
              ))}
            </Carousel>
          </>
        )
      ) : (
        <CircularProgressWrapper data-testid="loading-spinner" />
      )}
    </>
  )
}

export default NewsCorousel
