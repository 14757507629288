import { SearchRepository } from '../../../domain/repository/SearchRepository'
import { IAPIResponseAutocompleteSuggestions } from '../../dtos/Search'
import { ISearchDataSource } from '../../http/dataSource/SearchDataSource'

export class SearchRepositoryImpl implements SearchRepository {
  private searchDataSource: ISearchDataSource

  constructor(searchDataSource: ISearchDataSource) {
    this.searchDataSource = searchDataSource
  }

  getAutocompleteSuggestions(query: string): Promise<IAPIResponseAutocompleteSuggestions> {
    return this.searchDataSource.getAutocompleteSuggestions(query)
  }
}
