import { BaseTextFieldProps, FormHelperText, SxProps } from '@mui/material'
import { useState, JSX } from 'react'

import { AutocompleteStyled, FormControlStyled } from './styles'
import { TextFieldWrapper } from '../wrapper'
interface IComponent {
  id: string
  list: string[]
  value: string
  setValue: (valueData: string) => void
  error?: boolean
  message?: string
  InputProps?: BaseTextFieldProps
  startAdornment?: JSX.Element
  noOptionsText?: string
  freeSolo?: boolean
  sx?: SxProps
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: unknown) => React.ReactNode
  filterOptions?: (options: unknown[]) => unknown[]
}

const AutocompleteComponent = ({
  id,
  error,
  list,
  message,
  setValue,
  value,
  InputProps,
  startAdornment,
  noOptionsText = 'Sin resultados',
  freeSolo = false,
  sx,
  renderOption,
  filterOptions,
}: IComponent): JSX.Element => {
  const [dataSelect, setDataSelect] = useState<string | null>(value || null)
  return (
    <FormControlStyled error={error}>
      <AutocompleteStyled
        freeSolo={freeSolo}
        forcePopupIcon={false}
        value={dataSelect}
        onChange={(_: React.SyntheticEvent<Element, Event>, newValue: unknown) => {
          setDataSelect(newValue as string | null)
        }}
        inputValue={value}
        onInputChange={(_, newInputValue) => {
          setValue(newInputValue)
        }}
        id={id}
        options={list}
        sx={sx}
        noOptionsText={noOptionsText}
        renderOption={renderOption}
        filterOptions={filterOptions}
        renderInput={(params) => {
          return (
            <TextFieldWrapper
              {...params}
              {...InputProps}
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment,
              }}
            />
          )
        }}
      />
      {error && <FormHelperText>{message}</FormHelperText>}
    </FormControlStyled>
  )
}

export default AutocompleteComponent
