import React, { JSX } from 'react'

import { GridHours, HoursButton } from './AvailableHoursStyles'
import { GridWrapper, TypographyWrapper } from '../../../../components'

interface IAvailableHoursProps {
  availableHours: string[]
  selectedHour: string
  isOptionDisabled?(hour: string): boolean
  handleSelectedHour: (event: React.MouseEvent<HTMLElement>) => void
  maxHeight?: string
}

export const AvailableHours: React.FC<IAvailableHoursProps> = ({
  availableHours,
  handleSelectedHour,
  selectedHour,
  isOptionDisabled = () => undefined,
  maxHeight = '350px',
}): JSX.Element => {
  return (
    <GridHours container sx={{ width: '75%', position: 'relative', top: '130px' }}>
      <GridWrapper columns={2} maxHeight="300px">
        {availableHours.length === 0 && (
          <TypographyWrapper>Sin horas disponibles para este día</TypographyWrapper>
        )}

        <TypographyWrapper
          sx={{
            textAlign: 'center',
            marginBottom: '30px',
            fontSize: '18px',
            padding: '0',
          }}
        >
          Selecciona la hora para tu cita
        </TypographyWrapper>
        <GridWrapper columns={2} maxHeight={maxHeight} sx={{ overflowY: 'scroll' }}>
          {availableHours.map((hour: string, index: number) => (
            <HoursButton
              data-testid="button-available-hours"
              key={`${hour}-${index}`}
              type="button"
              value={hour}
              onClick={(e) => handleSelectedHour(e)}
              sx={{
                backgroundColor: selectedHour === hour ? '#31CACD' : '#DCE7FF',
                color: selectedHour === hour ? '#FFF' : 'primary',
                ':hover': {
                  backgroundColor: selectedHour === hour ? '#4CD6D8' : '',
                },
              }}
              disabled={isOptionDisabled(hour)}
            >
              {hour}
            </HoursButton>
          ))}
        </GridWrapper>
      </GridWrapper>
    </GridHours>
  )
}
