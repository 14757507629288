import dayjs from 'dayjs'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'
import { IPhoneVerification } from '../../../../infrastructure/dtos/PhoneVerification'
import {
  ResendPhoneVerificationUtility,
  TemporaryPhoneVerificationUtility,
  verifyTemporaryPhone,
} from '../../../../services/Contracts/Utility/TemporaryPhoneVerificationUtility'

interface IUseModalConfirmPhoneNumberHook {
  codeValue: string
  countdownTimer: string
  enabledResendCodeButton: boolean
  message: string
  openModal: boolean
  phoneVerification?: IPhoneVerification
  onChangeCodeValue(value: string): void
  handleCloseModal(): void
  handleOpenModal(): void
  fetchResendCode(): Promise<void>
  fetchVerifyCode(): Promise<void>
  fetchSendCode(): Promise<void>
  setPhoneVerification?: Dispatch<SetStateAction<IPhoneVerification | undefined>>
}

export function useModalConfirmPhoneNumber(newPhone?: string, onClose?: () => void): IUseModalConfirmPhoneNumberHook {
  const { patient, savePatientData, handleAlert } = useContext(AccountContext) as AuthContextType

  const [codeValue, setCodeValue] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const [enabledResendCodeButton, setEnabledResendCodeButton] = useState<boolean>(false)

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isSendCode, setIsSendCode] = useState<boolean>(false)

  const [phoneVerification, setPhoneVerification] = useState<IPhoneVerification>()

  const [countdownTimer, setCountdownTimer] = useState<string>('')

  const [initCountdown, setInitCountdown] = useState<boolean>(false)

  const onChangeCodeValue = (value: string): void => setCodeValue(value)
  const handleCloseModal = (): void => setOpenModal(false)
  const handleOpenModal = (): void => setOpenModal(true)

  const fetchResendCode = async (): Promise<void> => {
    try {
      setMessage('')
      const { data, status } = await ResendPhoneVerificationUtility(patient?.userId as string)
      if (status) {
        setPhoneVerification(data as IPhoneVerification)
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al reenviar el código', 'error')
    }
  }

  const fetchVerifyCode = async (): Promise<void> => {
    try {
      setMessage('')
      const { data, status } = await verifyTemporaryPhone({
        user_id: patient?.userId as string,
        code: codeValue,
      })

      if (status) {
        setPhoneVerification?.({
          ...(data as IPhoneVerification),
          message:
            (data as IPhoneVerification).remaining_intents_validation === 0
              ? `${(data as IPhoneVerification).message} Número máximo de intentos excedido, espere unos minutos para volver a intentar.`
              : (data as IPhoneVerification).message,
        })
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al verificar el código', 'error')
    }
  }

  const fetchSendCode = async (): Promise<void> => {
    setMessage('')
    try {
      const { data, status } = await TemporaryPhoneVerificationUtility(patient?.userId as string, newPhone as string)
      if (status) {
        setPhoneVerification(data as IPhoneVerification)
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al enviar el código', 'error')
    }
  }

  useEffect(() => {
    if (isSendCode) {
      fetchSendCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSendCode])

  useEffect(() => {
    setIsSendCode(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (initCountdown && phoneVerification?.created_up) {
      const date1: dayjs.Dayjs = dayjs(new Date())
      const date2: dayjs.Dayjs = dayjs(phoneVerification?.created_up)
      let min: number = date2.diff(date1, 'minutes')
      let sec: number = date2.diff(date1, 'seconds') - min * 60
      if (
        (min <= 0 && sec <= 0) ||
        phoneVerification?.send_status === false ||
        phoneVerification?.resend_status === false
      ) {
        // set message
        return () => null
      }
      // eslint-disable-next-line no-undef
      const interval: NodeJS.Timer = setInterval(() => {
        sec = -sec
        if (sec === -1) {
          sec = 59
          min = -min
        }
        setEnabledResendCodeButton(min < 4)
        setCountdownTimer(
          `${min}:${sec.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`,
        )
        if (min === 0 && sec === 0) {
          setEnabledResendCodeButton(false)
          clearInterval(interval)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initCountdown, phoneVerification?.created_up])

  useEffect(() => {
    if (phoneVerification) {
      if (phoneVerification?.verify_status === true) {
        // do some here
        savePatientData({
          ...patient,
          mobile: newPhone as string,
        })
        handleAlert(true, phoneVerification?.message as string, 'success')
        onClose?.()
      } else {
        setMessage(phoneVerification?.message || '')
      }
      if (phoneVerification?.created_at && phoneVerification?.created_up) {
        setInitCountdown(true)
      }
      setMessage(phoneVerification?.message || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneVerification])

  return {
    codeValue,
    enabledResendCodeButton,
    openModal,
    countdownTimer,
    message,
    phoneVerification,
    handleCloseModal,
    handleOpenModal,
    fetchResendCode,
    fetchVerifyCode,
    onChangeCodeValue,
    fetchSendCode,
    setPhoneVerification,
  }
}
