import { JSX } from 'react'

import { useNews } from './hook/useNews'
import { BoxLabel, CardNews } from './NewsStyles'
import { BoxWrapper, GridWrapper, TypographyWrapper } from '../../../components/wrapper'
import CardContentWrapper from '../../../components/wrapper/CardContentWrapper'
import CardMediaWrapper from '../../../components/wrapper/CardMediaWrapper'
import CircularProgressWrapper from '../../../components/wrapper/LoaderWrapper'
import { News } from '../../../infrastructure/dtos/News'
import { CommunityHomeBox } from '../styles'

function NewsFeed(): JSX.Element {
  const { loadingNews, news } = useNews()

  return (
    <CommunityHomeBox data-testid="news-feed">
      <BoxLabel>Novedades</BoxLabel>
      {!loadingNews ? (
        news?.length > 0 &&
        news?.map((item: News, index: number) => (
          <GridWrapper container key={`card-news-${index}`}>
            <CardNews variant="outlined">
              <GridWrapper item xs={4} sm={5} md={4}>
                <CardMediaWrapper image={item.logo_url} sx={{ height: '100%' }} />
              </GridWrapper>
              <GridWrapper item xs={8} sm={7} md={8}>
                <BoxWrapper sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContentWrapper sx={{ flex: '1 0 auto' }}>
                    <TypographyWrapper variant="h5">{item.title}</TypographyWrapper>
                    <TypographyWrapper variant="h5" color="text.secondary">
                      {item.summary}
                    </TypographyWrapper>
                  </CardContentWrapper>
                </BoxWrapper>
              </GridWrapper>
            </CardNews>
          </GridWrapper>
        ))
      ) : (
        <CircularProgressWrapper data-testid="loading-spinner" />
      )}
    </CommunityHomeBox>
  )
}

export default NewsFeed
