import { SelectChangeEvent } from '@mui/material'
import { useContext, useEffect, useState, JSX } from 'react'

import { AddressIconsList } from './AddressIconsList'
import { CustomMenuItem } from './styles'
import { LinkWrapper, TypographyWrapper } from '../../../../../components'
import { SelectInput } from '../../../../../components/StyledComponents/SelectInput'
import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import { IAddressPatient } from '../../../../Register/types'

interface Props<T> {
  address: T
  onChange: (event: SelectChangeEvent<unknown>) => void
  setAddressData: (data: IAddressPatient | undefined) => void
}

function Address<T>({ address, onChange, setAddressData }: Props<T>): JSX.Element {
  const { patient } = useContext(AccountContext) as AuthContextType
  const [isListOpen, setIsListOpen] = useState<boolean>(false)
  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    if (typeof event.target.value === 'string' && Boolean(event.target.value)) {
      const addressData: IAddressPatient | undefined = patient?.addresses.find(
        (ad) => ad.address === event.target.value,
      )
      setAddressData(addressData)
    } else {
      setAddressData(undefined)
    }
    onChange(event)
  }

  const handleOpen = (): void => {
    const dropdownElement = document.querySelector('[role="combobox"]')
    const dropdownRect = dropdownElement?.getBoundingClientRect()
    const rootElement = document.querySelector('#root') as HTMLElement
    const rootRect = rootElement?.getBoundingClientRect()

    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth
    const contentHeight = rootRect?.height || 0
    if (dropdownRect && dropdownRect.bottom > 142 && windowHeight < 942 && windowWidth < 1024) {
      if (contentHeight < 600) {
        rootElement.style.height = `1200px`
      }
      const pixelsToScroll = 295
      // Realiza el scroll hacia abajo
      window.scrollBy(0, pixelsToScroll)
    }

    setIsListOpen(true)
  }

  const handleClose = (): void => {
    const rootElement = document.querySelector('#root') as HTMLElement
    rootElement.style.height = 'auto'
    setIsListOpen(false)
  }

  useEffect(() => {
    setAddressData(patient?.addresses[0])
    onChange({
      target: { value: patient?.addresses[0].address },
    } as SelectChangeEvent<unknown>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <SelectInput
      value={address}
      onChange={handleChange}
      role="combobox"
      variant='outlined'
      open={isListOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      MenuProps={{
        PaperProps: {
          sx: {
            width: '10px',
            borderRadius: '0px 0px 30px 30px',
            backgroundColor: 'white',
            boxShadow: `
              6px 6px 8px 0px rgba(0, 0, 0, 0.03),
              6px 6px 8px 0px rgba(0, 0, 0, 0.03),
              0px 6px 8px -7px rgba(0, 0, 0, 0.03)
            `,
          },
        },
      }}
    >
      {/* <CustomMenuItem value="">
        <NearMeOutlined />
        Usar mi ubicación actual
      </CustomMenuItem>
      <DividerWrapper />
      <CustomMenuItem value={2}>
        <MyLocationOutlined />
        Seleccionar en el mapa
      </CustomMenuItem>
      <DividerWrapper /> */}
      {patient?.addresses &&
        patient?.addresses.map((address, index) => {
          const addressArray: string[] = address.address.split(',')
          const formattedAddress: string = addressArray.slice(0, addressArray.length - 2).join()
          return (
            <CustomMenuItem value={address.address} key={index} divider>
              <AddressIconsList addressName={address.name} />
              <TypographyWrapper noWrap>
                {address.name + ', ' + (formattedAddress.length > 0 ? formattedAddress : addressArray[0])}
              </TypographyWrapper>
            </CustomMenuItem>
          )
        })}
      <CustomMenuItem value={4} disabled={patient?.addresses?.length >= 5}>
        <AddressIconsList addressName={''} />
        <LinkWrapper href="#" underline="hover">
          {'Agregar nueva dirección'}
        </LinkWrapper>
      </CustomMenuItem>
    </SelectInput>
  )
}

export default Address
