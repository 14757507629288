import { JSX } from 'react'

import { NavigateFunction, useNavigate } from 'react-router-dom'

import { ItemsNavBar, links } from './NavBarData'
import { GridWrapper, ListItemButtonWrapper, ListItemTextWrapper, ListWrapper } from '../../../../components'

function NavBar(): JSX.Element {
  const navigate: NavigateFunction = useNavigate()
  return (
    <ListWrapper style={{ display: 'flex' }} data-testid={`navbar`}>
      {links.map((link: ItemsNavBar, index: number) => (
        <ListItemButtonWrapper
          key={index}
          data-testid={`navbar-option-${link.name}`}
          style={{
            display: 'block',
            textAlign: 'center',
            marginRight: '20px',
          }}
          onClick={() => navigate(link.path)}
        >
          <GridWrapper style={{ margin: 'auto' }}>{link.icon}</GridWrapper>
          <ListItemTextWrapper primary={link.name} />
        </ListItemButtonWrapper>
      ))}
    </ListWrapper>
  )
}

export default NavBar
