import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import { styled } from '@mui/material'

import {
  ButtonWrapper,
  GridWrapper,
  StackWrapper
} from '../../../../components/wrapper'

export const TextCard = styled(GridWrapper)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1fr)',
  gridGap: '10px',
  borderRadius: '32px',
  padding: '20px',
  width: '100%'
}))

export const ActionsButton = styled(ButtonWrapper)((props) => ({
  display: 'grid',
  placeItems: 'center',
  textTransform: 'capitalize',
  padding: '10px',
  backgroundColor: props.theme.palette.common.white,
  '&:hover': {
    backgroundColor: props.theme.palette.background.paper
  }
}))

export const EditIconPrescription = styled(EditIcon)((props) => ({
  color: props.theme.palette.primary.main
}))

export const DeleteIconPrescription = styled(DeleteOutlineIcon)((props) => ({
  color: props.theme.palette.primary.main
}))

export const StackButtons = styled(StackWrapper)((props) => ({
  marginLeft: 'auto',
  minWidth: '240px',
  [props.theme.breakpoints.only('xs')]: {
    minWidth: 'unset'
  }
}))

export const AddButton = styled(ButtonWrapper)((props) => ({
  margin: '0 5px',
  textTransform: 'none',
  borderRadius: '50px',
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.background.paper,
  padding: '14px 24px',
  '&:hover': {
    backgroundColor: props.theme.palette.primary.dark
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: props.theme.palette.text.disabled
  },
  [props.theme.breakpoints.only('xs')]: {
    width: '330px'
  }
}))
