import { IApiPendingTasks, IPendingTasks } from '../../../infrastructure/dtos'
import { IGetPendingTasksService, getPendingTasksService } from '../Persistencia/PendingTasksService'

export interface IGetPendingTasksUtility {
  status: number
  data: IPendingTasks | string
}

export async function getPendingTasksUtility(user_id: string): Promise<IGetPendingTasksUtility> {
  try {
    const { data, status }: IGetPendingTasksService = await getPendingTasksService(user_id)
    return {
      status: (data as IApiPendingTasks<string | IPendingTasks>).statusCode === 200 && status ? status : 0,
      data: (data as IApiPendingTasks<string | IPendingTasks>).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
