import { useEffect, useState } from 'react'

import { ErrorBody, Reminders } from '../../../infrastructure/dtos/Reminders'
import { getReminders } from '../../../services/Contracts/Utility/ReminderUtility'

interface IUseReminderComponentHook {
  reminders: Reminders[]
  loadingReminders: boolean
  fetchReminders: (userId: string) => Promise<void>
  haveReminders: boolean
}

export function useReminderComponent(): IUseReminderComponentHook {
  const [loadingReminders, setLoadingReminders] = useState<boolean>(false)
  const user_id = JSON.parse(localStorage.getItem('userData') as string)?.userId
  const [haveReminders, setHaveReminders] = useState<boolean>(false)
  const [reminders, setReminders] = useState<Reminders[]>([])

  const fetchReminders = async (user_id: string): Promise<void> => {
    setLoadingReminders(true)
    const { data } = await getReminders(user_id)

    const message = (data as ErrorBody)?.message
    setLoadingReminders(false)
    if (!message) {
      setHaveReminders(true)
    }
    setReminders(data as Reminders[])
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchReminders(user_id).then()
    }, 0)

    return () => clearTimeout(timer)
  }, [user_id])

  return {
    reminders,
    loadingReminders,
    fetchReminders,
    haveReminders,
  }
}
