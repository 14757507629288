import { JSX } from 'react'

import { useNavigate } from 'react-router-dom'

import { CalendarDate } from '../../../../components/StyledComponents/CalendarDate'
import { WhiteButton } from '../../../../components/StyledComponents/WhiteButton'

function DateButton(): JSX.Element {
  const navigate = useNavigate()
  return (
    <WhiteButton
      id="new-date-button"
      data-testid="new-date-button"
      onClick={() => {
        navigate('/appointment')
      }}
    >
      <CalendarDate data-testid="calendar-date" />
      Nueva Cita
    </WhiteButton>
  )
}

export default DateButton
