import { useMediaQuery, useTheme } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { JSX } from 'react'

import { ICalendarData } from '../../../../infrastructure/dtos/CalendarInfo'

interface ICalendarProps {
  onChangeDate: (value: dayjs.Dayjs | null) => void
  selectedDate: dayjs.Dayjs | null
  daysAvailability: ICalendarData
}

export default function CalendarInfo({ onChangeDate, selectedDate, daysAvailability }: ICalendarProps): JSX.Element {
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <DateCalendar
        shouldDisableDate={(day: dayjs.Dayjs): boolean => !daysAvailability[dayjs(day).format('YYYY-MM-DD')]}
        value={selectedDate}
        dayOfWeekFormatter={(day: string, date: dayjs.Dayjs): string => {
          const s = dayjs(date).format('ddd').slice(0, -1)
          return s.charAt(0).toUpperCase() + s.slice(1)
        }}
        disablePast
        onChange={onChangeDate}
        sx={{
          width: isExtraSmallSize ? '23rem' : '30rem', // Ajusta el ancho del calendario según tu preferencia
          alignItems: 'center',
          margin: '0',
          // slide
          '& .MuiDayCalendar-slideTransition': {
            width: isExtraSmallSize ? '30rem' : '24rem', // Ajusta el ancho del calendario según tu preferencia
            height: '30rem', // Ajusta la altura del calendario según tu preferencia
          }, // fechas
          '& .MuiPickersDay-root': {
            color: '#3850B2', // Cambia el color de los días seleccionados a azul
            fontSize: '1.2rem',
            marginTop: '0.35rem',
            marginLeft: isExtraSmallSize ? '0.5rem' : '1.3rem',
            opacity: 1,
            fontFamily: 'Helvetica',
          },
          '& .MuiDayCalendar-weekDayLabel': {
            color: '#B2B2B2',
            fontSize: '1rem',
            marginTop: '0.35rem',
            marginLeft: '1.3rem',
            opacity: 0.497931,
            fontFamily: 'Helvetica',
            margin: !isExtraSmallSize ? '0 10px' : '0 6px',
          },
          '& .MuiPickersDay-root .MuiDayCalendar-header, .MuiPickersDay-root .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition, & .MuiPickersDay-root .MuiDayCalendar-monthContainer':
            {
              width: '26rem',
            },
          // contenedor
          '& .MuiDateCalendar-viewTransitionContainer': {
            width: '30rem', // Ajusta el ancho del calendario según tu preferencia
          },
          '& .MuiDayCalendar-header': {
            width: isExtraSmallSize ? '29.5rem' : '25.5rem',
          },
          // fecha actual
          '& .MuiPickersDay-root.MuiPickersDay-today ': {
            fontSize: '1.2rem',
            border: 'none',
            marginTop: '0.35rem',
            opacity: 1,
            fontFamily: 'Helvetica',
            marginLeft: isExtraSmallSize ? '0.5rem' : '1.5rem',
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '7px', // Ancho del punto
              height: '7px', // Alto del punto
              borderRadius: '50%', // Hace que el punto tenga forma redondeada
              background: 'blue', // Color del punto
              bottom: '-4px', // Posiciona el punto debajo del día actual
              left: 'calc(50% - 2px)', // Centra el punto horizontalmente
              backgroundColor: '#31CACD',
            },
          },
          // fecha actual seleccionada
          '& .MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#31CACD',
            color: '#FFFFFF',
            width: '40px',
          },
          '& .MuiPickersDay-root:not(.Mui-selected)': {
            border: 'none',
          },
          // fecha seleccionada circulo
          '& .MuiPickersDay-root:focus.Mui-selected ': {
            backgroundColor: '#31CACD',
            color: '#FFFFFF',
            width: '40px',
          },
          // fecha superior jun 2023
          '& .MuiPickersCalendarHeader-label': {
            opacity: 1,
            color: 'rgba(0, 0, 0, 1)',
            fontFamily: 'Helvetica',
            fontSize: '1.2rem',
            fontWeight: 400,
            fontStyle: 'normal',
            letterSpacing: '0px',
            textAlign: 'center',
          },
        }}
      />
    </LocalizationProvider>
  )
}
