import { styled } from '@mui/material/styles'

import {
  BoxWrapper,
  FadeWrapper,
  GridWrapper,
  ModalWrapper,
  TypographyWrapper,
} from '../../../../../../../../components'

export const ModalCancelReasons = styled(ModalWrapper)(() => ({
  height: '100%',
  overflowY: 'scroll',
}))

export const BoxModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '140px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '400px',
  height: '400px',
  bgcolor: theme.palette.background.paper,
  background: 'white',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
}))

export const FadeModal = styled(FadeWrapper)(({ theme }) => ({
  width: '35%',
  height: 'auto',
  [theme.breakpoints.only('xs')]: {
    width: '90%',
  },
}))

export const GridModal = styled(GridWrapper)(({ theme }) => ({
  padding: '60px',
  [theme.breakpoints.only('xs')]: {
    padding: '60px 20px',
  },
}))

export const TypographyModal = styled(TypographyWrapper)((props) => ({
  left: '50%',
  fontFamily: 'Helvetica',
  fontSize: '15px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: props.theme.palette.primary.dark,
}))
