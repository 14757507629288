import { useMediaQuery, useTheme } from '@mui/material'
import { JSX } from 'react'

import NextDatesCarousel from './components/NextDatesCarousel/NextDatesCarousel'
import NextDatesList from './components/NextDatesList/NextDatesList'
import PastAppointmentsCarousel from './components/PastAppointmentsCarousel/PastAppointmentsCarousel'
import { PastAppointmentsList } from './components/PastMedicalAppointmentsList/PastAppointmentsList'
import { GridWrapper } from '../../../components'
import { GridPartialScreen } from '../../../components/StyledComponents/GridPartialScreen'

function Dates(): JSX.Element {
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <GridPartialScreen mt={5} px={{ xs: 2, sm: 5, md: 15 }}>
      {isExtraSmallSize ? (
        <GridWrapper data-testid="next-dates-carousel">
          <NextDatesCarousel />
        </GridWrapper>
      ) : (
        <GridWrapper data-testid="next-dates-list">
          <NextDatesList fromHome={false} />
        </GridWrapper>
      )}
      {isExtraSmallSize ? (
        <GridWrapper data-testid="past-appointments-carousel">
          <PastAppointmentsCarousel />
        </GridWrapper>
      ) : (
        <GridWrapper data-testid="past-appointments-list">
          <PastAppointmentsList />
        </GridWrapper>
      )}
    </GridPartialScreen>
  )
}

export default Dates
