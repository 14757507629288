import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, JSX } from 'react'

import containerText from './../Register/PatientForm/PatientForm.json'

const AuthPage = (): JSX.Element => {
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0()
  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        if (!error) {
          loginWithRedirect()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])
  return <>{error && <>{containerText.send_email_verification}</>}</>
}

export default AuthPage
