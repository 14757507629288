import { DoctorInfoRepository } from '../../domain/repository/DoctorInfoRepository';
import { IResponseApi } from '../dtos';
import {
  IApiResponseDoctorInfo,
  IRecommendedDoctors,
  ISearchDoctorParams,
  ISuggestedDoctorsParams,
} from '../dtos/DoctorInfo';
import DoctorInfoDataSource from '../http/dataSource/DoctorInfoDataSource';

export class DoctorInfoRepositoryImpl implements DoctorInfoRepository {
  dataSource: DoctorInfoDataSource;

  constructor(_dataSource: DoctorInfoDataSource) {
    this.dataSource = _dataSource;
  }

  async getRecommendedDoctors(
    patient_id: string
  ): Promise<IResponseApi<IRecommendedDoctors[] | string>> {
    return this.dataSource.getRecommendedDoctors(patient_id);
  }

  async getDoctorInfo(idDoctor: string): Promise<IApiResponseDoctorInfo> {
    return this.dataSource.getDoctorInfo(idDoctor);
  }

  async searchDoctor(
    params: ISearchDoctorParams
  ): Promise<IApiResponseDoctorInfo> {
    return this.dataSource.searchDoctor(params);
  }

  getSuggestedDoctors(
    params: ISuggestedDoctorsParams
  ): Promise<IApiResponseDoctorInfo> {
    return this.dataSource.getSuggestedDoctors(params);
  }
}
