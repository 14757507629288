import { styled } from '@mui/material'

import {
  BoxWrapper,
  ButtonWrapper,
  FadeWrapper,
  IconButtonWrapper,
  TypographyWrapper,
} from '../../../../../../components'

export const FadeCancelModal = styled(FadeWrapper)(({ theme }) => ({
  width: '608px',
  height: 'auto',
  overflowY: 'auto',
  [theme.breakpoints.only('xs')]: {
    width: '90%',
  },
}))

export const BoxCancelModal = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme.palette.background.paper,
  background: 'white',
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
}))

export const CloseButton = styled(IconButtonWrapper)((props) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px',
}))

export const TypographyCancelModal = styled(TypographyWrapper)((props) => ({
  color: props.theme.palette.text.primary,
  textAlign: 'center',
  fontFamily: 'Helvetica',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
}))

export const KeepAppointmentButton = styled(ButtonWrapper)(({ theme }) => ({
  height: '3.4375rem',
  borderRadius: '2.625rem',
  backgroundColor: theme.palette.primary.dark,
  fontSize: '14px',
  textTransform: 'none',
}))
