import {
  GetPatientInfo,
  UpdatePatientInfo,
  GetPatientInfoByCi,
} from '../../../domain/useCase/GetPatienInfo/GetPatientInfo'
import {
  IServicePatientByCiInfo,
  IServicePatientInfo,
  PatientBody,
  PatientUpdateResponseBody,
} from '../../../infrastructure/dtos/Patient'
import PatientInfoMapper from '../../../infrastructure/http/mappers/PatientMapper'
import { PatientInfoRepositoryImpl } from '../../../infrastructure/RepositoryImpl/PatientRepositryImpl/PatientRepositoryImpl'

export interface IUpdatePatientInfoService {
  status: number
  data: PatientUpdateResponseBody | string
}

export async function GetPatientInfoService(idPatient: string): Promise<IServicePatientInfo> {
  try {
    const UseCasePatient = new GetPatientInfo(new PatientInfoRepositoryImpl(new PatientInfoMapper()))
    return {
      status: 1,
      data: await UseCasePatient.invoke(idPatient),
    }
  } catch (error) {
    return {
      status: 0,
      data: 'Error Service',
    }
  }
}

export async function updatePatientInfoService(
  params: PatientBody,
  idPatient: string,
): Promise<IUpdatePatientInfoService> {
  try {
    const UseCaseDoctor = new UpdatePatientInfo(new PatientInfoRepositoryImpl(new PatientInfoMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params, idPatient),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function GetPatientInfoByCiService(ciPatient: string): Promise<IServicePatientByCiInfo> {
  try {
    const UseCasePatient: GetPatientInfoByCi = new GetPatientInfoByCi(
      new PatientInfoRepositoryImpl(new PatientInfoMapper()),
    )
    return {
      status: 1,
      data: await UseCasePatient.invoke(ciPatient),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
