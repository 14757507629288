import { IApiDaysAvailability, IDaysAvailabilityResponse } from '../../../infrastructure/dtos/OfficeDaysAvailability'
import { IMonthAvailabilityResponse } from '../../../infrastructure/dtos/OfficeMonthAvailability'
import { IOfficesProps, IOfficesResponse } from '../../../infrastructure/dtos/Offices'
import { OfficesService, getDaysAvailabilityService, getMonthAvailabilityService } from '../Persistencia/OfficesService'

export interface IGetMonthAvailabilityUtility {
  status: number
  data: IMonthAvailabilityResponse
}

export interface IGetDaysAvailabilityUtility {
  status: number
  data: IDaysAvailabilityResponse
}

export interface IGetOfficesUtility {
  status: number
  data: IOfficesResponse
}

export async function getMonthAvailabilityUtility(
  idOffice: string,
  idDoctor: string,
  officeType: string,
): Promise<IGetMonthAvailabilityUtility> {
  try {
    const { data, status } = await getMonthAvailabilityService(idOffice, idDoctor, officeType)

    return {
      data: data.body,
      status: status !== 1 || data.statusCode !== 200 ? 0 : 1,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: 'Ups! Tengo inconvenientes en este momento, por favor intenta más tarde.', data: {}
      },
    }
  }
}

export async function getDaysAvailabilityUtility(
  idOffice: string,
  idDoctor: string,
  officeType: string,
  date: string,
): Promise<IGetDaysAvailabilityUtility> {
  try {
    const { data, status } = await getDaysAvailabilityService(idOffice, idDoctor, officeType, date)

    return {
      status: status !== 1 || (data as IApiDaysAvailability).statusCode !== 200 ? 0 : 1,
      data: data.body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: 'Ups! Tengo inconvenientes en este momento, por favor intenta más tarde.', data: undefined
      }
    }
  }
}

export async function getOfficesUtility(params: IOfficesProps): Promise<IGetOfficesUtility> {
  try {
    const { data, status } = await OfficesService(params)
    return {
      status: status !== 1 || data.statusCode !== 200 ? 0 : 1,
      data: data.body
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        message: 'Error al obtener el listado de consultorios.', data: []
      }
    }
  }
}
