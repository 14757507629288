import {
  IApiResponseCalendar,
  ICalendarEvent,
  IEvent,
  INextAppointmentHour,
  IParamsGetCalendar,
  IParamsGetNextAppointmentHour,
} from '../../infrastructure/dtos/Calendar'
import { CalendarRepository } from '../repository/CalendarRepository'

export interface PostCalendarUseCase {
  invoke(event: ICalendarEvent): Promise<IApiResponseCalendar<IEvent>>
}

export interface GetCalendarUseCase {
  invoke(params: IParamsGetCalendar): Promise<IApiResponseCalendar<IEvent>>
}

export interface GetNextAppointmentHourUseCase {
  invoke(params: IParamsGetNextAppointmentHour): Promise<IApiResponseCalendar<INextAppointmentHour>>
}

export class PostCalendarEvent implements PostCalendarUseCase {
  private objectUseCase: CalendarRepository

  constructor(_objectUseCase: CalendarRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(event: ICalendarEvent): Promise<IApiResponseCalendar<IEvent>> {
    return this.objectUseCase.postAddCalendarEvent(event)
  }
}

export class GetCalendarEvent implements GetCalendarUseCase {
  private objectUseCase: CalendarRepository

  constructor(_objectUseCase: CalendarRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: IParamsGetCalendar): Promise<IApiResponseCalendar<IEvent>> {
    return this.objectUseCase.getEventsByMonth(params)
  }
}

export class GetAvailableHoursInADay implements GetCalendarUseCase {
  private objectUseCase: CalendarRepository

  constructor(_objectUseCase: CalendarRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: IParamsGetCalendar): Promise<IApiResponseCalendar<IEvent>> {
    return this.objectUseCase.getAvailableHoursInADay(params)
  }
}

export class GetNextAppointmentHour implements GetNextAppointmentHourUseCase {
  private objectUseCase: CalendarRepository

  constructor(_objectUseCase: CalendarRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: IParamsGetNextAppointmentHour): Promise<IApiResponseCalendar<INextAppointmentHour>> {
    return this.objectUseCase.getNextAppointmentHour(params)
  }
}