import { IResponseApi } from '../../infrastructure/dtos';
import { IResponseDoctorFeedback } from '../../infrastructure/dtos/DoctorFeedback';
import { DoctorFeedbackRepository } from '../repository/DoctorFeedbackRepository';

export interface DoctorFeedbackUseCase {
  invoke: (doctorId: string) => Promise<IResponseApi<IResponseDoctorFeedback>>;
}

export class GetDoctorFeedback implements DoctorFeedbackUseCase {
  private objectUseCase: DoctorFeedbackRepository;

  constructor(_objectUseCase: DoctorFeedbackRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(doctorId: string): Promise<IResponseApi<IResponseDoctorFeedback>> {
    return this.objectUseCase.getDoctorFeedback(doctorId);
  }
}
