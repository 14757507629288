import { LocationOnOutlined } from '@mui/icons-material';

import { AvatarWrapper, GridWrapper } from '../../../../../../../components'
import SubmitButton from '../../../../../../../components/SubmitButton';
import { IDoctorCM } from '../../../../../../../infrastructure/dtos/DoctorCM'
import { LabelCMTextName, SubTitle, SubTitleCM } from '../../styles'


export type IDoctorCMProps = IDoctorCM & { onClose(path?: string): void }

const DoctorCM = ({
  name,
  speciality,
  photo,
  name_office,
  consultation_value,
  full_price,
  user_id,
  office_id_cm,
  id_speciality,
  rol,
  branch_id_cm,
  doctor_id_cm,
  onClose
}: IDoctorCMProps): JSX.Element => {
  return (
    <GridWrapper
      container
      my={2.5}
      display={'flex'}
      justifyContent={'center'}
      sx={{
        borderRadius: '10px',
        padding: 2,
        backgroundColor: 'tertiary.light'
      }}
    >
      <GridWrapper item xs={12} sm={3}>
        <AvatarWrapper
          src={photo}
          sx={{
            border: '1px solid white',
            width: 80,
            height: 80,
          }}
        />
      </GridWrapper>
      <GridWrapper item xs={12} sm={9}>
        <GridWrapper display={'flex'} justifyContent={'space-between'}>
          <GridWrapper item>
            <LabelCMTextName>{name}</LabelCMTextName>
            <SubTitleCM>{speciality}</SubTitleCM>
          </GridWrapper>
          <GridWrapper item>
            <SubTitleCM
              sx={{
                fontSize: '10px',
                color: 'primary.main',
                fontWeight: 'bold',
                letterSpacing: .1,
              }}
            >
              precio normal: <span style={{ textDecoration: 'line-through' }}>{full_price}</span>
            </SubTitleCM>
            <SubTitleCM sx={{ color: 'primary.dark', textAlign: 'right' }} fontWeight={'bold'}>{consultation_value}</SubTitleCM>
          </GridWrapper>
        </GridWrapper>
        <GridWrapper display={'flex'} alignItems={'center'} my={1}>
          <LocationOnOutlined sx={{ marginRight: '5px' }} />
          <SubTitle><b>Consultorio:</b> {name_office}</SubTitle>
        </GridWrapper>
          <SubmitButton
            id={'separate-appointment'}
            variant={'contained'}
            type={'button'}
            text={'Separar cita'}
            fullWidth
            sx={{
              height: 'auto',
              fontSize: '13px',
              backgroundColor: 'primary.dark',
              '&:hover': {
                backgroundColor: 'primary.dark',
              }
            }}
            onClick={() =>onClose(`/appointment/booking/${user_id}?officeId=${office_id_cm}&idSpecialty=${id_speciality}&rol=${rol}&branchId=${branch_id_cm}&doctorCM=${doctor_id_cm}`)}
          />
      </GridWrapper>
      
    </GridWrapper>
  )
}

export default DoctorCM
