import { LocationRepository } from '../../domain/repository/LocationRepository';
import {
  ICreateLocation,
  IApiLocation,
  IModifiedLocation,
  IDeletedLocation,
} from '../dtos/location';
import LocationDataSource from '../http/dataSource/LocationDataSource';

export class LocationRepositoryImpl implements LocationRepository {
  dataSource: LocationDataSource;

  constructor(_DataSource: LocationDataSource) {
    this.dataSource = _DataSource;
  }

  postCreateLocation(params: ICreateLocation): Promise<IApiLocation> {
    return this.dataSource.postCreateLocation(params);
  }

  postModifiedLocation(params: IModifiedLocation): Promise<IApiLocation> {
    return this.dataSource.postModifiedLocation(params);
  }

  postDeletedLocation(params: IDeletedLocation): Promise<IApiLocation> {
    return this.dataSource.postDeletedLocation(params);
  }
}
