import { ICodeService } from './PhoneVerificationService'
import { VerifyTemporaryCode } from '../../../domain/useCase/VerifyTemporaryPhone'
import { IPhoneVerification, IVerifyTemporaryPhoneParams } from '../../../infrastructure/dtos/PhoneVerification'
import VerifyTemporaryPhoneMapper from '../../../infrastructure/http/mappers/VerifyTemporaryPhoneMapper'
import { VerifyTemporaryPhoneRepositoryImpl } from '../../../infrastructure/RepositoryImpl/VerifyTemporaryPhoneRepositoryImpl'

export async function verifyTemporaryPhoneService(
  params: IVerifyTemporaryPhoneParams,
): Promise<ICodeService<IPhoneVerification>> {
  try {
    const useCase = new VerifyTemporaryCode(new VerifyTemporaryPhoneRepositoryImpl(new VerifyTemporaryPhoneMapper()))

    return {
      status: 1,
      data: await useCase.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
