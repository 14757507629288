import DoctorCMRepository from '../../domain/repository/DoctorCMRepository'
import { IApiDoctorCM } from '../dtos/DoctorCM'
import DoctorCMDataSource from '../http/dataSource/DoctorCMDataSource'

export class DoctorCMRepositoryImpl implements DoctorCMRepository {
  dataSource: DoctorCMDataSource

  constructor(_datasource: DoctorCMDataSource) {
    this.dataSource = _datasource
  }

  getDoctorCM(idBranchCM: string, query: string): Promise<IApiDoctorCM> {
    return this.dataSource.getDoctorCM(idBranchCM, query)
  }
}
