import {
  IApiResponseSaveDataFollow,
  SaveDataFollow
} from '../../infrastructure/dtos/Follow'
import { FollowRepository } from '../repository/FollowRepository'

export interface FolowUseCase {
  invoke: (params: SaveDataFollow) => Promise<IApiResponseSaveDataFollow>
}

export class SaveFollow implements FolowUseCase {
  private objectUseCase: FollowRepository

  constructor(_objectUseCase: FollowRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(params: SaveDataFollow): Promise<IApiResponseSaveDataFollow> {
    return this.objectUseCase.saveFollow(params)
  }
}
