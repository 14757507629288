import { IResponseApi, IResponseService } from '../../../infrastructure/dtos'
import {
  ISearchDoctorParams,
  ISearchDoctorResponse,
  ISuggestedDoctorsParams,
  ISuggestedDoctors,
  IApiResponseDoctorInfo,
  IRecommendedDoctors,
} from '../../../infrastructure/dtos/DoctorInfo'
import {
  IDoctorService,
  SearchDoctorService,
  GetSuggestedDoctorsService,
  ISuggestedDoctorsService,
  RecommendedDoctorsService,
} from '../Persistencia/DoctorInfoService'

export interface IDoctorUtility<T> {
  status: number
  data: string | T
}

export async function SearchDoctorUtility(params: ISearchDoctorParams): Promise<IDoctorUtility<ISearchDoctorResponse>> {
  try {
    const { data, status }: IDoctorService = await SearchDoctorService(params)
    if (typeof data !== 'string' && data.statusCode === 200 && status === 1) {
      return {
        data: data.body as ISearchDoctorResponse,
        status,
      }
    }
    return {
      data: (data as IApiResponseDoctorInfo).body as string,
      status,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IDoctorService)?.data as IApiResponseDoctorInfo).body as string,
    }
  }
}

export async function getSuggestedDoctorsUtility(
  params: ISuggestedDoctorsParams,
): Promise<IDoctorUtility<ISuggestedDoctors[]>> {
  try {
    const { data, status }: ISuggestedDoctorsService = await GetSuggestedDoctorsService(params)
    if (typeof data !== 'string' && data.statusCode === 200 && status === 1) {
      return {
        data: data.body as ISuggestedDoctors[],
        status,
      }
    }
    return {
      data: (data as IApiResponseDoctorInfo).body as string,
      status,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as ISuggestedDoctorsService)?.data as IApiResponseDoctorInfo).body as string,
    }
  }
}
export async function RecommendedDoctorsUtility(
  patient_id: string,
): Promise<IResponseService<IRecommendedDoctors[] | string>> {
  try {
    const { data, status }: IResponseService<IResponseApi<string | IRecommendedDoctors[]>> =
      await RecommendedDoctorsService(patient_id)
    return {
      data: (data as IResponseApi<string | IRecommendedDoctors[]>)?.body,
      status: (data as IResponseApi<string | IRecommendedDoctors[]>)?.statusCode === 200 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as IResponseService<IResponseApi<string>>).data as IResponseApi<string>).body,
    }
  }
}
