import { JSX } from 'react'

import { useResumeComponent } from './ResumeComponentHooks'
import { BoxResumeStyled } from './ResumeComponentStyles'
import { BoxWrapper } from '../../../components'
import { CalendarIcon } from '../../../components/icons/CalendarIcon'
import { DoctorIcon } from '../../../components/icons/DoctorIcon'
import { SpecialtyIcon } from '../../../components/icons/SpecialtyIcon'
import { StartIcon } from '../../../components/icons/StarIcon'
import { LabelInputUserInfo } from '../../../components/StyledComponents/LabelInputUserInfo'
import { LabelInputUserAge } from '../../../components/StyledComponents/LabelUserAge'
import { LabelInputUserName } from '../../../components/StyledComponents/LabelUserName'
import CircularProgressWrapper from '../../../components/wrapper/LoaderWrapper'
import { UserInfoBox } from '../styles'

export const Resume = (): JSX.Element => {
  const { resume, loadingResume, isExtraSmallSize } = useResumeComponent()
  return (
    <BoxWrapper sx={{ width: 1, marginTop: 1 }} data-testid="resume">
      {!loadingResume ? (
        <>
          <UserInfoBox>
            <LabelInputUserName>{resume.name_user}</LabelInputUserName>
          </UserInfoBox>
          <UserInfoBox>
            <LabelInputUserAge>{resume.old_years} Años</LabelInputUserAge>
          </UserInfoBox>
          {!isExtraSmallSize && (
            <BoxWrapper
              display="flex"
              flexDirection={'column'}
              sx={{ marginTop: 2 }}
              gridTemplateColumns="repeat(12, 1fr)"
              alignItems={'flex-start'}
            >
              <BoxResumeStyled>
                <CalendarIcon />
                <LabelInputUserInfo>
                  {`${resume.number_appointments} ${
                    resume.number_appointments > 1 ? 'Atenciones recibidas' : 'Atención recibida'
                  }`}
                </LabelInputUserInfo>
              </BoxResumeStyled>
              <BoxResumeStyled>
                <DoctorIcon />
                <LabelInputUserInfo sx={{ marginLeft: '22px' }}>
                  {`Te atendieron ${resume.doctors} ${resume.doctors > 1 ? 'médicos' : 'médico'}`}
                </LabelInputUserInfo>
              </BoxResumeStyled>
              <BoxResumeStyled>
                <StartIcon />
                <LabelInputUserInfo>
                  {`Dejaste ${resume.number_qualification} ${resume.number_qualification > 1 ? 'reseñas' : 'reseña'}`}
                </LabelInputUserInfo>
              </BoxResumeStyled>
              <BoxResumeStyled>
                <SpecialtyIcon />
                <LabelInputUserInfo sx={{ marginLeft: '21px' }}>
                  {`Te atendieron ${resume.speciality} ${resume.speciality > 1 ? 'especialidades' : 'especialidad'}`}
                </LabelInputUserInfo>
              </BoxResumeStyled>
            </BoxWrapper>
          )}
        </>
      ) : (
        <CircularProgressWrapper data-testid="loading-spinner-reminder" />
      )}
    </BoxWrapper>
  )
}
