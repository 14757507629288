import { ResendTemporaryPhoneCodeResponse } from '../../infrastructure/dtos/TemporaryPhoneVerification'
import { ResendPhoneVerificationRepository } from '../repository/ResendPhoneVerificationRepository'

export interface ResendPhoneVerificationUseCase {
  invoke: (user_id: string) => Promise<ResendTemporaryPhoneCodeResponse>
}

export class GetCode implements ResendPhoneVerificationUseCase {
  private objectUseCase: ResendPhoneVerificationRepository

  constructor(_objectUseCase: ResendPhoneVerificationRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(user_id: string): Promise<ResendTemporaryPhoneCodeResponse> {
    return this.objectUseCase.getCode(user_id)
  }
}
