import ReminderRepository from '../../domain/repository/ReminderRepository'
import { IReminderResponse } from '../dtos/Reminders'
import ReminderDataSource from '../http/dataSource/ReminderDataSource'

export class ReminderRepositoryImpl implements ReminderRepository {
  dataSource: ReminderDataSource

  constructor(_dataSource: ReminderDataSource) {
    this.dataSource = _dataSource
  }

  getReminders(patient_id: string): Promise<IReminderResponse> {
    return this.dataSource.getReminders(patient_id)
  }
}
