import { useState, MouseEvent, useEffect, useContext, Dispatch, SetStateAction } from 'react'

import { AccountContext, AuthContextType } from '../../../../../../contexts/AccountContext'
import { IAppointmentQualification } from '../../../../../../infrastructure/dtos'
import { IPastAppointment, IPastAppointmentResponse } from '../../../../../../infrastructure/dtos/Appointments'
import { pastAppointmentsUtility } from '../../../../../../services/Contracts/Utility/AppointmentsUtility'

interface IPastDatesListHook {
  anchorEl: HTMLElement | null
  pastAppointments: IPastAppointment[]
  appointmentToModal?: IPastAppointment
  message?: string
  loading: boolean
  openModal: boolean
  pastId: IAppointmentQualification | undefined
  range: [Date, Date] | null | undefined
  searchAppointment: string
  handleClick: (event: MouseEvent<HTMLElement>, appointmentIndex: number) => void
  handleClose: (appointmentIndex: number) => void
  handleAppointmentToModal: (appointment: IPastAppointment) => void
  fetchPastAppointments: (user_id: string) => Promise<void>
  handleCloseModal: () => void
  handleOpenModal: (item: IPastAppointment) => void
  setRange: Dispatch<SetStateAction<[Date, Date] | null | undefined>>
  setSearchAppointment: Dispatch<SetStateAction<string>>
}

export function usePastAppointmentsList(): IPastDatesListHook {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [loading, setLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [user_id] = useState<string>(JSON.parse(localStorage.getItem('userData') as string)?.userId)
  const [message, setMessage] = useState<string>()
  const [pastAppointments, setPastAppointments] = useState<IPastAppointment[]>([])
  const [appointmentToModal, setAppointmentToModal] = useState<IPastAppointment>()
  const [searchAppointment, setSearchAppointment] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [pastId, setPastId] = useState<IAppointmentQualification>()
  const [range, setRange] = useState<[Date, Date] | null>()

  const handleOpenModal = (item: IPastAppointment): void => {
    setOpenModal(true)
    setPastId({
      appointment_id: item.appointment_id,
      doctor_id: item.user_id,
      email: item.email,
      last_name_doctor: item.last_name_doctor,
      name: item.name_patient,
      name_doctor: item.name_doctor,
      office_id: item.office_id,
      patient_id: item.patient_id,
      patient_id_number: item.patient_id_number,
      reschedule_id: '',
    })
  }

  const handleCloseModal = (): void => setOpenModal(false)

  const handleAppointmentToModal = (appointment: IPastAppointment): void => setAppointmentToModal(appointment)

  const fetchPastAppointments = async (user_id: string): Promise<void> => {
    setMessage('')
    try {
      setLoading(true)
      const { data, status } = await pastAppointmentsUtility(user_id)
      if (
        !status ||
        !(data as IPastAppointmentResponse).success ||
        (data as IPastAppointmentResponse).appointment.length === 0
      ) {
        setMessage((data as IPastAppointmentResponse).message || 'Error al obtener los datos')
        return
      }
      setPastAppointments((data as IPastAppointmentResponse).appointment)
      setLoading(false)
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener las citas pasadas', 'error')
    }
  }

  useEffect(() => {
    fetchPastAppointments(user_id)
  }, [])

  const handleClick = (event: MouseEvent<HTMLElement>, appointmentIndex: number): void => {
    const newAppointments: IPastAppointment[] = [...pastAppointments]
    newAppointments[appointmentIndex].showMenu = true
    setPastAppointments(newAppointments)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (appointmentIndex: number): void => {
    const newAppointments: IPastAppointment[] = [...pastAppointments]
    newAppointments[appointmentIndex].showMenu = false
    setPastAppointments(newAppointments)
    setAnchorEl(null)
  }

  return {
    anchorEl,
    message,
    pastAppointments,
    appointmentToModal,
    loading,
    openModal,
    pastId,
    range,
    searchAppointment,
    handleClick,
    handleClose,
    handleAppointmentToModal,
    fetchPastAppointments,
    handleCloseModal,
    handleOpenModal,
    setRange,
    setSearchAppointment,
  }
}
