import { AxiosError } from 'axios'

import { IApiPrescription, IRecipePrescription } from '../../dtos/Prescription'
import { PrescriptionsDataSource } from '../dataSource/PrescriptionsDataSourse'
import { http } from '../Http'

export default class PrescriptionsMapper implements PrescriptionsDataSource {
  async getAppointmentRecipes(appointmentId: string): Promise<IApiPrescription<IRecipePrescription | string>> {
    try {
      const { data } = await http.get<IApiPrescription<IRecipePrescription>>(`send_data/prescription/${appointmentId}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
