import { AxiosError, AxiosResponse } from 'axios'

import {
  DataPatient,
  IApiResponsePatientInfo,
  PatientBody,
  PatientUpdateResponseBody
} from '../../dtos/Patient'
import PatientInfoDataSource from '../dataSource/PatientDataSource'
import { http } from '../Http'

export default class PatientInfoMapper implements PatientInfoDataSource {
  async getPatientInfoByCi(ciPatient: string): Promise<DataPatient> {
    try {
      const { data } = await http.get<DataPatient>(
        `send_data/data_patient_by_ci/${ciPatient}?type=info`
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async getPatientInfo(idPatient: string): Promise<IApiResponsePatientInfo> {
    const data = await http
      .get<IApiResponsePatientInfo>(`/send_data/data_patient/${idPatient}`)
      .then((response: AxiosResponse<IApiResponsePatientInfo>) => {
        const { data } = response
        return data
      })
      .catch((error: AxiosError) => {
        const data: IApiResponsePatientInfo = {
          statusCode: error.response?.status as number,
          body: error.response?.statusText as string,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }
        return data
      })
    return data
  }

  async updatePatientInfo(
    params: PatientBody,
    idPatient: string
  ): Promise<PatientUpdateResponseBody> {
    try {
      const { data } = await http.put<PatientUpdateResponseBody>(
        `modified_data/patient/${idPatient}`,
        { ...params }
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }
}
