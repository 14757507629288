import AppointmentsRepository from '../../domain/repository/AppointmentsRepository';

import {
  IApiAppointment,
  IAppointmentInfoResponse,
  IAppointmentReasonCancellation,
  IAppointmentStatus,
  IConfirmAppointmentProps,
  INextAppointmentResponse,
  IPastAppointmentResponse
} from '../dtos/Appointments';

import AppointmentsDataSource from '../http/dataSource/AppointmentsDataSource';

export class AppointmentsRepositoryImpl implements AppointmentsRepository {
  dataSource: AppointmentsDataSource

  constructor(_datasource: AppointmentsDataSource) {
    this.dataSource = _datasource
  }

  reasonCancellationAppointment(): 
  Promise<IApiAppointment<string | IAppointmentReasonCancellation>> {
    return this.dataSource.reasonCancellationAppointment()
  }

  confirmAppointment(params: IConfirmAppointmentProps)
    : Promise<IApiAppointment<IAppointmentStatus | string>> {
      return this.dataSource.confirmAppointment(params)
    }

  getAppointmentInfo(appointmentId: string)
    : Promise<IApiAppointment<IAppointmentInfoResponse | string>> {
    return this.dataSource.getAppointmentInfo(appointmentId)
  }
  
  nextAppointments(userId: string)
    : Promise<IApiAppointment<INextAppointmentResponse | string>> {
      return this.dataSource.nextAppointments(userId)
    }
  
  pastAppointments(userId: string)
    : Promise<IApiAppointment<IPastAppointmentResponse | string>> {
      return this.dataSource.pastAppointments(userId)
    }
}

