import CloseIcon from '@mui/icons-material/Close'
import { useMediaQuery, useTheme } from '@mui/material'
import { useState, JSX } from 'react'

import { BoxCancelModal, CloseButton, FadeCancelModal, TypographyCancelModal } from './CancelAppointmentStyle'
import { BackdropWrapper, GridWrapper, ModalWrapper } from '../../../../../../components'
import { SecondaryMediumButton } from '../../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import SubmitButton from '../../../../../../components/SubmitButton'

interface ICancelAppointmentsProps {
  handleClose: () => void
  open: boolean
  handleShowCancelReasons: () => void
}

export const CancelAppointment = ({
  handleClose,
  open,
  handleShowCancelReasons,
}: ICancelAppointmentsProps): JSX.Element => {
  const jsonButton = {
    textFalse: 'No, deseo mantenerla',
    textTrue: 'Sí, deseo cancelarla',
  }
  const [textTrue] = useState(jsonButton.textTrue)
  const [textFalse] = useState(jsonButton.textFalse)

  const theme = useTheme()

  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-cancel-appointment"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeCancelModal in={open}>
        <BoxCancelModal>
          <GridWrapper paddingX={isExtraSmallSize ? 0 : 5} paddingY={5}>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <GridWrapper paddingX={isExtraSmallSize ? 0 : 2} paddingY={2}>
              <TypographyCancelModal
                id="transition-modal-title"
                variant="h6"
                data-testid="modal-cancel-appointment-title"
              >
                ¿Seguro deseas cancelar la cita?
              </TypographyCancelModal>
              <GridWrapper
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                gap={'23px'}
                mt={'50px'}
              >
                <GridWrapper item xs={isExtraSmallSize ? 6 : 5}>
                  <SecondaryMediumButton
                    fullWidth
                    id="cancelAppointmentButton"
                    text={textTrue}
                    type="button"
                    variant="contained"
                    onClick={handleShowCancelReasons}
                  />
                </GridWrapper>
                <GridWrapper item xs={isExtraSmallSize ? 6 : 5}>
                  <SubmitButton
                    id="keppAppointmentButton"
                    data-testid="keppAppointmentButton"
                    text={textFalse}
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={handleClose}
                  />
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>
          </GridWrapper>
        </BoxCancelModal>
      </FadeCancelModal>
    </ModalWrapper>
  )
}
