import { Edit, Save, Close as CloseIcon } from '@mui/icons-material'
import { useContext, useState, JSX, Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'

import { useModalConfirmPhoneNumber } from './hook'
import {
  BoxModal,
  CloseButton,
  EditPhoneButton,
  GridInputCode,
  ModalConfirmPhone,
  ResendCodeButton,
  SubtitleTypographyModal,
  TitleTypographyModal,
} from './styles'
import { GridWrapper, TypographyWrapper } from '../../../../../components'
import FormTextInput from '../../../../../components/FormInputText'
import { SecondaryMediumButton } from '../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { LabelInput } from '../../../../../components/StyledComponents/LabelInput'
import { LabelInputError } from '../../../../../components/StyledComponents/LabelInputError'
import SubmitButton from '../../../../../components/SubmitButton'
import CircularProgressWrapper from '../../../../../components/wrapper/LoaderWrapper'
import {
  PatientAppointmentContext,
  PatientAppointmentInitState,
} from '../../../../../contexts/PatientAppointmentContext'
import { SearchInitState, SearchOfficesContext } from '../../../../../contexts/SeachOfficesContext'
import { IPhoneVerification } from '../../../../../infrastructure/dtos/PhoneVerification'
import { validateInput } from '../../../../../utils/validateInput'
import { validatePhoneNumber } from '../../../../../utils/validatePhoneNumber'
import { TypographyWrapperValidationPhoneFalse } from '../../../../Register/PatientForm/PatientFormStyle'

interface IModalConfirmPhoneNumberProps {
  handleClose: () => void
  setPhoneVerification: Dispatch<SetStateAction<IPhoneVerification | undefined>>
  open: boolean
  phoneVerification?: IPhoneVerification
  setIsSubmitted: Dispatch<SetStateAction<boolean>>
}
export function ModalConfirmPhoneNumber({
  handleClose,
  setPhoneVerification,
  open,
  setIsSubmitted,
  phoneVerification,
}: IModalConfirmPhoneNumberProps): JSX.Element {
  const { saveScheduleSelected } = useContext(PatientAppointmentContext)
  const { savelastSearch } = useContext(SearchOfficesContext)
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([false])

  const handleCloseAlert = (isSuccess: boolean): void => {
    if (isSuccess) {
      navigate('/')
      saveScheduleSelected(PatientAppointmentInitState)
      savelastSearch(SearchInitState)
      handleClose()
    } else {
      setPhoneVerification(undefined)
      handleClose()
    }
  }

  const {
    isEditingPhoneNumber,
    enableSendCodeButton,
    enabledResendCodeButton,
    isSavingPhone,
    codeValue,
    phoneNumber,
    countdownTimer,
    message,
    loadCreatingNewAppointment,
    loadValidatePhone,
    handleIsEditingPhoneNumber,
    onChangePhoneNumber,
    onChangeCodeValue,
    fetchResendCode,
    fetchSendCode,
    fetchVerifyCode,
  } = useModalConfirmPhoneNumber(phoneVerification, setPhoneVerification, setIsSubmitted)

  const navigate = useNavigate()

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const validateMobile: boolean = !validateInput(phoneNumber) && phoneNumber.length === 0 && ValidationFocus[2]

  return (
    <ModalConfirmPhone onClose={() => handleCloseAlert(false)} open={open}>
      <BoxModal>
        {!loadCreatingNewAppointment && (
          <>
            <GridWrapper container display={'flex'} justifyContent={'center'}>
              <GridWrapper item xs={8} sm={12}>
                <TitleTypographyModal id="transition-modal-title" variant="h6">
                  Confirma tu número de celular
                </TitleTypographyModal>
              </GridWrapper>
              <CloseButton onClick={() => handleCloseAlert(false)}>
                <CloseIcon />
              </CloseButton>
            </GridWrapper>

            <GridWrapper
              container
              data-testid="phone-number"
              marginTop={{ xs: 1, sm: 3 }}
              spacing={{ xs: 2 }}
              display={'flex'}
              justifyContent={'space-around'}
              alignItems={'flex-end'}
            >
              <GridWrapper item xs={8} sm={5}>
                <LabelInput>Número de teléfono</LabelInput>
                <FormTextInput
                  placeholder="09......"
                  id="phone-number"
                  type="text"
                  fullWidth
                  value={phoneNumber}
                  name="phone-number"
                  onChange={(event) => onChangePhoneNumber(event.target.value)}
                  onBlur={() => onValidationFocus(0)}
                  disabled={!isEditingPhoneNumber}
                  inputRef={(element: HTMLInputElement) => (isEditingPhoneNumber ? element?.focus() : element?.blur())}
                  sx={{
                    '& .MuiInputBase-input': {
                      textAlign: 'center',
                    },
                  }}
                  error={validateMobile}
                  texterror={validateMobile ? 'Campo requerido.' : ''}
                />
                {!validatePhoneNumber(phoneNumber) && phoneNumber.length > 0 && (
                  <TypographyWrapperValidationPhoneFalse>
                    Ingresa un teléfono celular válido
                  </TypographyWrapperValidationPhoneFalse>
                )}
              </GridWrapper>

              <GridWrapper item xs={4} sm={3}>
                <EditPhoneButton
                  type="button"
                  variant="outlined"
                  startIcon={isEditingPhoneNumber || isSavingPhone ? <Save /> : <Edit />}
                  disabled={isSavingPhone}
                  onClick={() => handleIsEditingPhoneNumber(!isEditingPhoneNumber)}
                >
                  {isEditingPhoneNumber || isSavingPhone ? 'Guardar' : 'Modificar'}
                </EditPhoneButton>
              </GridWrapper>

              <GridWrapper item xs={12} sm={4} sx={{ '&.MuiGrid-item': { paddingLeft: 0 } }}>
                <SecondaryMediumButton
                  text="Enviar código"
                  id="send-code"
                  type="button"
                  fullWidth
                  disabled={isEditingPhoneNumber || !enableSendCodeButton || isSavingPhone}
                  onClick={fetchSendCode}
                />
              </GridWrapper>
            </GridWrapper>

            <GridWrapper
              container
              data-testid="code-section"
              marginTop={5}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <GridWrapper item xs={12}>
                <SubtitleTypographyModal>
                  Ingresa código de verificación enviado a tu número de teléfono
                </SubtitleTypographyModal>
              </GridWrapper>
              <GridInputCode item xs={12} sm={6}>
                <FormTextInput
                  placeholder="Ingresa tu código aquí"
                  id="code-sended"
                  type="text"
                  fullWidth
                  value={codeValue}
                  name="code-sended"
                  onChange={(event) => onChangeCodeValue(event.target.value)}
                  sx={{
                    '& .MuiInputBase-input': {
                      textAlign: 'center',
                    },
                  }}
                />
              </GridInputCode>
              <GridWrapper item marginTop={3}>
                <ResendCodeButton variant="text" onClick={fetchResendCode} disabled={!enabledResendCodeButton}>
                  ¿No recibiste el código? Reenvíalo
                </ResendCodeButton>
              </GridWrapper>
              {countdownTimer && (
                <GridWrapper item marginTop={0.5}>
                  <LabelInputError sx={{ color: 'GrayText' }}>
                    El código de verificación expira en: <b>{countdownTimer}</b>
                  </LabelInputError>
                </GridWrapper>
              )}
            </GridWrapper>
            <GridWrapper container marginTop={5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              {message && (
                <GridWrapper item marginBottom={0.5}>
                  <LabelInputError sx={{ color: 'primary.dark' }} textAlign={'center'}>
                    {message}
                  </LabelInputError>
                </GridWrapper>
              )}
              <SubmitButton
                id={'confirm-phone-number'}
                data-testid={'confirm-phone-number'}
                variant={'contained'}
                fullWidth
                type={'submit'}
                text={'Confirmar'}
                disabled={!codeValue || phoneVerification?.remaining_intents_validation === 0 || loadValidatePhone}
                onClick={fetchVerifyCode}
              />
            </GridWrapper>
          </>
        )}
        {loadCreatingNewAppointment && (
          <GridWrapper container flexDirection={'column'} justifyContent={'center'} alignItems={'center'} spacing={2}>
            <TypographyWrapper color={'primary.dark'} fontSize={'22px'} textAlign={'center'} marginBottom={2}>
              {message && (
                <>
                  {message}
                  <br />
                </>
              )}
              Creando cita...
            </TypographyWrapper>
            <CircularProgressWrapper />
          </GridWrapper>
        )}
      </BoxModal>
    </ModalConfirmPhone>
  )
}
