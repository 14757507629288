import { useState } from 'react'

interface IUseCancelReasonsHook {
  handleOpenCancelReasons: () => void
  handleCloseCancelReasons: () => void
  openCancelReasons: boolean
  selectedEvent: string
}

export function useCancelReasonsHook(): IUseCancelReasonsHook {
  const [openCancelReasons, setOpenCancelReasons] = useState<boolean>(false)
  const [selectedEvent] = useState<string>('')

  const handleOpenCancelReasons = (): void => setOpenCancelReasons(true)
  const handleCloseCancelReasons = (): void => setOpenCancelReasons(false)

  return {
    handleOpenCancelReasons,
    handleCloseCancelReasons,
    openCancelReasons,
    selectedEvent,
  }
}
