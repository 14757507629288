import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { styled } from '@mui/material'

import { AvatarWrapper, ButtonWrapper, GridWrapper, TypographyWrapper } from '../../../../../../../components'

export const AvatarStyled = styled(AvatarWrapper)(() => ({
  border: '1px solid white',
  width: 100,
  height: 100,
}))

export const TypographyStyled = styled(TypographyWrapper)(() => ({
  fontSize: '.9rem',
  fontWeight: 'bold',
}))

export const ShowDoctorButtomnStyled = styled(ButtonWrapper)(({ theme }) => ({
  marginTop: '10px',
  borderRadius: '1rem',
  padding: '3px 15px',
  width: '100px',
  color: theme.palette.primary.dark,
  borderColor: theme.palette.primary.dark,
  '&:hover': { color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark }
}))

export const GridArrowLeftIcon = styled(GridWrapper)(() => ({
  display: 'flex',
  position: 'absolute',
  top: 50,
  left: 0,
  justifyContent: 'center',
  justifyItems: 'center',
  alignItems: 'center',
  alignContent: 'center',
  zIndex: 1,
}))

export const ChevronLeftIconStyled = styled(ChevronLeft)(() => ({
  fontSize: '1.8rem',
  opacity: '.5',
  cursor: 'pointer',
  '&:hover': {
    opacity: '1',
  },
}))

export const GridArrowRightIcon = styled(GridWrapper)(() => ({
  display: 'flex',
  position: 'absolute',
  top: 50,
  right: 0,
  justifyContent: 'center',
  justifyItems: 'center',
  alignItems: 'center',
  alignContent: 'center',
  zIndex: 1,
}))

export const ChevronRightIconStyled = styled(ChevronRight)(() => ({
  fontSize: '1.8rem',
  opacity: '.5',
  cursor: 'pointer',
  '&:hover': {
    opacity: '1',
  },
}))

export const SmallAvatar = styled(AvatarWrapper)((props) => ({
  width: 25,
  height: 25,
  border: `2px solid ${props.theme.palette.background.paper}`,
}))
