import { RescheduleAppointment } from '../../../domain/useCase/dataReschedulingAppointmentUseCase'
import {
  ReschedulingAppointment,
  ResponseReschedulingAppointment,
} from '../../../infrastructure/dtos/ReschedulingAppointment'
import { ReschedulingAppointmentRepositoryImpl } from './../../../infrastructure/RepositoryImpl/ReschedulingAppointmentRepositoryImpl'
import ReschedulingAppointmentMapper from '../../../infrastructure/http/mappers/ReschedulingAppointmentMapper'

export interface IReschedulingAppointmentService {
  data: ResponseReschedulingAppointment | string
  status: number
}

export async function ReschedulingAppointmentService(
  dataReschedulingAppointment: ReschedulingAppointment,
): Promise<IReschedulingAppointmentService> {
  try {
    const UseCaseApi = new RescheduleAppointment(
      new ReschedulingAppointmentRepositoryImpl(new ReschedulingAppointmentMapper()),
    )
    return {
      status: 1,
      data: await UseCaseApi.invoke(dataReschedulingAppointment),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
