import PendingTasksRepository from '../../domain/repository/PendingTasksRepository'
import { IApiPendingTasks, IPendingTasks } from '../dtos'
import PendingTasksDataSource from '../http/dataSource/PendingTasksDataSource'

export class PendingTasksRepositoryImpl implements PendingTasksRepository {
  dataSource: PendingTasksDataSource

  constructor(_datasource: PendingTasksDataSource) {
    this.dataSource = _datasource
  }

  getPendingTasks(user_id: string): Promise<IApiPendingTasks<IPendingTasks | string>> {
    return this.dataSource.getPendingTasks(user_id)
  }
}
