import { styled } from '@mui/material/styles'

import { AvatarWrapper, BoxWrapper, TypographyWrapper } from '../../../../components'
import CardWrapper from '../../../../components/wrapper/CardWrapper'

export const CustomAvatar = styled(AvatarWrapper)(() => ({
  border: '1px solid #ffffff',
  width: '120px',
  height: '120px',
}))

export const CardInfo = styled(CardWrapper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: 'none',
  boxShadow: 'none',
  backgroundColor: '#F7F8FD',
  [theme.breakpoints.only('xs')]: {
    display: 'block',
  },
}))

export const TypographyInfo = styled(TypographyWrapper)(() => ({
  fontSize: '30px',
  fontWeight: 'bold',
}))

export const InfoBox = styled(BoxWrapper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '250px',
  marginTop: '20px',
  width: '100%',
  height: 230,
}))
