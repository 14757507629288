import { PatientRegisterRepository } from '../../domain/repository/PatientRegisterRepository';
import {
  ISaveRegisterPatient,
  IApiResponseRegisterPatient,
} from '../dtos/PatientRegister';
import PatientRegisterDataSource from '../http/dataSource/PatientRegisterDataSource';

export class PatientRegisterRepositoryImpl
  implements PatientRegisterRepository
{
  dataSource: PatientRegisterDataSource;

  constructor(_datasource: PatientRegisterDataSource) {
    this.dataSource = _datasource;
  }

  async postRegisterPatient(
    data: ISaveRegisterPatient
  ): Promise<IApiResponseRegisterPatient> {
    return this.dataSource.postRegisterPatient(data);
  }
}
