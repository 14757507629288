import { GetPendingTasks } from '../../../domain'
import { IApiPendingTasks, IPendingTasks } from '../../../infrastructure/dtos'
import PendingTasksMapper from '../../../infrastructure/http/mappers/PendingTasksMapper'
import { PendingTasksRepositoryImpl } from '../../../infrastructure/RepositoryImpl/PendingTasksRepositoryImpl'

export interface IGetPendingTasksService {
  status: number
  data: IApiPendingTasks<IPendingTasks | string> | string
}

export async function getPendingTasksService(user_id: string): Promise<IGetPendingTasksService> {
  try {
    const UseCase = new GetPendingTasks(new PendingTasksRepositoryImpl(new PendingTasksMapper()))
    return {
      status: 1,
      data: await UseCase.invoke(user_id),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
