import { AxiosError } from 'axios'

import { ResendTemporaryPhoneCodeResponse } from '../../dtos/TemporaryPhoneVerification'
import ResendPhoneVerificationDataSource from '../dataSource/ResendPhoneVerificationDataSource'
import { http } from '../Http'

export default class ResendPhoneVerificationMapper
  implements ResendPhoneVerificationDataSource
{
  async getCode(user_id: string): Promise<ResendTemporaryPhoneCodeResponse> {
    try {
      const data = await http.get<ResendTemporaryPhoneCodeResponse>(
        `send_data/resend_temporary_phone_code?user_id=${user_id}`
      )
      return data.data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }
}
