import { MouseEvent, useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import { INextAppointment, INextAppointmentResponse } from '../../../../../infrastructure/dtos/Appointments'
import { nextAppointmentsUtility } from '../../../../../services/Contracts/Utility/AppointmentsUtility'

interface INewDatesListHook {
  anchorEl: HTMLElement | null
  message?: string
  nextAppointments: INextAppointment[]
  loading: boolean
  handleClick: (event: MouseEvent<HTMLElement>, appointmentIndex: number) => void
  handleClose: (appointmentIndex: number) => void
  fetchNextAppointments: () => Promise<void>
}

export function useNewDatesList(): INewDatesListHook {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [loading, setLoading] = useState<boolean>(false)

  const [user_id] = useState<string>(JSON.parse(localStorage.getItem('userData') as string)?.userId)
  const [message, setMessage] = useState<string>('')
  const [nextAppointments, setNextAppointments] = useState<INextAppointment[]>([])

  const fetchNextAppointments = async (): Promise<void> => {
    setMessage('')
    try {
      setLoading(true)
      const { data, status } = await nextAppointmentsUtility(user_id)
      if (
        !status ||
        !(data as INextAppointmentResponse).success ||
        (data as INextAppointmentResponse).appointment.length === 0
      ) {
        setMessage((data as INextAppointmentResponse).message || 'Error al obtener los datos')
        return
      }
      setNextAppointments((data as INextAppointmentResponse).appointment)
      setLoading(false)
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener las próximas citas', 'error')
    }
  }

  useEffect(() => {
    fetchNextAppointments()
  }, [])

  const handleClick = (event: MouseEvent<HTMLElement>, appointmentIndex: number): void => {
    const newAppointments: INextAppointment[] = [...nextAppointments]
    newAppointments[appointmentIndex].showMenu = true
    setNextAppointments(newAppointments)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (appointmentIndex: number): void => {
    const newAppointments: INextAppointment[] = [...nextAppointments]
    newAppointments[appointmentIndex].showMenu = false
    setNextAppointments(newAppointments)
    setAnchorEl(null)
  }

  return {
    anchorEl,
    message,
    nextAppointments,
    loading,
    handleClick,
    handleClose,
    fetchNextAppointments,
  }
}
