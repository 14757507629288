import {
  IApiLocation,
  ICreateLocation,
  IDeletedLocation,
  IModifiedLocation,
} from '../../infrastructure/dtos/location';
import { LocationRepository } from '../repository/LocationRepository';

export interface PostCreateLocationUseCase {
  invoke: (params: ICreateLocation) => Promise<IApiLocation>;
}

export interface PostModifiedLocationUseCase {
  invoke: (params: IModifiedLocation) => Promise<IApiLocation>;
}
export interface PostDeletedLocationUseCase {
  invoke: (params: IDeletedLocation) => Promise<IApiLocation>;
}
export class PostCreateLocation implements PostCreateLocationUseCase {
  private objectUseCase: LocationRepository;

  constructor(_objectUseCase: LocationRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(params: ICreateLocation): Promise<IApiLocation> {
    return this.objectUseCase.postCreateLocation(params);
  }
}

export class PostModifiedLocation implements PostModifiedLocationUseCase {
  private objectUseCase: LocationRepository;

  constructor(_objectUseCase: LocationRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(params: IModifiedLocation): Promise<IApiLocation> {
    return this.objectUseCase.postModifiedLocation(params);
  }
}

export class PostDeletedLocation implements PostDeletedLocationUseCase {
  private objectUseCase: LocationRepository;

  constructor(_objectUseCase: LocationRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(params: IDeletedLocation): Promise<IApiLocation> {
    return this.objectUseCase.postDeletedLocation(params);
  }
}
