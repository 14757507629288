import React, { JSX } from 'react'

import { GridGeneric } from './LoadingStyle'
import CircularProgressWrapper from '../wrapper/LoaderWrapper'

export const Loading = (): JSX.Element => {
  return (
    <GridGeneric>
      <CircularProgressWrapper size="3em" />
    </GridGeneric>
  )
}
