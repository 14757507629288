import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { JSX } from 'react'

import { MedicalOffice } from '../../../../../../../infrastructure/dtos/Offices'
import Maps from '../Maps'

interface IPanelMap {
  offices: MedicalOffice[]
  selectedMarker: (officeId: string) => void
}

function PanelMap({ offices, selectedMarker }: IPanelMap): JSX.Element {
  const render = (status: Status): JSX.Element => {
    switch (status) {
      case Status.LOADING:
        return <h2>Loading</h2>
      case Status.FAILURE:
        return <h2>Error</h2>
      case Status.SUCCESS:
        return <Maps offices={offices} selectedMarker={(officeId: string) => selectedMarker(officeId)} />
    }
  }

  return (
    <>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
        libraries={['marker', 'places']}
        id="container_maps_wrapper"
        render={render}
      />
    </>
  )
}

export default PanelMap
