import { PrescriptionsRepository } from '../../domain/repository/PrescriptionsRepository'
import { IApiPrescription, IRecipePrescription } from '../dtos/Prescription'
import { PrescriptionsDataSource } from '../http/dataSource/PrescriptionsDataSourse'

export class PrescriptionsRepositoryImpl implements PrescriptionsRepository {
  dataSource: PrescriptionsDataSource

  constructor(_datasource: PrescriptionsDataSource) {
    this.dataSource = _datasource
  }

  async getAppointmentRecipes(appointmentId: string): Promise<IApiPrescription<IRecipePrescription | string>> {
    return this.dataSource.getAppointmentRecipes(appointmentId)
  }
}
