import { styled } from '@mui/material'

import ButtonWrapper from '../wrapper/ButtonWrapper'

export const OutLineDarkButton = styled(ButtonWrapper)(({ theme, size }) => {
  const customSize = size
    ? {}
    : {
        padding: '14px 24px',
        height: '43px',
        width: '95%'
      }
  return {
    margin: '0 5px',
    textTransform: 'capitalize',
    borderRadius: '50px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
    '&:hover': { color: theme.palette.primary.dark, borderColor: theme.palette.primary.dark },
    ...customSize,
    lineHeight: '13.5px',
    fontSize:13,
    [theme.breakpoints.only('xs')]: {
      fontSize: 11,
      width: 105,
      minWidth: 'inherit'
    }
  }
})
