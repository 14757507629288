import { TemporaryPhoneVerificationRepository } from '../../domain/repository/TemporaryPhoneVerificationRepository'
import { TemporaryPhoneCodeResponse } from '../dtos/TemporaryPhoneVerification'
import TemporaryPhoneVerificationDataSource from '../http/dataSource/TemporaryPhoneVerificationDataSource'

export class TemporaryPhoneVerificationRepositoryImpl
  implements TemporaryPhoneVerificationRepository
{
  dataSource: TemporaryPhoneVerificationDataSource

  constructor(_datasource: TemporaryPhoneVerificationDataSource) {
    this.dataSource = _datasource
  }

  async postCode(
    user_id: string,
    number_phone: string
  ): Promise<TemporaryPhoneCodeResponse> {
    return this.dataSource.postCode(user_id, number_phone)
  }
}
