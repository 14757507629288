import { styled } from '@mui/material'

import { AvatarWrapper } from '../wrapper'

export const AvatarProfile = styled(AvatarWrapper)((props) => ({
  width: '120px',
  height: '120px',
  [props.theme.breakpoints.only('xs')]: {
    width: '100px',
    height: '100px'
  }
}))
