import { Auth0Provider } from '@auth0/auth0-react'
import { ThemeProvider } from '@mui/material'
import dayjs from 'dayjs'
import es from 'dayjs/locale/es'
import React, { ReactNode, JSX } from 'react'
import ReactDOM from 'react-dom/client'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { BrowserRouter } from 'react-router-dom'
import './index.css'

import App from './App'
import { AuthProvider } from './contexts/AccountContext'
import { PatientAppointmentProvider } from './contexts/PatientAppointmentContext'
import { SearchOfficesProvider } from './contexts/SeachOfficesContext'
import reportWebVitals from './reportWebVitals'
import { theme } from './theme'

dayjs.locale(es)

interface IProps {
  children: ReactNode
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const PatientAppointmentState = ({ children }: IProps): JSX.Element => {
  return <PatientAppointmentProvider>{children}</PatientAppointmentProvider>
}

const domain = process.env.REACT_APP_AUTH0_DOMAIN as string
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID as string

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <PatientAppointmentState>
              <SearchOfficesProvider>
                <App />
              </SearchOfficesProvider>
            </PatientAppointmentState>
          </AuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Auth0Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
