import { styled } from '@mui/material'

import { ButtonWrapper, GridWrapper } from '../../../../components/wrapper'

export const ProfilePictureButton = styled(ButtonWrapper)(() => ({
  color: 'white',
  textTransform: 'capitalize'
}))

export const ProfilePictureGrid = styled(GridWrapper)(() => ({
  display: 'flex',
  alignItems: 'center'
}))
