import { SxProps } from '@mui/material'
import { JSX } from 'react'

import { ButtonSelectList } from '../../utils/RegisterMedicalFormStyles'
import { TypographyWrapperLabel } from '../../views/ScheduleAppointment/components/OfficesList/OfficeListStyle'
import { GridWrapper } from '../wrapper'

type AmountRangeProps = {
  amountRanges: string[]
  selectedAmountRange: string
  onAmountRangeChange: (option: string) => void
  style?: SxProps
  disabled?: boolean
  fullWidth?: boolean
  text?: string
}

const SelectButtonList = ({
  amountRanges,
  onAmountRangeChange,
  selectedAmountRange,
  style,
  disabled = false,
  fullWidth,
  text,
}: AmountRangeProps): JSX.Element => {
  const val = (item: string): string => {
    return 'b' + item.split(' ').join('')
  }

  const getStyles = (selectedAmountRange: string, item: string): SxProps => {
    const isSelected = selectedAmountRange === item
    const styles: SxProps = {
      backgroundColor: isSelected ? 'primary.main' : 'background.paper',
      color: isSelected ? 'white' : 'text.primary',
      '&.Mui-disabled': {
        backgroundColor: isSelected ? 'rgba(0, 0, 0, 0.12)' : 'white',
        color: isSelected ? 'inherit' : 'text.primary',
      },
    }
    return styles
  }

  return (
    <>
      <TypographyWrapperLabel className="label">{text}</TypographyWrapperLabel>
      <GridWrapper
        sx={style}
        alignItems="center"
        justifyContent="space-between"
        display={'flex'}
        gap={'20px'}
        data-testid="SelectButtonList"
      >
        {amountRanges.map((item: string, index: number) => {
          return (
            <ButtonSelectList
              id={val(item)}
              key={index}
              sx={getStyles(selectedAmountRange, item)}
              variant="contained"
              role="button"
              value={selectedAmountRange}
              onClick={() => onAmountRangeChange(item)}
              disabled={disabled}
              fullWidth={fullWidth}
            >
              {item}
            </ButtonSelectList>
          )
        })}
      </GridWrapper>
    </>
  )
}

export default SelectButtonList
