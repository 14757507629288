import React, { JSX } from 'react'

import { DialogTitleStyle } from './AdvertStyle'
import {
  ButtonWrapper,
  DialogActionsWrapper,
  DialogContentTextWrapper,
  DialogContentWrapper,
  DialogWrapper,
} from '../../wrapper'

interface IAdvertProps {
  open: boolean
  title: string
  onClose: () => void
  message: string
}

export const Advert = ({ title, open, onClose, message }: IAdvertProps): JSX.Element => {
  return (
    <DialogWrapper open={open} onClose={onClose} aria-labelledby="draggable-dialog-title">
      <DialogTitleStyle id="draggable-dialog-title">{title}</DialogTitleStyle>
      <DialogContentWrapper>
        <DialogContentTextWrapper>{message}</DialogContentTextWrapper>
      </DialogContentWrapper>
      <DialogActionsWrapper>
        <ButtonWrapper autoFocus onClick={onClose}>
          Cerrar
        </ButtonWrapper>
      </DialogActionsWrapper>
    </DialogWrapper>
  )
}
