import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IPhoneVerificationResponse,
  IResendCodeParams,
  IUpdatePatientPhoneParams,
  IVerifyCodeParams,
} from '../../../infrastructure/dtos/PhoneVerification'
import {
  ICodeService,
  resendCodeService,
  sendCodeService,
  verifyPhoneValidationService,
  verifyCodeService,
  updatePatientPhoneService,
} from '../Persistencia/PhoneVerificationService'

export interface ICodeUtility<T> {
  status: number
  data: IPhoneVerificationResponse<T>
}

export async function resendCodeUtility(params: IResendCodeParams): Promise<ICodeUtility<IPhoneVerification | string>> {
  try {
    const { data, status }: ICodeService<IPhoneVerification> = await resendCodeService(params)
    return {
      data: (data as IApiResponsePhoneVerification<IPhoneVerification>).body,
      status:
        (data as IApiResponsePhoneVerification<IPhoneVerification>).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as ICodeService<IPhoneVerification>).data as IApiResponsePhoneVerification<IPhoneVerification>)
        .body,
    }
  }
}

export async function sendCodeUtility(userId: string): Promise<ICodeUtility<IPhoneVerification>> {
  try {
    const { data, status }: ICodeService<IPhoneVerification> = await sendCodeService(userId)
    return {
      data: (data as IApiResponsePhoneVerification<IPhoneVerification>).body,
      status:
        (data as IApiResponsePhoneVerification<IPhoneVerification>).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as ICodeService<IPhoneVerification>).data as IApiResponsePhoneVerification<IPhoneVerification>)
        .body,
    }
  }
}

export async function verifyPhoneValidationUtility(userId: string): Promise<ICodeUtility<IPhoneVerification>> {
  try {
    const { data, status }: ICodeService<IPhoneVerification> = await verifyPhoneValidationService(userId)
    return {
      data: (data as IApiResponsePhoneVerification<IPhoneVerification>).body,
      status:
        (data as IApiResponsePhoneVerification<IPhoneVerification>).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as ICodeService<IPhoneVerification>).data as IApiResponsePhoneVerification<IPhoneVerification>)
        .body,
    }
  }
}

export async function VerifyCodeUtility(params: IVerifyCodeParams): Promise<ICodeUtility<IPhoneVerification>> {
  try {
    const { data, status }: ICodeService<IPhoneVerification> = await verifyCodeService(params)
    return {
      data: (data as IApiResponsePhoneVerification<IPhoneVerification>).body,
      status:
        (data as IApiResponsePhoneVerification<IPhoneVerification>).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as ICodeService<IPhoneVerification>).data as IApiResponsePhoneVerification<IPhoneVerification>)
        .body,
    }
  }
}

export async function updatePatientPhoneUtility(params: IUpdatePatientPhoneParams): Promise<ICodeUtility<string>> {
  try {
    const { data, status }: ICodeService<string> = await updatePatientPhoneService(params)
    return {
      data: (data as IApiResponsePhoneVerification<string>).body,
      status: (data as IApiResponsePhoneVerification<string>).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: ((error as ICodeService<string>).data as IApiResponsePhoneVerification<string>).body,
    }
  }
}
