import { styled } from '@mui/material'

import TypographyWrapper from '../wrapper/TypographyWrapper'

export const LabelInput = styled(TypographyWrapper)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.6875rem',
  marginLeft: '2rem'
}))
