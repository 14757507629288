import { useState } from 'react'

interface Confirm {
  openConfirm: boolean
  handleCloseConfirm: () => void
  handleOpenConfirm: () => void
}

export function useConfirm(): Confirm {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)

  const handleOpenConfirm = (): void => {
    setOpenConfirm(true)
  }

  const handleCloseConfirm = (): void => setOpenConfirm(false)

  return {
    handleOpenConfirm,
    handleCloseConfirm,
    openConfirm,
  }
}
