import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ReactNode, useEffect, JSX, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { DateRangePicker, CustomProvider } from 'rsuite'
import './rsuite.css'
import esES from 'rsuite/locales/es_ES'

import { DocumentsModal } from './components/documents/Documents'
import { useDocuments } from './components/documents/useDocuments'
import { LoadingRecipesListModal } from './components/LoadingRecipes/LoadingRecipe'
import { OrderModal } from './components/order/Order'
import useOrder from './components/order/useOrder'
import Recipe from './components/Recipe/Recipe'
import { ShowRecipesListModal } from './components/ShowRecipeList/ShowRecipesList'
import { useShowRecipesListModal } from './components/ShowRecipeList/useShowRecipesListModal'
import { usePastAppointmentsList } from './hooks/PastAppointmentsListHook'
import { DateRangeInput, PrimaryDarkButton } from './PastAppointmentsListStyle'
import {
  GridWrapper,
  IconButtonWrapper,
  ListItemWrapper,
  ListWrapper,
  MenuItemWrapper,
  MenuWrapper,
  TypographyWrapper,
} from '../../../../../components'
import { InputField } from '../../../../../components/StyledComponents/InputField'
import { OutLineButton } from '../../../../../components/StyledComponents/OutLineButton'
import { Title } from '../../../../../components/StyledComponents/Title'
import SubmitButton from '../../../../../components/SubmitButton'
import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import { IPastAppointment } from '../../../../../infrastructure/dtos/Appointments'
import { IRecipePrescription } from '../../../../../infrastructure/dtos/Prescription'
import QualifyDoctorModal from '../../../../Home/Pendings/components/QualifyDoctorModal'
import { AvatarNextDate } from '../NextDatesList/styles'

interface IGridBody {
  value: string | ReactNode
  width: string
}

interface IGridHeader {
  title: string
  width: string
}

const options: string[] = ['Pedido', 'Factura', 'Reembolso']
enum optionsList {
  Pedido = 'Pedido',
  Factura = 'Factura',
  Reembolso = 'Reembolso',
}

export function PastAppointmentsList(): JSX.Element {
  const { patient } = useContext(AccountContext) as AuthContextType
  const navigate = useNavigate()
  const {
    anchorEl,
    message,
    pastAppointments,
    handleClick,
    handleClose,
    fetchPastAppointments,
    openModal,
    pastId,
    handleCloseModal,
    handleOpenModal,
    range,
    setRange,
    searchAppointment,
    setSearchAppointment
  } = usePastAppointmentsList()

  const {
    handleOpenRecipesListModal,
    openRecipesListModal,
    openLoadingRecipesModal,
    handleOpenRecipe,
    openRecipe,
    appointmentDetails,
    prescriptionIndex,
    fetchAppointmentRecipes,
    handleOpenLoadingRecipesModal,
    handleSetPrescriptionIndex,
  } = useShowRecipesListModal()

  const{
    appointmentOrderDetails,
    openOrderModal,
    handleOpenOrderModal,
    setAppointmentOrderDetails
  } = useOrder()

  const{
    handleOpenDocumentsModal,
    handleSendDocuments,
    openDocumentsModal,
  } = useDocuments()

  const chooseRecipeModal = async (item: IPastAppointment): Promise<void> => {
    handleOpenLoadingRecipesModal(true)
    await fetchAppointmentRecipes(item.appointment_id)
  }

  const handleSelectOptions = async (option: string, item: IPastAppointment, itemIndex: number): Promise<void> => {
    handleClose(itemIndex)
    switch(option) {
      case optionsList.Pedido:
        setAppointmentOrderDetails({
          appointment_date: item.appointment_date,
          diagnostics: item.patient_diagnostic,
          doctor: {
            code_msp: '12355125',
            doctor_phone: item.phone,
            email_doctor: item.email,
            last_name_doctor: item.last_name_doctor,
            name_doctor: item.name_doctor,
            speciality_doctor: item.speciality_doctor,
          },
          remarks: 'MUSLO REGION MEDIAL Y REGION INGUINAL DERECHO',
          office: {
            name_medical_office: item.name_medical_office,
            office_phone: item.phone,
            reference_medical_office: item.reference_medical_office,
          },
          orders: [{
            description: 'ECOGRAFIA OSTEO-MUSCULAR 2 ZONAS',
            quantity: 1,
          }, {
            description: 'PELVIS 1 POSC',
            quantity: 1,
          }],
          patient: {
            last_name: patient.lastName,
            name: patient.name,
            age: '41 años',
          }
        })
        handleOpenOrderModal(true)
        break
      
      case optionsList.Reembolso:
        handleOpenDocumentsModal(true)
        break
    }
  }

  const onSetPrescriptionIndex = (index: number): void => {
    handleSetPrescriptionIndex(index)
    handleOpenRecipesListModal(false)
    handleOpenRecipe(true)
  }

  useEffect(() => {
    handleOpenLoadingRecipesModal(false)
    if (appointmentDetails) {
      if (appointmentDetails?.prescription?.length === 1) {
        handleSetPrescriptionIndex(0)
        handleOpenRecipe(true)
      } else {
        handleOpenRecipesListModal(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetails])

  const gridHeader: IGridHeader[] = [
    { title: '', width: '4%' },
    { title: 'Fecha', width: '14%' },
    { title: 'Hora', width: '11%' },
    { title: 'Doctor', width: '14%' },
    { title: 'Lugar', width: '12%' },
    { title: 'Especialidad', width: '14%' },
    { title: '', width: '38%' },
  ]

  const gridBody = (item: IPastAppointment, itemIndex: number): IGridBody[] => [
    {
      value: item.appointment_date.split('-').reverse().join('/'),
      width: '12%',
    },
    { value: item.hour_from.split(':').join('h'), width: '11%' },
    { value: `${item.name_doctor} ${item.last_name_doctor}`, width: '13%' },
    { value: item.reference_medical_office, width: '12%' },
    { value: item.speciality_doctor, width: '14%' },
    {
      value: (
        <PrimaryDarkButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={() => {
            navigate(`/appointment/booking/${item.user_id}`)
          }}
        >
          Agendar
        </PrimaryDarkButton>
      ),
      width: '10%',
    },
    {
      value: (
        <OutLineButton
          variant="outlined"
          disabled={item.assistance === 'NOT_ASSISTED' || item.prescription === false}
          onClick={() => chooseRecipeModal(item)}
          sx={{ lineHeight: '13.5px' }}
        >
          Ver receta
        </OutLineButton>
      ),
      width: '10%',
    },
    {
      value: (
        <OutLineButton
          variant="outlined"
          disabled={
            item.assistance === 'NOT_ASSISTED' || item.qualification_status === true || item.assistance === 'PENDING'
          }
          onClick={() => handleOpenModal(item)}
        >
          Calificar
        </OutLineButton>
      ),
      width: '10%',
    },
    {
      value: (
        <GridWrapper>
          <IconButtonWrapper
            aria-label="more"
            id="long-button"
            data-testid="long-button"
            aria-controls={item.showMenu ? 'long-menu' : undefined}
            aria-expanded={item.showMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleClick(event, itemIndex)}
            disabled={item.assistance === 'NOT_ASSISTED'}
          >
            <MoreHorizIcon
              fontSize="large"
              sx={{
                color: item.assistance === 'NOT_ASSISTED' ? 'lightgray' : 'primary.dark',
              }}
            />
          </IconButtonWrapper>
          <MenuWrapper
            id="long-menu"
            data-testid="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={Boolean(item?.showMenu)}
            onClose={() => handleClose(itemIndex)}
            sx={{ borderRadius: '20%' }}
            slotProps={{
              paper: {
                style: {
                  borderRadius: '32px',
                  padding: '7px 7px',
                  boxShadow: '1px 4px 8px 6px rgba(0, 0, 0, 0.03)',
                  backgroundColor: 'white',
                },
              },
            }}
          >
            {options.map((option: string, index: number) => (
              <MenuItemWrapper key={`${option}-${index}`} onClick={() => handleSelectOptions(option, item, itemIndex)}>
                {option}
              </MenuItemWrapper>
            ))}
          </MenuWrapper>
        </GridWrapper>
      ),
      width: '5%',
    },
  ]

  return (
    <GridWrapper pt={2}>
      <GridWrapper display={'flex'}>
        <Title sx={{ marginBottom: 0 }} data-testid="title-past-appointments">
          Citas pasadas
        </Title>
        <GridWrapper display={'flex'} sx={{ height: '20px', marginLeft: 'auto' }}>
          <GridWrapper marginRight={'35px'}>
            <InputField
              placeholder="Nombre o especialidad"
              id="email"
              type="text"
              fullWidth
              value={searchAppointment}
              onChange={(e) => setSearchAppointment(e.target.value)}
              name="email"
              sx={{
                '& .MuiInputBase-root': {
                  height: '3rem',
                  boxShadow: 'none',
                }
              }}
            />
          </GridWrapper>
          <DateRangeInput role="textbox">
            <CustomProvider locale={esES}>
              <DateRangePicker
                id={'filter-range'}
                value={range}
                placeholder="dd/mm - dd/mm"
                format="dd/MM/yyyy"
                appearance="subtle"
                onChange={(value: [Date, Date] | null) => setRange(value)}
                style={{
                  width: '100%',
                  borderRadius: '42px',
                }}
                aria-label="dd/mm - dd/mm"
                data-testid="date-range-picker"
              />
            </CustomProvider>
          </DateRangeInput>
          <GridWrapper sx={{ width: '100px' }}>
            <SubmitButton
              fullWidth
              id="suggested-doctor-button"
              text="Buscar"
              type="button"
              variant="contained"
              sx={{ height: '3rem' }}
            />
          </GridWrapper>
        </GridWrapper>
      </GridWrapper>

      {!Boolean(message) ? (
        <ListWrapper>
          <ListItemWrapper
            style={{
              borderRadius: '40px',
              margin: '10px 0 0 0',
              paddingBottom: '0px',
              fontSize: '14px',
            }}
            data-testid="past-dates"
          >
            {gridHeader.map((item: IGridHeader, index: number) => (
              <GridWrapper item textAlign={'center'} width={item.width} key={`GridHeader-${index}`}>
                {item.title}
              </GridWrapper>
            ))}
          </ListItemWrapper>
          {pastAppointments.map((appointment: IPastAppointment, index: number) => (
            <ListItemWrapper
              key={`pastAppointment-${appointment.appointment_id}-${index}`}
              style={{
                minHeight: '80px',
                background: 'white',
                borderRadius: '32px',
                margin: '0 0 10px 0',
              }}
              data-testid="past-dates-item-list"
            >
              {/* <AvatarNextDate src={appointment.photo_doctor} />*/}
              <AvatarNextDate src={appointment.photo_doctor} />
              {gridBody(appointment, index).map((i: IGridBody, idx: number) => (
                <GridWrapper
                  key={`GridBody-${appointment.appointment_id}-${idx}`}
                  item
                  textAlign={'center'}
                  width={i.width}
                  paddingX={0.5}
                >
                  {idx >= 5 ? (
                    i.value
                  ) : (
                    <TypographyWrapper color={appointment.assistance === 'NOT_ASSISTED' ? 'lightgray' : 'inherit'}>
                      {i.value}
                    </TypographyWrapper>
                  )}
                </GridWrapper>
              ))}
            </ListItemWrapper>
          ))}
        </ListWrapper>
      ) : (
        <TypographyWrapper marginTop={4} marginLeft={2}>
          {message}
        </TypographyWrapper>
      )}

      {openLoadingRecipesModal && (
        <LoadingRecipesListModal
          handleOpenLoadingRecipesModal={handleOpenLoadingRecipesModal}
          open={openLoadingRecipesModal}
        />
      )}

      {openRecipesListModal && (
        <ShowRecipesListModal
          appointmentDetails={appointmentDetails}
          handleOpenRecipesListModal={handleOpenRecipesListModal}
          open={openRecipesListModal}
          handleSetPrescriptionIndex={onSetPrescriptionIndex}
        />
      )}
      {openRecipe && (
        <Recipe
          appointmentDetails={appointmentDetails as IRecipePrescription}
          handleOpenRecipe={handleOpenRecipe}
          open={openRecipe}
          prescriptionIndex={prescriptionIndex as number}
        />
      )}
      {openModal && (
        <QualifyDoctorModal
          handleClose={handleCloseModal}
          open={openModal}
          pending={pastId}
          fetchPendingsTasks={fetchPastAppointments}
        />
      )}

      {openOrderModal && (
        <OrderModal
          handleOpenOrderModal={handleOpenOrderModal}
          open={openOrderModal}
          appointmentDetails={appointmentOrderDetails}
        />
      )}
      {openDocumentsModal && (
        <DocumentsModal
          open={openDocumentsModal}
          handleOpenDocumentsModal={handleOpenDocumentsModal}
          handleSendDocuments={handleSendDocuments}
        />
      )}
    </GridWrapper>
  )
}
