import { IResponseApi } from '../../infrastructure/dtos';
import { IPatientActivitiesInfo } from '../../infrastructure/dtos/PatientActivitiesInfo';
import { PatientActivitiesInfoRepository } from '../repository/PatientActivitiesInfoRepository';

export interface PatientActivitiesUseCase {
  invoke: (user_id: string) => Promise<IResponseApi<IPatientActivitiesInfo>>;
}

export class GetPatientActivities implements PatientActivitiesUseCase {
  private objectUseCase: PatientActivitiesInfoRepository;

  constructor(_objectUseCase: PatientActivitiesInfoRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(user_id: string): Promise<IResponseApi<IPatientActivitiesInfo>> {
    return this.objectUseCase.getPatientActivitiesInfo(user_id);
  }
}
