import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import React, { JSX } from 'react'

import { ButtonBack } from '../StyledComponents/ButtonBack'
import { LinkTab } from '../StyledComponents/LinkTab'
import { StackWrapper } from '../wrapper'

interface IComponentProps {
  id: string
  text: string
  onClick?: () => void
}
export const BackButton = ({ text, id, onClick }: IComponentProps): JSX.Element => {
  return (
    <StackWrapper
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="left"
      sx={{
        width: '100%',
        padding: 1,
        textAlign: 'left',
        marginTop: '5%',
      }}
    >
      <ButtonBack onClick={onClick} role="button">
        <ChevronLeftIcon />
      </ButtonBack>
      <LinkTab onClick={onClick} color="inherit" id={id} data-testid={id} role="link">
        {text}
      </LinkTab>
    </StackWrapper>
  )
}
