import {
  DataPatient,
  IServicePatientByCiInfo,
  IServicePatientInfo,
  IUtilityPatientByCiInfo,
  IUtilityPatientInfo,
  PatientBody,
  PatientUpdateResponse,
  PatientUpdateResponseBody,
} from '../../../infrastructure/dtos/Patient'
import {
  GetPatientInfoByCiService,
  GetPatientInfoService,
  IUpdatePatientInfoService,
  updatePatientInfoService,
} from '../Persistencia/PatientService'

export interface IUpdatePatientInfoUtility {
  status: number
  data: string
}

export async function GetPatientRegisterUtility(data: string): Promise<IUtilityPatientInfo> {
  const dataReturnError: IUtilityPatientInfo = {
    data: 'Ups! Tengo inconvenientes en este momento, por favor intenta más tarde.',
    status: 0,
  }
  try {
    const valueData: IServicePatientInfo = await GetPatientInfoService(data)
    if (typeof valueData.data !== 'string' && valueData.data.statusCode === 200 && valueData.status === 1) {
      return {
        data: valueData.data.body,
        status: valueData.status,
      }
    }
    return dataReturnError
  } catch (error) {
    return dataReturnError
  }
}

export async function updatePatientInfoUtility(
  params: PatientBody,
  idPatient: string,
): Promise<IUpdatePatientInfoUtility> {
  try {
    const { data, status }: IUpdatePatientInfoService = await updatePatientInfoService(params, idPatient)
    return {
      data: ((data as PatientUpdateResponseBody).body as PatientUpdateResponse).message || (data as string),
      status: (data as PatientUpdateResponseBody).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function GetPatientRegisterByCiUtility(ciPatient: string): Promise<IUtilityPatientByCiInfo> {
  try {
    const { data, status }: IServicePatientByCiInfo = await GetPatientInfoByCiService(ciPatient)
    return {
      data: (data as DataPatient).body,
      status: (data as DataPatient).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
