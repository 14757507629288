import { useState } from 'react'

import { IDiagnostic } from '../../../../../../../infrastructure/dtos/Prescription'

interface IOrderDescription {
  description: string
  quantity: number
}

export interface ICustomOrder {
  diagnostics: IDiagnostic[]
  orders: IOrderDescription[]
  appointment_date: string
  remarks: string
  doctor: {
    last_name_doctor: string
    name_doctor: string
    speciality_doctor: string
    code_msp: string
    doctor_phone: string
    email_doctor: string
  },
  patient: {
    name: string,
    last_name: string
    age?: string
  },
  office: {
    name_medical_office: string
    office_phone: string
    reference_medical_office: string
  },
}

interface IUseOrder {
  openOrderModal: boolean
  appointmentOrderDetails?: ICustomOrder
  handleOpenOrderModal: (value: boolean) => void
  setAppointmentOrderDetails: React.Dispatch<React.SetStateAction<ICustomOrder | undefined>>
}

const useOrder = (): IUseOrder => {
  const [openOrderModal, setOpenOrderModal] = useState<boolean>(false)
  const [appointmentOrderDetails, setAppointmentOrderDetails] = useState<ICustomOrder>()

  const handleOpenOrderModal = (value: boolean): void => setOpenOrderModal(value)

  return {
    appointmentOrderDetails,
    openOrderModal,
    setAppointmentOrderDetails,
    handleOpenOrderModal,
  }
}

export default useOrder
