import { AxiosError } from 'axios'

import {
  ICreateLocation,
  IApiLocation,
  IModifiedLocation,
  IDeletedLocation
} from '../../dtos/location'
import LocationDataSource from '../dataSource/LocationDataSource'
import { http } from '../Http'

export default class LocationMapper implements LocationDataSource {
  async postCreateLocation(params: ICreateLocation): Promise<IApiLocation> {
    try {
      const { data } = await http.post<IApiLocation>(
        `/save_data/patient/new_location`,
        { body: params }
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async postModifiedLocation(params: IModifiedLocation): Promise<IApiLocation> {
    try {
      const { data } = await http.put<IApiLocation>(
        `/modified_data/patient/location`,
        { body: params }
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }

  async postDeletedLocation(params: IDeletedLocation): Promise<IApiLocation> {
    try {
      const { data } = await http.put<IApiLocation>(
        `/deleted_data/patient/location`,
        { body: params }
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': ''
        }
      }
    }
  }
}
