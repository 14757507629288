import { useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../contexts/AccountContext'
import { IApiResponseDoctorInfo, IDoctorInfo } from '../../../infrastructure/dtos/DoctorInfo'
import { DoctorInfoService } from '../../../services/Contracts/Persistencia/DoctorInfoService'

interface IuseHook {
  dataDoctor: IDoctorInfo
}

export function useDataDoctor(idDoctor: string): IuseHook {
  const { handleAlert } = useContext(
    AccountContext
  ) as AuthContextType
  const [dataDoctor, setDataDoctor] = useState<IDoctorInfo>({
    description_doctor: '',
    name_doctor: '',
    user_id: '',
    years_experience_doctor: '',
    speciality_doctor: '',
    university_doctor: '',
    photo_doctor: '',
    last_name_doctor: '',
    email_doctor: '',
    medical_skills: [],
  })

  useEffect(() => {
    fetchDoctorInfo()
  }, [])

  const fetchDoctorInfo = async (): Promise<void> => {
    try {
      const { data } = await DoctorInfoService(idDoctor)
      if ((data as IApiResponseDoctorInfo).body) {
        setDataDoctor((data as IApiResponseDoctorInfo).body as IDoctorInfo)
      }
    } catch (error) {
      handleAlert(true, 'Error al obtener la información del médico', 'error')
    }
  }

  return {
    dataDoctor,
  }
}
