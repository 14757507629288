import { styled } from '@mui/material'

import { AvatarWrapper, BoxWrapper, ButtonWrapper } from '../../../../../components/wrapper'

export const AvatarPastAppointment = styled(AvatarWrapper)(() => ({
  width: '50px',
  height: '50px',
}))

export const PrimaryDarkButton = styled(ButtonWrapper)(({ theme }) => ({
  borderRadius: '2.625rem',
  backgroundColor: theme.palette.primary.dark,
  fontSize: '14px',
  height: '43px',
  textTransform: 'none',
}))

export const DateRangeInput = styled(BoxWrapper)((props) => ({
  borderRadius: '24px',
  height: '50px',
  backgroundColor: props.theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '180px', // Valor por defecto
  marginRight: '35px',
  '@media (min-width: 1601px)': {
    width: '240px',
  },

  '& fieldset': {
    border: 'none',
  },
  '& svg': {
    color: props.theme?.palette?.tertiary?.main,
    fontSize: '12px',
  },
  '& .rs-picker-toggle-value, & input::placeholder , & input': {
    fontSize: '11px',
    color: props.theme?.palette?.tertiary?.main,
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
  },
  '& .rs-picker-toggle-placeholder ': {
    fontSize: '11px',
    color: props.theme?.palette.text?.primary,
    '@media (min-width: 1601px)': {
      fontSize: '16px',
    },
  },

  '& .rs-picker-toggle-value': {
    color: `${props.theme?.palette?.tertiary?.main} !important`,
  },
}))
