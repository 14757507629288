import { IPatientInfo } from '../views/Register/types';

export type Severity = 'error' | 'warning' | 'info' | 'success';
export interface AuthState {
  errorMessage: string;
  patient: IPatientInfo;
  snack: {
    open: boolean;
    message: string;
    severity?: Severity;
  };
}

type AuthAction =
  | { type: 'addError'; payload: string }
  | { type: 'removeError' }
  | { type: 'patientData'; payload: { patient: IPatientInfo } }
  | {
      type: 'snack';
      payload: { open: boolean; message: string; severity?: Severity };
    };

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case 'addError':
      return {
        ...state,
        errorMessage: action.payload,
      };

    case 'removeError':
      return {
        ...state,
        errorMessage: '',
      };

    case 'patientData':
      return {
        ...state,
        patient: action.payload.patient,
      };

    case 'snack':
      return {
        ...state,
        snack: {
          message: action.payload.message,
          open: action.payload.open,
          severity: action.payload.severity,
        },
      };

    default:
      return state;
  }
};
