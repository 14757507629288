import {
  INextAppointmentHour,
  IParamsGetNextAppointmentHour,
  IResponseCalendar,
} from '../../../infrastructure/dtos/Calendar'
import { getNextAppointmentHour } from '../Persistencia/Calendar'

export interface IGetNextAppointmentHourUtility {
  status: number
  data: string | INextAppointmentHour
}

export async function getNextAppointmentHourUtility(
  params: IParamsGetNextAppointmentHour,
): Promise<IGetNextAppointmentHourUtility> {
  try {
    const { body, status }: IResponseCalendar<INextAppointmentHour> = await getNextAppointmentHour(params)
    return {
      data: body,
      status,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as IResponseCalendar<INextAppointmentHour>).body,
    }
  }
}
