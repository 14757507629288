import { styled } from '@mui/material'

import { TypographyWrapper } from '../wrapper'

export const Title = styled(TypographyWrapper)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '18px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginTop: '24px',
  marginBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '15px'
  }
}))
