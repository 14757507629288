import { useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'
import { News } from '../../../../infrastructure/dtos/News'
import { getNews } from '../../../../services/Contracts/Utility/NewsUtility'

interface IUseNews {
  news: News[]
  loadingNews: boolean
}

export const useNews = (): IUseNews => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [news, setNews] = useState<News[]>([])
  const [loadingNews, setLoadingNews] = useState<boolean>(false)

  const fetchNews = async (): Promise<void> => {
    try {
      setLoadingNews(true)
      const { data } = await getNews()
      setNews(data as News[])
      setLoadingNews(false)
    } catch (error) {
      handleAlert(true, 'Error al obtener las novedades', 'error')
    }
  }

  useEffect(() => {
    fetchNews()
  }, [])

  return {
    news,
    loadingNews,
  }
}
