import dayjs from 'dayjs'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../contexts/AccountContext'
import { Severity } from '../../contexts/AuthReducer'
import { BodyGenders } from '../../infrastructure/dtos/Genders'
import { BodyPatientInfo, IUtilityPatientInfo } from '../../infrastructure/dtos/Patient'
import { GendersUtility } from '../../services/Contracts/Utility/GendersUtility'
import { GetPatientRegisterUtility, updatePatientInfoUtility } from '../../services/Contracts/Utility/PatientUtility'
import { IPatientInfo } from '../Register/types'

interface IPatient {
  name: string
  lastName: string
  gender: string
  idNumber: string
  email: string
  phone: string
  photo: string
  birthdate: dayjs.Dayjs | null
}

interface IUseProfile {
  patient: IPatientInfo
  patientInfo: IPatient
  list: string[]
  disabled: boolean
  setPatientInfo: React.Dispatch<React.SetStateAction<IPatient>>
  handleSubmit: () => Promise<void>
  handleChangePhoto: (value: string) => void
  savePatientData: (patient: IPatientInfo) => void
  handleAlert: (open: boolean, message: string, severity?: Severity | undefined) => void
  handleImageUpload: (event: ChangeEvent<HTMLInputElement>, setStringValue: (value: string) => void) => void
}

export const useProfile = (): IUseProfile => {
  const { patient, handleAlert, savePatientData } = useContext(AccountContext) as AuthContextType
  const [patientInfo, setPatientInfo] = useState<IPatient>({
    name: '',
    lastName: '',
    gender: '',
    idNumber: '',
    email: '',
    phone: '',
    photo: '',
    birthdate: null,
  })
  const [list, setList] = useState<string[]>([])
  const [disabled, setDisabled] = useState<boolean>(false)

  useEffect(() => {
    setPatientInfo({
      name: patient?.name,
      lastName: patient?.lastName,
      gender: patient?.gender,
      idNumber: patient?.idNumber,
      email: patient?.email as string,
      phone: patient?.mobile,
      photo: patient?.photo_patient || '',
      birthdate: dayjs(patient?.birthdate || ''),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient])

  useEffect(() => {
    setDisabled(
      !(
        patientInfo?.name.length !== 0 &&
        patientInfo?.lastName.length !== 0 &&
        patientInfo?.gender.length !== 0 &&
        patientInfo?.idNumber.length !== 0 &&
        patientInfo?.email.length !== 0 &&
        patientInfo?.birthdate &&
        patientInfo?.birthdate.isValid() &&
        (patientInfo?.name !== patient?.name ||
          patientInfo?.lastName !== patient?.lastName ||
          patientInfo?.gender !== patient?.gender ||
          patientInfo?.idNumber !== patient?.idNumber ||
          patientInfo?.email !== patient?.email ||
          patientInfo?.birthdate?.format('YYYY-MM-DD') !== patient?.birthdate)
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo])

  useEffect(() => {
    fetchGenders()
  }, [])

  const handleSubmit = async (): Promise<void> => {
    const { data, status } = await updatePatientInfoUtility(
      {
        date_birth: patientInfo?.birthdate?.format('YYYY-MM-DD'),
        gender: patientInfo?.gender,
        photo_patient: patientInfo?.photo !== patient?.photo_patient ? patientInfo?.photo : '',
      },
      patient?.userId as string,
    )
    if (status) {
      const userBody: IUtilityPatientInfo = await GetPatientRegisterUtility(patient?.userId as string)

      savePatientData({
        userId: (userBody?.data as BodyPatientInfo).user_id,
        name: (userBody?.data as BodyPatientInfo).name_patient,
        lastName: (userBody?.data as BodyPatientInfo).last_name_patient,
        idNumber: (userBody?.data as BodyPatientInfo).identification_patient,
        mobile: (userBody?.data as BodyPatientInfo).number_phone_patient,
        email: (userBody?.data as BodyPatientInfo).email_patient,
        birthdate: (userBody?.data as BodyPatientInfo).date_birth,
        document_type: (userBody?.data as BodyPatientInfo).document_type,
        gender: (userBody?.data as BodyPatientInfo).gender,
        addresses:
          (userBody?.data as BodyPatientInfo)?.locations_patient &&
          (userBody?.data as BodyPatientInfo)?.locations_patient?.map((address) => ({
            id_location: address.id_location,
            address: address.address_locations_patient,
            name: address.name_locations_patient,
            coordinates: address.coordinates_medical_office,
          })),
        photo_patient: (userBody?.data as BodyPatientInfo)?.photo_patient,
        age: (userBody?.data as BodyPatientInfo)?.age,
      })
    }
    handleAlert(true, data, status ? 'success' : 'error')
  }

  const handleChangePhoto = (value: string): void => {
    setPatientInfo({
      ...patientInfo,
      photo: value,
    })
  }

  const fetchGenders = async (): Promise<void> => {
    try {
      const { data } = await GendersUtility()
      if ((data as BodyGenders).data) {
        setList((data as BodyGenders).data as string[])
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener listado de géneros', 'error')
    }
  }

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>, setStringValue: (value: string) => void): void => {
    try {
      const file = event.target.files as FileList
      if (file.length) {
        const maxSize = 1024 * 1024 * 2
        if (file[0].size > maxSize) {
          handleAlert(true, 'La imagen no puede pesar más de 2MB', 'error')
          return
        }

        const reader = new FileReader()
        reader.onload = function (e: ProgressEvent<FileReader>) {
          const content = e.target?.result as string
          setStringValue(content)
          handleSubmitPhoto(content)
        }
        reader.readAsDataURL(file?.[0])
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al subir la imagen', 'error')
    }
  }

  const handleSubmitPhoto = async (photo: string): Promise<void> => {
    const { data, status } = await updatePatientInfoUtility({ photo_patient: photo }, patient?.userId as string)
    if (status) {
      const userBody: IUtilityPatientInfo = await GetPatientRegisterUtility(patient?.userId as string)

      savePatientData({
        ...patient,
        photo_patient: (userBody?.data as BodyPatientInfo)?.photo_patient,
      })
    }
    handleAlert(true, data, status ? 'success' : 'error')
  }

  return {
    patient,
    patientInfo,
    list,
    disabled,
    setPatientInfo,
    handleSubmit,
    handleChangePhoto,
    savePatientData,
    handleAlert,
    handleImageUpload,
  }
}
