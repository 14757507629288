import { AxiosError, AxiosResponse } from 'axios'

import { ISaveRegisterPatient, IApiResponseRegisterPatient } from '../../dtos/PatientRegister'
import PatientRegisterDataSource from '../dataSource/PatientRegisterDataSource'
import { http } from '../Http'

export default class PatientRegisterMapper implements PatientRegisterDataSource {
  async postRegisterPatient(data: ISaveRegisterPatient): Promise<IApiResponseRegisterPatient> {
    const result = await http
      .post(`save_data/register_patient`, { body: data })
      .then((response: AxiosResponse) => {
        const { data: resultResponse } = response
        return resultResponse
      })
      .catch((error: AxiosError) => {
        const resultResponse = {
          statusCode: error.response?.status,
          body: error.response?.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
          },
        }
        return resultResponse
      })
    return result
  }
}
