import { styled } from '@mui/material'

import CardWrapper from '../wrapper/CardWrapper'

export const CardInfo = styled(CardWrapper)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.common.white,
  flexDirection: 'row',
  alignItems: 'center',
  padding: '20px 10px',
  borderRadius: '20px',
  width: '100%',
  boxShadow: '0px 1.6px 5.6px 3.2px #00000008',
  minHeight: '150px',
  cursor: 'pointer',
}))
