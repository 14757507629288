import { useMediaQuery, useTheme } from '@mui/material'
import { JSX } from 'react'

import { LabelText } from './styles'
import { GridWrapper } from '../../../../components'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import { IDoctorInfo } from '../../../../infrastructure/dtos/DoctorInfo'
import PhotoDoctor from '../PhotoDoctor'

interface DoctorInfoProps {
  info: IDoctorInfo
}

export default function DataDoctor({ info }: DoctorInfoProps): JSX.Element {
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <GridWrapper sx={{ marginTop: '1.5rem' }}>
      <GridWrapper container justifyContent="center" alignItems="center">
        <GridWrapper item width={'80%'}>
          <LabelInput
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Conoce más sobre el Dr. {info.name_doctor} {info.last_name_doctor}
          </LabelInput>
        </GridWrapper>
      </GridWrapper>
      <GridWrapper container justifyContent="center" alignItems="center">
        <GridWrapper item sm={4} sx={{ height: '100%', padding: '1.5rem', marginTop: '1rem' }}>
          <PhotoDoctor photo={info.photo_doctor} rating="5" />
        </GridWrapper>
        <GridWrapper item sm={8} sx={{ height: '100%', padding: '1rem' }}>
          <LabelText
            sx={{
              fontWeight: 'bold',
              textAlign: isExtraSmallSize ? 'center' : 'start',
            }}
          >
            Dr. {info.name_doctor} {info.last_name_doctor}
          </LabelText>
          {info.speciality_doctor?.length > 0 ? (
            <LabelText
              sx={
                isExtraSmallSize
                  ? {
                    textAlign: 'center',
                  }
                  : {}
              }
            >
              {info.speciality_doctor}
            </LabelText>
          ) : (
            <LabelText>Sin Especialidades</LabelText>
          )}
          <br />
          <LabelText>
            <b>Años de experiencia:</b> {info.years_experience_doctor}
          </LabelText>
          <LabelText>
            <b>Estudios:</b> {info.university_doctor}
          </LabelText>
          <LabelText>
            <b>Atenciones:</b> 56
          </LabelText>
        </GridWrapper>
      </GridWrapper>
    </GridWrapper>
  )
}
