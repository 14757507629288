import {
  ResendCode,
  SendCode,
  VerifyPhoneValidation,
  VerifyCode,
  UpdatePatientPhone,
} from '../../../domain/useCase/PhoneVerification'
import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IResendCodeParams,
  IUpdatePatientPhoneParams,
  IVerifyCodeParams,
} from '../../../infrastructure/dtos/PhoneVerification'
import PhoneVerificationMapper from '../../../infrastructure/http/mappers/PhoneVerificationMapper'
import { PhoneVerificationRepositoryImpl } from '../../../infrastructure/RepositoryImpl/PhoneVerificationRepositoryImpl'

export interface ICodeService<T> {
  status: number
  data: IApiResponsePhoneVerification<T> | string
}

export async function resendCodeService(params: IResendCodeParams): Promise<ICodeService<IPhoneVerification>> {
  try {
    const UseCaseDoctor = new ResendCode(new PhoneVerificationRepositoryImpl(new PhoneVerificationMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function sendCodeService(userId: string): Promise<ICodeService<IPhoneVerification>> {
  try {
    const UseCaseDoctor = new SendCode(new PhoneVerificationRepositoryImpl(new PhoneVerificationMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(userId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function verifyPhoneValidationService(userId: string): Promise<ICodeService<IPhoneVerification>> {
  try {
    const UseCaseDoctor = new VerifyPhoneValidation(new PhoneVerificationRepositoryImpl(new PhoneVerificationMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(userId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function verifyCodeService(params: IVerifyCodeParams): Promise<ICodeService<IPhoneVerification>> {
  try {
    const UseCaseDoctor = new VerifyCode(new PhoneVerificationRepositoryImpl(new PhoneVerificationMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function updatePatientPhoneService(params: IUpdatePatientPhoneParams): Promise<ICodeService<string>> {
  try {
    const UseCaseDoctor = new UpdatePatientPhone(new PhoneVerificationRepositoryImpl(new PhoneVerificationMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
