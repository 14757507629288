import QualificationQuestions from '../../../domain/useCase/QualificationQuestions'
import { QualificationQuestionsResponse } from '../../../infrastructure/dtos/QualificationQuestions'
import { QualificationQuestionsRepositoryImpl } from './../../../infrastructure/RepositoryImpl/QualificationQuestionsRepositoryImpl'
import QualificationQuestionsMapper from '../../../infrastructure/http/mappers/QualificationQuestionsMappers'

interface IQualificationQuestionsService {
  status: number
  data: QualificationQuestionsResponse | string
}

export async function QualificationQuestionsService(): Promise<IQualificationQuestionsService> {
  try {
    const UseCaseQualificationQuestions = new QualificationQuestions(
      new QualificationQuestionsRepositoryImpl(new QualificationQuestionsMapper()),
    )
    return {
      status: 1,
      data: await UseCaseQualificationQuestions.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
