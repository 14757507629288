import { styled } from '@mui/material'

import { BoxWrapper } from '../../components/wrapper'

export const HomeBox = styled(BoxWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '30px',
  padding: '20px',
  minWidth: '250px',
  maxWidth: '300px',
  height: '500px',
  marginTop: '24px',
  marginBottom: '15px',
  [theme.breakpoints.only('xs')]: {
    width: '100%',
    maxWidth: 366,
    marginTop: '0px',
    height: 230,
    marginBottom: '25px'
  }
}))

export const UserInfoBox = styled(BoxWrapper)(() => ({
  margin: '0 auto',
  textAlign: 'center'
}))

export const SmallHomeBox = styled(BoxWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '30px',
  minWidth: '250px',
  maxWidth: '300px',
  minHeight: '100px',
  marginTop: '15px',
  padding: '20px 0',
  boxShadow: '5px 6px 7px 0px rgba(0, 0, 0, 0.03)',
  [theme.breakpoints.only('xs')]: {
    width: '100%',
    maxWidth: 366
  }
}))

export const CommunityHomeBox = styled(BoxWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '30px',
  justifyContent: 'center',
  padding: '10px 30px',
  width: '100%',
  height: 'auto',
  marginTop: '20px'
}))
