import NewsRepository from '../../domain/repository/NewsRepository'
import { INewsResponse } from '../dtos/News'
import NewsDataSource from '../http/dataSource/NewsDataSource'

export class NewsRepositoryImpl implements NewsRepository {
  private newsDataSource: NewsDataSource

  constructor(newsDataSource: NewsDataSource) {
    this.newsDataSource = newsDataSource
  }

  async getNews(): Promise<INewsResponse> {
    return await this.newsDataSource.getNews()
  }
}
