import { Close as CloseIcon } from '@mui/icons-material'
import { useMediaQuery, useTheme } from '@mui/material'

import {
  BoxModalOrder,
  CloseButton,
  FadePatientOrderModal,
  LabelOrderInputTitle,
  ModalOrder,
  OrderDisabledInput,
  OrderDisableTextInput,
  SeparatorOrder,
  TypographyOrderDoctorInfo,
  TypographyOrderDoctorName,
  TypographyOrderLabel,
  TypographyOrderNumber,
} from './styles'
import { ICustomOrder } from './useOrder'
import helthyLogo from '../../../../../../../assets/helthyLogo3.png'
import { BackdropWrapper, DividerWrapper, GridWrapper, TypographyWrapper } from '../../../../../../../components'
import SubmitButton from '../../../../../../../components/SubmitButton'

interface OrderModalComponentProps {
  handleOpenOrderModal: (value: boolean) => void
  open: boolean
  appointmentDetails?: ICustomOrder
}

export const OrderModal = ({
  handleOpenOrderModal,
  open,
  appointmentDetails,
}: OrderModalComponentProps): JSX.Element => {
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))
  return (
    <ModalOrder
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-recipe"
      open={open}
      onClose={() => handleOpenOrderModal(false)}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadePatientOrderModal in={open}>
        <BoxModalOrder>
          <GridWrapper>
            <GridWrapper
              width={'100%'}
              height={'auto'}
              display={'flex'}
              container
              marginBottom={2}
              sx={{ color: 'primary.dark' }}
            >
              <CloseButton onClick={() => handleOpenOrderModal(false)} data-testid="close-button">
                <CloseIcon />
              </CloseButton>
              <GridWrapper item xs={4}>
                <img src={helthyLogo} alt="Helthy logo" width={'90%'} height={'auto'} />
              </GridWrapper>
              <GridWrapper item xs={8} data-testid="diagnostic-header">
                <TypographyOrderNumber>
                  Pedido No. 0000001
                </TypographyOrderNumber>
                <TypographyOrderDoctorName variant="h6">
                  Dr. {`${appointmentDetails?.doctor?.name_doctor} ${appointmentDetails?.doctor?.last_name_doctor}`}
                </TypographyOrderDoctorName>
                <TypographyOrderLabel>{appointmentDetails?.doctor?.speciality_doctor}</TypographyOrderLabel>
                <form data-testid="code-access">
                  <GridWrapper container spacing={3}>
                    <GridWrapper item xs={6} display={'flex'} alignItems={'center'} marginBottom={1}>
                      <TypographyOrderLabel>Reg. ACESS: {appointmentDetails?.doctor?.code_msp}</TypographyOrderLabel>
                    </GridWrapper>
                  </GridWrapper>
                </form>
                <TypographyOrderDoctorInfo>
                  {`${appointmentDetails?.office?.name_medical_office}, ${appointmentDetails?.office?.reference_medical_office}`}
                </TypographyOrderDoctorInfo>
                <TypographyOrderDoctorInfo>
                  {appointmentDetails?.doctor?.doctor_phone}
                  {appointmentDetails?.office?.office_phone ? ` - ${appointmentDetails?.office?.office_phone}` : ''}
                </TypographyOrderDoctorInfo>
                <TypographyOrderDoctorInfo>{appointmentDetails?.doctor?.email_doctor}</TypographyOrderDoctorInfo>
              </GridWrapper>
            </GridWrapper>

            <DividerWrapper variant="fullWidth" flexItem />
            <GridWrapper container display={'flex'} flexDirection={'row'}>
              <GridWrapper item xs={12} md={5.9}>
                <GridWrapper container display={'flex'} flexDirection={'row'} paddingTop={2}>
                  <GridWrapper item xs={6} paddingX={2}>
                    <LabelOrderInputTitle sx={{ marginLeft: 1.4 }}>Nombre del paciente</LabelOrderInputTitle>
                    <OrderDisabledInput padding={2} display={'flex'} justifyContent={'center'}>
                      {`${appointmentDetails?.patient?.name} ${appointmentDetails?.patient?.last_name}`}
                    </OrderDisabledInput>
                  </GridWrapper>
                  <GridWrapper item xs={6} paddingX={2}>
                    <LabelOrderInputTitle sx={{ marginLeft: 1.4 }}>Fecha</LabelOrderInputTitle>
                    <OrderDisabledInput
                      padding={2}
                      display={'flex'}
                      justifyContent={'center'}
                      data-testid={'prescription-date'}
                    >
                      {appointmentDetails?.appointment_date?.split('-').reverse().join('/')}
                    </OrderDisabledInput>
                  </GridWrapper>
                  <GridWrapper item xs={12} marginY={3} paddingX={2}>
                    <LabelOrderInputTitle sx={{ marginLeft: 1.4 }}>Diagnóstico</LabelOrderInputTitle>
                    <OrderDisableTextInput>
                      {appointmentDetails?.diagnostics.map((d) => `${d.cie_code} - ${d.diagnostic}`).join(' | ')}
                    </OrderDisableTextInput>
                  </GridWrapper>
                  <GridWrapper item xs={12} marginY={1} paddingX={2}>
                    <GridWrapper display={'flex'} flexDirection={'row'}>
                      <GridWrapper item xs={8}>
                        <LabelOrderInputTitle sx={{ marginLeft: 1.4, textDecoration: 'underline' }}>Descripción</LabelOrderInputTitle>
                      </GridWrapper>
                      <GridWrapper item xs={4}>
                        <LabelOrderInputTitle sx={{ marginLeft: 1.4, textDecoration: 'underline', textAlign: 'right' }}>Cantidad</LabelOrderInputTitle>
                      </GridWrapper>
                    </GridWrapper>
                    <OrderDisableTextInput>
                      {appointmentDetails?.orders.map(
                        (item, index) => (
                          <GridWrapper key={`order-${index}`} display={'flex'} flexDirection={'row'}>
                            <GridWrapper item xs={10}>
                              <TypographyWrapper
                                sx={{ fontSize: '13px', lineHeight: 1 }}
                              >{item.description}</TypographyWrapper>
                            </GridWrapper>
                            <GridWrapper item xs={2}>
                              <TypographyWrapper
                                sx={{ fontSize: '13px', lineHeight: 1, textAlign: 'right' }}
                              >{item.quantity}</TypographyWrapper>
                            </GridWrapper>
                          </GridWrapper>
                        ),
                      )}
                    </OrderDisableTextInput>
                  </GridWrapper>
                </GridWrapper>
              </GridWrapper>

              {!isExtraSmallSize ? (
                <GridWrapper item xs={0.2} display={'flex'} justifyContent={'center'} py={2}>
                  <DividerWrapper orientation="vertical" flexItem />
                </GridWrapper>
              ) : (
                <SeparatorOrder
                  item
                  xs={12}
                  display={'flex'}
                  justifyContent={'center'}
                  py={'0.1px'}
                  sx={{ backgroundColor: '#D6D6D6' }}
                >
                  <DividerWrapper orientation="horizontal" flexItem />
                </SeparatorOrder>
              )}

              <GridWrapper item xs={12} md={5.9}>

                <GridWrapper item xs={12} display={'flex'} flexDirection={'row'} py={2}>
                  <GridWrapper item xs={6} paddingX={2}>
                    <LabelOrderInputTitle sx={{ marginLeft: 1.4 }}>Edad</LabelOrderInputTitle>
                    <OrderDisabledInput padding={2} display={'flex'} justifyContent={'center'}>
                      {appointmentDetails?.patient.age}
                    </OrderDisabledInput>
                  </GridWrapper>

                  <GridWrapper item xs={6} paddingX={2}>
                    <LabelOrderInputTitle sx={{ marginLeft: 1.4 }}>Nº Historia</LabelOrderInputTitle>
                    <OrderDisabledInput padding={2} display={'flex'} justifyContent={'center'}>
                      00012734
                    </OrderDisabledInput>
                  </GridWrapper>
                </GridWrapper>

                <GridWrapper padding={2}>
                  <LabelOrderInputTitle sx={{ marginLeft: 1.4 }}>
                    Observaciones:
                  </LabelOrderInputTitle>
                  <OrderDisableTextInput>
                    {appointmentDetails?.remarks || 'Sin recomendaciones'}
                  </OrderDisableTextInput>
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>

            <GridWrapper display="flex" justifyContent="center" marginTop={3}>
              <SubmitButton
                id={''}
                variant={'contained'}
                data-testid="print-button"
                fullWidth
                type={'button'}
                text={'Imprimir'}
                sx={{
                  width: isExtraSmallSize ? '100%' : '25%',
                  margin: '0 20px',
                  backgroundColor: 'primary.main',
                }}
              />
            </GridWrapper>
          </GridWrapper>
        </BoxModalOrder>
      </FadePatientOrderModal>
    </ModalOrder>
  )
}
