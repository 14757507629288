import { useMediaQuery, useTheme } from '@mui/material'
import React, { useState, JSX } from 'react'

import QualifyDoctorModal from './components/QualifyDoctorModal'
import { usePendingComponent } from './PendingsComponentHook'
import { BoxPendingsStyled, ButtonPendingsStyled } from './PendingsComponentStyles'
import { BoxWrapper, TypographyWrapper } from '../../../components'
import CircularProgressWrapper from '../../../components/wrapper/LoaderWrapper'
import { IAppointmentQualification } from '../../../infrastructure/dtos'
import ConfirmAppointments from '../../pages/Dates/components/NextDatesList/ConfirmAppointments/ConfirmAppointments'
import { useConfirm } from '../../pages/Dates/components/NextDatesList/ConfirmAppointments/hooks/useConfirmModal'

export const Pendings = (): JSX.Element => {
  const [appointment, setAppointment] = useState<IAppointmentQualification>({} as IAppointmentQualification)

  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const { pendings, loadingPendings, handleCloseModal, handleOpenModal, openModal, pendingId, fetchPendingsTasks } =
    usePendingComponent()
  const { handleCloseConfirm, handleOpenConfirm, openConfirm } = useConfirm()

  return (
    <>
      <BoxWrapper sx={{ width: isExtraSmallSize ? 365 : 1, marginTop: 2 }} data-testid="pendings">
        {!loadingPendings ? (
          <BoxWrapper display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={3}>
            {pendings?.data?.appointment_qualification?.length || pendings?.data?.confirmation_appointment?.length ? (
              <>
                {pendings?.data?.appointment_qualification?.map((pending, index) => (
                  <BoxPendingsStyled key={index}>
                    <TypographyWrapper>{pending.name}</TypographyWrapper>
                    <ButtonPendingsStyled
                      role="button"
                      variant="outlined"
                      onClick={() => handleOpenModal(pending)}
                      data-testid="qualify-button"
                    >
                      Calificar
                    </ButtonPendingsStyled>
                  </BoxPendingsStyled>
                ))}
                {pendings?.data?.confirmation_appointment?.map((pending, index) => (
                  <BoxPendingsStyled key={index}>
                    <TypographyWrapper>{pending.name}</TypographyWrapper>
                    <ButtonPendingsStyled
                      role="button"
                      variant="outlined"
                      onClick={() => {
                        setAppointment(pending)
                        handleOpenConfirm()
                      }}
                      data-testid="confirmation-button"
                    >
                      Confirmar
                    </ButtonPendingsStyled>
                  </BoxPendingsStyled>
                ))}
              </>
            ) : (
              <BoxPendingsStyled>
                <TypographyWrapper>{pendings?.message}</TypographyWrapper>
              </BoxPendingsStyled>
            )}
          </BoxWrapper>
        ) : (
          <CircularProgressWrapper data-testid="loading-spinner" />
        )}
      </BoxWrapper>
      {openModal && (
        <QualifyDoctorModal
          handleClose={handleCloseModal}
          open={openModal}
          pending={pendingId}
          fetchPendingsTasks={fetchPendingsTasks}
        />
      )}
      {openConfirm && (
        <ConfirmAppointments
          handleClose={handleCloseConfirm}
          open={openConfirm}
          appointment={appointment}
          fetchAppointments={fetchPendingsTasks}
        />
      )}
    </>
  )
}
