import { GetCode } from '../../../domain/useCase/ResendPhoneVerification'
import { ResendTemporaryPhoneCodeResponse } from '../../../infrastructure/dtos/TemporaryPhoneVerification'
import ResendPhoneVerificationMapper from '../../../infrastructure/http/mappers/ResendPhoneVerificationMapper'
import { ResendPhoneVerificationRepositoryImpl } from '../../../infrastructure/RepositoryImpl/ResendPhoneVerificationRepositoryImpl'

export interface IResendPhoneVerification {
  status: number
  data: ResendTemporaryPhoneCodeResponse | string
}

export async function ResendPhoneVerificationService(user_id: string): Promise<IResendPhoneVerification> {
  try {
    const UseCaseRegistro = new GetCode(new ResendPhoneVerificationRepositoryImpl(new ResendPhoneVerificationMapper()))
    return {
      status: 1,
      data: await UseCaseRegistro.invoke(user_id),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
