import { styled } from '@mui/material'

import { ButtonWrapper, GridWrapper } from '../../../../components'

export const GridHours = styled(GridWrapper)(() => ({
  width: '80%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
}))

export const HoursButton = styled(ButtonWrapper)(() => ({
  backgroundColor: '#DCE7FF',
  width: '48%',
  marginTop: '5px',
  marginRight: '5px',
}))
