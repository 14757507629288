import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import { JSX } from 'react'

import { CircularOutLineDarkButton } from './FollowButtonStyles'
import { Severity } from '../../contexts/AuthReducer'
import { FollowUtility } from '../../services/Contracts/Utility/FollowUtility'
import { OutLineDarkButton } from '../StyledComponents/OutLineDarkButton'

export interface IFollowButtonProps {
  fromHome: boolean
  idPatient: string
  doctorId: string
  status: string
  isFollow: boolean
  size?: 'small' | 'large' | 'medium'
  handleAlert?: (open: boolean, message: string, severity?: Severity) => void
  callbackAfterFollow?: () => Promise<void>
}

export const FollowButton = ({
  fromHome,
  idPatient,
  doctorId,
  status,
  isFollow,
  size,
  callbackAfterFollow,
  handleAlert,
}: IFollowButtonProps): JSX.Element => {
  const followButtonText = (follow: boolean): string => (follow ? 'No seguir' : 'SEGUIR')

  const follow = async (patientId: string, userId: string): Promise<void> => {
    try {
      await FollowUtility({ doctor_id: userId, patient_id: patientId })
      callbackAfterFollow?.()
    } catch (error: unknown) {
      handleAlert?.(true, 'Error al actualizar el estatus de seguimiento', 'error')
    }
  }

  return (
    <>
      {fromHome ? (
        <CircularOutLineDarkButton
          data-testid="short-follow-button"
          onClick={() => follow(idPatient, doctorId)}
          disabled={status === 'CANCELLED'}
        >
          {isFollow ? <StarIcon /> : <StarOutlineIcon />}
        </CircularOutLineDarkButton>
      ) : (
        <OutLineDarkButton
          data-testid="follow-button"
          size={size}
          onClick={() => follow(idPatient, doctorId)}
          disabled={status === 'CANCELLED'}
          variant="outlined"
        >
          <StarIcon />
          {followButtonText(isFollow)}
        </OutLineDarkButton>
      )}
    </>
  )
}
