import { ResendPhoneVerificationRepository } from '../../domain/repository/ResendPhoneVerificationRepository'
import { ResendTemporaryPhoneCodeResponse } from '../dtos/TemporaryPhoneVerification'
import ResendPhoneVerificationDataSource from '../http/dataSource/ResendPhoneVerificationDataSource'

export class ResendPhoneVerificationRepositoryImpl
  implements ResendPhoneVerificationRepository
{
  dataSource: ResendPhoneVerificationDataSource

  constructor(_datasource: ResendPhoneVerificationDataSource) {
    this.dataSource = _datasource
  }

  async getCode(user_id: string): Promise<ResendTemporaryPhoneCodeResponse> {
    return this.dataSource.getCode(user_id)
  }
}
