import { SxProps, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { JSX } from 'react'

import { CardInfo, CustomAvatar, InfoBox, TypographyInfo } from './styles'
import { GridWrapper } from '../../../../components'
import { AccessAlamr, CalendarToday, LocationOnOutlinedIcon } from '../../../../components/icons'
import { AvatarBox } from '../../../../components/StyledComponents/AvatarBox'
import CardContentWrapper from '../../../../components/wrapper/CardContentWrapper'
import TypographyWrapper from '../../../../components/wrapper/TypographyWrapper'
import { IPatientAppointmentState } from '../../../../contexts/PatientAppointmentContext'
import { IDoctorInfo } from '../../../../infrastructure/dtos/DoctorInfo'

interface DoctorInfoProps {
  message: string
  info: IDoctorInfo
  patientAppointment?: IPatientAppointmentState | null
  sx?: SxProps
  imageSize?: {
    width: string
    height: string
  }
  align?: boolean
}

export default function DoctorInfo({
  message,
  info,
  patientAppointment = null,
  sx,
  imageSize = { width: '110px', height: '110px' },
  align = false,
}: DoctorInfoProps): JSX.Element {
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))
  return (
    <GridWrapper container>
      {!isExtraSmallSize ? (
        <CardInfo
          sx={
            align
              ? {
                  maxWidth: '750px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: 'auto',
                }
              : {}
          }
        >
          <GridWrapper item xs={12} md={3}>
            <CustomAvatar
              src={info?.photo_doctor}
              alt={`${info?.name_doctor} ${info?.last_name_doctor}`}
              sx={imageSize}
            />
          </GridWrapper>
          <GridWrapper item xs={12} md={9} style={{ marginLeft: 20 }}>
            <CardContentWrapper sx={{ padding: '16px 16px 16px 0' }}>
              <TypographyInfo color="primary.dark" gutterBottom sx={sx}>
                {message}
                {info?.name_doctor} {info?.last_name_doctor}
              </TypographyInfo>
              {patientAppointment && (
                <GridWrapper marginLeft={1}>
                  <TypographyWrapper display={'flex'} alignItems={'center'} color={'primary.dark'} fontSize={'.9rem'}>
                    <CalendarToday />
                    <span style={{ marginLeft: '5px' }}>
                      {dayjs(patientAppointment.date_appointment).format('dddd D ') +
                        ' de ' +
                        dayjs(patientAppointment.date_appointment).format(' MMMM ') +
                        ' - ' +
                        patientAppointment.consultation_time_minutes +
                        ' min'}
                    </span>
                  </TypographyWrapper>
                  <TypographyWrapper
                    display={'flex'}
                    alignItems={'center'}
                    color={'primary.dark'}
                    fontSize={'.9rem'}
                    marginY={'2px'}
                  >
                    <AccessAlamr />
                    <span style={{ marginLeft: '5px' }}>
                      {patientAppointment.hour_appointment +
                        'h - ' +
                        dayjs(`${patientAppointment.date_appointment}T${patientAppointment.hour_appointment}`)
                          .add(patientAppointment.consultation_time_minutes, 'minutes')
                          .format('HH:mm') +
                        'h'}
                    </span>
                  </TypographyWrapper>
                  <TypographyWrapper
                    display={'flex'}
                    alignItems={'center'}
                    color={'primary.dark'}
                    fontSize={'.9rem'}
                    marginY={'2px'}
                  >
                    <LocationOnOutlinedIcon />
                    <span style={{ marginLeft: '5px' }}>{patientAppointment.office?.reference_medical_office}</span>
                  </TypographyWrapper>
                </GridWrapper>
              )}
            </CardContentWrapper>
          </GridWrapper>
        </CardInfo>
      ) : (
        <InfoBox>
          <TypographyInfo color="primary.dark" gutterBottom sx={sx} textAlign={'center'}>
            {`${message} ${info?.name_doctor} ${info?.last_name_doctor}`}
          </TypographyInfo>
          <AvatarBox data-testid="avatar-profile" src={info?.photo_doctor} />

          {patientAppointment && (
            <GridWrapper marginTop={3}>
              <TypographyWrapper display={'flex'} alignItems={'center'} color={'primary.dark'} fontSize={'.9rem'}>
                <CalendarToday />
                <span style={{ marginLeft: '5px' }}>
                  {dayjs(patientAppointment.date_appointment).format('dddd D ') +
                    ' de ' +
                    dayjs(patientAppointment.date_appointment).format(' MMMM ') +
                    ' - ' +
                    patientAppointment.consultation_time_minutes +
                    ' min'}
                </span>
              </TypographyWrapper>
              <TypographyWrapper
                display={'flex'}
                alignItems={'start'}
                color={'primary.dark'}
                fontSize={'.9rem'}
                marginY={'2px'}
              >
                <AccessAlamr />
                <span style={{ marginLeft: '5px' }}>
                  {patientAppointment.hour_appointment +
                    'h - ' +
                    dayjs(`${patientAppointment.date_appointment}T${patientAppointment.hour_appointment}`)
                      .add(patientAppointment.consultation_time_minutes, 'minutes')
                      .format('HH:mm') +
                    'h'}
                </span>
              </TypographyWrapper>
              <TypographyWrapper
                display={'flex'}
                alignItems={'center'}
                color={'primary.dark'}
                fontSize={'.9rem'}
                marginY={'2px'}
              >
                <LocationOnOutlinedIcon />
                <span style={{ marginLeft: '5px' }}>{patientAppointment.office?.reference_medical_office}</span>
              </TypographyWrapper>
            </GridWrapper>
          )}
        </InfoBox>
      )}
    </GridWrapper>
  )
}
