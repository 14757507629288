import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { CardActions } from '@mui/material'
import React, { JSX } from 'react'

import { FollowButton } from '../../../../../../components/FollowButton/FollowButton'
import { SecondaryMediumButton } from '../../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { OutLineButton } from '../../../../../../components/StyledComponents/OutLineButton'
import {
  BoxWrapper,
  CardContentWrapper,
  GridWrapper,
  IconButtonWrapper,
  MenuWrapper,
  TypographyWrapper,
} from '../../../../../../components/wrapper'
import { INextAppointment } from '../../../../../../infrastructure/dtos/Appointments'
import { handleClickOpenGMaps } from '../../../../../../utils/openGMap'
import { NewDateCard, OptionGrid } from '../../NextDatesList/NextDateCard/NextDateCardStyle'
import { AvatarNextDate, OptionMenu } from '../../NextDatesList/styles'

interface NextDate {
  data: INextAppointment
  anchorEl: HTMLElement | null
  index: number
  handleClickOption: (option: string, appointment: INextAppointment, index: number) => void
  handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>, appointmentIndex: number) => void
  fetchNextAppointments: () => Promise<void>
  handleClose: (appointmentIndex: number) => void
  setAppointmentToModal: React.Dispatch<React.SetStateAction<INextAppointment>>
  handleOpenConfirm: () => void
}

const options: string[] = ['Reagendar', 'Cancelar']

function NextDatesCarouselCard({
  data,
  anchorEl,
  index,
  handleClickOption,
  handleClick,
  fetchNextAppointments,
  handleClose,
  setAppointmentToModal,
  handleOpenConfirm,
}: NextDate): JSX.Element {
  const userInfo = localStorage.getItem('userData') ?? '{}'
  const idPatient = JSON.parse(userInfo)?.userId
  return (
    <NewDateCard
      sx={{ maxWidth: 390, marginBottom: '40px' }}
      color={data.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}
    >
      <GridWrapper container>
        <GridWrapper item xs={4} sx={{ paddingTop: '16px' }}>
          <BoxWrapper
            sx={{
              width: 70,
              height: 70,
              borderRadius: '50%',
              overflow: 'hidden',
              margin: 'auto',
            }}
          >
            <AvatarNextDate src={data.photo_doctor} sx={{ width: '100%', height: '100%' }} />
          </BoxWrapper>
        </GridWrapper>
        <GridWrapper item xs={7}>
          <CardContentWrapper sx={{ textAlign: 'left' }}>
            <TypographyWrapper variant="body2" color={data.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
              {`${data.name_doctor} ${data.last_name_doctor}`}
            </TypographyWrapper>
            <TypographyWrapper variant="body2" color={data.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
              {`${data.speciality_doctor}`}
            </TypographyWrapper>
            <TypographyWrapper variant="body2" color={data.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
              {`${data.appointment_date.split('-').reverse().join('/')} ${data.hour_from.split(':').join('h')}`}
            </TypographyWrapper>
            <TypographyWrapper variant="body2" color={data.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
              {`${data.reference_medical_office}`}
            </TypographyWrapper>
          </CardContentWrapper>
        </GridWrapper>
        <OptionGrid item xs={1}>
          <IconButtonWrapper
            key={`menu-button-${data.appointment_id}`}
            aria-label="more"
            id="long-button"
            data-testid="long-button"
            aria-controls={data.showMenu ? 'long-menu' : undefined}
            aria-expanded={data.showMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event) => handleClick(event, index)}
            disabled={data.status === 'CANCELLED' ? true : false}
          >
            <MoreHorizIcon
              fontSize="medium"
              sx={{
                color: data.status === 'CANCELLED' ? '#e4ecf1' : 'primary.dark',
              }}
            />
          </IconButtonWrapper>
          <MenuWrapper
            key={`menu-${data.appointment_id}`}
            id="long-menu"
            data-testid="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={Boolean(data?.showMenu)}
            onClose={() => handleClose(index)}
            slotProps={{
              paper: {
                style: {
                  borderRadius: '32px',
                  padding: '7px 7px',
                  boxShadow: '1px 4px 8px 6px rgba(0, 0, 0, 0.03)',
                  backgroundColor: '#fff',
                },
              },
            }}
          >
            {options.map((option: string, i: number) => (
              <OptionMenu
                key={`${option}-${i}`}
                data-testid={`${option}`}
                onClick={() => handleClickOption(option, data, index)}
              >
                {option}
              </OptionMenu>
            ))}
          </MenuWrapper>
        </OptionGrid>
        <GridWrapper
          item
          xs={12}
          sx={{
            '& :not(:first-of-type)': {
              marginLeft: 0,
            },
          }}
        >
          <CardActions>
            <SecondaryMediumButton
              id="Coming"
              data-testid="coming"
              text="Cómo llegar"
              onClick={() => handleClickOpenGMaps(data)}
              type="button"
              sx={{ height: '43px', width: 101, fontSize: 12 }}
              variant="contained"
              disabled={data.status === 'CANCELLED' ? true : false}
            />
            <OutLineButton
              data-testid="confirm-button"
              onClick={() => {
                setAppointmentToModal(data)
                handleOpenConfirm()
              }}
              variant="outlined"
              disabled={data.status === 'ACTIVE' ? false : true}
              sx={{
                height: '43px',
                fontSize: 12,
                width: 101,
                minWidth: 'inherit',
              }}
            >
              {data.status === 'CONFIRMED' ? 'Confirmada' : 'Confirmar'}
            </OutLineButton>
            <FollowButton
              callbackAfterFollow={fetchNextAppointments}
              doctorId={data.user_id}
              isFollow={data.follow}
              fromHome={false}
              idPatient={idPatient}
              status={data.status}
            />
          </CardActions>
        </GridWrapper>
      </GridWrapper>
    </NewDateCard>
  )
}

export default NextDatesCarouselCard
