import { CalendarRepository } from '../../domain/repository/CalendarRepository'
import {
  IApiResponseCalendar,
  ICalendarEvent,
  IEvent,
  INextAppointmentHour,
  IParamsGetCalendar,
  IParamsGetNextAppointmentHour,
} from '../dtos/Calendar'
import CalendarDataSource from '../http/dataSource/CalendarDataSource'

export class CalendarRepositoryImpl implements CalendarRepository {
  dataSource: CalendarDataSource

  constructor(_datasource: CalendarDataSource) {
    this.dataSource = _datasource
  }

  async postAddCalendarEvent(event: ICalendarEvent): Promise<IApiResponseCalendar<IEvent>> {
    return this.dataSource.postAddCalendarEvent(event)
  }

  async getEventsByMonth(params: IParamsGetCalendar): Promise<IApiResponseCalendar<IEvent>> {
    return this.dataSource.getEventsByMonth(params)
  }

  async getAvailableHoursInADay(params: IParamsGetCalendar): Promise<IApiResponseCalendar<IEvent>> {
    return this.dataSource.getAvailableHoursInADay(params)
  }

  async getNextAppointmentHour(
    params: IParamsGetNextAppointmentHour,
  ): Promise<IApiResponseCalendar<INextAppointmentHour>> {
    return this.dataSource.getNextAppointmentHour(params)
  }
}
