import { SaveFollow } from '../../../domain/useCase/SaveFollow'
import { IApiResponseSaveDataFollow, SaveDataFollow } from '../../../infrastructure/dtos/Follow'
import FollowMapper from '../../../infrastructure/http/mappers/FollowMapper'
import { FollowRepositoryImpl } from '../../../infrastructure/RepositoryImpl/FollowRepositoryImpl'

export interface IFollowService {
  status: number
  data: IApiResponseSaveDataFollow | string
}

export async function FollowService(params: SaveDataFollow): Promise<IFollowService> {
  try {
    const UseCaseFollow: SaveFollow = new SaveFollow(new FollowRepositoryImpl(new FollowMapper()))
    return {
      status: 1,
      data: await UseCaseFollow.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
