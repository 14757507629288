import { styled } from '@mui/material'

import { GridWrapper } from '../../../../../../components/wrapper'
import CardWrapper from '../../../../../../components/wrapper/CardWrapper'

export const NewDateCard = styled(CardWrapper)((props) => ({
  backgroundColor: props.theme.palette.common.white,
  borderRadius: '30px',
  boxShadow: '5px 6px 7px 0px rgba(0, 0, 0, 0.03)',
  padding: '0 25px 16px 0',
  '&:not(:last-child)': {
    marginBottom: '16px',
  },
}))

export const OptionGrid = styled(GridWrapper)(() => ({
  position: 'relative',
  right: '4px',
  top: '5px',
}))
