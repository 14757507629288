import { styled } from '@mui/material'

import { AvatarWrapper, BadgeWrapper, GridWrapper } from '../../../../../components'

export const AvatarMap = styled(AvatarWrapper)(() => ({
  width: 90,
  height: 90,
}))

export const BadgeMap = styled(BadgeWrapper)((props) => ({
  right: -45,
  top: -95,
  '& .MuiBadge-badge': {
    right: 18,
    top: 17,
    borderRadius: '50%',
    width: 35,
    height: 35,
    backgroundColor: '#F79D25', // TODO: Google maps doesnt work with custom theme
    color: props.theme.palette.common.white,
    fontSize: '.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
}))

export const PatientIconMap = styled(GridWrapper)(() => ({
  width: 20,
  height: 20,
  // backgroundColor: theme.palette.primary.main,
  backgroundColor: '#3850B2',
  borderRadius: 50,
}))

export const GridDoctorInfo = styled(GridWrapper)(() => ({
  '&.MuiGrid-root': {
    position: 'absolute',
    top: 75,
  },
  minWidth: 90,
  height: 'auto',
  padding: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '10px',
}))
