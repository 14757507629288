import {SelectChangeEvent, useMediaQuery, useTheme} from '@mui/material'
import dayjs from 'dayjs'
import React, {useContext, useEffect, useState, JSX} from 'react'
import {useParams} from 'react-router-dom'

import {GridContent, GridLayoutBottom, GridLayoutRight} from './SelectScheduleStyle'
import {BoxWrapper, LoaderWrapper, TypographyWrapper} from '../../../../components'
import {List} from '../../../../components/List/List'
import {GridHalfScreen} from '../../../../components/StyledComponents/GridHalfScreen'
import SubmitButton from '../../../../components/SubmitButton'
import GridWrapper from '../../../../components/wrapper/GridWrapper'
import {AccountContext, AuthContextType} from '../../../../contexts/AccountContext'
import {PatientAppointmentContext} from '../../../../contexts/PatientAppointmentContext'
import {ICalendarData} from '../../../../infrastructure/dtos/CalendarInfo'
import { getDaysAvailabilityUtility, getMonthAvailabilityUtility } from '../../../../services/Contracts/Utility/OfficesUtility'
import {AvailableHours} from '../../components/AvailableHours/AvailableHours'
import CalendarInfo from '../../components/CalendarInfo/CalendarInfo'
import DoctorInfo from '../../components/DoctorInfo'
import {OfficeList} from '../../components/OfficesList/OfficesList'
import {useDataDoctor} from '../../Hooks/useDataDoctor'

interface SelectScheduleProps {
  onStepper: () => void
}

export const SelectSchedule = ({onStepper}: SelectScheduleProps): JSX.Element => {
  const {PatientAppointment, saveScheduleSelected} = useContext(PatientAppointmentContext)
  const {handleAlert} = useContext(AccountContext) as AuthContextType
  const {idDoctor} = useParams<string>()
  const {dataDoctor} = useDataDoctor(idDoctor as string)
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [loadCalendar, setLoadCalendar] = useState<boolean>(false)
  const [showAvailableHours, setShowAvailableHours] = useState<boolean>(false)
  const [loadAvailableHours, setLoadAvailableHours] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const [selectedOffice, setSelectedOffice] = useState<string>(PatientAppointment?.office_id)
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
    PatientAppointment?.date_appointment ? dayjs(PatientAppointment?.date_appointment) : null,
  )
  const [selectedHour, setSelectedHour] = useState<string>(PatientAppointment?.hour_appointment)
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const [availableHours, setAvailableHours] = useState<string[]>([])
  const [consultationTimeMinutes, setConsultationTimeMinutes] = useState<string>('')
  const [daysAvailability, setDaysAvailability] = useState<ICalendarData>()

  const fetchDaysAvailabilityInAMonth = async (): Promise<ICalendarData> => {
    const { data } = await getMonthAvailabilityUtility(
      selectedOffice,
      idDoctor as string,
      'OFFICE'
    )
    return data.data
  }

  const handleChangeOffice = (event: SelectChangeEvent<unknown>): void => {
    setSelectedOffice((event.target as HTMLSelectElement)?.value)
    setSelectedDate(null)
    setSelectedHour('')
    setDisabledButton(true)
  }
  const handleChangeDate = (value: dayjs.Dayjs | null): void => {
    setSelectedDate(value)
    setSelectedHour('')
    setDisabledButton(true)
  }
  const handleSelectedHour = (event: React.MouseEvent<HTMLElement>): void =>
    setSelectedHour((event.target as HTMLInputElement).value)

  const setSaveScheduleSelected = (): void => {
    saveScheduleSelected({
      ...PatientAppointment,
      office_id: selectedOffice,
      date_appointment: selectedDate?.format('YYYY-MM-DD') as string,
      hour_appointment: selectedHour,
      consultation_time_minutes: parseInt(consultationTimeMinutes.split(' ')[0]),
    })
  }

  const handleGoNext = (): void => {
    setSaveScheduleSelected()
    onStepper()
  }

  const fetchAvailableHoursInADay = async (): Promise<void> => {
    try {
      setLoadAvailableHours(true)
      const { data } = await getDaysAvailabilityUtility(
        selectedOffice,
        idDoctor as string,
        'OFFICE',
        selectedDate?.format('YYYY-MM-DD') as string,
      )

      setAvailableHours(data.data?.intervals_available_hours as string[])
      setConsultationTimeMinutes(data.data?.consultation_time_minutes?.toString() as string)
      setShowAvailableHours(true)
      setLoadAvailableHours(false)
    } catch (error) {
      handleAlert(true, 'Error al obtener horas del día seleccionado', 'error')
      setShowAvailableHours(true)
      setLoadAvailableHours(false)
    }
  }

  useEffect(() => {
    if (selectedOffice && PatientAppointment?.office) {
      setShowAvailableHours(false)
      setLoadCalendar(true)
      fetchDaysAvailabilityInAMonth().then((result) => {
        setDaysAvailability(result)
        setLoadCalendar(false)
        setShowCalendar(true)
      })
    }
  }, [selectedOffice, PatientAppointment])

  useEffect(() => {
    if (selectedDate) {
      setShowAvailableHours(true)
      fetchAvailableHoursInADay()
    }
  }, [selectedDate])

  useEffect(() => {
    if (selectedHour) {
      setDisabledButton(false)
    }
  }, [selectedHour])

  return (
    <BoxWrapper flexGrow={1} paddingX={!isExtraSmallSize ? '32px' : '15px'}>
      <GridContent container>
        <GridHalfScreen
          item
          sm={4}
          style={{
            marginTop: '2%',
          }}
        >
          <DoctorInfo
            message="Selecciona el lugar y fecha para tu cita con Dr. "
            info={dataDoctor}
            sx={{
              fontSize: '16px',
            }}
          />
          <GridWrapper sx={{margin: '30px 0', display: 'grid', width: !isExtraSmallSize ? '500px' : '100%'}}>
            <OfficeList handleChangeOffice={handleChangeOffice} office={selectedOffice} idDoctor={idDoctor as string} rol={'DOCTOR'}/>
          </GridWrapper>
          {loadCalendar && (
            <GridWrapper item display={'flex'} justifyContent={'center'}>
              <LoaderWrapper/>
            </GridWrapper>
          )}
          {!loadCalendar && showCalendar && (
            <CalendarInfo
              onChangeDate={handleChangeDate}
              selectedDate={selectedDate}
              daysAvailability={daysAvailability as ICalendarData}
            />
          )}
        </GridHalfScreen>
        <GridLayoutRight
          item
          sm={4}
          sx={{
            alignItems: 'start',
          }}
        >
          {loadAvailableHours && (
            <GridWrapper item display={'flex'} justifyContent={'center'}>
              <LoaderWrapper/>
            </GridWrapper>
          )}
          {!loadAvailableHours && showAvailableHours && (
            <>
              {isExtraSmallSize ? (
                <GridContent display={'flex'} flexDirection={'column'} width={'100%'}>
                  <TypographyWrapper
                    sx={{
                      textAlign: 'start',
                      marginBottom: '30px',
                      fontSize: '16px',
                      padding: '0 85px',
                    }}
                  >
                    Selecciona la hora para tu cita
                  </TypographyWrapper>
                  <List
                    id="selectIdType"
                    lists={availableHours}
                    onChange={(value) => setSelectedHour(value)}
                    value={selectedHour}
                    minWidth="100%"
                  />
                </GridContent>
              ) : (
                <AvailableHours
                  availableHours={availableHours}
                  handleSelectedHour={handleSelectedHour}
                  selectedHour={selectedHour}
                  maxHeight={'455px'}
                />
              )}
            </>
          )}
        </GridLayoutRight>
      </GridContent>
      <GridLayoutBottom sx={{}}>
        <SubmitButton
          id="next-button"
          data-testid="next-button"
          onClick={handleGoNext}
          variant="contained"
          type="button"
          fullWidth
          disabled={disabledButton}
          text="Siguiente"
          sx={{width: isExtraSmallSize ? '97%' : '60%', marginTop: '30px'}}
        />
      </GridLayoutBottom>
    </BoxWrapper>
  )
}
