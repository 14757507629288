import { useAuth0 } from '@auth0/auth0-react'
import { Grid } from '@mui/material'
import { useEffect, JSX } from 'react'

import { TypographyWrapperAuth } from '../../utils/RegisterMedicalFormStyles'

export const Status = (): JSX.Element => {
  const { isAuthenticated, isLoading, logout } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        logout()
      }
    }
  }, [])

  return (
    <Grid
      item
      xs={3}
      sx={{
        width: { sm: 450 },
        backgroundColor: 'white',
        padding: 3,
        borderRadius: 2,
      }}
    >
      <TypographyWrapperAuth>Vuelva pronto..</TypographyWrapperAuth>
    </Grid>
  )
}
