import { PostCode } from '../../../domain/useCase/TemporaryPhoneVerification'
import { TemporaryPhoneCodeResponse } from '../../../infrastructure/dtos/TemporaryPhoneVerification'
import { TemporaryPhoneVerificationRepositoryImpl } from './../../../infrastructure/RepositoryImpl/TemporaryPhoneVerificationRepositoryImpl'
import TemporaryPhoneVerificationMapper from '../../../infrastructure/http/mappers/TemporaryPhoneVerificationMapper'

export interface ITemporaryPhoneVerification {
  status: number
  data: TemporaryPhoneCodeResponse | string
}

export async function TemporaryPhoneVerificationService(
  user_id: string,
  number_phone: string,
): Promise<ITemporaryPhoneVerification> {
  try {
    const UseCaseRegistro: PostCode = new PostCode(
      new TemporaryPhoneVerificationRepositoryImpl(new TemporaryPhoneVerificationMapper()),
    )
    return {
      status: 1,
      data: await UseCaseRegistro.invoke(user_id, number_phone),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
