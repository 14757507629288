import { AxiosError } from 'axios'

import { IReminderResponse } from '../../dtos/Reminders'
import ReminderDataSource from '../dataSource/ReminderDataSource'

import { http } from '../Http'
export default class ReminderMapper implements ReminderDataSource {
  async getReminders(patient_id: string): Promise<IReminderResponse> {
    try {
      const { data } = await http.get<IReminderResponse>(`/send_data/reminders?patient_id=${patient_id}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: { message: (error as AxiosError).response?.statusText as string},
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
