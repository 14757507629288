import { CheckCircleOutline, Close as CloseIcon, UploadFileOutlined } from '@mui/icons-material'

import {
  BoxModalDocument,
  CloseButton,
  FadePatientDocumentModal,
  GridDocumentItem,
  InputFileStyled,
  ModalDocument,
  // TypographyDocumentSubtitle,
  TypographyDocumentTitle,
} from './styles'
import { useDocuments } from './useDocuments'
import { BackdropWrapper, GridWrapper, TypographyWrapper } from '../../../../../../../components'
import SubmitButton from '../../../../../../../components/SubmitButton'

interface DocumentsModalComponentProps {
  handleOpenDocumentsModal: (value: boolean) => void
  handleSendDocuments: () => void
  open: boolean
}

export const DocumentsModal = ({
  handleOpenDocumentsModal,
  handleSendDocuments,
  open,
}: DocumentsModalComponentProps): JSX.Element => {
  const { documents, isExtraSmallSize, handleChangeDocuments } = useDocuments()

  return (
    <ModalDocument
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-documents"
      open={open}
      onClose={() => handleOpenDocumentsModal(false)}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadePatientDocumentModal in={open}>
        <BoxModalDocument>
          <GridWrapper
            width={'100%'}
            height={'auto'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <CloseButton onClick={() => handleOpenDocumentsModal(false)} data-testid="close-button">
              <CloseIcon />
            </CloseButton>
            <TypographyDocumentTitle>
              Adjuntar documentos para reembolso
            </TypographyDocumentTitle>
            {/* <TypographyDocumentSubtitle sx={{ fontSize: '12px', mt: 1 }}>
              Seleccione los documentos solicitados o arrástrelos al item respectivo
            </TypographyDocumentSubtitle> */}
          </GridWrapper>
          <GridWrapper my={2} display={'flex'} flexDirection={'column'} gap={'20px'}>
            {/* Area de carga de archivo */}
            {documents.map((document, index) => (
              <GridDocumentItem key={index}>
                {document.isLoaded
                  ? <CheckCircleOutline sx={{ color: 'lightgreen' }}/>
                  : <UploadFileOutlined sx={{ color: 'primary.main' }}/>
                }
                  <InputFileStyled
                    data-testid={`input-file-${index}`}
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => handleChangeDocuments(e.target.files ? e.target.files[0] : null, index)}
                  />
                <TypographyWrapper ml={2} fontWeight={'bold'}>
                  {document.name}
                </TypographyWrapper>
              </GridDocumentItem>
            ))}
            {/* Fin Area de carga de archivo */}
          </GridWrapper>
          <GridWrapper display="flex" justifyContent="center" marginTop={3}>
            <SubmitButton
              id={''}
              variant={'contained'}
              data-testid="print-button"
              type={'button'}
              text={'Enviar documentos'}
              disabled={documents.some((d) => !d.isLoaded)}
              onClick={handleSendDocuments}
              sx={{
                width: isExtraSmallSize ? '100%' : '50%',
                margin: '0 20px',
              }}
            />
          </GridWrapper>
        </BoxModalDocument>
      </FadePatientDocumentModal>
    </ModalDocument>
  )
}
