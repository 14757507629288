import { styled } from '@mui/material/styles'

import { GridWrapper } from '../wrapper'
export const GridGeneric = styled(GridWrapper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw', // Utiliza el 100% del ancho de la ventana
  height: '100vh', // Utiliza el 100% de la altura de la ventana
}))
