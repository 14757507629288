import { SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material'
import { useContext, useEffect, useState, JSX } from 'react'

import { MapLink, SelectOffice, TypographyWrapperLabel } from './OfficeListStyle'
import { GridWrapper, MenuItemWrapper } from '../../../../components'
import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'
import { PatientAppointmentContext } from '../../../../contexts/PatientAppointmentContext'
import { IOffice } from '../../../../infrastructure/dtos/Offices'
import { getOfficesUtility } from '../../../../services/Contracts/Utility/OfficesUtility'

interface OfficeListProps {
  handleChangeOffice: (event: SelectChangeEvent<unknown>) => void
  office: string
  idDoctor: string
  rol: string
  idSpecialty?: string
  branchId?: string
  doctorCM?: string
}

export const OfficeList = ({
  handleChangeOffice, office, idDoctor, idSpecialty, branchId, rol, doctorCM
}: OfficeListProps): JSX.Element => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [list, setList] = useState<IOffice[]>([])
  const [selected, setSelected] = useState<IOffice>()
  const { PatientAppointment, saveScheduleSelected } = useContext(PatientAppointmentContext)

  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const fetchOffices = async (): Promise<void> => {
    const id: string = rol === 'DOCTOR_CM' ? doctorCM as string : idDoctor
    try {
      const { data, status } = await getOfficesUtility({ idDoctor: id, rol, branchId, idSpecialty })
      if (status) {
        setList(data.data)
      }
    } catch (error) {
      handleAlert(true, 'Error al obtener las oficinas', 'error')
    }
  }

  useEffect(() => {
    fetchOffices()
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }, [])

  useEffect(() => {
    saveScheduleSelected({
      ...PatientAppointment,
      office: selected as IOffice,
    })
  }, [selected])

  const onClickMap = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    const url = `https://www.google.com/maps/place/${selected?.coordinates.latitude},${selected?.coordinates.longitude}`
    window.open(url, '_blank')
  }

  return (
    <>
      <TypographyWrapperLabel
        sx={{
          marginLeft: '3px',
          fontWeight: 'normal',
        }}
      >
        Selecciona el lugar de atención
      </TypographyWrapperLabel>
      <GridWrapper container>
        <GridWrapper item xs={isExtraSmallSize ? 12 : 11}>
          <SelectOffice
            name="SelectOffice"
            labelId="selectInputLabel"
            data-testid="select-office"
            id="SelectOffice"
            variant='outlined'
            value={office}
            onChange={(event: SelectChangeEvent<unknown>) => {
              handleChangeOffice(event)
              const selectedOffice = list.find((item) => item.office_id === event.target.value)
              setSelected(selectedOffice)
            }}
            defaultValue={''}
          >
            {list?.length > 0 &&
              list?.map((item: IOffice, index) => (
                <MenuItemWrapper key={index} defaultValue={office} value={item.office_id}>
                  {item.reference_medical_office}
                </MenuItemWrapper>
              ))}
          </SelectOffice>
        </GridWrapper>
        <GridWrapper item sx={{ marginTop: '15px' }} xs={12}>
          {selected && (
            <MapLink
              rel="stylesheet"
              href=""
              onClick={(event) => {
                onClickMap(event)
              }}
            >
              ¿Cómo llegar a esta dirección?
            </MapLink>
          )}
        </GridWrapper>
      </GridWrapper>
    </>
  )
}
