import { INewsResponse } from '../../infrastructure/dtos/News';
import NewsRepository from '../repository/NewsRepository';

export interface NewsUseCase {
  invoke: () => Promise<INewsResponse>;
}

export class News implements NewsUseCase {
  private objectUseCase: NewsRepository;

  constructor(_objectUseCase: NewsRepository) {
    this.objectUseCase = _objectUseCase;
  }

  async invoke(): Promise<INewsResponse> {
    return this.objectUseCase.getNews();
  }
}
