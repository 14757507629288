import { ErrorBody, INewsResponse, INewsService, INewsUtility } from '../../../infrastructure/dtos/News'
import { getNewsService } from '../Persistencia/NewsService'

export async function getNews(): Promise<INewsUtility> {
  try {
    const response: INewsService = await getNewsService()

    return {
      status: (response.data as INewsResponse).statusCode === 200 ? 1 : 0,
      data: (response.data as INewsResponse).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as ErrorBody).message,
    }
  }
}
