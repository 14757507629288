import { validateInput } from './validateInput'

export function validateField(field: string, focus: boolean): boolean {
  return !validateInput(field) && field.length === 0 && focus
}

export function getValidationMessage(isInvalid: boolean): string {
  return isInvalid ? 'Campo requerido.' : ''
}

export const validateId = (
  idType: string,
  validateIdNumberPassport: boolean,
  validateIdNumberCI: boolean
): string => {
  return idType === 'Pasaporte'
    ? validateIdNumberPassport
      ? 'El pasaporte debe contener al menos 3 caracteres.'
      : ''
    : validateIdNumberCI
    ? 'Debe tener 10 caracteres numéricos'
    : ''
}
