import { styled } from '@mui/material'

import { TypographyWrapper } from '../../../components/wrapper'
import CardWrapper from '../../../components/wrapper/CardWrapper'

export const CardNews = styled(CardWrapper)(() => ({
  borderRadius: '20px',
  margin: '15px 0',
  display: 'flex',
  width: '100%',
  border: 'none'
}))

export const BoxLabel = styled(TypographyWrapper)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  margin: '0.6860rem 0 0.5rem 0'
}))
