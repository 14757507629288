import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, useMediaQuery, useTheme } from '@mui/material'
import { useJsApiLoader } from '@react-google-maps/api'
import React, { useEffect, useState, JSX } from 'react'

import { GridMap } from './AddressPanelStyles'
import { GridWrapper, TypographyWrapper } from '../../../../components'

import { GridItem } from '../../../../components/GridItem/GridItem'
import { List } from '../../../../components/List/List'
import { BoxSidePanel } from '../../../../components/StyledComponents/BoxSidePanel'
import { LabelInput } from '../../../../components/StyledComponents/LabelInput'
import SubmitButton from '../../../../components/SubmitButton'
import CircularProgressWrapper from '../../../../components/wrapper/LoaderWrapper'
import { Severity } from '../../../../contexts/AuthReducer'
import { ICreateLocation } from '../../../../infrastructure/dtos/location'
import { BodyPatientInfo } from '../../../../infrastructure/dtos/Patient'
import { CreateLocationUtility, ModifiedLocationUtility } from '../../../../services/Contracts/Utility/LocationUtility'
import { GetPatientRegisterUtility } from '../../../../services/Contracts/Utility/PatientUtility'
import { useMaps } from '../../../Register/PatientMap/Hooks/useMaps'
import AutocompleteGoogleMaps from '../../../Register/PatientMap/MapComponents/AutocompleteGoogle'
import { MapsGoogle } from '../../../Register/PatientMap/MapComponents/MapsGoogle'
import { IAddressPatientAux, IPatientInfo } from '../../../Register/types'

interface IMAnageAddressProps {
  toggleDrawer(open: boolean): void
  patient: IPatientInfo
  handleAlert: (open: boolean, message: string, severity?: Severity) => void
  handleUpdateAddress: (addresses: IAddressPatientAux[]) => void
  itemIndex: number
}

declare type Library =
  | 'core'
  | 'maps'
  | 'places'
  | 'geocoding'
  | 'routes'
  | 'marker'
  | 'geometry'
  | 'elevation'
  | 'streetView'
  | 'journeySharing'
  | 'drawing'
  | 'visualization'

const libraries: Library[] = ['marker', 'places']

export const AddressPanel = ({
  toggleDrawer,
  handleAlert,
  handleUpdateAddress,
  patient,
  itemIndex,
}: IMAnageAddressProps): JSX.Element => {
  const [location] = useState<IAddressPatientAux>(patient.addresses[itemIndex])
  const [addressPatient, setAddressPatient] = useState<IAddressPatientAux[]>([])
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))
  const {
    zoom,
    markerPosition,
    address,
    selected,
    setAddress,
    setMapCenter,
    setMarkerPosition,
    setSelected,
    setZoom,
    mapCenter,
    nameLocation,
    setNameLocation,
    optionsList,
    value,
    setValue,
    setOptionsList,
  } = useMaps({ addressPatient, setAddressPatient })

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries: libraries,
  })

  useEffect(() => {
    if (location && isLoaded) {
      setAddress(location?.address)
      setValue({
        description: location?.address,
        structured_formatting: {
          main_text: '',
          secondary_text: '',
        },
      })
      setMarkerPosition(
        new google.maps.LatLng(Number(location?.coordinates.latitude), Number(location?.coordinates.longitude)),
      )
      setSelected(true)
    }
  }, [isLoaded])

  useEffect(() => {
    if (selected) {
      setMapCenter({
        lat: markerPosition?.lat() as number,
        lng: markerPosition?.lng() as number,
      })
      setZoom(12)
    }
  }, [selected])

  const [map, setMap] = useState<google.maps.Map | null>(null)

  useEffect(() => {
    const newOptions: string[] = optionsList
    let homeIndex: number, officeIndex: number, otherIndex: number
    if (isHomeSelected) {
      homeIndex = optionsList.indexOf('Casa')
      if (homeIndex >= 0) {
        newOptions.splice(homeIndex, 1)
      }
    }
    if (isOfficeSelected) {
      officeIndex = optionsList.indexOf('Oficina')
      if (officeIndex >= 0) {
        newOptions.splice(officeIndex, 1)
      }
    }
    if (isOthersSelected) {
      otherIndex = optionsList.indexOf('Otra')
      if (otherIndex >= 0) {
        newOptions.splice(otherIndex, 1)
      }
    }

    setOptionsList(newOptions)
  }, [patient])

  useEffect(() => {
    const newOptions: string[] = optionsList
    if (itemIndex >= 0) {
      if ((location.name === 'Otra' && !optionsList.includes('Otra')) || location.name !== 'Otra') {
        newOptions.push(location.name)
      }
      setOptionsList(newOptions)
      setNameLocation(location.name)
    }
  }, [location])

  const isHomeSelected: boolean = patient.addresses.some((a) => a.name === 'Casa')
  const isOfficeSelected: boolean = patient.addresses.some((a) => a.name === 'Oficina')
  const isOthersSelected: boolean = patient.addresses.filter((a) => a.name === 'Otra').length >= 3

  const isSubmitDisabled: boolean =
    (itemIndex >= 0 &&
      location.name === nameLocation &&
      location.address === value?.description &&
      location.coordinates.latitude === markerPosition?.lat().toString() &&
      location.coordinates.longitude === markerPosition?.lng().toString()) ||
    (itemIndex < 0 && isHomeSelected && isOfficeSelected && isOthersSelected) ||
    nameLocation.length === 0 ||
    !Boolean(value) ||
    loadingData

  const handleSubmit = async (): Promise<void> => {
    try {
      if (itemIndex >= 0) {
        setLoadingData(true)
        const { data, status } = await ModifiedLocationUtility({
          user_id: patient.userId as string,
          address_locations_patient: address,
          coordinates_medical_office: {
            latitude: markerPosition?.lat().toString() as string,
            longitude: markerPosition?.lng().toString() as string,
          },
          name_locations_patient: nameLocation,
          id_location: location.id_location,
        })
        if (status) {
          const { status: statusInfo, data: dataPatient } = await GetPatientRegisterUtility(patient.userId as string)
          if (statusInfo) {
            const body: BodyPatientInfo = dataPatient as BodyPatientInfo
            handleUpdateAddress(
              body.locations_patient
                ?.map((address) => ({
                  id_location: address.id_location,
                  address: address.address_locations_patient,
                  name: address.name_locations_patient,
                  coordinates: address.coordinates_medical_office,
                }))
                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
            )
          }
          setLoadingData(false)
          toggleDrawer(false)
        }
        handleAlert(true, data, status ? 'success' : 'error')
      } else {
        setLoadingData(true)
        const params: ICreateLocation = {
          user_id: patient.userId as string,
          address_locations_patient: address,
          coordinates_medical_office: {
            latitude: markerPosition?.lat().toString() as string,
            longitude: markerPosition?.lng().toString() as string,
          },
          name_locations_patient: nameLocation,
        }
        const { data, status } = await CreateLocationUtility(params)
        if (status) {
          const { status: statusInfo, data: dataPatient } = await GetPatientRegisterUtility(patient.userId as string)
          if (statusInfo) {
            const body: BodyPatientInfo = dataPatient as BodyPatientInfo
            handleUpdateAddress(
              body.locations_patient
                ?.map((address) => ({
                  id_location: address.id_location,
                  address: address.address_locations_patient,
                  name: address.name_locations_patient,
                  coordinates: address.coordinates_medical_office,
                }))
                .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
            )
          }
          setLoadingData(false)
          toggleDrawer(false)
        }
        handleAlert(true, data, status ? 'success' : 'error')
      }
    } catch (error: unknown) {
      setLoadingData(false)
      handleAlert(true, 'Error al actualizar dirección', 'error')
    }
  }

  const title: string = itemIndex >= 0 ? 'Modificar dirección' : 'Agrega una dirección'

  return (
    <BoxSidePanel
      role="presentation"
      data-testid="address-right-panel"
      sx={{
        paddingX: isExtraSmallSize ? '20px' : '30px',
      }}
    >
      <GridWrapper container spacing={4} direction={'column'} paddingX={1} mt={0}>
        <GridWrapper item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <IconButton onClick={() => toggleDrawer(false)} sx={{ position: 'absolute', left: 15 }}>
            <ClearIcon sx={{ color: 'primary.main' }} />
          </IconButton>
          <TypographyWrapper color={'primary.dark'} variant="body1" fontWeight={'bold'}>
            {title}
          </TypographyWrapper>
        </GridWrapper>
        <GridMap xs={12}>
          <MapsGoogle
            map={map}
            markerPosition={markerPosition}
            zoom={zoom}
            address={address}
            mapCenter={mapCenter}
            setMap={setMap}
            setMarkerPosition={setMarkerPosition}
            setZoom={setZoom}
            setAddress={setAddress}
            setMapCenter={setMapCenter}
            isLoaded={isLoaded}
            setSelected={setSelected}
          />
        </GridMap>
        <GridItem xs={12}>
          <AutocompleteGoogleMaps
            address={address}
            setMarkerPosition={setMarkerPosition}
            setZoom={setZoom}
            setAddress={setAddress}
            setMapCenter={setMapCenter}
            isLoaded={isLoaded}
            setSelected={setSelected}
            selected={selected}
            value={value}
            setValue={setValue}
          />
        </GridItem>
        <GridItem flexDirection={'column'} alignItems={'flex-start'}>
          <LabelInput>Nombre</LabelInput>
          <List id="nameLocation" lists={optionsList} minWidth="100%" onChange={setNameLocation} value={nameLocation} />
        </GridItem>

        <GridItem xs={12} sm={10} mb={2}>
          <SubmitButton
            id=""
            fullWidth
            text="Actualizar"
            type="button"
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            endIcon={loadingData ? <CircularProgressWrapper size={30} color="inherit" /> : null}
          />
        </GridItem>
      </GridWrapper>
    </BoxSidePanel>
  )
}
