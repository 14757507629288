import { styled } from '@mui/material/styles'

import { BoxWrapper, GridWrapper, SelectWrapper, TypographyWrapper } from '../../../components'

export const GridFormRegister = styled(GridWrapper)(() => ({
  width: '100%',
  alignItems: 'center',
  marginTop: '4rem',
  // justifyContent: 'space-around',
}))
export const TypographyWrapperValidationPhoneFalse = styled(TypographyWrapper)(() => ({
  color: 'red',
  fontSize: '0.9rem',
  fontFamily: '"Helvetica"',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  marginLeft: '14px',
  marginTop: '1px',
}))

export const SelectID = styled(SelectWrapper)((props) => ({
  width: '100%',
  borderRadius: '55px',
  backgroundColor: 'white',
  height: '3.3rem',
  boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.02)',
  '& fieldset': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    color: props.theme.palette?.tertiary?.dark,
    marginLeft: '12px',
  },
}))

export const DateBox = styled(BoxWrapper)(({ theme }) => ({
  width: '100%',
  '.MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: theme.palette.common.white,
    fontSize: '1.1rem',
    color: theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.4rem',
    boxShadow: '1px 1px 7px 7px rgba(0, 0, 0, 0.03)',
  },
  '& fieldset': { borderColor: theme.palette.common.white },
  '&::placeholder': {
    marginLeft: '1.875rem',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: theme.palette.common.white,
    },
  },
}))

export const InputsGrid = styled(GridWrapper)(() => ({
  marginTop: '2em',
  display: 'flex',
  gap: '20px',
  '@media (max-width: 900px)': {
    display: 'block',
  },
}))

export const SecondInputGrid = styled(GridWrapper)(() => ({
  '@media (max-width: 900px)': {
    marginTop: '2em',
  },
}))

export const FormRegister = styled('form')(({ theme }) => ({
  width: '82%',
  [theme.breakpoints.up('sm')]: {
    width: '55%', // Apply a width of 50% from the 'sm' breakpoint and up
  },
}))
