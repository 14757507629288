import { useState, JSX } from 'react'

import { GridLayout } from './ScheduleAppointmentStyle'
import { FillData } from './steps/FillData/FillData'
import { SelectSchedule } from './steps/SelectSchedule/SelectSchedule'

enum STEPS {
  SELECT_SCHEDULE,
  FILL_DATA,
}

export const ScheduleAppointment = (): JSX.Element => {
  const [stepper, setStepper] = useState<number>(0)

  const onNext = (): void => setStepper(STEPS.FILL_DATA)
  const onBack = (): void => setStepper(STEPS.SELECT_SCHEDULE)

  return (
    <GridLayout>
      {stepper === STEPS.SELECT_SCHEDULE && <SelectSchedule data-testid="select-schedule" onStepper={onNext} />}
      {stepper === STEPS.FILL_DATA && <FillData data-testid="fill-data" onStepper={onBack} />}
    </GridLayout>
  )
}
