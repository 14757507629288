import { AxiosError, AxiosResponse } from 'axios'

import { IResponseApi } from '../../dtos'
import {
  IApiResponseDoctorInfo,
  IRecommendedDoctors,
  ISearchDoctorParams,
  ISuggestedDoctorsParams,
} from '../../dtos/DoctorInfo'
import DoctorInfoDataSource from '../dataSource/DoctorInfoDataSource'
import { http } from '../Http'

export default class DoctorInfoMapper implements DoctorInfoDataSource {
  async getRecommendedDoctors(patient_id: string): Promise<IResponseApi<IRecommendedDoctors[] | string>> {
    try {
      const { data } = await http.get<IResponseApi<IRecommendedDoctors[] | string>>(
        `/send_data/recommended_doctors/${patient_id}`,
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async getDoctorInfo(idDoctor: string): Promise<IApiResponseDoctorInfo> {
    const data = await http
      .get(`/send_data/data_medical/${idDoctor}`)
      .then((response) => {
        const { data } = response
        return data
      })
      .catch((error) => {
        const data = {
          statusCode: error.response.status,
          body: error.response.statusText,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': '',
            'Access-Control-Allow-Credentials': '',
          },
        }
        return data
      })
    return data
  }

  async searchDoctor({ page, limit, query }: ISearchDoctorParams): Promise<IApiResponseDoctorInfo> {
    try {
      let params = `page=${page}&limit=${limit}`
      if (Boolean(query)) {
        params += `&query=${query}`
      }
      const data: AxiosResponse<IApiResponseDoctorInfo> = await http.get(`/send_data/show_doctor_map?${params}`)
      return data.data
    } catch (error: unknown) {
      const data: IApiResponseDoctorInfo = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
      return data
    }
  }

  async getSuggestedDoctors({ userId, officeId, specialty }: ISuggestedDoctorsParams): Promise<IApiResponseDoctorInfo> {
    try {
      const user_id = JSON.parse(localStorage.getItem('userData') as string)?.userId
      const queryString = `user_id=${userId}&office_id=${officeId}&specialty=${specialty || ''} &patient_id=${user_id}`
      const data: AxiosResponse<IApiResponseDoctorInfo> = await http.get(`/send_data/suggested_doctors?${queryString}`)
      return data.data
    } catch (error: unknown) {
      const data: IApiResponseDoctorInfo = {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
      return data
    }
  }
}
