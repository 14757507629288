import { styled } from '@mui/material/styles'

import { ButtonWrapper, BoxWrapper } from '../../../components'

export const BoxPendingsStyled = styled(BoxWrapper)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '70px',
  background: 'white',
  borderRadius: '21px',
  padding: '20px 30px',
  gridColumn: 'span 12'
}))

export const ButtonPendingsStyled = styled(ButtonWrapper)(() => ({
  borderRadius: '2rem',
  padding: '3px 15px',
  minWidth: '130px',
  height: '40px',
  textTransform: 'none'
}))
