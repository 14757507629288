import { useState } from 'react'

import { IAddressPatientAux, IPatientForm, IPatientInfo } from '../types'

interface IUsePatient {
  patient: IPatientInfo
  setPatientInfo: (patientInfo: IPatientForm) => void
  setPatientAddresses: (addresses: IAddressPatientAux[]) => void
}

const DocumentType: { [key: string]: string } = {
  Pasaporte: 'PASSPORT',
  Cédula: 'CI',
}

export const usePatient = (): IUsePatient => {
  const [patient, setPatient] = useState<IPatientInfo>({
    name: '',
    lastName: '',
    mobile: '',
    idNumber: '',
    addresses: [],
    birthdate: '',
    gender: '',
    photo_patient: '',
    document_type: '',
  })

  const setPatientInfo = (patientInfo: IPatientForm): void => {
    setPatient({
      ...patient,
      name: patientInfo.name,
      lastName: patientInfo.lastName,
      mobile: patientInfo.mobile,
      idNumber: patientInfo.idNumber,
      birthdate: patientInfo.birthdate,
      gender: patientInfo.gender,
      photo_patient: patientInfo.photo,
      document_type: DocumentType[patientInfo.document_type],
    })
  }

  const setPatientAddresses = (addresses: IAddressPatientAux[]): void => {
    setPatient({
      ...patient,
      addresses,
    })
  }

  return {
    patient,
    setPatientInfo,
    setPatientAddresses,
  }
}
