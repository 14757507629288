import { styled } from '@mui/material'

import { AvatarWrapper, MenuItemWrapper } from '../../../../../components/wrapper'

export const AvatarNextDate = styled(AvatarWrapper)(() => ({
  width: '50px',
  height: '50px',
}))

export const OptionMenu = styled(MenuItemWrapper)(() => ({
  '&:not(:last-child)': {
    borderBottom: '1px solid rgba(151, 151, 151, 0.27)',
  },
}))
