import { ICodeUtility } from './PhoneVerificationUtility'
import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IVerifyTemporaryPhoneParams,
} from '../../../infrastructure/dtos/PhoneVerification'
import {
  ResendTemporaryPhoneCode,
  ResendTemporaryPhoneCodeResponse,
  TemporaryPhoneCode,
  TemporaryPhoneCodeResponse,
} from '../../../infrastructure/dtos/TemporaryPhoneVerification'
import {
  IResendPhoneVerification,
  ResendPhoneVerificationService,
} from '../Persistencia/ResendPhoneVerificationService'
import {
  ITemporaryPhoneVerification,
  TemporaryPhoneVerificationService,
} from '../Persistencia/TemporaryPhoneVerificationService'
import { verifyTemporaryPhoneService } from '../Persistencia/VerifyTemporaryPhoneService'

export interface ITemporaryPhoneVerificationUtility {
  data: string | TemporaryPhoneCode
  status: number
}

export interface IResendPhoneVerificationUtility {
  data: string | ResendTemporaryPhoneCode
  status: number
}

export async function TemporaryPhoneVerificationUtility(
  user_id: string,
  number_phone: string,
): Promise<ITemporaryPhoneVerificationUtility> {
  try {
    const { data, status }: ITemporaryPhoneVerification = await TemporaryPhoneVerificationService(user_id, number_phone)
    return {
      data: (data as TemporaryPhoneCodeResponse).body,
      status: (data as TemporaryPhoneCodeResponse).statusCode === 200 && status ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function ResendPhoneVerificationUtility(user_id: string): Promise<IResendPhoneVerificationUtility> {
  try {
    const { data, status }: IResendPhoneVerification = await ResendPhoneVerificationService(user_id)
    return {
      data: (data as ResendTemporaryPhoneCodeResponse).body,
      status: (data as ResendTemporaryPhoneCodeResponse).statusCode === 200 && status ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function verifyTemporaryPhone(
  params: IVerifyTemporaryPhoneParams,
): Promise<ICodeUtility<IPhoneVerification>> {
  try {
    const { data, status } = await verifyTemporaryPhoneService(params)
    return {
      data: (data as IApiResponsePhoneVerification<IPhoneVerification>).body,
      status:
        (data as IApiResponsePhoneVerification<IPhoneVerification>).statusCode === 200 && status === 1 ? status : 0,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
