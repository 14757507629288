import CloseIcon from '@mui/icons-material/Close'
import { Backdrop } from '@mui/material'
import React, { SyntheticEvent, JSX } from 'react'

import { useQualifyDoctor } from './hook/useQualifyDoctor'
import {
  CloseButton,
  FadeModal,
  TypographyModal,
  TypographyTitleModal,
  BoxModal,
  GridRating,
  TypographyText,
  TextAreaField,
  ModaQualifyDoctor,
} from './styles'
import { CheckboxSquare } from '../../../../../components/CheckboxSquare/CheckboxSquare'
import SubmitButton from '../../../../../components/SubmitButton'
import { GridWrapper } from '../../../../../components/wrapper'

import RatingWrapper from '../../../../../components/wrapper/RatingWrapper'
import { IAppointmentQualification } from '../../../../../infrastructure/dtos/PendingTasks'

interface ModalProps {
  handleClose: () => void
  open: boolean
  pending: IAppointmentQualification | undefined
  fetchPendingsTasks: (userId: string) => Promise<void>
}

function QualifyDoctorModal({ handleClose, open, pending, fetchPendingsTasks }: ModalProps): JSX.Element {
  const {
    handleAnsweredQuestions,
    handleSubmit,
    item,
    setAnonymous,
    setComments,
    setRating,
    rating,
    answeredQuestions,
    comments,
    anonymous,
  } = useQualifyDoctor(handleClose, pending, fetchPendingsTasks)

  return (
    <ModaQualifyDoctor
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-qualify-doctor"
      onClose={handleClose}
      open={open}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal>
          <GridWrapper sx={{ padding: '10px 30px' }}>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <TypographyTitleModal>{`Gana ${item?.points_to_win} puntos por calificar`}</TypographyTitleModal>
            <form onSubmit={handleSubmit}>
              <GridWrapper>
                <TypographyModal>
                  {`¿En general, qué tanto recomendarías al Dr. ${pending?.last_name_doctor}?`}
                </TypographyModal>
                <GridRating>
                  <RatingWrapper
                    name="simple-controlled"
                    value={rating}
                    max={Number(item?.recommendation_scale) || 0}
                    onChange={(event: SyntheticEvent, newRating: number | null) => {
                      setRating(newRating as number)
                    }}
                    sx={{ color: 'primary.dark', fontSize: '40px' }}
                  />
                </GridRating>
                {item?.questions.map((question, index) => (
                  <GridWrapper sx={{ margin: '10px 0' }} key={question.question_id}>
                    <TypographyText>{question.question}</TypographyText>
                    <GridRating>
                      <RatingWrapper
                        name="simple-controlled"
                        value={answeredQuestions[index].qualification}
                        max={Number(item?.recommendation_scale) || 0}
                        onChange={(event: SyntheticEvent, newRating: number | null) => {
                          handleAnsweredQuestions(newRating as number, index)
                        }}
                        sx={{ color: 'primary.dark', fontSize: '40px' }}
                      />
                    </GridRating>
                  </GridWrapper>
                ))}

                <GridWrapper>
                  <TypographyText>
                    {`Déjanos tus comentarios ¿Hay algo en lo que el`}
                    <br />
                    {`Dr. ${pending?.last_name_doctor} pueda mejorar?`}
                  </TypographyText>
                  <GridWrapper sx={{ margin: '10px 0' }}>
                    <TextAreaField
                      multiline
                      rows={4}
                      fullWidth
                      sx={{
                        display: 'flex',
                        justifyItems: 'center',
                        '& .MuiInputBase-root': {
                          height: '4.5rem',
                          fontSize: '15px',
                        },
                      }}
                      value={comments}
                      inputProps={{
                        'aria-label': 'comments',
                        maxLength: 151,
                      }}
                      error={comments.length > 150}
                      onChange={(event) => setComments(event.target.value)}
                      helperText={comments.length > 150 ? 'Máximo número de caracteres 150' : ''}
                      id="comments-test"
                    />
                  </GridWrapper>
                </GridWrapper>
                <GridWrapper>
                  <CheckboxSquare
                    text="Deseo que mi calificación sea anónima"
                    id="checkboxSesion"
                    value={anonymous}
                    onChange={setAnonymous}
                  />
                </GridWrapper>
                <GridWrapper sx={{ margin: '10px auto' }}>
                  <SubmitButton
                    fullWidth
                    id="button-qualification"
                    text="Calificar"
                    type="submit"
                    variant="contained"
                    data-testid="button-qualification-submit"
                    disabled={!rating || answeredQuestions.some((x) => !x.qualification) || comments.length > 150}
                  />
                </GridWrapper>
              </GridWrapper>
            </form>
          </GridWrapper>
        </BoxModal>
      </FadeModal>
    </ModaQualifyDoctor>
  )
}

export default QualifyDoctorModal
