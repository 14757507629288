import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import React, { JSX } from 'react'

import { Severity } from '../../contexts/AuthReducer'
import { SnackbarWrapper } from '../wrapper'
import AlertWrapper from '../wrapper/AlertWrapper'

interface ICustomSnackbarProps {
  openAlert: boolean
  message: string
  severity?: Severity
  handleAlert(open: boolean, message: string, severity?: Severity): void
}
export default function CustomSnackbar({
  openAlert,
  message,
  severity = 'info',
  handleAlert,
}: ICustomSnackbarProps): JSX.Element {
  const handleClose = (): void => {
    handleAlert(false, '')
  }

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <SnackbarWrapper
      open={openAlert}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      data-testid="snackbar-message"
      sx={{ top: { xs: 130 }, left: { xs: 130 } }}
    >
      <AlertWrapper
        action={action}
        severity={severity}
        data-testid="alert-message"
      >
        {message}
      </AlertWrapper>
    </SnackbarWrapper>
  )
}
