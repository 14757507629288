import { AxiosError, AxiosResponse } from 'axios'

import { TemporaryPhoneCodeResponse } from '../../dtos/TemporaryPhoneVerification'
import TemporaryPhoneVerificationDataSource from '../dataSource/TemporaryPhoneVerificationDataSource'
import { http } from '../Http'

export default class TemporaryPhoneVerificationMapper
  implements TemporaryPhoneVerificationDataSource
{
  async postCode(
    user_id: string,
    number_phone: string
  ): Promise<TemporaryPhoneCodeResponse> {
    const data = await http
      .post<TemporaryPhoneCodeResponse>(
        `save_data/temporary_phone_verification_code`,
        { user_id, number_phone }
      )
      .then((response: AxiosResponse<TemporaryPhoneCodeResponse>) => {
        const { data } = response
        return data
      })
      .catch((error: AxiosError) => {
        const data: TemporaryPhoneCodeResponse = {
          statusCode: error.response?.status as number,
          body: error.response?.statusText as string,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }
        return data
      })
    return data
  }
}
