import { GetDoctorFeedback } from '../../../domain/useCase/DoctorFeedbackUseCase'
import { IResponseApi, IResponseService } from '../../../infrastructure/dtos'
import { IResponseDoctorFeedback } from '../../../infrastructure/dtos/DoctorFeedback'
import DoctorFeedbackMapper from '../../../infrastructure/http/mappers/DoctorFeedbackMapper'
import { DoctorFeedbackRepositoryImpl } from '../../../infrastructure/RepositoryImpl/DoctorFeedbackRepositoryImpl'

export async function DoctorFeedbackService(
  doctorId: string,
): Promise<IResponseService<IResponseApi<IResponseDoctorFeedback>>> {
  try {
    const UseCaseDoctorFeedback = new GetDoctorFeedback(new DoctorFeedbackRepositoryImpl(new DoctorFeedbackMapper()))
    return {
      status: 1,
      data: await UseCaseDoctorFeedback.invoke(doctorId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
