import {
  ReschedulingAppointment,
  ResponseReschedulingAppointment
} from '../../infrastructure/dtos/ReschedulingAppointment'
import { ReschedulingAppointmentRepository } from '../repository/ReschedulingAppointmentRepository'

export interface ReschedulingAppointmentUseCase {
  invoke: (
    datadataReschedulingAppointment: ReschedulingAppointment
  ) => Promise<ResponseReschedulingAppointment>
}

export class RescheduleAppointment implements ReschedulingAppointmentUseCase {
  private objectUseCase: ReschedulingAppointmentRepository

  constructor(_objectUseCase: ReschedulingAppointmentRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(datadataReschedulingAppointment: ReschedulingAppointment): Promise<ResponseReschedulingAppointment> {
    return this.objectUseCase.saveReschedulingAppointment(
      datadataReschedulingAppointment
    )
  }
}
