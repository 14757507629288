import { ButtonProps } from '@mui/material'
import { JSX } from 'react'

import { PrimaryButton } from '../StyledComponents/PrimaryButton'

interface Props {
  text: string
}

type SubmitButtonProps = ButtonProps & Props

const SubmitButton = (props: SubmitButtonProps): JSX.Element => {
  const { text } = props
  return <PrimaryButton {...props}>{text}</PrimaryButton>
}

export default SubmitButton
