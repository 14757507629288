import {
  ReschedulingAppointment,
  ResponseReschedulingAppointment,
} from '../../../infrastructure/dtos/ReschedulingAppointment'
import {
  IReschedulingAppointmentService,
  ReschedulingAppointmentService,
} from '../Persistencia/ReschedulingAppointmentService'

export interface IReschedulingAppointmentUtility {
  data: string
  status: number
}

export async function saveReschedulingAppointmentUtility(
  dataReschedulingAppointment: ReschedulingAppointment,
): Promise<IReschedulingAppointmentUtility> {
  try {
    const { data, status }: IReschedulingAppointmentService =
      await ReschedulingAppointmentService(dataReschedulingAppointment)
    return {
      status: (data as ResponseReschedulingAppointment).statusCode === 200 && status ? status : 0,
      data: (data as ResponseReschedulingAppointment).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
