import { AxiosError } from 'axios'

import { IApiDaysAvailability } from '../../dtos/OfficeDaysAvailability'
import { IApiMonthAvailability } from '../../dtos/OfficeMonthAvailability'
import { IApiResponseOffices, IOfficesProps } from '../../dtos/Offices'
import OfficesDataSource from '../dataSource/OfficesDataSource'
import { http } from '../Http'

export default class OfficesMapper implements OfficesDataSource {
  async getDaysAvailability(
    idOffice: string,
    idDoctor: string,
    officeType: string,
    date: string,
  ): Promise<IApiDaysAvailability> {
    try {
      const { data } = await http.get(`send_data/availability_day_for_patient/${idOffice}/${idDoctor}/${officeType}/${date}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string, data: undefined
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }

  async getMonthAvailability(idOffice: string, idDoctor: string, officeType: string): Promise<IApiMonthAvailability> {
    try {
      const { data } = await http.get(`send_data/availability_month_for_patient/${idOffice}/${idDoctor}/${officeType}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string, data: {}
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }      
    }
  }

  async getOffices({ idDoctor, rol, branchId, idSpecialty }: IOfficesProps): Promise<IApiResponseOffices> {
    try {
      const { data } = await http.get<IApiResponseOffices>(
        `send_data/offices_appointment_patient/${rol}/${idDoctor}?id_speciality=${idSpecialty}&id_branch=${branchId}`
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: { data: [], message: (error as AxiosError)?.response?.statusText as string },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
