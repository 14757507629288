import { useMediaQuery, useTheme } from '@mui/material'
import { useContext, JSX } from 'react'
import { Outlet } from 'react-router-dom'

import { AppToolbar } from './AppLayoutStyles'
import DateButton from './components/DateButton'
import NavBar from './components/NavBar'
import NavBarButton from './components/NavBarButton/NavBarButton'
import { ProfilePicture } from './components/ProfilePicture'
import SessionOptionsButton from './components/SignOutButton'
import { Logo } from '../../components/Logo/Logo'
import { AppBarWrapper, BoxWrapper, CssBaselineWrapper, GridWrapper, StackWrapper } from '../../components/wrapper'
import { AccountContext, AuthContextType } from '../../contexts/AccountContext'
import { getUrlPhoto } from '../../utils/getPhoto'

const boxHeigth = 110

function AppLayout(): JSX.Element {
  const { patient } = useContext(AccountContext) as AuthContextType

  const theme = useTheme()

  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))
  return (
    <BoxWrapper>
      <CssBaselineWrapper />
      <AppBarWrapper position="fixed" sx={{ zIndex: 2000 }}>
        <AppToolbar data-testid="app-toolbar">
          <Logo />
          {!isExtraSmallSize && (
            <StackWrapper spacing={2} direction="row" sx={{ marginLeft: 'auto' }}>
              <NavBar />
            </StackWrapper>
          )}
          <StackWrapper spacing={2} direction="row" sx={{ marginLeft: isExtraSmallSize ? '7%' : 'auto' }}>
            {!isExtraSmallSize && (
              <>
                <DateButton />
                <ProfilePicture
                  name={`${patient.name} ${patient.lastName}`}
                  imageUrl={getUrlPhoto(patient.photo_patient || '')}
                  isExtraSmallSize={isExtraSmallSize}
                />
              </>
            )}
            <SessionOptionsButton />
          </StackWrapper>
        </AppToolbar>
      </AppBarWrapper>

      {isExtraSmallSize && <NavBarButton />}

      <GridWrapper sx={{ mt: `${boxHeigth}px` }}>
        <Outlet />
      </GridWrapper>
    </BoxWrapper>
  )
}

export default AppLayout
