import React, { JSX } from 'react'

import { GridGeneric } from './CheckboxSquareStyled'
import { Checkbox } from '../StyledComponents/Checkbox'
import { SquareCheckbox } from '../StyledComponents/SquareCheckbox'

interface ICheckboxSquareProps {
  id: string
  text: string
  value: boolean
  onChange: (value: React.SetStateAction<boolean>) => void
}

export const CheckboxSquare = ({ text, id, value, onChange }: ICheckboxSquareProps): JSX.Element => {
  return (
    <GridGeneric item xs={12} data-testid="grid-generic">
      <SquareCheckbox
        control={
          <Checkbox
            id={id}
            value={value}
            onChange={(event) => onChange(event.target.checked)}
            data-testid="checkbox"
          />
        }
        data-testid="square-checkbox"
        label={text}
      />
    </GridGeneric>
  )
}
