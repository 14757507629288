import {IAPIResponseAutocompleteSuggestions} from '../../../infrastructure/dtos/Search';
import {SearchRepository} from '../../repository/SearchRepository';


export interface GetAutocompleteSuggestionsUseCase {
  invoke(query: string): Promise<IAPIResponseAutocompleteSuggestions>;
}

export class GetAutocompleteSuggestions implements GetAutocompleteSuggestionsUseCase {
  private objectUseCase: SearchRepository

  constructor(_objectUseCase: SearchRepository) {
    this.objectUseCase = _objectUseCase
  }

  invoke(query: string): Promise<IAPIResponseAutocompleteSuggestions> {
    return this.objectUseCase.getAutocompleteSuggestions(query)
  }
}
