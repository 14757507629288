import { styled } from '@mui/material'

import CardWrapper from '../../../../../components/wrapper/CardWrapper'

export const StyledCarouselCard = styled(CardWrapper)((props) => ({
  backgroundColor: props.theme.palette.common.white,
  borderRadius: '30px',
  boxShadow: '5px 6px 7px 0px rgba(0, 0, 0, 0.03)',
  padding: '14px 25px 10px 20px',
  margin: '10px 0 35px 0',
  width: '100%',
  maxWidth: 370,
  height: 180
}))
