import { JSX } from 'react'

import { BuildingCustomIcon, GridWrapper, HomeCustomIcon, OtherHomeCustomIcon } from '../../../../../components'

interface AddressIconsListProps {
  addressName: string
}

export function AddressIconsList({ addressName }: AddressIconsListProps): JSX.Element {
  return addressName === 'Casa' ? (
    <HomeCustomIcon />
  ) : addressName === 'Oficina' ? (
    <BuildingCustomIcon />
  ) : addressName === 'Otra' ? (
    <OtherHomeCustomIcon />
  ) : (
    <GridWrapper width={'22.39px'} height={'22.39px'} ml={'7px'} />
  )
}
