import { styled } from '@mui/material'

import {
  BoxWrapper,
  ButtonWrapper,
  SelectWrapper,
  TypographyWrapper
} from '../../components/wrapper'

export const SelectGender = styled(SelectWrapper)(({ theme }) => ({
  width: '100%',
  borderRadius: '32px',
  backgroundColor: theme.palette.common.white,
  height: '3.3rem',
  boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.02)',
  '& fieldset': { border: 'none' }
}))

export const DateBox = styled(BoxWrapper)(({ theme }) => ({
  width: '100%',
  '.MuiFormControl-root': {
    width: '100%'
  },
  '& .MuiInputBase-root': {
    borderRadius: '3.4375rem',
    backgroundColor: theme.palette.common.white,
    fontSize: '1.1rem',
    color: theme.palette?.text.primary,
    fontFamily: 'Helvetica',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    height: '3.4rem',
    '& .MuiInputBase-input': {
      paddingLeft: '30px' // Add your desired marginLeft (as paddingLeft) here
    }
  },
  '& fieldset': { borderColor: theme.palette.common.white },
  '&::placeholder': {
    marginLeft: '1.875rem'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    '& > fieldset': {
      borderColor: theme.palette.common.white
    }
  }
}))

export const LabelCard = styled(TypographyWrapper)(() => ({
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '0.6875rem'
}))

export const UpdatePhoneButton = styled(ButtonWrapper)((props) => ({
  margin: '10px 0',
  textTransform: 'none',
  borderRadius: '50px',
  backgroundColor: props.theme.palette.primary.main,
  color: props.theme.palette.background.paper,
  padding: '14px 80px',
  '&:hover': {
    backgroundColor: props.theme.palette.primary.dark
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: props.theme.palette.text.disabled
  }
}))

export const UserBox = styled(BoxWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '30px',
  padding: '20px',
  minWidth: '250px',
  maxWidth: '300px',
  height: '500px',
  marginTop: '24px',
  marginBottom: '15px',
  [theme.breakpoints.only('xs')]: {
    width: '100%',
    maxWidth: 366,
    marginTop: '0px',
    marginBottom: '25px',
    height: '380px'
  }
}))
