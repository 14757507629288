import { styled } from '@mui/material';

import { TypographyWrapper } from '../../../../../components';

export const SubTitle = styled(TypographyWrapper)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.common.black,
  fontSize: '12px',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
}))

export const SubTitleCM = styled(SubTitle)(({ theme }) => ({
  textAlign: 'left',
  letterSpacing: .7,
  [theme.breakpoints.only('xs')]: {
    textAlign: 'center',
  },
}))

export const LabelCMTextName = styled(TypographyWrapper)(({ theme }) => ({
  textAlign: 'left',
  color: theme.palette.common.black,
  fontSize: '14px',
  fontFamily: 'Helvetica',
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  [theme.breakpoints.only('xs')]: {
    textAlign: 'center',
  },
}))