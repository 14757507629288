import { JSX } from 'react'

import { StyledCarouselCard } from './CarouselCard.style'
import { BoxWrapper, CardContentWrapper, GridWrapper, TypographyWrapper } from '../../../../../components/wrapper'

type CardProps = {
  title: string
  description: string
  imageUrl: string
}

function CarouselCard({ description, imageUrl, title }: CardProps): JSX.Element {
  return (
    <StyledCarouselCard>
      <GridWrapper container>
        <GridWrapper item xs={4} sx={{ paddingTop: '16px' }}>
          <BoxWrapper
            sx={{
              width: 90,
              height: 60,
              overflow: 'hidden',
              margin: 'auto',
              borderRadius: '10px',
            }}
          >
            <img src={imageUrl} alt={title} style={{ width: '100%', height: '100%' }} />
          </BoxWrapper>
        </GridWrapper>
        <GridWrapper item xs={8}>
          <CardContentWrapper sx={{ textAlign: 'left', padding: 1 }}>
            <TypographyWrapper gutterBottom variant="h5">
              <b>{title}</b>
            </TypographyWrapper>
            <TypographyWrapper variant="h5">{description}</TypographyWrapper>
          </CardContentWrapper>
        </GridWrapper>
      </GridWrapper>
    </StyledCarouselCard>
  )
}

export default CarouselCard
