import { AxiosError } from 'axios'

import { IApiDoctorCM } from '../../dtos/DoctorCM'
import DoctorCMDataSource from '../dataSource/DoctorCMDataSource'
import { http } from '../Http'

export default class DoctorCMMapper implements DoctorCMDataSource {
  async getDoctorCM(idBranchCM: string, query: string): Promise<IApiDoctorCM> {
    try {
      const { data } = await http.get(`send_data/show_doctor_cm_map/${idBranchCM}?query=${query}`)
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: (error as AxiosError).response?.statusText as string,
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
          'Access-Control-Allow-Credentials': false,
        },
      }
    }
  }
}
