import { useEffect, useState } from 'react'

import { IDoctorCM, IDoctorCMResponse } from '../../../../../infrastructure/dtos/DoctorCM'
import { DoctorCMUtility } from '../../../../../services/Contracts/Utility/DoctorCMUtility'

interface IUseCMResultsDetails {
  loading: boolean
  doctors: IDoctorCM[]
}

export const useCMResultsDetails = (branchId: string, query: string): IUseCMResultsDetails => {
  const [loading, setLoading] = useState<boolean>(false)
  const [doctors, setDoctors] = useState<IDoctorCM[]>([])

  const fetchDoctorsOfCM = async (): Promise<void> => {
    setLoading(true)
    const { data, status } = await DoctorCMUtility(branchId, query)
    if (status) {
      setDoctors((data as IDoctorCMResponse).data)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchDoctorsOfCM()
  }, [])

  return {
    loading,
    doctors,
  }
}
