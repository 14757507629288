import { useMediaQuery, useTheme } from '@mui/material'
import { ChangeEvent, useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../../../../../../contexts/AccountContext'

import {
  confirmAppointmentUtility,
  getReasonCancellationAppointmentUtility,
} from '../../../../../../../../../services/Contracts/Utility/AppointmentsUtility'

interface IUseCancelReasons {
  optionsList: string[]
  error: boolean
  isDisabled: boolean
  isExtraSmallSize: boolean
  customOption: string
  fetchListReason: () => Promise<void>
  handleChangeOption: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string) => void
  handleConfirm: () => Promise<void>
  option: string
}

interface IAppointment {
  appointment_id: string
  name_doctor: string
  last_name_doctor: string
  doctor_id?: string
  user_id?: string
  patient_id?: string
  reschedule_id: string
}

const MAX_LENGTH = 100

export const useCancelReasons = (
  handleClose: () => void,
  appointment: IAppointment | undefined,
  fetchAppointments: (userId: string) => Promise<void>,
): IUseCancelReasons => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [optionsList, setOptionsList] = useState<string[]>([])
  const [option, setOption] = useState<string>('')
  const [customOption, setCustomOption] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const fetchListReason = async (): Promise<void> => {
    const { data, status } = await getReasonCancellationAppointmentUtility()
    if (status) {
      if (typeof data != 'string') {
        setOptionsList(data.data)
      }
    }
  }
  useEffect(() => {
    fetchListReason()
  }, [])

  const handleChangeOption = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string): void => {
    if (typeof event === 'string') {
      setOption(event)
      setCustomOption('')
    } else {
      setError(event.target.value.length > MAX_LENGTH)
      setCustomOption(event.target.value)
      setOption('')
    }
  }

  const handleConfirm = async (): Promise<void> => {
    setIsDisabled(true)
    const response = await confirmAppointmentUtility({
      action: 'cancelled',
      appointment_id: appointment?.appointment_id ?? '',
      reschedule_id: appointment?.reschedule_id ?? '',
      reason: option || customOption,
    })
    let { message = '' } = response.data as { message?: string }
    if (typeof response.data !== 'string') {
      ;({ message } = response.data)
    }
    handleAlert(true, message, 'success')
    handleClose()
    await fetchAppointments(appointment?.patient_id as string)
  }

  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  return {
    optionsList,
    error,
    isDisabled,
    isExtraSmallSize,
    customOption,
    option,
    fetchListReason,
    handleChangeOption,
    handleConfirm,
  }
}
