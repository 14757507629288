import { styled } from '@mui/material'

import { DrawerWrapper } from '../wrapper'

export const DrawerSidePanel = styled(DrawerWrapper)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '20px 0 0 20px',
    top: '105.5px',
    height: 'calc(100vh - 105.5px)',
    zIndex: 1,
    [theme.breakpoints.only('xs')]: {
      borderRadius: '62px 62px 0 0',
      top: '150px',
      bottom: '56px',
      height: 'auto',
    },
  },
}))
