import { styled } from '@mui/material/styles'

import { TypographyWrapper } from '../wrapper'

export const TextProfile = styled(TypographyWrapper)((props) => ({
  color: props.theme.palette.primary.dark,
  fontSize: '18px',
  fontFamily: 'Helvetica',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  marginBottom: '24px',
  marginTop: '24px',
  textAlign: 'center',
  [props.theme.breakpoints.only('xs')]: {
    fontSize: '15px'
  }
}))
