import { News } from '../../../domain/useCase/NewsUseCase'
import { INewsService } from '../../../infrastructure/dtos/News'
import NewsMapper from '../../../infrastructure/http/mappers/NewsMapper'
import { NewsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/NewsRepositoryImpl'

export async function getNewsService(): Promise<INewsService> {
  try {
    const useNews = new News(new NewsRepositoryImpl(new NewsMapper()))
    return {
      status: 1,
      data: await useNews.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
