import { QualificationQuestionsRepository } from '../../domain/repository/QualificationQuestionsRepository'
import { QualificationQuestionsResponse } from '../dtos/QualificationQuestions'
import QualificationQuestionsDataSource from '../http/dataSource/QualificationQuestionsDataSource'

export class QualificationQuestionsRepositoryImpl
  implements QualificationQuestionsRepository
{
  dataSource: QualificationQuestionsDataSource

  constructor(_dataSource: QualificationQuestionsDataSource) {
    this.dataSource = _dataSource
  }

  getQualificationQuestions(): Promise<QualificationQuestionsResponse> {
    return this.dataSource.getQualificationQuestions()
  }
}
