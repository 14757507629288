import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useContext, useState, JSX } from 'react'
import { useNavigate } from 'react-router-dom'

import { CancelAppointment } from './CancelAppointment/CancelAppointment'
import { CancelReasons } from './CancelAppointment/modals/CancelReasons/CancelReasons'
import { useCancelReasonsHook } from './CancelAppointment/modals/CancelReasons/useCancelReasons'
import { useCancelAppointment } from './CancelAppointment/useCancelAppointment'
import ConfirmAppointments from './ConfirmAppointments/ConfirmAppointments'
import { useConfirm } from './ConfirmAppointments/hooks/useConfirmModal'
import { useNewDatesList } from './NewDatesLitsHook'
import { AvatarNextDate, OptionMenu } from './styles'
import { FollowButton } from '../../../../../components/FollowButton/FollowButton'
import { SecondaryMediumButton } from '../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { OutLineButton } from '../../../../../components/StyledComponents/OutLineButton'
import { Title } from '../../../../../components/StyledComponents/Title'
import {
  GridWrapper,
  IconButtonWrapper,
  ListItemWrapper,
  ListWrapper,
  MenuWrapper,
  TypographyWrapper,
} from '../../../../../components/wrapper'
import { AccountContext, AuthContextType } from '../../../../../contexts/AccountContext'
import { INextAppointment } from '../../../../../infrastructure/dtos/Appointments'
import { handleClickOpenGMaps } from '../../../../../utils/openGMap'

const options: string[] = ['Reagendar', 'Cancelar']

interface IGridBody {
  value: string
  width: string
}

interface IGridHeader {
  title: string
  width: string
}

interface INextDateListProps {
  fromHome: boolean
}

function NextDatesList({ fromHome }: INextDateListProps): JSX.Element {
  const navigate = useNavigate()
  const { anchorEl, message, nextAppointments, handleClick, handleClose, fetchNextAppointments } = useNewDatesList()

  const userInfo = localStorage.getItem('userData') ?? '{}'
  const idPatient = JSON.parse(userInfo)?.userId

  const [appointmentToModal, setAppointmentToModal] = useState<INextAppointment>({} as INextAppointment)
  const { handleCloseConfirm, handleOpenConfirm, openConfirm } = useConfirm()
  const { openCancelModal, handleOpenCancelAppointment } = useCancelAppointment()
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { openCancelReasons, handleOpenCancelReasons, handleCloseCancelReasons } = useCancelReasonsHook()

  const handleClickOption = (option: string, appointment: INextAppointment, index: number): void => {
    if (appointment.assistance === 'PENDING') {
      switch (option) {
        case 'Reagendar':
          navigate(`/appointment/booking/${appointment.user_id}/${appointment.appointment_id}`)
          break
        case 'Cancelar':
          handleOpenCancelAppointment(true)
          setAppointmentToModal(appointment)
          break
        default:
          handleClose(index)
      }
    } else {
      const message = appointment.assistance === 'ATTENDED' ? 'asistida' : 'no asistida'
      handleAlert(true, `¡Lo siento! La cita ya fue marcada como ${message}.`, 'error')
    }
  }

  const handleShowCancelReasons = (): void => {
    handleOpenCancelAppointment(false)
    handleOpenCancelReasons()
  }

  const spacing_width: number = fromHome ? 7 : 4
  const date_width: number = fromHome ? 11 : 14
  const hour_width: number = fromHome ? 14 : 11
  const doctor_width: number = fromHome ? 12 : 14
  const place_width: number = fromHome ? 18 : 14
  const specialty_width: number = fromHome ? 14 : 15

  const gridHeader: IGridHeader[] = [
    { title: '', width: `${spacing_width}%` },
    { title: 'Fecha', width: `${date_width}%` },
    { title: 'Hora', width: `${hour_width}%` },
    { title: 'Doctor', width: `${doctor_width}%` },
    { title: 'Lugar', width: `${place_width}%` },
    { title: 'Especialidad', width: `${specialty_width}%` },
    { title: '', width: fromHome ? '26%' : '35%' },
  ]
  const data_date_width: number = fromHome ? 15 : 12
  const data_doctor_width: number = fromHome ? 16 : 13
  const data_place_width: number = fromHome ? 16 : 12
  const data_specialty_width: number = fromHome ? 16 : 14

  const gridBody = (item: INextAppointment): IGridBody[] => [
    {
      value: item.appointment_date.split('-').reverse().join('/'),
      width: `${data_date_width}%`,
    },
    { value: item.hour_from.split(':').join('h'), width: `${11}%` },
    {
      value: `${item.name_doctor} ${item.last_name_doctor}`,
      width: `${data_doctor_width}%`,
    },
    { value: item.reference_medical_office, width: `${data_place_width}%` },
    { value: item.speciality_doctor, width: `${data_specialty_width}%` },
  ]

  return (
    <GridWrapper pt={!fromHome ? 2 : 0}>
      <GridWrapper>
        {fromHome && Boolean(message) ? (
          ''
        ) : (
          <Title data-testid="title-next-dates" sx={{ marginBottom: '10px' }}>
            Tus próximas citas
          </Title>
        )}
        {!Boolean(message) ? (
          <ListWrapper>
            <ListItemWrapper
              style={{
                borderRadius: '40px',
                margin: '10px 0 0 0',
                paddingBottom: '0px',
                fontSize: '14px',
              }}
              data-testid="next-dates"
            >
              {gridHeader.map((item: IGridHeader, index: number) => (
                <GridWrapper item textAlign={'center'} width={item.width} key={`GridHeader-${index}`}>
                  {item.title}
                </GridWrapper>
              ))}
            </ListItemWrapper>
            {nextAppointments.map((appointment: INextAppointment, index: number) => (
              <ListItemWrapper
                key={`appointment-${appointment.appointment_id}-${index}`}
                style={{
                  minHeight: '80px',
                  background: 'white',
                  borderRadius: '32px',
                  marginBottom: '20px',
                  paddingRight: '0px',
                }}
                data-testid="next-dates-item-list"
              >
                <AvatarNextDate src={appointment.photo_doctor} />
                {gridBody(appointment).map((i: IGridBody, idx: number) => (
                  <GridWrapper
                    key={`GridBody-${appointment.appointment_id}-${idx}`}
                    item
                    textAlign={'center'}
                    width={i.width}
                    color={appointment.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}
                  >
                    {i.value}
                  </GridWrapper>
                ))}
                <GridWrapper item textAlign={'center'} width={fromHome ? '13%' : '9%'}>
                  <SecondaryMediumButton
                    id="Coming"
                    text="Cómo llegar"
                    onClick={() => handleClickOpenGMaps(appointment)}
                    type="button"
                    sx={{
                      height: '43px',

                      lineHeight: '14px',
                    }}
                    variant="contained"
                    disabled={appointment.status === 'CANCELLED' ? true : false}
                  />
                </GridWrapper>
                {!fromHome && (
                  <GridWrapper item textAlign={'center'} width={'10%'}>
                    <OutLineButton
                      data-testid="confirm-button"
                      onClick={() => {
                        setAppointmentToModal(appointment)
                        handleOpenConfirm()
                      }}
                      variant="outlined"
                      disabled={appointment.status === 'ACTIVE' ? false : true}
                    >
                      {appointment.status === 'CONFIRMED' ? 'Confirmada' : 'Confirmar'}
                    </OutLineButton>
                  </GridWrapper>
                )}
                <GridWrapper item textAlign={'center'} width={'9%'}>
                  <FollowButton
                    callbackAfterFollow={fetchNextAppointments}
                    doctorId={appointment.user_id}
                    isFollow={appointment.follow}
                    fromHome={fromHome}
                    idPatient={idPatient}
                    status={appointment.status}
                  />
                </GridWrapper>
                <GridWrapper item textAlign={'center'} width={'5%'}>
                  <IconButtonWrapper
                    key={`menu-button-${appointment.appointment_id}`}
                    aria-label="more"
                    id="long-button"
                    data-testid="long-button"
                    aria-controls={appointment?.showMenu ? 'long-menu' : undefined}
                    aria-expanded={appointment?.showMenu ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)}
                    disabled={appointment.status === 'CANCELLED' ? true : false}
                  >
                    <MoreHorizIcon
                      fontSize="large"
                      sx={{
                        color: appointment.status === 'CANCELLED' ? '#e4ecf1' : 'primary.dark',
                      }}
                    />
                  </IconButtonWrapper>
                  <MenuWrapper
                    key={`menu-${appointment.appointment_id}`}
                    id="long-menu"
                    data-testid="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(appointment?.showMenu)}
                    onClose={() => handleClose(index)}
                    slotProps={{
                      paper: {
                        style: {
                          borderRadius: '32px',
                          padding: '7px 7px',
                          boxShadow: '1px 4px 8px 6px rgba(0, 0, 0, 0.03)',
                          backgroundColor: 'white',
                        },
                      },
                    }}
                  >
                    {options.map((option: string, i: number) =>  (
                      <OptionMenu
                        key={`${option}-${i}`}
                        data-testid={`${option}`}
                        onClick={() => handleClickOption(option, appointment, index)}
                      >
                        {option}
                      </OptionMenu>
                    ))}
                  </MenuWrapper>
                </GridWrapper>
              </ListItemWrapper>
            ))}
          </ListWrapper>
        ) : (
          !fromHome && (
            <TypographyWrapper marginTop={4} marginLeft={2}>
              {message}
            </TypographyWrapper>
          )
        )}
      </GridWrapper>
      {openConfirm && (
        <ConfirmAppointments
          handleClose={handleCloseConfirm}
          open={openConfirm}
          appointment={appointmentToModal}
          fetchAppointments={fetchNextAppointments}
        />
      )}
      {openCancelModal && (
        <CancelAppointment
          handleClose={() => handleOpenCancelAppointment(false)}
          open={openCancelModal}
          handleShowCancelReasons={handleShowCancelReasons}
        />
      )}
      {openCancelReasons && (
        <CancelReasons
          handleClose={() => handleCloseCancelReasons()}
          open={openCancelReasons}
          fetchAppointments={fetchNextAppointments}
          appointment={appointmentToModal}
        />
      )}
    </GridWrapper>
  )
}

export default NextDatesList
