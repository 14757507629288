import { MouseEvent, useState } from 'react'

import { BadgeHeader, CustomAvatar } from './styles'
import { BoxQualifications } from '../../../../components/BoxQualifications/BoxQualifications'
import { Severity } from '../../../../contexts/AuthReducer'

interface DoctorInfoProps {
  photo: string
  rating: string
  doctorId?: string
  handleAlert?: (open: boolean, message: string, severity?: Severity) => void
}
``
export default function PhotoDoctor({ photo, rating, doctorId, handleAlert }: DoctorInfoProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [showQualification, setShowQualification] = useState<boolean>(false)

  const handleQualifications = (open: boolean, event?: MouseEvent<HTMLSpanElement>): void => {
    setShowQualification(open)
    setAnchorEl(open && event ? event.currentTarget : null)
  }

  const handlePhotoClick = (event: MouseEvent<HTMLDivElement>): void => event.stopPropagation()

  return (
    <>
      <BadgeHeader
        overlap="circular"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={rating}
        onClick={(event?: MouseEvent<HTMLSpanElement>) => handleQualifications(true, event)}
      >
        <CustomAvatar src={photo} onClick={handlePhotoClick} />
      </BadgeHeader>
      {showQualification && (
        <BoxQualifications
          doctorId={doctorId}
          closeQualification={() => handleQualifications(false)}
          handleAlert={handleAlert}
          popoverProps={{
            open: showQualification,
            anchorEl,
            onClose: () => handleQualifications(false),
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          }}
        />
      )}
    </>
  )
}
