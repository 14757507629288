import { GendersResponse } from '../../infrastructure/dtos/Genders'
import { GendersRepository } from '../repository/GendersRepository'

export interface GendersUseCase {
  invoke: () => Promise<GendersResponse>
}

export class GetGenders implements GendersUseCase {
  private objectUseCase: GendersRepository

  constructor(_objectUseCase: GendersRepository) {
    this.objectUseCase = _objectUseCase
  }

  async invoke(): Promise<GendersResponse> {
    return this.objectUseCase.getGenders()
  }
}
