import { styled } from '@mui/material'

import { BoxWrapper, FadeWrapper, GridWrapper, IconButtonWrapper, ModalWrapper, TypographyWrapper } from '../../../../../../../components'

export const ModalDocument = styled(ModalWrapper)(() => ({
  height: '100%',
  overflowY: 'scroll',
}))

export const FadePatientDocumentModal = styled(FadeWrapper)(({ theme }) => ({
  height: 'auto',
  [theme.breakpoints.only('xs')]: {
    width: '90%',
  },
}))

export const BoxModalDocument = styled(BoxWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '140px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '600px',
  minHeight: '400px',
  bgcolor: theme.palette.background.paper,
  background: theme.palette.common.white,
  borderRadius: '30px',
  p: 4,
  boxShadow: '2px 2px 18px 14px rgba(0, 0, 0, 0.03)',
  padding: '40px 30px',
}))

export const CloseButton = styled(IconButtonWrapper)((props) => ({
  position: 'absolute',
  right: 20,
  top: 20,
  color: props.theme.palette.primary.dark,
  marginBottom: '20px',
}))

export const TypographyDocumentTitle = styled(TypographyWrapper)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 700,
  fontStyle: 'normal',
  letterSpacing: '0px',
  color: theme.palette.primary.dark,
  marginBottom: 10,
  [theme.breakpoints.only('xs')]: {
    fontSize: '10px',
  },
}))

// export const TypographyDocumentSubtitle = styled(TypographyWrapper)(({ theme }) => ({
//   fontSize: '14px',
//   fontWeight: 400,
//   fontStyle: 'normal',
//   letterSpacing: '0px',
//   color: theme.palette.primary.dark,
//   [theme.breakpoints.only('xs')]: {
//     fontSize: '8px',
//   },
// }))

const leftSpacing = 40

export const GridDocumentItem = styled(GridWrapper)(({ theme }) => ({
  position: 'relative',
  height: 80,
  width: '100%',
  backgroundColor: theme.palette.tertiary?.light,
  borderRadius: 40,
  paddingBlock: 16,
  paddingInline: leftSpacing,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: theme.transitions.create(['box-shadow'], {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    boxShadow: '0px 0px 7px 6px rgba(0, 0, 0, 0.03)',
  }
}))

export const InputFileStyled = styled('input')(() => ({
  position: 'absolute',
  margin: -leftSpacing,
  padding: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  opacity: 0,
  cursor: 'pointer',
}))
