import { convertSpecialCharacters } from '../../utils/convertSpecialCharacters'
import AutocompleteComponent from '../Autocomplete'

interface ISearchAutocompleteProps {
  value: string
  id: string
  options: string[]
  setValue: (valueData: string) => void
  placeholder?: string
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
}

const SearchAutocomplete = ({
  value,
  id,
  options,
  placeholder,
  setValue,
  onKeyDown
}: ISearchAutocompleteProps): JSX.Element => {
  return (
    <AutocompleteComponent
      id={id}
      list={options}
      setValue={setValue}
      value={value}
      InputProps={{ placeholder, onKeyDown }}
      freeSolo
      filterOptions={(options) => options}
      renderOption={(props, option) => {
        const inputString = convertSpecialCharacters(value)
        const optionString = convertSpecialCharacters(option as string)
        if (inputString.length > 0 && optionString.includes(inputString)) {
          const position = optionString.indexOf(inputString)
          if (position >= 0) {
            const opt = option as string
            return <li {...props} key={`option-${option}`}>
              <div>
                {opt.slice(0, position)}
                  <strong>{opt.slice(position, position + inputString.length)}</strong>
                {opt.slice(position + inputString.length)}
              </div>
            </li>
          }
        }
        return <li {...props} key={`option-${option}`}>
          {option as string}
        </li>
      }}
    />
  )
}

export default SearchAutocomplete
