import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

import { IPatientActivitiesInfo } from '../../../infrastructure/dtos/PatientActivitiesInfo'
import { PatientActivitiesInfoUtility } from '../../../services/Contracts/Utility/PatientActivitiesInfoUtility'
interface IUseResumeComponentHook {
  resume: IPatientActivitiesInfo
  loadingResume: boolean
  fetchResume: (userId: string) => Promise<void>
  isExtraSmallSize: boolean
}
export function useResumeComponent(): IUseResumeComponentHook {
  const user_id = JSON.parse(localStorage.getItem('userData') as string)?.userId
  const [loadingResume, setLoadingResume] = useState<boolean>(false)
  const [resume, setResume] = useState<IPatientActivitiesInfo>({
    number_appointments: 0,
    number_qualification: 0,
    name_user: 'Usuario',
    speciality: 0,
    old_years: '0',
    doctors: 0,
    message: 'si',
  })
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const fetchResume = async (user_id: string): Promise<void> => {
    setLoadingResume(true)
    const { data, status } = await PatientActivitiesInfoUtility(user_id)
    if (status !== 0) {
      setResume(data as IPatientActivitiesInfo)
    }
    setLoadingResume(false)
  }

  useEffect(() => {
    fetchResume(user_id).then()
  }, [user_id])

  return {
    resume,
    loadingResume,
    fetchResume,
    isExtraSmallSize,
  }
}
