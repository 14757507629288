
import {
  ConfirmAppointment,
  GetAppointmentInfo,
  GetAppointmentReasonCancellation,
  NextAppointments,
  PastAppointments,
} from '../../../domain/useCase/Appointments'
import {
  IApiAppointment,
  IAppointmentInfoResponse,
  IAppointmentReasonCancellation,
  IAppointmentStatus,
  IConfirmAppointmentProps,
  INextAppointmentResponse,
  IPastAppointmentResponse,
} from '../../../infrastructure/dtos/Appointments'

import AppointmentsMapper from '../../../infrastructure/http/mappers/AppointmentsMapper'
import { AppointmentsRepositoryImpl } from '../../../infrastructure/RepositoryImpl/AppointmentsRepositoryImpl'

export interface IAppointmentService<T> {
  status: number
  data: IApiAppointment<T>
}

export interface IGetAppointmentInfoService {
  status: number
  data: IApiAppointment<IAppointmentInfoResponse | string>
}

export async function confirmAppointmentService(
  params: IConfirmAppointmentProps,
): Promise<IAppointmentService<IAppointmentStatus | string>> {
  try {
    const UseCaseAppointment = new ConfirmAppointment(new AppointmentsRepositoryImpl(new AppointmentsMapper()))
    return {
      status: 1,
      data: await UseCaseAppointment.invoke(params),
    }
  } catch (error) {
    return {
      status: 0,
      data: error as IApiAppointment<string>,
    }
  }
}

export async function getAppointmentInfoService(appointmentId: string): Promise<IGetAppointmentInfoService> {
  try {
    const UseCaseRegistro = new GetAppointmentInfo(new AppointmentsRepositoryImpl(new AppointmentsMapper()))
    return {
      status: 1,
      data: await UseCaseRegistro.invoke(appointmentId),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IApiAppointment<IAppointmentInfoResponse | string>,
    }
  }
}

export async function nextAppointmentsService(
  userId: string,
): Promise<IAppointmentService<INextAppointmentResponse | string>> {
  try {
    const UseCaseAppointment = new NextAppointments(new AppointmentsRepositoryImpl(new AppointmentsMapper()))
    return {
      status: 1,
      data: await UseCaseAppointment.invoke(userId),
    }
  } catch (error) {
    return {
      status: 0,
      data: error as IApiAppointment<string>,
    }
  }
}

export async function pastAppointmentsService(
  userId: string,
): Promise<IAppointmentService<IPastAppointmentResponse | string>> {
  try {
    const UseCaseAppointment = new PastAppointments(new AppointmentsRepositoryImpl(new AppointmentsMapper()))
    return {
      status: 1,
      data: await UseCaseAppointment.invoke(userId),
    }
  } catch (error) {
    return {
      status: 0,
      data: error as IApiAppointment<string>,
    }
  }
}

export async function getReasonCancellationAppointmentService(): Promise<
  IAppointmentService<IAppointmentReasonCancellation | string>
> {
  try {
    const UseCaseGetList = new GetAppointmentReasonCancellation(
      new AppointmentsRepositoryImpl(new AppointmentsMapper()),
    )
    return {
      status: 1,
      data: await UseCaseGetList.invoke(),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: error as IApiAppointment<string>,
    }
  }
}
