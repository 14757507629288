import { Close } from '@mui/icons-material'
import { useMediaQuery, useTheme } from '@mui/material'
import { JSX } from 'react'

import {
  BoxModal,
  DisableTextInput,
  DisabledInput,
  LabelInputTitle,
  ModalRecipe,
  Separation,
  TypographyDoctorInfo,
  TypographyLabel,
  TypographyName,
  TypographyRecipeNumber,
} from './styles'
import helthyLogo from '../../../../../../../assets/helthyLogo.png'
import { BackdropWrapper, DividerWrapper, GridWrapper, TypographyWrapper } from '../../../../../../../components'
import SubmitButton from '../../../../../../../components/SubmitButton'
import { IPrescriptionItem, IRecipePrescription } from '../../../../../../../infrastructure/dtos/Prescription'
import { CloseButton, FadePatientRecipeModal } from '../ShowRecipeList/ShowRecipesListStyle'

interface PatientRecipeModalProps {
  handleOpenRecipe: (value: boolean) => void
  open: boolean
  prescriptionIndex: number
  appointmentDetails: IRecipePrescription
}

function Recipe({
  handleOpenRecipe,
  open,
  prescriptionIndex,
  appointmentDetails,
}: PatientRecipeModalProps): JSX.Element {
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <ModalRecipe
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-recipe"
      open={open}
      onClose={() => handleOpenRecipe(false)}
      closeAfterTransition
      slots={{ backdrop: BackdropWrapper }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadePatientRecipeModal in={open}>
        <BoxModal>
          <GridWrapper>
            <GridWrapper
              width={'100%'}
              height={'auto'}
              display={'flex'}
              container
              marginBottom={2}
              sx={{ color: 'primary.dark' }}
            >
              <CloseButton onClick={() => handleOpenRecipe(false)} data-testid="close-button">
                <Close />
              </CloseButton>
              <GridWrapper item xs={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <img src={helthyLogo} alt="Helthy logo" width={'220'} height={'auto'}/>
              </GridWrapper>
              <GridWrapper item xs={8} data-testid="diagnostic-header">
                <TypographyRecipeNumber>
                  Receta No. {appointmentDetails?.prescription[prescriptionIndex]?.prescription_no?.padStart(6, '0')}
                </TypographyRecipeNumber>
                <TypographyName variant="h6">
                  Dr. {`${appointmentDetails?.doctor?.name_doctor} ${appointmentDetails?.doctor?.last_name_doctor}`}
                </TypographyName>
                <TypographyLabel>{appointmentDetails?.doctor?.speciality_doctor}</TypographyLabel>
                <form data-testid="code-access">
                  <GridWrapper container spacing={3}>
                    <GridWrapper item xs={6} display={'flex'} alignItems={'center'} marginBottom={1}>
                      <TypographyLabel>Reg. ACESS: {appointmentDetails?.doctor?.code_msp}</TypographyLabel>
                    </GridWrapper>
                  </GridWrapper>
                </form>
                <TypographyDoctorInfo>
                  {`${appointmentDetails?.office?.name_medical_office}, ${appointmentDetails?.office?.reference_medical_office}`}
                </TypographyDoctorInfo>
                <TypographyDoctorInfo>
                  {appointmentDetails?.doctor?.doctor_phone}
                  {appointmentDetails?.office?.office_phone ? ` - ${appointmentDetails?.office?.office_phone}` : ''}
                </TypographyDoctorInfo>
                <TypographyDoctorInfo>{appointmentDetails?.doctor?.email_doctor}</TypographyDoctorInfo>
              </GridWrapper>
            </GridWrapper>

            <DividerWrapper variant="fullWidth" flexItem />
            <GridWrapper container display={'flex'} flexDirection={'row'}>
              <GridWrapper item xs={12} md={5.9}>
                <GridWrapper container display={'flex'} flexDirection={'row'} paddingTop={2}>
                  <GridWrapper item xs={6} paddingX={2}>
                    <LabelInputTitle sx={{ marginLeft: 1.4 }}>Nombre del paciente</LabelInputTitle>
                    <DisabledInput padding={2} display={'flex'} justifyContent={'center'}>
                      {`${appointmentDetails?.patient?.name} ${appointmentDetails?.patient?.last_name}`}
                    </DisabledInput>
                  </GridWrapper>
                  <GridWrapper item xs={6} paddingX={2}>
                    <LabelInputTitle sx={{ marginLeft: 1.4 }}>Fecha</LabelInputTitle>
                    <DisabledInput
                      padding={2}
                      display={'flex'}
                      justifyContent={'center'}
                      data-testid={'prescription-date'}
                    >
                      {appointmentDetails?.appointment_date?.split('-').reverse().join('/')}
                    </DisabledInput>
                  </GridWrapper>
                  <GridWrapper item xs={12} marginY={3} paddingX={2}>
                    <LabelInputTitle sx={{ marginLeft: 1.4 }}>Diagnóstico</LabelInputTitle>
                    <DisableTextInput>
                      {appointmentDetails?.diagnostics?.map((d) => `${d?.cie_code} - ${d?.diagnostic}`).join(' | ')}
                    </DisableTextInput>
                  </GridWrapper>
                  <GridWrapper item xs={12} marginY={1} paddingX={2}>
                    <LabelInputTitle sx={{ marginLeft: 1.4 }}>RP.</LabelInputTitle>
                    <DisableTextInput>
                      {appointmentDetails?.prescription[prescriptionIndex]?.prescription_items?.map(
                        (item: IPrescriptionItem, index: number) => (
                          <TypographyWrapper
                            key={`receta-${index}`}
                            sx={{
                              [theme.breakpoints.only('xs')]: {
                                fontSize: '13px',
                              },
                            }}
                          >{`${item?.quantity} ${item?.medicament} (${item?.dosage})`}</TypographyWrapper>
                        ),
                      )}
                    </DisableTextInput>
                  </GridWrapper>
                </GridWrapper>
              </GridWrapper>

              {!isExtraSmallSize ? (
                <GridWrapper item xs={0.2} display={'flex'} justifyContent={'center'} py={2}>
                  <DividerWrapper orientation="vertical" flexItem />
                </GridWrapper>
              ) : (
                <Separation
                  item
                  xs={12}
                  display={'flex'}
                  justifyContent={'center'}
                  py={'0.1px'}
                  sx={{ backgroundColor: '#D6D6D6' }}
                >
                  <DividerWrapper orientation="horizontal" flexItem />
                </Separation>
              )}

              <GridWrapper item xs={12} md={5.9}>
                <GridWrapper marginY={1} paddingX={2}>
                  <LabelInputTitle sx={{ marginLeft: 1.4 }}>Indicaciones</LabelInputTitle>
                  <DisableTextInput>
                    {appointmentDetails?.prescription[prescriptionIndex]?.prescription_items?.map(
                      (item: IPrescriptionItem, index: number) => (
                        <TypographyWrapper
                          key={`prescription-${index}`}
                          sx={{
                            [theme.breakpoints.only('xs')]: {
                              fontSize: '13px',
                            },
                          }}
                        >
                          <b>{item.medicament}</b>
                          <br />
                          {`${item?.prescription}`}
                        </TypographyWrapper>
                      ),
                    )}
                  </DisableTextInput>
                </GridWrapper>

                <GridWrapper padding={2}>
                  <LabelInputTitle sx={{ marginLeft: 1.4 }}>Recomendaciones no farmacológicas:</LabelInputTitle>
                  <DisableTextInput>
                    {appointmentDetails?.prescription[prescriptionIndex]?.remarks || 'Sin recomendaciones'}
                  </DisableTextInput>
                </GridWrapper>
              </GridWrapper>
            </GridWrapper>

            <GridWrapper display="flex" justifyContent="center" marginTop={3}>
              <SubmitButton
                id={''}
                variant={'contained'}
                data-testid="print-button"
                fullWidth
                type={'button'}
                text={'Imprimir'}
                sx={{
                  width: isExtraSmallSize ? '100%' : '25%',
                  margin: '0 20px',
                  backgroundColor: 'primary.main',
                }}
              />
            </GridWrapper>
          </GridWrapper>
        </BoxModal>
      </FadePatientRecipeModal>
    </ModalRecipe>
  )
}

export default Recipe
