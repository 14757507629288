export const convertSpecialCharacters = (value: string): string => {
  return value
    .toLowerCase()
    .replace(/á|é|í|ó|ú/g, (match) => {
      return {
        'á': 'a',
        'é': 'e',
        'í': 'i',
        'ó': 'o',
        'ú': 'u'
      }[match] as string;
    })
}