import { IResponseApi, IResponseService } from '../../../infrastructure/dtos'
import { IResponseDoctorFeedback } from '../../../infrastructure/dtos/DoctorFeedback'
import { DoctorFeedbackService } from '../Persistencia/DoctorFeedbackService'

export async function DoctorFeedbackUtility(doctorId: string): Promise<IResponseService<IResponseDoctorFeedback>> {
  try {
    const { data, status } = await DoctorFeedbackService(doctorId)
    if (status !== 0 && typeof data != 'string') {
      if (data.statusCode === 200) {
        return {
          status: 1,
          data: (data as IResponseApi<IResponseDoctorFeedback>).body,
        }
      }
    }
    return {
      status: 0,
      data: (data as IResponseApi<IResponseDoctorFeedback>).body.message,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as IResponseDoctorFeedback).message,
    }
  }
}
