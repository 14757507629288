import { IResponseApi, IResponseService } from '../../../infrastructure/dtos'
import { ISendValidationIdDocument } from '../../../infrastructure/dtos/Validation'
import { ValidatorsIdDocumentService } from '../Persistencia/ValidatorsService'

export async function ValidatorsIdDocumentUtility(
  params: ISendValidationIdDocument,
): Promise<IResponseService<string>> {
  try {
    const { data, status } = await ValidatorsIdDocumentService(params)
    return {
      data: (data as IResponseApi<string>).body,
      status: (data as IResponseApi<string>).statusCode !== 200 || status === 0 ? 0 : 1,
    }
  } catch (error) {
    return {
      data: 'Error Utility',
      status: 0,
    }
  }
}
