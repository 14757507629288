import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'

import { AccountContext, AuthContextType } from '../../../../../../contexts/AccountContext'
import { IAppointmentQualification } from '../../../../../../infrastructure/dtos/PendingTasks'
import {
  QualificationQuestions,
  QualificationQuestionsResponse,
  Question,
} from '../../../../../../infrastructure/dtos/QualificationQuestions'
import { Answer, QualificationDoctorResponse } from '../../../../../../infrastructure/dtos/SaveQualifications'
import { QualificationQuestionsService } from '../../../../../../services/Contracts/Persistencia/QualificationQuestionsService'
import { SaveQualificationService } from '../../../../../../services/Contracts/Persistencia/SaveQualificationService'

interface IUseQualifyDoctor {
  handleSubmit: (e: { preventDefault: () => void }) => Promise<void>
  handleAnsweredQuestions: (val: number, index: number) => void
  item: QualificationQuestions | undefined
  setRating: Dispatch<SetStateAction<number>>
  setAnonymous: Dispatch<SetStateAction<boolean>>
  setComments: Dispatch<SetStateAction<string>>
  rating: number
  answeredQuestions: Answer[]
  comments: string
  anonymous: boolean
}

export const useQualifyDoctor = (
  handleClose: () => void,
  pending: IAppointmentQualification | undefined,
  fetchPendingsTasks: (userId: string) => Promise<void>,
): IUseQualifyDoctor => {
  const [user_id] = useState<string>(JSON.parse(localStorage.getItem('userData') as string)?.userId)
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const [rating, setRating] = useState<number>(0)
  const [anonymous, setAnonymous] = useState<boolean>(false)
  const [item, setItem] = useState<QualificationQuestions>()
  const [answeredQuestions, setAnsweredQuestions] = useState<Answer[]>([])
  const [comments, setComments] = useState<string>('')

  useEffect(() => {
    GetQuestions()
  }, [])

  const GetQuestions = async (): Promise<void> => {
    try {
      const { data, status } = await QualificationQuestionsService()
      if (status || (data as QualificationQuestionsResponse).statusCode === 200) {
        const questions: QualificationQuestions = (data as QualificationQuestionsResponse)
          .body as QualificationQuestions

        setAnsweredQuestions(
          questions?.questions.map((question: Question) => {
            return {
              question: question.question_id,
              qualification: 0,
            }
          }),
        )
        setItem(questions)
      }
    } catch (error: unknown) {
      handleAlert(true, 'Error al obtener las preguntas', 'error')
    }
  }

  const handleAnsweredQuestions = (val: number, index: number): void => {
    const newArray: Answer[] = [...answeredQuestions]
    newArray[index].qualification = val
    setAnsweredQuestions(newArray)
  }

  const handleSubmit = async (e: { preventDefault: () => void }): Promise<void> => {
    e.preventDefault()
    try {
      const { data, status } = await SaveQualificationService({
        comment: comments,
        answers: answeredQuestions,
        qualification: rating,
        anonymous: anonymous,
        appointment_id: pending?.appointment_id,
      })
      if (status || (data as QualificationDoctorResponse).statusCode === 200) {
        fetchPendingsTasks(user_id)
        handleAlert(true, (data as QualificationDoctorResponse).body as string, 'success')
        handleClose()
      } else {
        handleAlert(true, (data as QualificationDoctorResponse).body as string, 'warning')
      }
    } catch (error) {
      handleAlert(true, 'Error al guardar la calificación', 'error')
    }
  }

  return {
    handleSubmit,
    handleAnsweredQuestions,
    item,
    setRating,
    setAnonymous,
    setComments,
    rating,
    answeredQuestions,
    comments,
    anonymous,
  }
}
