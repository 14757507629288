import {
  GetAutocompleteSuggestionsUtilityResponse,
  IAPIResponseAutocompleteSuggestions,
} from '../../../infrastructure/dtos/Search'
import { getAutocompleteSuggestionsService } from '../Persistencia/SearchService'

export const getAutocompleteSuggestionsUtility = async (
  query: string,
): Promise<GetAutocompleteSuggestionsUtilityResponse> => {
  try {
    const { data } = await getAutocompleteSuggestionsService(query)

    return {
      status: (data as IAPIResponseAutocompleteSuggestions).statusCode === 200 ? 1 : 0,
      data: (data as IAPIResponseAutocompleteSuggestions).body,
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        data: [],
        message: (error as Error)?.message,
      },
    }
  }
}
