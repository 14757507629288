import { ButtonProps } from '@mui/material'
import { FunctionComponent, ReactElement } from 'react'

import { ButtonGeneric } from './SecondaryMediumButtonStyled'

interface IExtraProps {
  text: string
  children?: ReactElement
}

type ISecondaryMediumButtonProps = ButtonProps & IExtraProps

export const SecondaryMediumButton: FunctionComponent<
  ISecondaryMediumButtonProps
> = (props) => {
  const { text, children } = props
  return (
    <ButtonGeneric { ...props }>
      {text}
      {children}
    </ButtonGeneric>
  )
}
