import { AxiosError } from 'axios'

import { IAPIResponseAutocompleteSuggestions } from '../../dtos/Search'
import { ISearchDataSource } from '../dataSource/SearchDataSource'
import { http } from '../Http'

export class SearchMapper implements ISearchDataSource {
  async getAutocompleteSuggestions(query: string): Promise<IAPIResponseAutocompleteSuggestions> {
    try {
      const { data } = await http.get<IAPIResponseAutocompleteSuggestions>(
        `/send_data/autocomplete_search_doctor?query=${query}`,
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError)?.response?.status as number,
        body: {
          message: (error as AxiosError)?.response?.statusText as string,
          data: [],
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
