import { useState } from 'react'

import { ISendValidationIdDocument } from '../../../infrastructure/dtos/Validation'
import { ValidatorsIdDocumentUtility } from '../../../services/Contracts/Utility/ValidationUtility'

interface IUseValidationComponentHook {
  validatorIdDocument: IErrorValidationIdDocument
  loadingValidation: boolean
  fetchValidationIdDocument: (params: ISendValidationIdDocument) => Promise<void>
}
interface IErrorValidationIdDocument {
  status: boolean
  message: string
}
export function useValidationComponent(): IUseValidationComponentHook {
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false)
  const [validatorIdDocument, setValidatorIdDocument] = useState<IErrorValidationIdDocument>({
    status: false,
    message: '',
  })

  const fetchValidationIdDocument = async (params: ISendValidationIdDocument): Promise<void> => {
    setLoadingValidation(true)
    const { data, status } = await ValidatorsIdDocumentUtility(params)
    if (status === 0) {
      setValidatorIdDocument({
        status: true,
        message: data,
      })
    } else {
      setValidatorIdDocument({
        status: false,
        message: '',
      })
    }
    setLoadingValidation(false)
  }

  return {
    validatorIdDocument,
    loadingValidation,
    fetchValidationIdDocument,
  }
}
