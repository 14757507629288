import CloseIcon from '@mui/icons-material/Close'
import { Backdrop } from '@mui/material'
import { useContext, JSX } from 'react'
import { useNavigate } from 'react-router-dom'

import { BoxModal, CloseButton, FadeModal, GridConfirm, GridModal } from './styles'

import SubmitButton from '../../../../../../components/SubmitButton'
import { ModalWrapper } from '../../../../../../components/wrapper'

import { AccountContext, AuthContextType } from '../../../../../../contexts/AccountContext'
import { confirmAppointmentUtility } from '../../../../../../services/Contracts/Utility/AppointmentsUtility'
import { TypographyModal } from '../../../../../Home/Pendings/components/QualifyDoctorModal/styles'

interface IAppointment {
  appointment_id: string
  name_doctor: string
  last_name_doctor: string
  doctor_id?: string
  user_id?: string
  patient_id?: string
  reschedule_id: string
}

interface ConfirmAppointmentsProps {
  handleClose: () => void
  open: boolean
  appointment?: IAppointment
  fetchAppointments: (userId: string) => Promise<void>
}

function ConfirmAppointments({
  handleClose,
  open,
  appointment,
  fetchAppointments,
}: ConfirmAppointmentsProps): JSX.Element {
  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const userInfo: string = localStorage.getItem('userData') ?? '{}'
  const idPatient = JSON.parse(userInfo)?.userId

  const navigate = useNavigate()

  const handleReschedule = (): void => {
    navigate(`/appointment/booking/${appointment?.user_id || appointment?.doctor_id}/${appointment?.appointment_id}`)
  }

  const handleConfirm = async (): Promise<void> => {
    const response = await confirmAppointmentUtility({
      action: 'confirmed',
      appointment_id: appointment?.appointment_id as string,
      reschedule_id: appointment?.reschedule_id as string,
    })
    let { message = '' } = response.data as { message?: string }
    if (typeof response.data !== 'string') {
      ;({ message } = response.data)
    }
    handleClose()
    await fetchAppointments(idPatient as string)
    handleAlert(true, message, 'success')
  }

  return (
    <ModalWrapper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      data-testid="modal-attendance"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal>
          <GridModal>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
            <TypographyModal id="transition-modal-title" variant="h6" data-testid="modal-attendance-title">
              ¿Asistirás a tu cita con el Dr. {appointment?.name_doctor} {appointment?.last_name_doctor}?
            </TypographyModal>
            <GridConfirm>
              <SubmitButton
                fullWidth
                id="attended"
                data-testid="attended"
                text="Sí, confirmar"
                onClick={handleConfirm}
                type="button"
                variant="contained"
              />
              <SubmitButton
                id="didNotAttend"
                data-testid="didNotAttend"
                text="No, reagendar"
                type="button"
                variant="outlined"
                fullWidth
                onClick={handleReschedule}
                sx={{
                  color: '#6191fe',
                  backgroundColor: '#fff',
                  border: '1px solid #6191fe',
                }}
              />
            </GridConfirm>
          </GridModal>
        </BoxModal>
      </FadeModal>
    </ModalWrapper>
  )
}

export default ConfirmAppointments
