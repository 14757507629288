import { styled } from '@mui/material'

import { ButtonWrapper, GridWrapper } from '../../../../components'

export const GridRow = styled(GridWrapper)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
}))
export const ButtonR = styled(ButtonWrapper)((props) => ({
  textTransform: 'capitalize',
  borderRadius: '50px',
  height: 50,
  width: 250,
  backgroundColor: props.theme.palette.primary.main,
  color: '#ffffff',

  border: '1px solid ' + props.theme.palette.primary.main,
  '&:hover': {
    backgroundColor: '#ffffff',
    color: props.theme.palette.primary.main,
  },
}))
