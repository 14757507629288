import {
  GetDoctoInfo,
  SearchDoctor,
  GetSuggestedDoctor,
  GetRecommendedDoctors,
} from '../../../domain/useCase/DoctorInfo'
import { IResponseApi, IResponseService } from '../../../infrastructure/dtos'
import {
  IApiResponseDoctorInfo,
  IRecommendedDoctors,
  ISearchDoctorParams,
  ISuggestedDoctorsParams,
} from '../../../infrastructure/dtos/DoctorInfo'
import DoctorInfoMapper from '../../../infrastructure/http/mappers/DoctorInfoMapper'
import { DoctorInfoRepositoryImpl } from '../../../infrastructure/RepositoryImpl/DoctorInfoRepositoryImpl'

export interface IDoctorService {
  status: number
  data: string | IApiResponseDoctorInfo
}

export interface IDoctorInfoService {
  status: number
  data: IApiResponseDoctorInfo | string
}

export interface ISuggestedDoctorsService {
  status: number
  data: string | IApiResponseDoctorInfo
}

export async function DoctorInfoService(idDoctor: string): Promise<IDoctorInfoService> {
  try {
    const UseCaseDoctor = new GetDoctoInfo(new DoctorInfoRepositoryImpl(new DoctorInfoMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(idDoctor),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function SearchDoctorService(params: ISearchDoctorParams): Promise<IDoctorService> {
  try {
    const UseCaseDoctor = new SearchDoctor(new DoctorInfoRepositoryImpl(new DoctorInfoMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function GetSuggestedDoctorsService(params: ISuggestedDoctorsParams): Promise<ISuggestedDoctorsService> {
  try {
    const UseCaseDoctor = new GetSuggestedDoctor(new DoctorInfoRepositoryImpl(new DoctorInfoMapper()))
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}

export async function RecommendedDoctorsService(
  patient_id: string,
): Promise<IResponseService<IResponseApi<IRecommendedDoctors[] | string>>> {
  try {
    const UseCaseDoctor: GetRecommendedDoctors = new GetRecommendedDoctors(
      new DoctorInfoRepositoryImpl(new DoctorInfoMapper()),
    )
    return {
      status: 1,
      data: await UseCaseDoctor.invoke(patient_id),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
