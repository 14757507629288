import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { CardActions } from '@mui/material'
import React, { useContext, useState, JSX } from 'react'
import { useNavigate } from 'react-router-dom'

import { NewDateCard, OptionGrid } from './NextDateCardStyle'
import {
  GridWrapper,
  IconButtonWrapper,
  TypographyWrapper,
  BoxWrapper,
  MenuWrapper,
} from '../../../../../../components'
import { FollowButton } from '../../../../../../components/FollowButton/FollowButton'
import { SecondaryMediumButton } from '../../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import { Title } from '../../../../../../components/StyledComponents/Title'
import CardContentWrapper from '../../../../../../components/wrapper/CardContentWrapper'
import { AccountContext, AuthContextType } from '../../../../../../contexts/AccountContext'
import { INextAppointment } from '../../../../../../infrastructure/dtos/Appointments'
import { handleClickOpenGMaps } from '../../../../../../utils/openGMap'
import { CancelAppointment } from '../CancelAppointment/CancelAppointment'
import { CancelReasons } from '../CancelAppointment/modals/CancelReasons/CancelReasons'
import { useCancelReasonsHook } from '../CancelAppointment/modals/CancelReasons/useCancelReasons'
import { useCancelAppointment } from '../CancelAppointment/useCancelAppointment'
import ConfirmAppointments from '../ConfirmAppointments/ConfirmAppointments'
import { useConfirm } from '../ConfirmAppointments/hooks/useConfirmModal'
import { useNewDatesList } from '../NewDatesLitsHook'
import { AvatarNextDate, OptionMenu } from '../styles'

const options: string[] = ['Reagendar', 'Cancelar']

function NextDateCard(): JSX.Element {
  const userInfo = localStorage.getItem('userData') ?? '{}'
  const idPatient = JSON.parse(userInfo)?.userId

  const [appointmentToModal, setAppointmentToModal] = useState<INextAppointment>({} as INextAppointment)
  const { handleCloseConfirm, openConfirm } = useConfirm()
  const { openCancelModal, handleOpenCancelAppointment } = useCancelAppointment()
  const navigate = useNavigate()
  const { anchorEl, nextAppointments, handleClick, handleClose, fetchNextAppointments, message } = useNewDatesList()

  const { handleAlert } = useContext(AccountContext) as AuthContextType

  const { openCancelReasons, handleOpenCancelReasons, handleCloseCancelReasons } = useCancelReasonsHook()

  const handleShowCancelReasons = (): void => {
    handleOpenCancelAppointment(false)
    handleOpenCancelReasons()
  }

  const handleClickOption = (option: string, appointment: INextAppointment, index: number): void => {
    if (appointment.assistance === 'PENDING') {
      switch (option) {
        case 'Reagendar':
          navigate(`/appointment/booking/${appointment.user_id}/${appointment.appointment_id}`)
          break
        case 'Cancelar':
          handleOpenCancelAppointment(true)
          setAppointmentToModal(appointment)
          break
        default:
          handleClose(index)
      }
    } else {
      const message = appointment.assistance === 'ATTENDED' ? 'asistida' : 'no asistida'
      handleAlert(true, `¡Lo siento! La cita ya fue marcada como ${message}.`, 'error')
    }
  }
  return (
    <>
      {Boolean(message) ? (
        ''
      ) : (
        <Title data-testid="title-next-dates" sx={{ marginTop: '8px', marginBottom: '10px' }}>
          Tus próximas citas
        </Title>
      )}
      {nextAppointments.map((item: INextAppointment, index: number) => (
        <NewDateCard sx={{ maxWidth: 365 }} key={index} color={item.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
          <GridWrapper container>
            <GridWrapper item xs={4} sx={{ paddingTop: '16px' }}>
              <BoxWrapper
                sx={{
                  width: 70,
                  height: 70,
                  borderRadius: '50%',
                  overflow: 'hidden',
                  margin: 'auto',
                }}
              >
                <AvatarNextDate src={item.photo_doctor} sx={{ width: '100%', height: '100%' }} />
              </BoxWrapper>
            </GridWrapper>
            <GridWrapper item xs={7}>
              <CardContentWrapper>
                <TypographyWrapper variant="body2" color={item.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
                  {`${item.name_doctor} ${item.last_name_doctor}`}
                </TypographyWrapper>
                <TypographyWrapper variant="body2" color={item.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
                  {`${item.speciality_doctor}`}
                </TypographyWrapper>
                <TypographyWrapper variant="body2" color={item.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
                  {`${item.appointment_date.split('-').reverse().join('/')} ${item.hour_from.split(':').join('h')}`}
                </TypographyWrapper>
                <TypographyWrapper variant="body2" color={item.status === 'CANCELLED' ? '#e4ecf1' : 'inherit'}>
                  {`${item.reference_medical_office}`}
                </TypographyWrapper>
              </CardContentWrapper>
            </GridWrapper>
            <OptionGrid item xs={1}>
              <IconButtonWrapper
                key={`menu-button-${item.appointment_id}`}
                aria-label="more"
                id="long-button"
                data-testid="long-button"
                aria-controls={item?.showMenu ? 'long-menu' : undefined}
                aria-expanded={item?.showMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(event) => handleClick(event, index)}
                disabled={item.status === 'CANCELLED' ? true : false}
              >
                <MoreHorizIcon
                  fontSize="medium"
                  sx={{
                    color: item.status === 'CANCELLED' ? '#e4ecf1' : 'primary.dark',
                  }}
                />
              </IconButtonWrapper>
              <MenuWrapper
                key={`menu-${item.appointment_id}`}
                id="long-menu"
                data-testid="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={Boolean(item?.showMenu)}
                onClose={() => handleClose(index)}
                slotProps={{
                  paper: {
                    style: {
                      borderRadius: '32px',
                      padding: '7px 7px',
                      boxShadow: '1px 4px 8px 6px rgba(0, 0, 0, 0.03)',
                      backgroundColor: '#fff',
                    },
                  },
                }}
              >
                {options.map((option: string, i: number) => (
                    <OptionMenu
                      key={`${option}-${i}`}
                      data-testid={`${option}`}
                      onClick={() => handleClickOption(option, item, index)}
                    >
                      {option}
                    </OptionMenu>
                  ))}
              </MenuWrapper>
            </OptionGrid>
            <GridWrapper item xs={10} sx={{ marginLeft: 'auto' }}>
              <CardActions>
                <SecondaryMediumButton
                  id="Coming"
                  text="Cómo llegar"
                  onClick={() => handleClickOpenGMaps(item)}
                  type="button"
                  sx={{ height: '43px', width: 115, fontSize: 12 }}
                  variant="contained"
                  disabled={item.status === 'CANCELLED' ? true : false}
                />
                <FollowButton
                  callbackAfterFollow={fetchNextAppointments}
                  doctorId={item.user_id}
                  isFollow={item.follow}
                  fromHome={false}
                  idPatient={idPatient}
                  status={item.status}
                />
              </CardActions>
            </GridWrapper>
          </GridWrapper>
        </NewDateCard>
      ))}
      {openConfirm && (
        <ConfirmAppointments
          handleClose={handleCloseConfirm}
          open={openConfirm}
          appointment={appointmentToModal}
          fetchAppointments={fetchNextAppointments}
        />
      )}
      {openCancelModal && (
        <CancelAppointment
          handleClose={() => handleOpenCancelAppointment(false)}
          open={openCancelModal}
          handleShowCancelReasons={handleShowCancelReasons}
        />
      )}
      {openCancelReasons && (
        <CancelReasons
          handleClose={() => handleCloseCancelReasons()}
          open={openCancelReasons}
          fetchAppointments={fetchNextAppointments}
          appointment={appointmentToModal}
        />
      )}
    </>
  )
}

export default NextDateCard
