import { SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { ChangeEvent, useState, JSX } from 'react'

import Addresses from './components/Addresses/Addresses'
import { AddressPanel } from './components/ManageAddress/AddressPanel'
import { ModalConfirmPhoneNumber } from './components/ModalConfirmPhoneNumber'
import { useModalConfirmPhoneNumber } from './components/ModalConfirmPhoneNumber/hook'
import { DateBox, LabelCard, SelectGender } from './styles'
import { useProfile } from './useProfile'
import FormTextInput from '../../components/FormInputText'
import { GridItem } from '../../components/GridItem/GridItem'
import { DrawerSidePanel } from '../../components/StyledComponents/DrawerSidePanel'
import { LabelInput } from '../../components/StyledComponents/LabelInput'
import SubmitButton from '../../components/SubmitButton'
import UploadPhoto from '../../components/UploadPhoto'
import { BoxWrapper, GridWrapper, MenuItemWrapper } from '../../components/wrapper'
import { getUrlPhoto } from '../../utils/getPhoto'
import { validatePhoneNumber } from '../../utils/validatePhoneNumber'
import { BoxPendingsStyled } from '../Home/Pendings/PendingsComponentStyles'
import { IAddressPatientAux } from '../Register/types'

function Profile(): JSX.Element {
  const {
    patient,
    patientInfo,
    disabled,
    list,
    setPatientInfo,
    handleSubmit,
    handleChangePhoto,
    savePatientData,
    handleAlert,
    handleImageUpload,
  } = useProfile()

  const { openModal, handleCloseModal, handleOpenModal } = useModalConfirmPhoneNumber()
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))
  const [showAddressPanel, setShowAddressPanel] = useState<boolean>(false)

  const [itemIndex, setItemIndex] = useState<number>(-1)

  const toggleDrawer = (open: boolean): void => {
    setShowAddressPanel(open)
  }

  const onClickEdit = (index: number): void => {
    setItemIndex(index)
    toggleDrawer(true)
  }

  const handleToggleDrawer = (open: boolean): void => {
    toggleDrawer(open)
    setItemIndex(-1)
  }

  const disabledPhoneButton: boolean =
    patientInfo?.phone.length === 0 ||
    patientInfo?.phone === patient?.mobile ||
    !validatePhoneNumber(patientInfo?.phone)

  const handleUpdateAddress = (addresses: IAddressPatientAux[]): void => {
    savePatientData({
      ...patient,
      addresses,
    })
  }

  return (
    <BoxWrapper flexGrow={1} paddingY={'20px'} paddingX={isExtraSmallSize ? '15px' : '50px'}>
      <GridWrapper container spacing={{ xs: 2, md: 6 }} columns={16}>
        <GridWrapper item xs={16} md={4}>
          <BoxPendingsStyled sx={{ display: 'block', padding: '20px 15px' }}>
            <UploadPhoto
              id="UploadPhotoId"
              selectedImage={getUrlPhoto(patientInfo?.photo || '')}
              title={`${patient?.name} ${patient?.lastName} - ${patient?.age} años`}
              tooltipText="Subir imagenes con formato jpg o png"
              buttonText={!patientInfo?.photo ? 'Subir foto' : 'Cambiar foto'}
              buttonType="button"
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleImageUpload(event, handleChangePhoto)}
              sx={{ margin: 0 }}
              data-Testid="upload-file"
            />
            <GridWrapper container display={'flex'} justifyContent={'left'} mt={5}>
              <GridWrapper item xs={12} textAlign={'center'}>
                <LabelCard>Teléfono</LabelCard>
              </GridWrapper>
              <FormTextInput
                placeholder="0912345678"
                id="phone"
                name="phone"
                type="text"
                fullWidth
                value={patientInfo?.phone}
                sx={{
                  '& .MuiInputBase-input': {
                    textAlign: 'center',
                    paddingLeft: '5px',
                  },
                }}
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setPatientInfo({
                    ...patientInfo,
                    phone: event.target.value,
                  })
                }
                error={!(validatePhoneNumber(patientInfo?.phone) && patientInfo?.phone.length > 0)}
                texterror={
                  patientInfo?.phone.length === 0
                    ? 'Campo requerido.'
                    : !validatePhoneNumber(patientInfo?.phone)
                      ? 'Por favor, ingresa un teléfono celular válido'
                      : ''
                }
              />
              {!isExtraSmallSize ? (
                <GridItem xs={12} data-testid="save-profile-button">
                  <SubmitButton
                    id="responsive-phone-button"
                    fullWidth
                    text="Actualizar"
                    type="submit"
                    variant="contained"
                    disabled={disabledPhoneButton}
                    onClick={() => handleOpenModal()}
                    sx={{ marginTop: '20px', backgroundColor: '#6191FE' }}
                  />
                </GridItem>
              ) : (
                <GridItem xs={12} data-testid="save-profile-button">
                  <SubmitButton
                    id="responsive-phone-button"
                    fullWidth
                    text="Actualizar"
                    type="submit"
                    variant="contained"
                    disabled={disabledPhoneButton}
                    onClick={() => handleOpenModal()}
                    sx={{ marginTop: '20px', backgroundColor: '#6191FE' }}
                  />
                </GridItem>
              )}
            </GridWrapper>
          </BoxPendingsStyled>
        </GridWrapper>
        <GridWrapper item xs={16} md={12} data-testid="edit-profile-form">
          <GridWrapper container spacing={4} mt={0.5}>
            <GridItem xs={12} sm={6} flexDirection={'column'} alignItems={'flex-start'}>
              <FormTextInput
                text="Nombre"
                placeholder="Nombre"
                id="name"
                data-testid="name"
                type="text"
                fullWidth
                value={patientInfo?.name}
                name="name"
                disabled
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setPatientInfo({
                    ...patientInfo,
                    name: event.target.value,
                  })
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6} flexDirection={'column'} alignItems={'flex-start'}>
              <FormTextInput
                text="Apellido"
                placeholder="Apellido"
                id="lastName"
                type="text"
                fullWidth
                value={patientInfo?.lastName}
                name="lastName"
                disabled
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setPatientInfo({
                    ...patientInfo,
                    lastName: event.target.value,
                  })
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6} flexDirection={'column'} alignItems={'flex-start'} textAlign={'left'}>
              <LabelInput>Sexo</LabelInput>
              <SelectGender
                id="gender-select"
                placeholder="Sexo"
                variant='outlined'
                value={patientInfo.gender}
                onChange={(event: SelectChangeEvent<unknown>) =>
                  setPatientInfo({
                    ...patientInfo,
                    gender: event.target.value as string,
                  })
                }
                sx={{
                  boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.02)',
                  '& .MuiInputBase-input': {
                    paddingLeft: '30px', // Add your desired marginLeft (as paddingLeft) here
                  },
                }}
              >
                {list?.length > 0 &&
                  list?.map((item: string, index: number) => (
                    <MenuItemWrapper
                      key={index}
                      value={item}
                      defaultValue={patientInfo.gender || ''}
                      data-testid={`gender-item`}
                    >
                      {item}
                    </MenuItemWrapper>
                  ))}
              </SelectGender>
            </GridItem>
            <GridItem xs={12} sm={6} flexDirection={'column'} alignItems={'flex-start'}>
              <FormTextInput
                text="Cédula"
                placeholder="Cédula"
                id="documentId"
                type="text"
                fullWidth
                value={patientInfo?.idNumber}
                name="documentId"
                disabled
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setPatientInfo({
                    ...patientInfo,
                    idNumber: event.target.value,
                  })
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6} flexDirection={'column'} alignItems={'flex-start'}>
              <FormTextInput
                text="Correo electrónico"
                placeholder="Correo electrónico"
                id="email"
                type="text"
                fullWidth
                value={patientInfo?.email}
                name="email"
                disabled
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setPatientInfo({
                    ...patientInfo,
                    email: event.target.value,
                  })
                }
              />
            </GridItem>
            <GridItem xs={12} sm={6} flexDirection={'column'} alignItems={'flex-start'}>
              <LabelInput>Fecha de nacimiento</LabelInput>
              <DateBox>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    value={patientInfo?.birthdate}
                    format="DD/MM/YYYY"
                    onChange={(value: dayjs.Dayjs | null) =>
                      setPatientInfo({
                        ...patientInfo,
                        birthdate: value,
                      })
                    }
                    sx={{
                      '& .MuiInputBase-root': {
                        boxShadow: '6px 6px 8px 7px rgba(0, 0, 0, 0.02);',
                      },
                    }}
                  />
                </LocalizationProvider>
              </DateBox>
            </GridItem>
            <GridItem xs={12} flexDirection={'column'} alignItems={'flex-start'} textAlign={'left'}>
              <LabelInput>Direcciones guardadas</LabelInput>
              <Addresses
                toggleDrawer={handleToggleDrawer}
                address={patient}
                savePatientData={savePatientData}
                handleAlert={handleAlert}
                onClickEdit={onClickEdit}
              />
            </GridItem>
            <GridItem xs={12} data-testid="save-profile-button">
              <SubmitButton
                id="save-profile-button"
                fullWidth
                text="Guardar"
                type="submit"
                variant="contained"
                disabled={disabled}
                onClick={handleSubmit}
              />
            </GridItem>
          </GridWrapper>
        </GridWrapper>
      </GridWrapper>
      {openModal && (
        <ModalConfirmPhoneNumber handleClose={handleCloseModal} open={openModal} newPhone={patientInfo.phone} />
      )}

      <DrawerSidePanel
        anchor={isExtraSmallSize ? 'bottom' : 'right'}
        open={showAddressPanel}
        onClose={() => handleToggleDrawer(false)}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0)',
            },
          },
        }}
      >
        <AddressPanel
          toggleDrawer={handleToggleDrawer}
          patient={patient}
          handleAlert={handleAlert}
          handleUpdateAddress={handleUpdateAddress}
          itemIndex={itemIndex}
        />
      </DrawerSidePanel>
    </BoxWrapper>
  )
}

export default Profile
