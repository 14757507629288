import { GetOffices } from '../../../domain/useCase/GetOffices'
import { IApiDaysAvailability } from '../../../infrastructure/dtos/OfficeDaysAvailability'
import { IApiMonthAvailability } from '../../../infrastructure/dtos/OfficeMonthAvailability'
import { IApiResponseOffices, IOfficesProps } from '../../../infrastructure/dtos/Offices'
import OfficesMapper from '../../../infrastructure/http/mappers/OfficesMapper'
import { OfficesRepositoryImpl } from '../../../infrastructure/RepositoryImpl/OfficesRepositoryImpl'

export interface IOfficeService {
  status: number
  data: IApiResponseOffices
}

export interface IMonthAvailabilityService {
  status: number
  data: IApiMonthAvailability
}

export interface IDayAvailabilityService {
  status: number
  data: IApiDaysAvailability
}

export async function OfficesService(params: IOfficesProps): Promise<IOfficeService> {
  try {
    const UseCaseRegistro = new GetOffices(new OfficesRepositoryImpl(new OfficesMapper()))
    return {
      status: 1,
      data: await UseCaseRegistro.invoke(params),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: (error as IApiResponseOffices).statusCode,
        body: (error as IApiResponseOffices).body,
        headers: (error as IApiResponseOffices).headers,
      },
    }
  }
}

export async function getMonthAvailabilityService(
  idOffice: string,
  idDoctor: string,
  officeType: string,
): Promise<IMonthAvailabilityService> {
  try {
    const UseCaseOffice = new GetOffices(new OfficesRepositoryImpl(new OfficesMapper()))
    return {
      status: 1,
      data: await UseCaseOffice.getMonthAvailability(idOffice, idDoctor, officeType),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: (error as IApiMonthAvailability).statusCode,
        body: (error as IApiMonthAvailability).body,
        headers: (error as IApiMonthAvailability).headers,
      },
    }
  }
}

export async function getDaysAvailabilityService(
  idOffice: string,
  idDoctor: string,
  officeType: string,
  date: string,
): Promise<IDayAvailabilityService> {
  try {
    const UseCaseOffice = new GetOffices(new OfficesRepositoryImpl(new OfficesMapper()))
    return {
      status: 1,
      data: await UseCaseOffice.getDaysAvailability(idOffice, idDoctor, officeType, date),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: {
        statusCode: (error as IApiDaysAvailability).statusCode,
        body: (error as IApiDaysAvailability).body,
        headers: (error as IApiDaysAvailability).headers,
      },
    }
  }
}
