import { JSX } from 'react'
import { useNavigate } from 'react-router-dom'

import { ProfilePictureButton, ProfilePictureGrid } from './styles'
import { AvatarWrapper, TypographyWrapper } from '../../../../components'

interface Props {
  name?: string
  imageUrl?: string
  isExtraSmallSize?: boolean
}

export const ProfilePicture = ({ name, imageUrl, isExtraSmallSize }: Props): JSX.Element => {
  const navigate = useNavigate()
  return (
    <ProfilePictureGrid>
      <ProfilePictureButton
        id="prolife-button"
        data-testid="prolife-button"
        onClick={() => {
          navigate('/profile')
        }}
      >
        <AvatarWrapper src={imageUrl} alt={name} />
        {!isExtraSmallSize && <TypographyWrapper sx={{ marginLeft: '1rem' }}>{name}</TypographyWrapper>}
      </ProfilePictureButton>
    </ProfilePictureGrid>
  )
}
