import { PatientActivitiesInfoRepository } from '../../domain/repository/PatientActivitiesInfoRepository';
import { IResponseApi } from '../dtos';
import { IPatientActivitiesInfo } from '../dtos/PatientActivitiesInfo';
import PatientActivitiesInfoDataSource from '../http/dataSource/PatientActivitiesDataSource';

export class PatientActivitiesRepositoryImpl
  implements PatientActivitiesInfoRepository
{
  dataSource: PatientActivitiesInfoDataSource;

  constructor(_dataSource: PatientActivitiesInfoDataSource) {
    this.dataSource = _dataSource;
  }

  async getPatientActivitiesInfo(
    user_id: string
  ): Promise<IResponseApi<IPatientActivitiesInfo>> {
    return this.dataSource.getPatientActivitiesInfo(user_id);
  }
}
