import { useEffect, useState, Dispatch, SetStateAction } from 'react'

import { Severity } from '../../contexts/AuthReducer'
import { IResponseDoctorFeedback } from '../../infrastructure/dtos/DoctorFeedback'
import { DoctorFeedbackUtility } from '../../services/Contracts/Utility/DoctorFeedbackUtility'

interface IUseBoxQualifications {
  showComments: boolean
  loadingFeedback: boolean
  feedback?: IResponseDoctorFeedback
  setShowComments: Dispatch<SetStateAction<boolean>>
}

export const useBoxQualifications = (
  doctorId?: string,
  handleAlert?: (open: boolean, message: string, severity?: Severity | undefined) => void,
): IUseBoxQualifications => {
  const [showComments, setShowComments] = useState<boolean>(false)
  const [loadingFeedback, setLoadingFeedback] = useState<boolean>(false)
  const [feedback, setFeedback] = useState<IResponseDoctorFeedback>()

  const fetchDoctorFeedbackUtility = async (doctorId: string): Promise<void> => {
    try {
      setLoadingFeedback(true)
      const { data, status } = await DoctorFeedbackUtility(doctorId)
      if (status) {
        setFeedback(data as IResponseDoctorFeedback)
      }
      setLoadingFeedback(false)
    } catch (error: unknown) {
      setLoadingFeedback(false)
      handleAlert?.(true, 'Error al obtener el feddback', 'error')
    }
  }

  useEffect(() => {
    if (doctorId) {
      fetchDoctorFeedbackUtility(doctorId)
    }
  }, [doctorId])
  return {
    showComments,
    loadingFeedback,
    feedback,
    setShowComments,
  }
}
