import { createTheme } from '@mui/material';


declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary']
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#6191FE',
      dark: '#3850B2'
    },
    secondary: {
      main: '#F79D25'
    },
    background: {
      default: '#FAFAFA',
      paper: '#FAF9FD'
    },
    tertiary: {
      main: '#000',
      dark: '#7F7F7F',
      light: '#F7F8FD'
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontSize: '13px'
        },
        h5: {
          fontSize: '14px'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standard: {
          alignItems: 'center',
          minWidth: '400px',
          height: '60px',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: 'normal',
          borderRadius: '19px'
        },
        standardSuccess: {
          backgroundColor: '#DEF4D3',
          color: '#75C44C'
        }
      }
    }
  }
})