import { BodyGenders, GendersResponse } from '../../../infrastructure/dtos/Genders'
import { GetGendersService } from '../Persistencia/GendersService'

export interface IGendersUtility {
  status: number
  data: BodyGenders | string
}

export async function GendersUtility(): Promise<IGendersUtility> {
  try {
    const { data, status } = await GetGendersService()
    if (status !== 1 || (data as GendersResponse).statusCode !== 200) {
      return {
        data: ((data as GendersResponse).body as BodyGenders).message as string,
        status: 0,
      }
    }

    return {
      data: (data as GendersResponse).body,
      status: 1,
    }
  } catch (error: unknown) {
    return {
      data: 'Ups! Tengo inconvenientes en este momento, por favor intenta más tarde.',
      status: 0,
    }
  }
}
