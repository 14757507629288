import { styled } from '@mui/material'

import { GridWrapper, IconButtonWrapper } from '../../../../components'

export const SideGrid = styled(GridWrapper)(({ theme }) => ({
  flexBasis: '100%',
  borderRadius: 2,
  textAlign: 'center',
  marginLeft: '0px',
  marginRight: '0px',
  marginTop: '2%',
  width: '80%',
  padding: '0 20px',
  [theme.breakpoints.up('sm')]: {
    flexBasis: '90%',
    borderRadius: 2,
    textAlign: 'center',
    marginLeft: '10%',
    marginRight: '20%',
    marginTop: '2%',
    width: '100%',
    padding: '2% 15% 0 7%',
  },
}))

export const MobileBackButton = styled(IconButtonWrapper)(({ theme }) => ({
  position: 'absolute',
  top: '31px',
  left: '10px',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  textAlign: 'center',
}))
