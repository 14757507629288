import { styled } from '@mui/material'

import { AvatarWrapper, BoxWrapper, GridWrapper } from '../../../components'

export const SuggestedDoctorsHomeBox = styled(BoxWrapper)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: '30px',
  justifyContent: 'center',
  padding: '10px 20px',
  height: 'auto',
  width: '100%',
  marginTop: '20px',
  boxShadow: '5px 6px 7px 0px rgba(0, 0, 0, 0.03)',
  [theme.breakpoints.only('xs')]: {
    marginTop: 0,
    width: 365
  }
}))

export const GridSuggestedDoctor = styled(GridWrapper)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '10px 20px',
  margin: '20px',
  marginLeft: '0px',
  backgroundColor: theme.palette.tertiary?.light,
  borderRadius: '3rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center'
}))

export const AvatarDoctor = styled(AvatarWrapper)(() => ({
  minWidth: '50px',
  minHeight: '50px',
  maxWidth: '60px',
  maxHeight: '60px'
}))
