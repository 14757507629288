import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import BookigForm from './components/BookingForm'
import BreadcrumbsBooking from './components/BreadcrumbsBooking'
import { ModalConfirmPhoneNumber } from './components/ModalConfirmPhoneNumber'
import { useBookingDate } from './hooks/useBookingDate'
import { GridBookingForm } from './styles'
import { List } from '../../../components/List/List'
import { AvatarBox } from '../../../components/StyledComponents/AvatarBox'
import { GridHalfScreen } from '../../../components/StyledComponents/GridHalfScreen'
import { BoxWrapper, GridWrapper, LoaderWrapper, TypographyWrapper } from '../../../components/wrapper'
import { ICalendarData } from '../../../infrastructure/dtos/CalendarInfo'
import { Resume } from '../../Home/Resume/ResumeComponent'
import { HomeBox } from '../../Home/styles'
import { MobileBackButton } from '../../Register/PatientMap/styles/RegisterAddressFormStyles'
import { AvailableHours } from '../../ScheduleAppointment/components/AvailableHours/AvailableHours'
import CalendarInfo from '../../ScheduleAppointment/components/CalendarInfo/CalendarInfo'
import DoctorInfo from '../../ScheduleAppointment/components/DoctorInfo'
import { OfficeList } from '../../ScheduleAppointment/components/OfficesList/OfficesList'
import { GridContent, GridLayoutRight } from '../../ScheduleAppointment/steps/SelectSchedule/SelectScheduleStyle'

function BookingDate(): JSX.Element {
  const {
    isExtraSmallSize,
    patient,
    appointmentId,
    handleGoBack,
    dataDoctor,
    handleChangeOffice,
    selectedOffice,
    doctorId,
    loadCalendar,
    showCalendar,
    handleChangeDate,
    selectedDate,
    daysAvailability,
    loadAvailableHours,
    showAvailableHours,
    availableHours,
    handleSelectedHour,
    selectedHour,
    isOptionDisabled,
    showForm,
    disabledButton,
    comments,
    reference,
    onSubmit,
    setComments,
    setReference,
    openModal,
    handleCloseModal,
    phoneVerification,
    setPhoneVerification,
    setIsSubmitted,
    globalError,
    branchId,
    idSpecialty,
    rol,
    doctorCM,
  } = useBookingDate()

  return (
    <BoxWrapper flexGrow={1} paddingY={'5px'} paddingX={!isExtraSmallSize ? '32px' : '15px'}>
      <GridContent container>
        {!isExtraSmallSize && (
          <GridWrapper item sm={12} md={4}>
            <HomeBox>
              <AvatarBox data-testid="avatar-profile" src={patient?.photo_patient} />
              <Resume />
            </HomeBox>
          </GridWrapper>
        )}
        <GridHalfScreen
          item
          sm={4}
          style={{
            marginTop: '1.2%',
          }}
        >
          {!isExtraSmallSize ? (
            <BreadcrumbsBooking isReschedule={Boolean(appointmentId)} />
          ) : (
            <MobileBackButton onClick={handleGoBack} sx={{ zIndex: 9999, top: '17px', position: 'fixed' }}>
              <ChevronLeftIcon />
            </MobileBackButton>
          )}

          <DoctorInfo
            message="Selecciona el lugar y fecha para tu cita con Dr. "
            info={dataDoctor}
            sx={{ width: '300px', fontSize: '1.18rem' }}
          />

          <GridWrapper
            sx={{
              margin: '30px 0',
              display: 'grid',
              width: !isExtraSmallSize ? '450px' : '100%',
            }}
          >
            <OfficeList
              handleChangeOffice={handleChangeOffice}
              office={selectedOffice}
              idDoctor={doctorId as string}
              idSpecialty={idSpecialty}
              rol={rol}
              branchId={branchId}
              doctorCM={doctorCM}
            />

            {globalError && (
              <TypographyWrapper sx={{ textAlign: 'left', color:'primary.dark' }}>
                {globalError}
              </TypographyWrapper>

            )}
          </GridWrapper>

          {loadCalendar && (
            <GridWrapper item display={'flex'} justifyContent={'center'}>
              <LoaderWrapper />
            </GridWrapper>
          )}
          {!loadCalendar && showCalendar && !globalError && (
            <CalendarInfo
              onChangeDate={handleChangeDate}
              selectedDate={selectedDate}
              daysAvailability={daysAvailability as ICalendarData}
            />
          )}
        </GridHalfScreen>

        <GridLayoutRight item xs={12} md={4} mt={isExtraSmallSize ? 3 : 20}>
          {loadAvailableHours && !globalError && (
            <GridWrapper item display={'flex'} justifyContent={'center'}>
              <LoaderWrapper />
            </GridWrapper>
          )}
          {!loadAvailableHours && showAvailableHours && !globalError && (
            <>
              {isExtraSmallSize ? (
                <>
                  <TypographyWrapper
                    sx={{
                      textAlign: 'center',
                      marginBottom: '30px',
                      fontSize: '18px',
                      padding: '0 50px',
                    }}
                  >
                    Selecciona la hora para tu cita
                  </TypographyWrapper>
                  <List
                    id="selectIdType"
                    lists={availableHours}
                    onChange={handleSelectedHour}
                    value={selectedHour}
                    minWidth="100%"
                  />
                </>
              ) : (
                <AvailableHours
                  availableHours={availableHours}
                  handleSelectedHour={(e) => handleSelectedHour((e.target as HTMLInputElement).value)}
                  selectedHour={selectedHour}
                  isOptionDisabled={isOptionDisabled}
                />
              )}
            </>
          )}
        </GridLayoutRight>
        <GridBookingForm item xs={12}>
          {!loadAvailableHours && showForm && !globalError && (
            <BookigForm
              disabledButton={disabledButton}
              comments={comments}
              reference={reference}
              disabledForm={Boolean(appointmentId)}
              onSubmit={onSubmit}
              setComments={setComments}
              setReference={setReference}
            />
          )}
        </GridBookingForm>
      </GridContent>
      {openModal && (
        <ModalConfirmPhoneNumber
          handleClose={handleCloseModal}
          open={openModal}
          phoneVerification={phoneVerification}
          setPhoneVerification={setPhoneVerification}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </BoxWrapper>
  )
}

export default BookingDate
