import Backdrop from '@mui/material/Backdrop'
import { JSX } from 'react'

import { FadeModal, BoxModal, GridModal, TypographyModal, ModalCancelReasons } from './CancelReasonsStyle'
import { useCancelReasons } from './hooks/useCancelReasons'
import { GridWrapper } from '../../../../../../../../components'
import FormTextInput from '../../../../../../../../components/FormInputText'
import { SecondaryMediumButton } from '../../../../../../../../components/SecondaryMediumButton/SecondaryMediumButton'
import SubmitButton from '../../../../../../../../components/SubmitButton'

interface IAppointment {
  appointment_id: string
  name_doctor: string
  last_name_doctor: string
  doctor_id?: string
  user_id?: string
  patient_id?: string
  reschedule_id: string
}

interface ICancelReasonsProps {
  handleClose: () => void
  open: boolean
  fetchAppointments: (userId: string) => Promise<void>
  appointment?: IAppointment
}
const MAX_LENGTH = 100

export const CancelReasons = ({
  handleClose,
  open,
  appointment,
  fetchAppointments,
}: ICancelReasonsProps): JSX.Element => {
  const { error, handleChangeOption, handleConfirm, isDisabled, isExtraSmallSize, optionsList, customOption, option } =
    useCancelReasons(handleClose, appointment, fetchAppointments)

  return (
    <ModalCancelReasons
      aria-labelledby="modal-cancel-reasons-title"
      aria-describedby="modal-cancel-reasons-description"
      open={open}
      role="dialog"
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <FadeModal in={open}>
        <BoxModal>
          <GridModal>
            <TypographyModal id="modal-cancel-reasons-title" variant="h6">
              Para mejorar queremos saber el motivo por el que cancelaste tu cita
            </TypographyModal>
            <GridWrapper container spacing={3} direction={'column'} py={5} id="modal-cancel-reasons-description">
              {optionsList.map((opt: string, index: number) => (
                <SecondaryMediumButton
                  key={index}
                  text={opt}
                  id={`cancel-option-${index}`}
                  data-testid={`cancel-option-${index}`}
                  disabled={Boolean(customOption)}
                  fullWidth
                  onClick={() => handleChangeOption(opt)}
                  type={'button'}
                  variant={option === opt ? 'contained' : 'outlined'}
                  sx={option === opt ? {} : { backgroundColor: 'background.paper' }}
                />
              ))}
              <GridWrapper
                display={'flex'}
                justifyContent={'center'}
                flexDirection={'column'}
                pt={'12px'}
                pl={'26px'}
                pr={'5px'}
              >
                <FormTextInput
                  id={`custom-cancel-option`}
                  type={'text'}
                  placeholder={'Escribe tu propio mensaje'}
                  fullWidth
                  inputProps={{ maxLength: MAX_LENGTH + 1 }}
                  name={'custom-message'}
                  value={customOption}
                  onChange={handleChangeOption}
                  error={error}
                  texterror={error ? `Máximo número de caracteres ${MAX_LENGTH}` : ''}
                />
              </GridWrapper>
            </GridWrapper>
            <GridWrapper display={'flex'} justifyContent={'center'} data-testid={'button-grid-wrapper'}>
              <SubmitButton
                data-testid={'send-cancel-reason'}
                id={'send-cancel-reason'}
                variant={'contained'}
                fullWidth={isExtraSmallSize ? true : false}
                type={'button'}
                text={'Enviar'}
                sx={{
                  width: isExtraSmallSize ? '-webkit-fill-available' : '180px',
                }}
                onClick={handleConfirm}
                disabled={(!Boolean(option) && !Boolean(customOption)) || error || isDisabled}
              />
            </GridWrapper>
          </GridModal>
        </BoxModal>
      </FadeModal>
    </ModalCancelReasons>
  )
}
