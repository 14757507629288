import { JSX } from 'react'

import { FormControlSelect } from './ListStyle'
import { SelectID } from '../../views/Register/PatientForm/PatientFormStyle'
import { TypographyWrapperLabel } from '../../views/ScheduleAppointment/components/OfficesList/OfficeListStyle'
import { LabelInputError } from '../StyledComponents/LabelInputError'
import { MenuItemWrapper } from '../wrapper'

interface IComponentProps {
  id: string
  value: string
  onChange: (value: string) => void
  lists: string[]
  minWidth: string
  error?: boolean
  textError?: string
  onBlur?: () => void
  text?: string
  disabled?: boolean
}

export const List = ({
  lists,
  id,
  value,
  onChange,
  minWidth,
  text,
  error,
  onBlur,
  textError,
  disabled,
}: IComponentProps): JSX.Element => {
  return (
    <>
      {text && (
        <TypographyWrapperLabel
          className="label"
          sx={{
            fontWeight: '400',
          }}
        >
          {text}
        </TypographyWrapperLabel>
      )}

      <FormControlSelect style={{ minWidth: minWidth }} data-testid={id} error={error}>
        <SelectID
          name={id}
          labelId={id}
          id={id}
          value={value}
          variant='outlined'
          onChange={(event) => onChange(event.target.value as string)}
          defaultValue=""
          onBlur={onBlur}
          data-testid={`${id}-select`}
          disabled={disabled}
        >
          {lists?.map((item: string, index: number) => {
            return (
              <MenuItemWrapper key={`specialist${index}`} value={item}>
                {item}
              </MenuItemWrapper>
            )
          })}
        </SelectID>
      </FormControlSelect>
      {error && <LabelInputError id={`${id}-error`}>{textError}</LabelInputError>}
    </>
  )
}
