import { useState, useEffect, useCallback, Dispatch, SetStateAction, useContext } from 'react'

import { Geolocation } from './Geolocation'
import { AccountContext, AuthContextType } from '../../../../contexts/AccountContext'
import { PlaceType, IAddressPatientAux } from '../../types'

interface ICoordinates {
  lat: number
  lng: number
}

const center: ICoordinates = {
  lat: -0.0102496,
  lng: -78.4464668,
}
interface IMapsHook {
  loadCoordinates: () => void
  onValidationFocus: (index: number) => void
  validateInput: (name: string) => boolean
  markerPosition: google.maps.LatLng | null
  setMarkerPosition: Dispatch<SetStateAction<google.maps.LatLng | null>>
  address: string
  setAddress: Dispatch<SetStateAction<string>>
  setMapCenter: Dispatch<SetStateAction<ICoordinates>>
  setSelected: Dispatch<SetStateAction<boolean>>
  selected: boolean
  setZoom: Dispatch<SetStateAction<number>>
  zoom: number
  mapCenter: ICoordinates
  optionsList: Array<string>
  nameLocation: string
  setNameLocation: Dispatch<SetStateAction<string>>
  ValidationFocus: boolean[]
  newAddressPatient: () => void
  eliminar: (value: IAddressPatientAux) => void
  modificar: (value: IAddressPatientAux) => void
  // editar: (value: any, valor: string) => void
  addressEdition: (id: number, valor: PlaceType | null) => void
  value: PlaceType | null
  setValue: Dispatch<SetStateAction<PlaceType | null>>
  setOptionsList: Dispatch<SetStateAction<string[]>>
  disable: boolean
  guardar: (value: IAddressPatientAux) => void
}
interface IMapsProps {
  addressPatient: IAddressPatientAux[]
  setAddressPatient: Dispatch<SetStateAction<IAddressPatientAux[]>>
}

export function useMaps({ addressPatient, setAddressPatient }: IMapsProps): IMapsHook {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { coordinates, geolocationPosition } = Geolocation(handleAlert)
  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLng | null>(null)
  const [zoom, setZoom] = useState(5) // initial zoom
  const [address, setAddress] = useState<string>('')
  const [selected, setSelected] = useState<boolean>(false)
  const [mapCenter, setMapCenter] = useState<ICoordinates>(center)
  const [nameLocation, setNameLocation] = useState<string>('')
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([false])
  const [actionButton, setactionButton] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const [optionsList, setOptionsList] = useState<string[]>(['Casa', 'Oficina', 'Otra'])
  const [value, setValue] = useState<PlaceType | null>(null)

  const loadCoordinates = (): void => {
    geolocationPosition()
    setactionButton(true)
  }
  const geolocationPositionCenter = (): void => {
    const lat: number = coordinates.latitud
    const lng: number = coordinates.longitud
    setMapCenter({
      lat: lat,
      lng: lng,
    })
    setZoom(18)
    const latLng: google.maps.LatLng = new google.maps.LatLng(lat, lng)
    getAddressFromLatLng(latLng)
    setMarkerPosition(latLng)
    setSelected(true)
  }
  useEffect(() => {
    if (actionButton) {
      geolocationPositionCenter()
      setactionButton(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates])

  const eliminar = (value: IAddressPatientAux): void => {
    const newList: IAddressPatientAux[] = addressPatient.filter((item: IAddressPatientAux) => item.id !== value.id)
    setAddressPatient(newList)
    if (!optionsList.includes(value.name)) {
      setOptionsList([value.name, ...optionsList])
    }
  }
  const modificar = (value: IAddressPatientAux): void => {
    setDisable(true)
    const newList: IAddressPatientAux[] = addressPatient.map((item: IAddressPatientAux) => {
      if (item === value) {
        const updatedItem: IAddressPatientAux = {
          ...item,
          coordinates: {
            latitude: markerPosition?.lat()?.toString() as string,
            longitude: markerPosition?.lng()?.toString() as string,
          },
          state: !item.state,
        }

        return updatedItem
      }

      return item
    })

    setAddressPatient(newList)
  }

  const guardar = (value: IAddressPatientAux): void => {
    setDisable(false)
    const newList: IAddressPatientAux[] = addressPatient.map((item: IAddressPatientAux) => {
      if (item.id === value.id) {
        const updatedItem: IAddressPatientAux = {
          ...item,
          coordinates: {
            latitude: markerPosition?.lat()?.toString() as string,
            longitude: markerPosition?.lng()?.toString() as string,
          },
          state: !item.state,
        }

        return updatedItem
      }

      return item
    })

    setAddressPatient(newList)
  }

  const addressEdition = (id: number, valor: PlaceType | null): void => {
    const newList = addressPatient.map((item: IAddressPatientAux) => {
      if (item.id === id) {
        const updatedItem = {
          ...item,
          value: valor,
          address: valor?.description || '',
        }

        return updatedItem
      }

      return item
    })

    setAddressPatient(newList)
  }

  // const editar = (value: any, valor: string): void => {
  //   const newList = addressPatient.map((item: IAddressPatientAux) => {
  //     if (item === value) {
  //       const updatedItem = {
  //         ...item,
  //         name: valor,
  //       }

  //       return updatedItem
  //     }

  //     return item
  //   })

  //   setAddressPatient(newList)
  // }

  const newAddressPatient = (): void => {
    let lat = 0
    let lng = 0
    if (markerPosition?.lat() !== undefined && markerPosition?.lng() !== undefined) {
      lat = markerPosition?.lat()
      lng = markerPosition?.lng()
    }
    const newOfficeMedical: IAddressPatientAux = {
      id_location: '',
      address: address,
      name: nameLocation,
      coordinates: {
        latitude: lat.toString(),
        longitude: lng.toString(),
      },
      state: false,
      id: addressPatient.length + 1,
      value: value,
    }
    setAddressPatient((prevAddressPatient) => [...prevAddressPatient, newOfficeMedical])

    if (nameLocation !== 'Otra' || addressPatient.filter((a) => a.name === 'Otra').length >= 2) {
      const newOptionsList: string[] = optionsList
      const optionIndex: number = newOptionsList.indexOf(nameLocation)
      newOptionsList.splice(optionIndex, 1)
      setOptionsList(newOptionsList)
    }

    setNameLocation('')
    setValidationFocus([false])
    setAddress('')
    setValue(null)
  }

  const validateInput = (name: string): boolean => {
    const regex = /^[a-zA-Z0-9]+$/
    return regex.test(name)
  }

  const getAddressFromLatLng = useCallback(
    async (latLng: google.maps.LatLng): Promise<void> => {
      try {
        const geocoder: google.maps.Geocoder = new google.maps.Geocoder()
        await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
          geocoder.geocode(
            { location: latLng },
            function (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) {
              if (status === 'OK') {
                setAddress(results === null ? '' : results[0].formatted_address)
              } else {
                reject(new Error(`Couldnt't find the location ${address}`))
              }
            },
          )
        })
      } catch (error) {
        handleAlert(true, 'Error al obtener la dirección', 'error')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAddress],
  )

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  return {
    setZoom,
    zoom,
    loadCoordinates,
    onValidationFocus,
    validateInput,
    markerPosition,
    address,
    selected,
    setAddress,
    setMapCenter,
    setMarkerPosition,
    setSelected,
    mapCenter,
    nameLocation,
    setNameLocation,
    ValidationFocus,
    // editar,
    eliminar,
    modificar,
    newAddressPatient,
    optionsList,
    addressEdition,
    value,
    setValue,
    setOptionsList,
    disable,
    guardar,
  }
}
