import { useMediaQuery, useTheme } from '@mui/material'
import { JSX } from 'react'

import {
  ActionsButton,
  AddButton,
  DeleteIconPrescription,
  EditIconPrescription,
  StackButtons,
  TextCard,
} from './styles'
import { BoxWrapper, GridWrapper, TypographyWrapper } from '../../../../components/wrapper'
import { Severity } from '../../../../contexts/AuthReducer'
import { DeletedLocationUtility } from '../../../../services/Contracts/Utility/LocationUtility'
import { BoxPendingsStyled } from '../../../Home/Pendings/PendingsComponentStyles'
import { IAddressPatient, IAddressPatientAux, IPatientInfo } from '../../../Register/types'

interface IAddress {
  address: IPatientInfo
  savePatientData: (patient: IPatientInfo) => void
  toggleDrawer: (open: boolean) => void
  handleAlert: (open: boolean, message: string, severity?: Severity | undefined) => void
  onClickEdit: (index: number) => void
}

function Addresses({ address, savePatientData, handleAlert, toggleDrawer, onClickEdit }: IAddress): JSX.Element {
  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const handleDelete = async (idLocation: string, index: number): Promise<void> => {
    try {
      const { data, status } = await DeletedLocationUtility({
        id_location: idLocation,
        user_id: address?.userId as string,
      })
      if (status === 1) {
        const newAddress: IAddressPatientAux[] = structuredClone(address.addresses)
        newAddress.splice(index, 1)
        savePatientData({ ...address, addresses: newAddress })
        handleAlert(true, data, 'success')
      }
    } catch (error) {
      handleAlert(true, 'Error al eliminar dirección', 'error')
    }
  }

  const isOneAddress: boolean = address.addresses.length <= 1

  return (
    <>
      {address.addresses.length > 0 &&
        address.addresses.map((item: IAddressPatient, index: number) => (
          <BoxWrapper sx={{ width: 1, marginTop: 2 }} data-testid="addresses" key={`address-${index}`}>
            <BoxWrapper key={index} display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={3}>
              <BoxPendingsStyled>
                <TextCard display={'flex'}>
                  <TypographyWrapper>{item.name}</TypographyWrapper>
                  <TypographyWrapper>{item.address}</TypographyWrapper>
                </TextCard>
                <StackButtons
                  direction={isExtraSmallSize ? 'column' : 'row'}
                  spacing={isExtraSmallSize ? 2 : 8}
                  justifyContent={'flex-end'}
                >
                  <ActionsButton id="edit" onClick={() => onClickEdit(index)}>
                    <EditIconPrescription />
                    <TypographyWrapper>Modificar</TypographyWrapper>
                  </ActionsButton>
                  {!isOneAddress && (
                    <ActionsButton
                      id="delete"
                      onClick={() => handleDelete(item.id_location, index)}
                      disabled={isOneAddress}
                    >
                      <DeleteIconPrescription />
                      <TypographyWrapper>Eliminar</TypographyWrapper>
                    </ActionsButton>
                  )}
                </StackButtons>
              </BoxPendingsStyled>
            </BoxWrapper>
          </BoxWrapper>
        ))}
      <GridWrapper
        sx={{
          display: 'flex',
          alignSelf: isExtraSmallSize ? 'center' : 'end',
          margin: '26px 0',
        }}
      >
        <AddButton
          id="address-button"
          type="button"
          onClick={() => toggleDrawer(true)}
          disabled={address.addresses.length >= 5}
        >
          Agregar otra
        </AddButton>
      </GridWrapper>
    </>
  )
}

export default Addresses
