import { JSX } from 'react'

import { CarouselFunction, IRecommendedDoctorsProps } from './components/Carousel'
import { GridWrapper } from '../../../../../../../components'

export function RecommendedDoctors({
  doctors,
  getDoctorInfo,
  handleQualifications,
  handleAlert,
}: IRecommendedDoctorsProps): JSX.Element {
  return (
    <GridWrapper
      item
      maxWidth={'460px'}
      data-testid="carousel-root"
      sx={{
        '& .carousel-root > .carousel .slider-wrapper.axis-horizontal .slider': {
          justifyContent: doctors.length > 3 ? 'start' : 'center',
        },
      }}
    >
      <CarouselFunction
        doctors={doctors}
        getDoctorInfo={getDoctorInfo}
        handleQualifications={handleQualifications}
        handleAlert={handleAlert}
      />
    </GridWrapper>
  )
}
