import VerifyTemporaryPhoneRepository from '../../domain/repository/VerifiyTemporaryPhoneRepository'
import {
  IApiResponsePhoneVerification,
  IPhoneVerification,
  IVerifyTemporaryPhoneParams,
} from '../dtos/PhoneVerification'
import VerifyTemporaryPhoneDataSource from '../http/dataSource/VerifyTemporaryPhoneDataSource'

export class VerifyTemporaryPhoneRepositoryImpl implements VerifyTemporaryPhoneRepository {
  dataSource: VerifyTemporaryPhoneDataSource

  constructor(private readonly _dataSource: VerifyTemporaryPhoneDataSource) {
    this.dataSource = _dataSource
  }

  async verifyTemporaryPhone(
    params: IVerifyTemporaryPhoneParams,
  ): Promise<IApiResponsePhoneVerification<IPhoneVerification>> {
    return await this.dataSource.verifyTemporaryPhone(params)
  }
}
