import { styled } from '@mui/material'

import TypographyWrapper from '../wrapper/TypographyWrapper'

export const LabelInputUserInfo = styled(TypographyWrapper)(({ theme }) => ({
  color: theme.palette.tertiary?.dark,
  fontFamily: 'Helvetica',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  marginLeft: '1rem'
}))
