import { ReactElement, useContext, useState } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'

import { AccountContext, AuthContextType } from './contexts/AccountContext'
import { AuthLayout } from './layouts'
import AppLayout from './layouts/AppLayout'
import { useSession } from './services/Session/sessionHook'
import AuthPage from './views/Auth'
import { Status } from './views/Auth/Status'
import { ConfirmAppointment } from './views/ConfirmAppointment/ConfirmAppointment'
import Home from './views/Home'
import BookingDate from './views/pages/BookingDate'
import Dates from './views/pages/Dates/Dates'
import SearchDoctor from './views/pages/SearchDoctor'
import Profile from './views/Profile/Profile'
import { PatientRegister } from './views/Register/'
import { ScheduleAppointment } from './views/ScheduleAppointment'

export const AppRouter = (): ReactElement | null => {
  const [loading] = useState<boolean>(false)
  const { patient } = useContext(AccountContext) as AuthContextType
  const { isAuthenticated } = useSession()

  const commonRoutes: RouteObject[] = [
    {
      path: 'medical-consultation',
      element: <AuthLayout />,
      children: [
        {
          path: ':idDoctor',
          element: <ScheduleAppointment />,
        },
      ],
    },
    {
      path: 'confirm-appointment',
      element: <AuthLayout />,
      children: [
        {
          path: ':appointmentId/:rescheduleId',
          element: <ConfirmAppointment />,
        },
      ],
    },
  ]

  const AuthRoutes: RouteObject[] = [
    {
      path: 'register',
      element: <AuthLayout />,
      children: [{ path: '', element: <PatientRegister /> }],
    },
    {
      path: 'appointment',
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <SearchDoctor />,
        },
        {
          path: ':booking/:doctorId/:appointmentId?',
          element: <BookingDate />,
        },
        {
          path: '*',
          element: <Navigate to="/appointment" replace />,
        },
      ],
    },
    {
      path: 'prescription',
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <Navigate to="/" replace />,
        },
      ],
    },
    {
      path: 'profile',
      element: <AppLayout />,
      children: [
        {
          index: true,
          element: <Profile />,
        },
      ],
    },
    {
      path: '/',
      element: patient?.name !== '' ? <AppLayout /> : <></>,
      children: [
        {
          index: true,
          element: patient?.name !== '' ? <Home /> : <></>,
        },
        {
          path: 'dates',
          element: <Dates />,
        },
      ],
    },
    { path: 'status', element: <Status /> },
    ...commonRoutes,
    {
      path: '/*',
      element: <Navigate to="/" replace />,
    },
  ]

  const publicRoutes: RouteObject[] = [
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: <AuthPage />,
        },
      ],
    },
    ...commonRoutes,
    {
      path: '/*',
      element: <Navigate to="/" replace />,
    },
  ]

  const loadingRouters: RouteObject[] = [
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          index: true,
          element: <h1>Cargando...</h1>,
        },
      ],
    },
    ...commonRoutes,
    {
      path: '/*',
      element: <Navigate to="/" replace />,
    },
  ]

  return useRoutes(loading ? loadingRouters : !isAuthenticated ? publicRoutes : AuthRoutes)
}
