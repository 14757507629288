import { AxiosError } from 'axios'

import { IResponseApi } from '../../dtos'
import { IPatientActivitiesInfo } from '../../dtos/PatientActivitiesInfo'
import PatientActivitiesInfoDataSource from '../dataSource/PatientActivitiesDataSource'
import { http } from '../Http'

export default class PatientActivitiesInfoMapper implements PatientActivitiesInfoDataSource {
  async getPatientActivitiesInfo(user_id: string): Promise<IResponseApi<IPatientActivitiesInfo>> {
    try {
      const { data } = await http.get<IResponseApi<IPatientActivitiesInfo>>(
        `/send_data/patient_activities_info/${user_id}`,
      )
      return data
    } catch (error: unknown) {
      return {
        statusCode: (error as AxiosError).response?.status as number,
        body: {
          number_appointments: 0,
          message: (error as AxiosError).response?.statusText as string,
          number_qualification: 0,
          doctors: 0,
          name_user: '',
          old_years: '',
          speciality: 0,
        },
        headers: {
          'Access-Control-Allow-Headers': '',
          'Access-Control-Allow-Origin': '',
          'Access-Control-Allow-Methods': '',
        },
      }
    }
  }
}
