import { useMediaQuery, useTheme } from '@mui/material'
import React, { ReactNode, JSX } from 'react'

import { StackFullScreen } from '../StyledComponents/StackFullScreen'

interface IGridFullScreenProps {
  children: ReactNode
  id: string
  container?: boolean
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
}

export const GridFullScreen = ({ children, id, direction = 'row' }: IGridFullScreenProps): JSX.Element => {
  const theme = useTheme()
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <StackFullScreen spacing={isXsScreen ? 0 : 4} direction={direction} id={id}>
      {children}
    </StackFullScreen>
  )
}
