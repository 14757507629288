import { InsertDriveFileOutlined } from '@mui/icons-material'
import { Box, SxProps, useMediaQuery, useTheme } from '@mui/material'
import { ChangeEvent, createRef, JSX, RefObject } from 'react'

import { TextProfile } from './UploadPhotoStyles'
import { SecondaryMediumButton } from '../SecondaryMediumButton/SecondaryMediumButton'
import { AvatarProfile } from '../StyledComponents/AvatarProfile'
import { GridWrapper, StackWrapper, TooltipWrapper } from '../wrapper'

interface UploadPhotoProps {
  id: string
  selectedImage: string
  title?: string
  tooltipText: string
  buttonText: string
  buttonType?: 'button' | 'reset' | 'submit' | undefined
  xs?: number
  sx?: SxProps
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const UploadPhoto = ({
  id,
  selectedImage,
  title = '',
  tooltipText,
  buttonText,
  buttonType,
  xs,
  sx,
  onChange,
}: UploadPhotoProps): JSX.Element => {
  const inputRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>()
  const handleClick = (): void => {
    inputRef.current?.click()
  }

  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <GridWrapper item xs={xs} sx={sx}>
      <StackWrapper
        spacing={2}
        position="relative"
        direction={isExtraSmallSize ? 'row' : 'column'}
        alignItems="center"
        justifyContent="center"
      >
        <AvatarProfile src={selectedImage} role="img" id={'profileImage'} />
        <GridWrapper>
          <TextProfile sx={{ mb: 1, textAlign: 'center' }}>{title}</TextProfile>
          <TooltipWrapper title={tooltipText} placement="top">
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <SecondaryMediumButton
                id={id}
                text={buttonText}
                type={buttonType}
                onClick={handleClick}
                sx={{ width: '100%' }}
                startIcon={<InsertDriveFileOutlined />}
              >
                <input hidden accept="image/*" multiple type="file" onChange={onChange} ref={inputRef} />
              </SecondaryMediumButton>
            </Box>
          </TooltipWrapper>
        </GridWrapper>
      </StackWrapper>
    </GridWrapper>
  )
}

export default UploadPhoto
