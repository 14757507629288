import { debounce, useMediaQuery, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState, JSX, useContext } from 'react'

import BirthDate from './components/BirthDate/BirthDate'
import containerText from './PatientForm.json'
import { useValidationComponent } from './PatientFormHook'
import { FormRegister, GridFormRegister, InputsGrid, SecondInputGrid } from './PatientFormStyle'
import FormTextInput from '../../../components/FormInputText'
import { GridFullScreen } from '../../../components/GridFullScreen/GridFullScreen'
import { List } from '../../../components/List/List'
import SelectButtonList from '../../../components/SelectButtonList/SelectButtonList'
import { Title } from '../../../components/StyledComponents/Title'
import SubmitButton from '../../../components/SubmitButton'
import { GridWrapper } from '../../../components/wrapper'
import { AccountContext, AuthContextType } from '../../../contexts/AccountContext'
import { BodyGenders } from '../../../infrastructure/dtos/Genders'
import { GendersUtility } from '../../../services/Contracts/Utility/GendersUtility'
import { getValidationMessage, validateField, validateId } from '../../../utils/requiredField'
import { validateAndDisplayPhone, validatePhoneNumber } from '../../../utils/validatePhoneNumber'
import { IPatientForm, IPatientInfo } from '../types'

interface PatientFormProps {
  onNext: () => void
  handlePatientInfo: (patientInfo: IPatientForm) => void
  patientInfo: IPatientInfo
}

const DocumentType: { [key: string]: string } = {
  PASSPORT: 'Pasaporte',
  CI: 'Cédula',
}

const idTypes: string[] = ['Cédula', 'Pasaporte']

const PatientForm = ({ onNext, handlePatientInfo, patientInfo }: PatientFormProps): JSX.Element => {
  const { handleAlert } = useContext(AccountContext) as AuthContextType
  const { fetchValidationIdDocument, validatorIdDocument } = useValidationComponent()
  const [name, setName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [mobile, setMobile] = useState<string>('')
  const [idNumber, setIdNumber] = useState<string>('')
  const [ValidationFocus, setValidationFocus] = useState<boolean[]>([false, false, false, false])

  const [idType, setIdType] = useState<string>('Cédula')
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null)

  const [list, setList] = useState<string[]>([])
  const [selectedGender, setSelectedGender] = useState<string>('')

  const theme = useTheme()
  const isExtraSmallSize = useMediaQuery(theme.breakpoints.only('xs'))

  const searchDelayed = useMemo(() => debounce(fetchValidationIdDocument, 1000), [])
  const fetchGenders = async (): Promise<void> => {
    try {
      const { data } = await GendersUtility()
      if ((data as BodyGenders)?.data) {
        setList((data as BodyGenders)?.data as string[])
      }
    } catch (error) {
      handleAlert(true, 'Error al obtener listado de géneros', 'error')
    }
  }

  useEffect(() => {
    setName(patientInfo.name)
    setLastName(patientInfo.lastName)
    setMobile(patientInfo.mobile)
    setIdNumber(patientInfo.idNumber)
    setSelectedGender(patientInfo.gender)
    setSelectedDate(dayjs(patientInfo.birthdate))
    setIdType(
      Object.keys(DocumentType).includes(patientInfo.document_type)
        ? DocumentType[patientInfo.document_type]
        : 'Cédula',
    )

    fetchGenders()
  }, [])

  useEffect(() => {
    const document_type: 'CI' | 'PASSPORT' = idType === 'Cédula' ? 'CI' : 'PASSPORT'
    if ((document_type === 'CI' && idNumber?.length === 10) || (document_type === 'PASSPORT' && idNumber?.length > 2)) {
      searchDelayed({
        document: idNumber,
        document_type: document_type,
        type: 'REGISTER',
      })
    }
  }, [idNumber])

  const textErrorIdNumber = (): string => {
    if (!validateId(idType, validateIdNumberPassport, validateIdNumberCI)) {
      return validatorIdDocument.message
    }
    return validateId(idType, validateIdNumberPassport, validateIdNumberCI)
  }

  const onValidationFocus = (index: number): void => {
    const newValidationFocus: boolean[] = [...ValidationFocus]
    newValidationFocus[index] = true
    setValidationFocus(newValidationFocus)
  }

  const handleChangeDate = (value: dayjs.Dayjs | null): void => setSelectedDate(value)

  const onSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()

    handlePatientInfo({
      name: name,
      lastName: lastName,
      idNumber: idNumber,
      mobile: mobile,
      birthdate: selectedDate?.format('YYYY-MM-DD') as string,
      gender: selectedGender,
      document_type: idType,
    })
    onNext()
  }

  const validateName: boolean = validateField(name, ValidationFocus[0])
  const validateLastName: boolean = validateField(lastName, ValidationFocus[1])

  const validateIdNumberPassport: boolean = !(idNumber?.length >= 3) && ValidationFocus[2]

  const validateIdNumberCI: boolean = !(idNumber?.length === 10 && /^\d*$/.test(idNumber)) && ValidationFocus[2]

  const validateIdNumber: boolean = idType === 'Pasaporte' ? validateIdNumberPassport : validateIdNumberCI

  const validateMobile: boolean = validateField(mobile, ValidationFocus[3])

  const submitValidation = (): boolean => {
    if (!validatePhoneNumber(mobile)) {
      return true
    }
    if (
      validatorIdDocument.status ||
      name?.length === 0 ||
      lastName?.length === 0 ||
      idNumber?.length === 0 ||
      mobile?.length === 0 ||
      idType?.length === 0 ||
      selectedDate === null ||
      !selectedDate.isValid() ||
      selectedGender?.length === 0 ||
      validateIdNumber ||
      validateMobile
    ) {
      return true
    }

    return false
  }

  const isDisabled: boolean = submitValidation()

  return (
    <GridFullScreen id="PatientRegistry">
      <GridFormRegister container flexDirection={'column'}>
        <Title variant="h5">{containerText.title}</Title>
        <FormRegister data-testid="patient-form" onSubmit={onSubmit}>
          <InputsGrid item xs={12}>
            <GridWrapper item xs={12} md={6}>
              <List
                id="selectIdType"
                lists={idTypes}
                onChange={(event: string) => setIdType(event)}
                value={idType}
                minWidth="100%"
                text={containerText.id_type}
              />
            </GridWrapper>
            <SecondInputGrid item xs={12} md={6}>
              <FormTextInput
                text={containerText.set_id_number}
                placeholder={containerText.set_id_number_placeholder}
                id="idNumber"
                name="idNumber"
                type="text"
                fullWidth
                value={idNumber}
                onBlur={() => onValidationFocus(2)}
                onChange={(event) => setIdNumber(event.target.value)}
                error={validateIdNumber || validatorIdDocument.status}
                texterror={textErrorIdNumber()}
              />
            </SecondInputGrid>
          </InputsGrid>
          <InputsGrid item xs={12}>
            <GridWrapper item xs={12} md={6}>
              <FormTextInput
                text={containerText.whats_your_name}
                placeholder={containerText.whats_your_name_placeholder}
                id="name"
                type="text"
                fullWidth
                value={name}
                name="name"
                onBlur={() => onValidationFocus(0)}
                onChange={(event) => setName(event.target.value)}
                error={validateName}
                texterror={getValidationMessage(validateName)}
              />
            </GridWrapper>

            <SecondInputGrid item xs={12} md={6}>
              <FormTextInput
                text={containerText.whats_your_last_name}
                placeholder={containerText.whats_your_last_name_placeholder}
                id="lastName"
                name="lastName"
                type="text"
                fullWidth
                value={lastName}
                onBlur={() => onValidationFocus(1)}
                onChange={(event) => setLastName(event.target.value)}
                error={validateLastName}
                texterror={getValidationMessage(validateLastName)}
              />
            </SecondInputGrid>
          </InputsGrid>

          <InputsGrid item xs={12}>
            <GridWrapper item xs={12} md={6}>
              <FormTextInput
                text={containerText.phone}
                placeholder={containerText.whats_your_phone_planceholder}
                id="mobile"
                type="tel"
                fullWidth
                value={mobile}
                name="mobile"
                onBlur={() => onValidationFocus(3)}
                onChange={(event) => setMobile(event.target.value)}
                error={validateMobile}
                texterror={getValidationMessage(validateMobile)}
              />
              {validateAndDisplayPhone(mobile, containerText.phone_valid_message)}
            </GridWrapper>
            <SecondInputGrid item xs={12} md={6}>
              <BirthDate
                handleChangeDate={handleChangeDate}
                selectedDate={selectedDate}
                text={containerText.birthday}
              />
            </SecondInputGrid>
          </InputsGrid>

          <GridWrapper item xs={12} sx={{ marginTop: '2em' }}>
            <GridWrapper item xs={12}>
              {!isExtraSmallSize ? (
                <SelectButtonList
                  fullWidth
                  amountRanges={list}
                  text={containerText.gender}
                  onAmountRangeChange={(value: string) => setSelectedGender(value)}
                  selectedAmountRange={selectedGender}
                />
              ) : (
                <List
                  id="selectIdType"
                  lists={list}
                  onChange={(event: string) => setSelectedGender(event)}
                  value={selectedGender}
                  minWidth="100%"
                  text={containerText.gender}
                />
              )}
            </GridWrapper>
          </GridWrapper>

          <GridWrapper item xs={12} sx={{ my: 2, marginTop: '4rem' }}>
            <SubmitButton
              data-testid="ok-button"
              id="button"
              type="submit"
              variant="contained"
              fullWidth
              text={containerText.next_button}
              disabled={isDisabled}
            />
          </GridWrapper>
        </FormRegister>
      </GridFormRegister>
    </GridFullScreen>
  )
}

export default PatientForm
