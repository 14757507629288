import { AxiosError, AxiosResponse } from 'axios'

import {
  QualificationDoctor,
  QualificationDoctorResponse
} from '../../dtos/SaveQualifications'
import SaveQualificationDataSource from '../dataSource/SaveQualificationDataSource'
import { http } from '../Http'

export default class SaveQualifiactionMapper
  implements SaveQualificationDataSource
{
  async saveQuestions(
    dataQuestions: QualificationDoctor
  ): Promise<QualificationDoctorResponse> {
    const data = await http
      .post<QualificationDoctorResponse>(`save_data/qualification_doctor`, {
        body: dataQuestions
      })
      .then((response: AxiosResponse) => {
        const { data } = response
        return data
      })
      .catch((error: AxiosError) => {
        const data: QualificationDoctorResponse = {
          statusCode: error.response?.status as number,
          body: error.response?.statusText as string,
          headers: {
            'Access-Control-Allow-Headers': '',
            'Access-Control-Allow-Origin': '',
            'Access-Control-Allow-Methods': ''
          }
        }
        return data
      })
    return data
  }
}
