import { PostNewDate } from '../../../domain/useCase/NewDateUseCase/NewDateUseCase'
import { IApiResponseSaveNewDate, SaveNewDate } from '../../../infrastructure/dtos/NewDate'
import NewDateMapper from '../../../infrastructure/http/mappers/NewDateMapper'
import { NewDateRepositoryImpl } from '../../../infrastructure/RepositoryImpl/NewDateRepositoryImpl/NewDateRepositoryImpl'

export interface ISaveQualificationService {
  status: number
  data: IApiResponseSaveNewDate | string
}

export async function NewDateService(dataNewDate: SaveNewDate): Promise<ISaveQualificationService> {
  try {
    const UseCaseApi: PostNewDate = new PostNewDate(new NewDateRepositoryImpl(new NewDateMapper()))
    return {
      status: 1,
      data: await UseCaseApi.invoke(dataNewDate),
    }
  } catch (error: unknown) {
    return {
      status: 0,
      data: (error as Error).message,
    }
  }
}
