import { PatientInfoRepository } from '../../../domain/repository/PatientRepository'
import {
  DataPatient,
  IApiResponsePatientInfo,
  PatientBody,
  PatientUpdateResponseBody
} from '../../dtos/Patient'
import PatientInfoDataSource from '../../http/dataSource/PatientDataSource'

export class PatientInfoRepositoryImpl implements PatientInfoRepository {
  dataSource: PatientInfoDataSource

  constructor(_dataSource: PatientInfoDataSource) {
    this.dataSource = _dataSource
  }

  getPatientInfoByCi(ciPatient: string): Promise<DataPatient> {
    return this.dataSource.getPatientInfoByCi(ciPatient)
  }

  async getPatientInfo(idPatient: string): Promise<IApiResponsePatientInfo> {
    return this.dataSource.getPatientInfo(idPatient)
  }

  async updatePatientInfo(
    params: PatientBody,
    idPatient: string
  ): Promise<PatientUpdateResponseBody> {
    return this.dataSource.updatePatientInfo(params, idPatient)
  }
}
