import { styled } from '@mui/material/styles'

import { BoxWrapper } from '../../../components'

export const BoxResumeStyled = styled(BoxWrapper)(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '10px 0px',
  background: 'white',
  borderRadius: '21px',
  paddingLeft: '20px',
  paddingRight: '20px',
  gridColumn: 'span 12',
  minHeight: '35px',
}))
