import Dialog, { DialogProps } from '@mui/material/Dialog'
import React, { JSX } from 'react'

interface IProps {
  children: JSX.Element[]
}

const DialogWrapper: React.FC<IProps & DialogProps> = ({ children, ...props }) => <Dialog {...props}>{children}</Dialog>

export default DialogWrapper
